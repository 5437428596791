
// import aclCondition from '../../utils/acl-condition'
import store from '../../store'

export default async (to: any, from: any, next: any) => {
  // if (aclCondition(store.state.acl.wolf)) {
  //   await store.dispatch('acl/getAcl')
  // }
  let product: string
  if (to.query.product) {
    product = to.query.product
  }
  if (store.state.session && store.state.session.user && store.state.session.token) {
    store.dispatch('session/isValid')
      .then((res: any) => {
        if (res) {
          store.dispatch('session/findHost', product)
            .then((res: any) => {
              const token = store.state.session.token
              if (res && res.url) {
                location.replace(`${res.url}/auth/sso?ssoToken=${token}`)
              } else {
                next('/auth/sign-in')
              }
            })
        } else {
          store.dispatch('session/signOut')
            .then((res: any) => {
              next(`/auth/sign-in?product=${to.query.product}`)
            })
        }
      })
  } else {
    next(`/auth/sign-in?product=${to.query.product}`)
  }
}
