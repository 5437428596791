
export default {
  add_employee: 'Add employee',
  employee_fields: 'Employee data',
  birthdate: 'Date of birth',
  personal_data: 'Personal data',
  employment_data: 'Employment data',
  created_employee: 'Employee created successfully',
  import_employees: 'Import employee',
  download_template: 'Download template',
  download_docs: 'Download instructions',
  upload_description: 'Upload an excel (.xls/.xlsx) or csv (.csv) file containing your employees\' data. This action will update your total collaborator base, so you need to add the entire base of available employees.',
  select_file_to_upload: 'Select file to upload',
  select_file: 'Select file',
  summary: 'Process summary',
  fix_errors: 'Fix errors',
  total_unchanged: '{total} collaborators will remain active.',
  total_updated: '{total} collaborators will be updated.',
  total_inserted: '{total} collaborators will be added.',
  total_deleted: '{total} collaborators will be deactivated.',
  total_failed: 'Found {total} failed records.',
  file_updated: 'File updated',
  save_changes: 'Save changes',
  error_fix: 'Fix errors',
  if_any: 'If any',
  process_cancelled: 'Process canceled',
  massive_success: 'Massive upload executed successfully.',
  massive_upload: 'Massive upload',
  download_log: 'Download logs',
  id: 'Id',
  name: 'Name',
  last_name: 'last name',
  city: 'City',
  email: 'Email',
  action: 'Action',
  employment: 'Job',
  employees: 'Employees',
  list_employees: 'Employee list',
  look_for: 'Find employee',
  filter_by: 'Sort by...',
  no_data: 'No employees to display',
  modal_enable: 'Do you want to enable the collaborator?',
  modal_disable: 'Do you want to disable the collaborator?',
  modal_action: 'Execute',
  status: 'Status',
  edit_employee: 'Edit employee',
  enable_title: 'Enable employee',
  disable_title: 'Disable employee',
  enable_text: 'Are you sure you want to enable this employee? After this, the collaborator will be able to enter the platform and take the assessments',
  disable_text: 'Are you sure you want to disable this employee? After this, the collaborator will not be able to enter the platform or participate in assessments. This action does not affect the assessments in which he/she has previously participated.',
  employment_at: '{title} at',
  updated_employee: 'Employee updated successfuly',
  detected_errors: 'Errors detected:',
  massive_load_in_progress: 'You have a massive collaborator upload process in progress. During this process you cannot make changes or add new collaborators manually. If you wish you can go to the bulk upload option to complete or cancel the active process',
  massive_upload_recomendations: 'The purpose of this section is to carry out the massive upload of the collaborators that are going to be assessed to prevent errors in the upload of user data. Please keep in mind:',
  massive_upload_recomendations_items: [
    'Before carrying out the massive load you must create all the demographic cuts that the collaborators are going to have.',
    'Click on the download template button to download the file where you are going to list the company\'s collaborators.',
    'All columns in the file are required except email.',
    'Collaborators with the same identification number or with the same email are not allowed.',
    'The file you will upload is the database of employees to perform the assessment. Please include all employees who will participate in the assessment. After launching the assessment, collaborators cannot be added, edited or removed.',
    'The platform is sensitive to capital letters, spaces and accents. Please fill in each demographic data exactly as you have created it above.',
    'Remember that any changes made apply only to future assessments.',
    'This process is also used to update the employee database that you have previously uploaded.'
  ],
  massive_upload_confirm_delete: 'Are you sure you want to remove this employee from bulk upload?',
  employee_data_recomendations: [
    'If you can\'t find the required demographic option, you can create it in the demographic cuts section.',
    'All fields are required except email.',
    'Collaborators with the same identification number or with the same email are not allowed.',
    'Remember that any changes made apply only to future assessments.'
  ]
}
