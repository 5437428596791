
export default {
  dashboard: 'Dashboard',
  enterprises: 'Companies',
  employees: 'Employees',
  setting: 'Settings',
  academic_degrees: 'Level of education',
  languages: 'Languages',
  departments: 'Area / Department',
  countries: 'Country',
  demographic_cuts: 'Demographic cuts',
  enterprise_sizes: 'Company sizes',
  genders: 'Gender',
  job_types: 'Type of contract',
  sectors: 'Industries',
  committalQuestions: 'Engagement questions',
  wordQuestions: 'Open questions',
  group: 'Group',
  headquarters: 'Location',
  charges: 'Position level',
  ages: 'Age range',
  antiquity: 'Seniority range',
  sign_out: 'Log out',
  token: 'OCC Tokens',
  profile: 'Profile',
  go_back: 'Back',
  language: 'Language',
  calculator: 'Calculator',
  operations: 'Operations Summary',
  services: 'Rates and recommendations',
  enterprises_list: 'Companies',
  profile_list: 'Company profile',
  measuring_tools: 'Tools',
  customers: 'Clients',
  terms_and_conditions: 'Terms and conditions',
  products: 'Products',
  token_price: 'Token Price',
  currencies: 'Currencies',
  emails: 'Emails',
  operations_emails: 'Operations email',
  additional_demographics1: 'Additional Demographics 1',
  additional_demographics2: 'Additional Demographics 2',
  additional_segmentation: 'Additional Segmentation'
}
