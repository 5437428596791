
import Services from './base-services'

const service = new Services('headquarters')

export default {
  list: (country: number) => {
    return service.get(`list?country=${country}`)
  },
  fetchByEnterprise: () => {
    return service.get('fetch-by-enterprise')
  },
  fetchByEnterpriseForView: () => {
    return service.get('fetch-by-enterprise-for-headquarters-view')
  },
  create: (data: { enterpriseId: number, countryId: number, name: string }) => {
    return service.post('create', { data })
  },
  update: (id: number, data: { countryId: number, name: string }) => {
    return service.post(`update/${id}`, { data })
  },
  toggleActive: (id: number, active: boolean) => {
    return service.post(`toggle-active/${id}`, { active })
  },
  delete: (id: number) => {
    return service.get(`delete/${id}`)
  }
}
