<template>
  <v-card>
      <v-container>
        <v-row wrap>
          <v-col cols="12" class="pb-5 px-5">
            <ul>
              <li v-for="(item, i) in $t('employees.employee_data_recomendations')" :key="i"
                class="body-2 grey--text text--darken-1"
              >
                {{ item }}
              </li>
            </ul>
          </v-col>
          <v-col cols="12" sm="6">
            <x-inputs-autocomplete
              :items="getFormattedIdentifyTypes"
              :input="employeeForm.identifyTypeId"
              @updateInput="($event) => employeeForm.identifyTypeId = $event"
              light
              :label="$t('input.identify_type')"
              name="identify_type_id"
              rules="required"
            ></x-inputs-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <x-inputs-input
              :input="employeeForm.identifyDocument"
              @updateInput="($event) => employeeForm.identifyDocument = $event"
              :append-outer-icon="$t('help.icon')"
              :label="$t('input.identify_document')"
              rules="required|alpha_num"
              light
              name="identify_document"
              :help-message="$t('help.employee.create.identifyDocument')"
            ></x-inputs-input>
          </v-col>

          <v-col cols="12" sm="6" style="padding-top: 1.3em">
            <x-inputs-input
              :input="employeeForm.firstName"
              @updateInput="($event) => employeeForm.firstName = $event"
              :append-outer-icon="$t('help.icon')"
              :label="$t('input.first_name')"
              rules="required|alpha_spaces"
              light
              name="first_name"
              :help-message="$t('help.employee.create.firstName')"
            ></x-inputs-input>
          </v-col>

          <v-col cols="12" sm="6">
            <x-inputs-input
              :input="employeeForm.lastName"
              @updateInput="($event) => employeeForm.lastName = $event"
              :append-outer-icon="$t('help.icon')"
              :label="$t('input.last_name')"
              rules="required|alpha_spaces"
              light
              name="last_name"
              :help-message="$t('help.employee.create.lastName')"
            ></x-inputs-input>
          </v-col>

          <v-col cols="12" sm="6">
            <x-inputs-autocomplete
              :items="getFormattedGenders"
              :input="employeeForm.genderId"
              @updateInput="($event) => employeeForm.genderId = $event"
              light
              :label="$t('input.gender')"
              name="gender_id"
              :append-outer-icon="$t('help.icon')"
              :help-message="$t('help.employee.create.gender')"
              :rules="fieldsRequired.genderId"
            >
            </x-inputs-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
              <x-date-picker
              v-model="employeeForm.birthdate"
              @date-picked="employeeForm.birthdate = $event"
              :label="$t('employees.birthdate')"
              name="birthdate"
              :help="{ ...$t('help.employee.create.birthdate') }"
              :max="new Date().toISOString()"
              :rules="fieldsRequired.birthdate"
              ></x-date-picker>
          </v-col>

          <v-col cols="12" sm="6">
            <x-inputs-autocomplete
              :items="getFormattedAcademicDegrees"
              :input="employeeForm.academicDegreeId"
              @updateInput="($event) => employeeForm.academicDegreeId = $event"
              light
              :label="$t('input.academic_degree')"
              name="academic_degree"
              :append-outer-icon="$t('help.icon')"
              :help-message="$t('help.employee.create.academicDegree')"
              :rules="fieldsRequired.academicDegreeId"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <x-inputs-autocomplete
              :items="getFormattedCountries"
              :input="employeeForm.countryId"
              @updateInput="($event) => employeeForm.countryId = $event"
              light
              :label="$t('input.country')"
              name="country_id"
              :append-outer-icon="$t('help.icon')"
              :help-message="$t('help.employee.create.country')"
              :rules="fieldsRequired.countryId"
              :on-change="updateHeadquarters"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <x-inputs-autocomplete
              :items="headquarters"
              :input="employeeForm.headquarterId"
              @updateInput="($event) => employeeForm.headquarterId = $event"
              light
              :label="$t('input.headquarter')"
              name="headquarter_id"
              :append-outer-icon="$t('help.icon')"
              :help-message="$t('help.employee.create.headquarter')"
              :rules="fieldsRequired.headquarterId"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <x-inputs-input
              :input="employeeForm.email"
              @updateInput="($event) => employeeForm.email = $event"
              :append-outer-icon="$t('help.icon')"
              :label="$t('input.email')"
              rules="email"
              light
              name="email"
              :help-message="$t('help.employee.create.email')"
            ></x-inputs-input>
          </v-col>
        </v-row>

          <v-row justify="end" align="end">
            <v-col md="6" xs="12" sm="6" align="end">
              <v-btn
                small
                to="/employees"
                text
                class="text-capitalize"
              >{{ $t('input.cancel') }}</v-btn>

              <v-btn
                color="primary"
                small
                style="margin-left: 1em"
                class="text-capitalize"
                @click="onSubmit"
              >{{ $t('input.next') }}
              </v-btn>
            </v-col>
          </v-row>
      </v-container>
  </v-card>
</template>

<script>

import resolver from '../../../utils/resolver'
import formatItems from '../../../utils/form-format-items-list'

import academicDegreesService from '../../../services/academic-degrees'
import countriesService from '../../../services/countries'
import gendersService from '../../../services/genders'
import headquartersService from '../../../services/headquarters'
import identifyTypesService from '../../../services/identify-types'

export default {
  props: {
    employee: Object,
    fieldsRequired: Object,
    step: String,
    nextAction: String,
    prevAction: String
  },
  data () {
    return {
      employeeForm: {
        identifyDocument: '',
        identifyTypeId: '',
        firstName: '',
        lastName: '',
        genderId: '',
        birthdate: '',
        academicDegreeId: '',
        countryId: '',
        headquarterId: '',
        email: '',
        phoneNumber: ''
      },
      academicDegrees: [],
      countries: [],
      genders: [],
      headquarters: [],
      identifyTypes: [],
      valid: false
    }
  },
  watch: {
    employee: {
      handler () {
        this.updateForm()
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    getFormattedAcademicDegrees () {
      if (!this.academicDegrees.length) return []
      return formatItems(this.academicDegrees, (item) => {
        const translation = item.translations.find(i => i.lang === this.$i18n.locale)
        const label = !translation || !translation.label ? item.translate.label : translation.label

        return {
          text: label,
          value: item.id
        }
      }).sort((a, b) => b.text - a.text)
    },
    getFormattedCountries () {
      if (!this.countries.length) return []
      return formatItems(this.countries, (item) => {
        const translation = item.translations.find(i => i.lang === this.$i18n.locale)
        const label = !translation || !translation.label ? item.translate.label : translation.label

        return {
          text: label,
          value: item.id
        }
      }).sort((a, b) => b.text - a.text)
    },
    getFormattedGenders () {
      if (!this.genders.length) return []
      return formatItems(this.genders, (item) => {
        const translation = item.translations.find(i => i.lang === this.$i18n.locale)
        const label = !translation || !translation.label ? item.translate.label : translation.label

        return {
          text: label,
          value: item.id
        }
      }).sort((a, b) => b.text - a.text)
    },
    getFormattedIdentifyTypes () {
      if (!this.identifyTypes.length) return []
      return formatItems(this.identifyTypes, (item) => {
        const iType = item.translations.find(i => i.lang === this.$i18n.locale)
        return {
          text: iType.label,
          value: item.id,
          code: item.code
        }
      })
    }
  },
  methods: {
    onSubmit () {
      this.changeStep(+this.step + 1)
    },
    changeStep (step) {
      this.$emit('changeStep', this.employeeForm, step.toString())
    },
    updateForm () {
      this.employeeForm.identifyDocument = this.employee.identifyDocument
      this.employeeForm.identifyTypeId = this.employee.identifyTypeId
      this.employeeForm.firstName = this.employee.firstName
      this.employeeForm.lastName = this.employee.lastName
      this.employeeForm.genderId = this.employee.genderId
      this.employeeForm.birthdate = this.employee.birthdate
      this.employeeForm.academicDegreeId = this.employee.academicDegreeId
      this.employeeForm.countryId = this.employee.countryId
      if (this.employeeForm.countryId) {
        this.updateHeadquarters()
      }
      this.employeeForm.headquarterId = this.employee.headquarterId
      this.employeeForm.email = this.employeeForm.email ? this.employeeForm.email : this.employee.email
      this.employeeForm.phoneNumber = this.employee.phoneNumber
      this.employeeForm.birthdate = this.getDateOfDatetime(this.employee.birthdate)
    },
    getDateOfDatetime (date) {
      return date ? date.split('T').shift() : ''
    },
    updateHeadquarters () {
      headquartersService.list(this.employeeForm.countryId)
        .then((res) => {
          this.headquarters = formatItems(res.items, (headquarter) => ({
            text: headquarter.name,
            value: headquarter.id
          }))
        })
    }
  },
  created () {
    this.$emit('loading', true)
    resolver
      .all({
        academicDegrees: academicDegreesService.list(),
        countries: countriesService.listByEnterprise(),
        genders: gendersService.list(),
        identifyTypes: identifyTypesService.list()
      })
      .then((res) => {
        this.academicDegrees = res.academicDegrees.items
        this.countries = res.countries.items
        this.genders = res.genders.items
        this.identifyTypes = res.identifyTypes.items
        const it = this.identifyTypes.find(i => i.code === 'others')
        const index = this.identifyTypes.indexOf(it)
        this.identifyTypes.splice(index, 1)
        this.identifyTypes.splice(this.identifyTypes.length, 0, it)
        this.updateForm()
      })
      .finally(() => {
        this.$emit('loading', false)
      })
  }
}
</script>
