
<template>
  <v-list-item
    :to="path"
    style="background-color: #3b3b3b !important"
    :class="{ 'x-list-leaf': !onRoot }"
  >
    <v-list-item-icon>
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ $t(`navigation.${title}`) }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    icon: String,
    title: String,
    path: String,
    onRoot: Boolean
  }
})
</script>

<style scoped>
.x-list-leaf {
  padding-left: 32px !important;
}
</style>
