
import Services from './base-services'

const service = new Services('language-enterprises')

export default {
  list: () => {
    return service.get('list')
  },
  create: (enterpriseId: number, languageId: number) => {
    return service.post('create', { enterpriseId, languageId })
  },
  delete: (register: object) => {
    return service.post('delete', { register })
  }
}
