
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="8">
        <h4 class="display-1">{{$t('emails.operations_emails') }}</h4>
      </v-col>
      <v-col cols="12" sm="4" class="text-right">
        <v-btn class="text-right"
          large
          color="primary"
          @click="add = true">
          <v-icon left>mdi-plus-circle-outline</v-icon>
          {{ $t('settings.add') }}
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-divider></v-divider>
        <v-card>
          <v-card-title>
            <div class="title">{{ $t('emails.emails_list') }}</div>
            <v-spacer></v-spacer>
            <v-autocomplete
              :label="$t('emails.filter_by')"
              :items="filters"
              prepend-inner-icon="mdi-filter-outline"
              clearable
              @change="changeOption({ filter: $event })"
            ></v-autocomplete>
          </v-card-title>
          <v-divider class="my-3"></v-divider>
          <x-data-table
            :headers="headers"
            :parent-fetch-data="getEmails"
            :options="options"
            no-data="emails.no_data"
          >
            <template v-slot:structure="prop">
              <td>{{ prop.item.email }}</td>
              <td>
                <v-row justify="center">
                  <v-col cols="2">
                    <v-switch
                      v-model="prop.item.active"
                      class="ma-2"
                      @click="openModal(prop.item.active, prop.item)"
                    />
                  </v-col>
                </v-row>
              </td>
            </template>
          </x-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="add" persistent max-width="600">
      <v-card>
        <v-card-title class="d-flex justify-end pr-1 pt-1">
          <v-btn large icon @click="resetAdd()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="10">
              <v-card class="d-flex justify-space-around text-center" color="blue-grey lighten-5">
                <v-col cols="12">
                  <h4 class="subtitle-1 font-weight-black text-uppercase">{{ $t('emails.email') }}</h4>
                  <div class="my-6">
                     <v-form
                      ref="form"
                      v-model="valid"
                      :lazy-validation="lazy"
                    >
                      <v-text-field
                        v-model="inputValue"
                        :rules="emailRules"
                        required
                      />
                     </v-form>
                  </div>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn color="blue-grey lighten-2" class="text-capitalize" dark @click="resetAdd">{{ $t('input.cancel') }}</v-btn>
          <v-btn color="blue darken-1" class="text-capitalize" dark @click="save">{{ $t('input.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <x-confirmation-modal
      :show.sync="modalEna.open"
      :reversible="true"
      :title="$t('emails.modal_enable')"
      :action="enableEmail"
      color="success"
      :btn-save="$t('input.enable')"
      @close="modalEna.open = false"
    >
    </x-confirmation-modal>

    <x-confirmation-modal
      :show.sync="modalDis.open"
      :reversible="true"
      :title="$t('emails.modal_disable')"
      :action="disableEmail"
      color="error"
      :btn-save="$t('input.disable')"
      @close="modalDis.open = false"
    >
    </x-confirmation-modal>

  </v-container>
</template>

<script>

import TokenOperationsEmailsService from '../../services/token-operations-emails'

export default {
  data () {
    return {
      headers: [
        'emails.email',
        'emails.status'
      ],
      options: {
        filter: null,
        search: null,
        dateTo: null,
        dateFrom: null
      },
      modalDis: {
        open: false,
        item: {}
      },
      modalEna: {
        open: false,
        item: {}
      },
      inputValue: '',
      add: false,
      valid: true,
      lazy: false,
      emailRules: [
        v => !!v || this.$t('emails.email_required'),
        v => /.+@.+\..+/.test(v) || this.$t('emails.email_valid')
      ],
      search: null,
      timer: null,
      dateTo: null,
      dateFrom: null
    }
  },
  computed: {
    filters () {
      const translate = (option) => this.$t(`emails.${option}`)
      return [
        { text: translate('active'), value: 1 },
        { text: translate('disabled'), value: -1 }
      ]
    }
  },
  methods: {
    getEmails (options) {
      return TokenOperationsEmailsService.list(options)
        .then((res) => ({
          items: res.items,
          total: res.total
        }))
    },
    changeOption (keyValue) {
      this.options = {
        ...this.options,
        ...keyValue
      }
    },
    openModal (active, item) {
      if (active) {
        this.modalDis.item = item
        this.modalDis.open = true
      } else {
        this.modalEna.item = item
        this.modalEna.open = true
      }
    },
    resetAdd () {
      this.inputValue = ''
      this.valid = true
      this.add = false
    },
    save () {
      this.loading = true
      const email = this.inputValue
      if (!this.$refs.form.validate()) {
        return
      }
      this.resetAdd()
      return TokenOperationsEmailsService.create({ email })
        .then(() => {
          this.loading = false
          this.$store.dispatch('alert/success', this.$t('emails.email_saved'))
          this.search = this.search === null ? undefined : null
        })
        .catch((err) => {
          const error = this.$t(`errors.${err.code}`)
          this.$store.dispatch('alert/error', error)
          this.loading = false
        })
    },
    enableEmail () {
      return TokenOperationsEmailsService.toggle({ id: this.modalEna.item.id, active: 1 })
        .then(() => {
          this.modalEna.item.active = true
          this.modalEna.open = false
        })
    },
    disableEmail () {
      return TokenOperationsEmailsService.toggle({ id: this.modalDis.item.id, active: 0 })
        .then(() => {
          this.modalDis.item.active = false
          this.modalDis.open = false
        })
    }
  },
  watch: {
    search () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
        this.getEmails()
      }
      this.timer = setTimeout(() => {
        this.options = {
          ...this.options,
          search: this.search
        }
      }, 500)
    }
  }
}
</script>
