
export default {
  groups: {
    list: ['admin'],
    create: ['admin'],
    getOne: ['admin', 'customer'],
    getOneByLeader: ['customer'],
    edit: ['admin'],
    profile: ['customer'],
    applyUpdatePlan: ['admin'],
    rejectUpdatePlan: ['admin'],
    applyExtendPlan: ['admin'],
    rejectExtendPlan: ['admin']
  },
  enterprises: {
    list: ['admin', 'customer'],
    getOne: ['admin', 'customer'],
    profile: ['admin.customer', 'customer', 'enterprise_admin'],
    create: ['admin', 'customer'],
    edit: ['admin', 'customer', 'enterprise_admin']
  },
  employees: {
    list: ['admin.customer', 'customer', 'enterprise_admin'],
    disable: ['admin.customer', 'customer', 'enterprise_admin'],
    enable: ['admin.customer', 'customer', 'enterprise_admin'],
    edit: ['admin.customer', 'customer', 'enterprise_admin'],
    create: ['admin.customer', 'customer', 'enterprise_admin'],
    massiveUpload: ['admin.customer', 'customer', 'enterprise_admin'],
    getOne: ['admin.customer', 'customer', 'enterprise_admin']
  },
  genders: {
    list: ['customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin']
  },
  headquarters: {
    list: ['customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin']
  },
  jobTypes: {
    list: ['customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin']
  },
  countries: {
    settings: ['admin'],
    read: ['admin', 'admin.customer', 'customer', 'enterprise_admin']
  },
  departments: {
    list: ['admin.customer', 'customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin']
  },
  additionalDemographics1: {
    list: ['admin.customer', 'customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin']
  },
  additionalDemographics2: {
    list: ['admin.customer', 'customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin']
  },
  charges: {
    list: ['customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin']
  },
  academicDegrees: {
    list: ['admin', 'customer', 'enterprise_admin'],
    settings: ['admin'],
    read: ['admin', 'admin.customer', 'customer', 'enterprise_admin']
  },
  sectors: {
    list: ['admin', 'customer', 'enterprise_admin'],
    settings: ['admin']
  },
  languages: {
    list: ['admin', 'customer', 'enterprise_admin'],
    settings: ['admin']
  },
  enterpriseSizes: {
    list: ['admin'],
    settings: ['admin']
  },
  identifyTypes: {
    list: ['admin', 'customer', 'enterprise_admin'],
    settings: ['admin']
  },
  products: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin']
  },
  productServices: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin']
  },
  tokenAccounts: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin', 'customer'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin']
  },
  tokenAccountDetails: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin', 'customer', 'enterprise_admin'],
    buy: ['customer', 'enterprise_admin'],
    move: ['admin', 'customer'],
    spend: ['customer', 'enterprise_admin'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin']
  },
  tokenAuthorization: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin', 'customer'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin', 'customer', 'enterprise_admin']
  },
  tokenPrice: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin']
  },
  tokenOperationsEmails: {
    all: ['admin']
  },
  terms: {
    list: ['admin'],
    settings: ['admin'],
    getOne: ['admin'],
    create: ['admin'],
    getActiveByType: ['customer']
  },
  customers: {
    list: ['admin', 'customer'],
    create: ['admin', 'customer'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin'],
    changePassword: ['customer'],
    changeEmail: ['customer'],
    requestProducts: ['customer', 'enterprise_admin'],
    assignProducts: ['admin']
  },
  activities: {
    current: ['customer', 'enterprise_admin'],
    required: ['customer', 'enterprise_admin']
  },
  dashboard: {
    getInfo: ['admin', 'customer', 'enterprise_admin'],
    activities: ['admin']
  },
  demographicItems: {
    list: ['admin', 'customer', 'enterprise_admin']
  },
  emails: {
    list: ['admin', 'customer', 'enterprise_admin'],
    createCurrentCulture: ['admin', 'customer', 'employee', 'enterprise_admin'],
    createDmlEvaluation: ['admin', 'customer', 'enterprise_admin']
  },
  currencies: {
    getAll: ['admin', 'customer', 'enterprise_admin'],
    list: ['admin'],
    create: ['admin'],
    getOne: ['admin'],
    edit: ['admin'],
    delete: ['admin']
  }
}
