
<template>
  <div>
    <v-switch hide-details
      v-model="localValue"
      :disabled="disabled"
      :loading="showModal"
      class="mt-0"
      @click="openModal"
    />

    <x-confirmation-modal
      :show.sync="showModal"
      :reversible="true"
      :title="modal.title"
      :action="execute"
      :color="modal.color"
      :btn-save="modal.btn_text"
      @cancel="localValue = value"
      @close="closeModal"
    >
      <template v-slot:question>{{ modal.question }}</template>
    </x-confirmation-modal>

    <v-dialog v-model="showAlert" width="500px">
      <v-card>
        <v-toolbar light flat>
          <v-toolbar-title>{{ alertData.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon light @click="showAlert = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>{{ alertData.text }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showAlert = false">
            {{ alertData.btn_text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: Boolean,
    disabled: Boolean,
    enable: Object,
    disable: Object,
    item: Object,
    canEnable: {
      type: Boolean,
      default: undefined
    },
    canDisable: {
      type: Boolean,
      default: undefined
    },
    alert: Object
  },
  data () {
    return {
      showModal: false,
      showAlert: false,
      localValue: this.value,
      alertData: {}
    }
  },
  watch: {
    value: function (val) {
      this.localValue = val
    }
  },
  computed: {
    modal () {
      return this.value ? this.disable : this.enable
    }
  },
  created () {
    this.alertData = {
      title: this.$t('components.not_allowed'),
      text: this.$t('components.not_allowed_to_enable'),
      btn_text: this.$t('input.accept'),
      ...this.alert
    }
  },
  methods: {
    openModal () {
      if ((this.value && this.canDisable !== undefined && !this.canDisable) ||
          (!this.value && this.canEnable !== undefined && !this.canEnable)) {
        this.showAlert = true
        this.$nextTick(() => {
          this.localValue = this.value
        })
      } else {
        this.showModal = true
      }
    },
    closeModal () {
      this.showModal = false
    },
    execute () {
      return this.modal.action(this.item)
    }
  }
})
</script>
