
import cr from './base64/circles'
import occ from './base64/occ'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts.js'

import quotationsService from '../../services/quotations'

import initial from './mixins/00-init'
import pageOne from './mixins/01-page'
import pageTwoPulse from './mixins/02a-page-pulse'
import pageTwoCultura from './mixins/02b-page-cultura'
import pageTwoDml from './mixins/02c-page-dml'
import pageThree from './mixins/03-page'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  components: {
    //
  },
  mixins: [
    initial,
    pageOne,
    pageTwoPulse,
    pageTwoCultura,
    pageTwoDml,
    pageThree
  ],
  data () {
    return {
      downloadPdf: true,
      circles: '',
      occLogo: '',
      lang: 'es',
      quotationDate: '',
      loadingBtn: false,
      dataFetched: false,
      data: {}
    }
  },
  watch: {
    //
  },
  computed: {
    //
  },
  created () {
    this.circles = cr
    this.occLogo = occ
    this.getData()
  },
  methods: {
    getData () {
      this.loadingBtn = true
      quotationsService.getOne(this.$route.params.uuid)
        .then(res => {
          this.data = res
          this.dataFetched = true
          if (res.createdAt) {
            const date = new Date(res.createdAt)
            this.quotationDate = date.toLocaleDateString('es-CO', { month: '2-digit', day: '2-digit', year: 'numeric' })
          }
        })
        .finally(() => {
          this.loadingBtn = false
        })
    },
    async openPdf () {
      this.loadingBtn = true
      const configuration = await this.$getConfiguration()
      if (this.downloadPdf) {
        pdfMake.createPdf(configuration).download(`Cotización OCC - ${this.data.name}.pdf`, () => {
          this.closeRenderPdf()
        })
      } else {
        this.closeRenderPdf()
      }
    },
    closeRenderPdf () {
      this.loadingBtn = false
    },
    round (value, decimals = 2) {
      if (isNaN(Number(value))) {
        return '--'
      }
      if ((value * 100) < 1 && (value * 100) > -1) {
        value = 0
      }
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
    }
  }
}
