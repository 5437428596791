var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"display-1"},[_vm._v(" "+_vm._s(_vm.tab === 0 ? _vm.$t('settings.terms_and_conditions_settings') : _vm.$t('settings.data_protection_policies_settings'))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-tabs',{attrs:{"grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t('settings.terms_and_conditions')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('settings.data_protection_policies')))])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-simple-table',{staticClass:"mt-7",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('settings.customer_type')))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('settings.description')))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('settings.actions')))])])]),_c('tbody',_vm._l((_vm.terms),function(term){return _c('tr',{key:term.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t(("customers." + (term.type)))))]),_c('td',{staticStyle:{"max-width":"30em"}},[_c('p',{domProps:{"innerHTML":_vm._s(((((term || {}).description || '').substring(0, 400)) + "..."))}})]),_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("/settings/terms-and-conditions/" + (term.id) + "/show"),"icon":"","color":"success"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('input.see_more')))])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("/settings/terms-and-conditions/" + (term.id) + "/edit"),"icon":"","color":"primary"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('input.edit')))])])],1),_c('v-divider')],1)}),0)]},proxy:true}])})],1),_c('v-tab-item',[_c('v-simple-table',{staticClass:"mt-7",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('settings.customer_type')))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('settings.description')))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('settings.actions')))])])]),_c('tbody',_vm._l((_vm.policies),function(policy){return _c('tr',{key:policy.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t(("customers." + (policy.type)))))]),_c('td',{staticStyle:{"max-width":"30em"}},[_c('p',{domProps:{"innerHTML":_vm._s(((((policy || {}).description || '').substring(0, 513)) + "..."))}})]),_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("/settings/data-protection-policies/" + (policy.id) + "/show"),"icon":"","color":"success"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('input.see_more')))])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("/settings/data-protection-policies/" + (policy.id) + "/edit"),"icon":"","color":"primary"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('input.edit')))])])],1),_c('v-divider')],1)}),0)]},proxy:true}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }