
const required = '<br/><br/><b>This field is required.</b>'

export default {
  icon: 'mdi-help-circle',
  engagement: {
    create: {
      name: {
        title: 'Assessment name',
        text: `Works as identification for the assessment. ${required}`
      },
      displayName: {
        title: 'External name for respondents',
        text: 'If selected, this field is the means of identification for the assessment respondents, which replaces the "assessment name".'
      },
      deliveredAt: {
        title: 'Release date',
        text: `Represents the date from which you can participate in the assessment. ${required}`
      },
      validUntil: {
        title: 'Assessment valid until',
        text: `Represents the date until which you can participate in assessment. ${required}`
      },
      timezone: {
        title: 'Time zone',
        text: `Release, validity and reminder dates will be based on the chosen time zone. ${required}`
      },
      reminders: {
        title: 'Reminders',
        text: 'Each reminder is emailed to all assessment respondents on the specified date. In addition, the date to send the reminder must be between the dates of the release and the validity of the assessment.'
      },
      openQuestion: {
        title: 'Add additional open questions',
        text: 'Allows you to add up to three extra open questions to the assessment with a maximum of five options each.'
      },
      subject: {
        title: 'Message subject',
        text: 'Subject to be used in the email sent.'
      },
      body: {
        title: 'Message body',
        text: 'Content of the email sent.'
      },
      thankMessage: {
        title: 'Thank you message',
        text: 'This message will be sent to the email address of the recipient who has successfully completed the assessment.'
      }
    }
  },
  employee: {
    create: {
      identifyDocument: {
        title: 'Identification Document',
        text: `Employee identification document. It is necessary to specify the type of document.<br>Enter only letters and numbers. Special characters are not allowed. ${required}`,
        required
      },
      firstName: {
        title: 'First name',
        text: `Employee’s names. ${required}`,
        required
      },
      lastName: {
        title: 'Last name',
        text: `Employee’s last names. ${required}`,
        required
      },
      gender: {
        title: 'Gender',
        text: `Employee’s gender.`,
        required
      },
      birthdate: {
        title: 'Date of birth',
        text: `Employee’s date of birth.`,
        required
      },
      academicDegree: {
        title: 'Level of education',
        text: `Select the last level of education achieved by the employee. .`,
        required
      },
      country: {
        title: 'Country',
        text: `Country where employee lives.`,
        required
      },
      headquarter: {
        title: 'Location',
        text: `Specific location of the employee’s workplace.`,
        required
      },
      email: {
        title: 'Email',
        text: `Employee’s email. f you don't have email, leave this field empty.`,
        required
      },
      phoneNumber: {
        title: 'Phone number',
        text: `Employee’s pone number.`,
        required
      },
      admission: {
        title: 'Start date',
        text: `Date on which the employee was hired.`,
        required
      },
      jobType: {
        title: 'Type of contract',
        text: `Employee’s type of contract.`,
        required
      },
      department: {
        title: 'Area or department',
        text: `Area or department the employee belongs to.`,
        required
      },
      charge: {
        title: 'Job Titel',
        text: `Position held by the employee within the organization.`,
        required
      },
      additionalDemographics1: {
        title: 'Additional Demographic 1',
        text: 'Value corresponding to additional demographic 1'
      },
      additionalDemographics2: {
        title: 'Additional Demographic 2',
        text: 'Value corresponding to additional demographic 2'
      }
    }
  },
  pulse: {
    create: {
      committal: {
        title: 'Add engagement questions',
        text: 'Allows you to add one to six engagement questions to the assessment. This action is mandatory if questions from a questionnaire are not included in the assessment.'
      },
      questionnaire: {
        title: 'Add questions from a questionnaire',
        text: 'Allows you to add the questions of one or more dimensions belonging to a questionnaire. This action is mandatory if no engagement questions are to be included in the assessment.'
      },
      open: {
        title: 'Add additional open questions',
        text: 'Allows you to add up to three extra open questions to the assessment with a maximum of five options each.'
      },
      selection: {
        title: 'Who do you want to send this assessment to?',
        text: `Allows you to select the respondents of the assessment based on three different criteria: the entire organization, filtered by demographic cuts or by random selection based on a percentage of all employees. ${required}`
      }
    }
  },
  questionnaire: {
    create: {
      name: {
        title: 'Name',
        text: `Name that will be assigned to the questionnaire. ${required}`
      },
      base: {
        title: 'Base questionnaire',
        text: `Questionnaire from which a copy will be made to generate the new questionnaire. ${required}`
      },
      language: {
        title: 'Languages',
        text: `Languages in which the questionnaire will be available. ${required}`
      },
      assignate: {
        title: 'Assign questionnaire',
        text: 'Allows you to assign the questionnaire to a specific business sector or a particular organization.'
      }
    }
  },
  enterprise: {
    create: {
      logo: {
        title: 'Logo',
        text: 'The company logo must be an image whose weight does not exceed 2MB.'
      },
      name: {
        title: 'Name',
        text: `Company name. ${required}`
      },
      sector: {
        title: 'Industry',
        text: `Select the economic sector that most closely resembles the company's activity. If you don't find one, please select another. ${required}`
      },
      language: {
        title: 'Language',
        text: `Main language used in the company. ${required}`
      },
      country: {
        title: 'Country',
        text: `Country where the company's headquarters are located. ${required}`
      },
      headquarter: {
        title: 'Location',
        text: `Write the company’s headquarters or main city of operations. ${required}`
      },
      address: {
        title: 'Address',
        text: `WWrite the company’s main address. ${required}`
      },
      groups: {
        title: 'Group',
        text: 'Group to which the company belongs {0}.'
      },
      size: {
        title: 'Company size',
        text: `Select company size based on your annual turnover. ${required}`
      },
      employeesNumber: {
        title: 'Number of employees',
        text: `Number of collaborators/employees the company has. ${required}`
      },
      license: {
        title: 'License',
        text: `License to be assigned to the company. ${required}`
      },
      engagements: {
        title: 'Amount of Engagement',
        text: 'Amount of Engagement available in the custom plan.'
      },
      pulses: {
        title: 'Amount of Pulse',
        text: 'Amount of Pulse available in the custom plan.'
      },
      startAt: {
        title: 'Start date',
        text: `Start date for the company plan. ${required}`
      },
      duration: {
        title: 'Duration',
        text: `License duration. ${required}`
      },
      identifyDocument: {
        title: 'Identification document',
        text: `Administrator identification document. It is necessary to indicate the type of document. ${required}`
      },
      firstName: {
        title: 'Name',
        text: `Administrator’s first and last name. ${required}`
      },
      lastName: {
        title: 'Last name',
        text: `Contact collaborator’s last names. ${required}`
      },
      email: {
        title: 'Email',
        text: `Administrator email. ${required}`
      },
      phoneNumber: {
        title: 'Phone number',
        text: `Administrator’s pone number. ${required}`
      },
      contactAddress: {
        title: 'Address',
        text: `Contact collaborator’s address.`
      },
      intoCurrentPassword: {
        title: 'Enter current password',
        text: `You must provide your current password to perform this operation. ${required}`,
        required
      },
      intoPassword: {
        title: 'Enter password',
        text: `Enter new password. ${required}`,
        required
      },
      intoConfirmPassword: {
        title: 'Entewr password confirmation',
        text: `Enter password again. ${required}`,
        required
      },
      intoEmail: {
        title: 'Enter email',
        text: `Enter current email. ${required}`,
        required
      },
      intoConfirmEmail: {
        title: 'Enter new email',
        text: `Enter email to be assigned to the account. ${required}`,
        required
      }
    },
    plan: {
      pulse_quantity: {
        title: 'Amount of Pulse to add',
        text: `Pulse to be added to the company in the plan extension. ${required}`
      },
      engagement_quantity: {
        title: 'Amount of Engagement to add',
        text: `Engagement to be added to the company in the plan extension. ${required}`
      },
      new: {
        title: 'New plan',
        text: `Plan to be assigned to the company. ${required}`
      },
      duration: {
        title: 'Duration',
        text: `Duration in months for the new plan. ${required}`
      }
    },
    massive: {
      file_input: {
        title: 'File to upload',
        text: `Upload an excel (.xls/.xlsx) or csv (.csv) file containing your collaborators' data. This action will update your total employee base, so you must add the entire base of available collaborators.<br/><br/>Remember to use the downloadable template that you can get by clicking the "DOWNLOAD TEMPLATE" button". ${required}`
      }
    },
    group: {
      name: {
        title: 'Group name',
        text: `Name with which the group of companies will be identified. ${required}`
      },
      leader: {
        title: 'Leading company',
        text: 'The leading/main company (group leader) has the ability to manage the plans of the companies belonging to its group.'
      }
    }
  }
}
