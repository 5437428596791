
<template>
  <span>
    <v-btn large
      color="primary"
      class="mr-1 mt-1"
      :loading="loadingBtn"
      @click="generatePdfInstructive"
    >
      <v-icon left>mdi-download</v-icon>
      {{ $t('employees.download_docs') }}
    </v-btn>
    <!--
    <img v-for="i in 8" :key="i"
      :src="'../img/step_' + i + '_excel.png'"
      style="visibility:hidden;" :id="'excelImage'+i"
      width="0"
      height="0"
      alt="Excel step"
    />
    -->
  </span>
</template>

<script>
import Vue from 'vue'

import employeesService from '../../../services/employees'

const pdfmake = require('pdfmake/build/pdfmake')
const pdffonts = require('pdfmake/build/vfs_fonts.js')

pdfmake.vfs = pdffonts.pdfMake.vfs

export default Vue.extend({
  data () {
    return {
      loadingBtn: false
      // imageStep1: null,
      // imageStep2: null,
      // imageStep3: null,
      // imageStep4: null,
      // imageStep5: null,
      // imageStep6: null,
      // imageStep7: null,
      // imageStep8: null,
      // imagesBase64: []
    }
  },
  methods: {
    /* to download help */
    generatePdfInstructive () {
      this.loadingBtn = true
      return employeesService.getDataForPdf()
        .then((res) => {
          this.getPDF(res)
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
        .finally(() => {
          this.loadingBtn = false
        })
    },
    getPDF (data) {
      const docDefinition = {
        content: [
          { text: this.$t('instructive.title'), fontSize: 20, alignment: 'center', margin: [ 70, 35, 70, 10 ] },
          { text: this.$t('instructive.title_body'), fontSize: 12, margin: [ 40, 10, 30, 10 ] },
          { text: this.$t('instructive.demographic_items'), fontSize: 17, bold: true, alignment: 'left', margin: [ 40, 20, 30, 10 ] }
        ]
      }

      data.employeeLabels.forEach((label) => {
        docDefinition.content.push({
          text: this.$t('instructive.' + label), style: 'h2'
        })

        if (label === 'identifyType') {
          docDefinition.content.push(
            { text: this.$t('instructive.availableItems'), style: 'h3' },
            { ul: data.identifyItems, style: 'paragraph' },
            { text: this.$t('instructive.matchCaseValue'), style: 'paragraph' },
            { text: this.$t('instructive.example'), style: 'paragraph' },
            { text: data.identifyItems[0], bold: true, margin: [50, 0, 30, 20] }
          )
        } else if (label === 'identifyDocument') {
          docDefinition.content.push(
            { text: this.$t('instructive.matchCaseNumber'), style: 'paragraph' },
            { text: this.$t('instructive.example'), style: 'paragraph' },
            { text: '22514096', bold: true, margin: [50, 0, 30, 10] }
          )
        } else if (label === 'firstName' || label === 'lastName') {
          docDefinition.content.push(
            { text: this.$t('instructive.matchCaseText'), style: 'paragraph' },
            { text: this.$t('instructive.example'), style: 'paragraph' },
            { text: label === 'firstName' ? 'John José' : 'Doe Pérez', bold: true, margin: [50, 0, 30, 20] }
          )
        } else if (label === 'email') {
          docDefinition.content.push(
            { text: this.$t('instructive.matchCaseEmail'), style: 'paragraph' },
            { text: this.$t('instructive.example'), style: 'paragraph' },
            { text: 'johndoe@email.com', bold: true, margin: [50, 0, 30, 20] }
          )
        }
      })

      data.enterpriseItems.forEach((item) => {
        if (item.code !== 'age' && item.code !== 'antiquity') {
          const codeArray = data.totalItems[item.code] || []
          if (codeArray.length === 0) {
            return
          }
          const columns = codeArray.length > 3 ? 3 : codeArray.length
          const body = []
          codeArray.forEach((subItem, idx) => {
            const i = idx ? Math.floor(idx / columns) : 0
            if (!body[i]) {
              body.push([])
            }
            let label = subItem.translate ? subItem.translate.label : subItem.name
            if (item.code === 'charge' && subItem.departamentId) {
              const departament = data.totalItems['departaments'].find(d => d.id === subItem.departamentId)
              label = `${label} (${this.$t('instructive.departaments')}: ${departament.translate.label})`
            } else if (item.code === 'headquarter' && subItem.countryId) {
              const country = data.totalItems['country'].find(c => c.id === subItem.countryId)
              label = `${label} (${this.$t('instructive.country')}: ${country.translate.label})`
            }
            body[i].push(label)
          })

          if (body.length && body[body.length - 1].length !== columns) {
            const lg = columns - body[body.length - 1].length
            for (let i = 0; i < lg; i++) body[body.length - 1].push('')
          }

          const table = {
            table: { headerRows: null, body },
            bold: true,
            margin: [40, 5, 30, 10]
          }
          let example = ''
          if (codeArray.length) {
            example = codeArray[0].translate ? codeArray[0].translate.label : codeArray[0].name
          }
          docDefinition.content.push(
            { text: this.$t('instructive.' + item.code), style: 'h2' },
            { text: this.$t('instructive.availableItems'), style: 'h3' },
            table,
            { text: this.$t('instructive.matchCaseValue'), style: 'paragraph' },
            { text: this.$t('instructive.example'), style: 'paragraph' },
            { text: example, bold: true, margin: [50, 0, 30, 10] }
          )
          if (item.code === 'charge' || item.code === 'headquarter') {
            docDefinition.content.push(
              { text: this.$t(`instructive.${item.code}_dependent_info`), style: 'paragraph', alignment: 'justify' }
            )
          }
        } else {
          docDefinition.content.push(
            { text: this.$t('instructive.' + item.code), style: 'h2' },
            { text: this.$t('instructive.matchCaseDate'), style: 'paragraph', alignment: 'justify' },
            { text: this.$t('instructive.example'), style: 'paragraph', alignment: 'justify' },
            { text: item.code === 'antiquity' ? '2018-04-15' : '1994-02-28', bold: true, margin: [50, 0, 30, 10] }
          )
        }
      })
      /*
      docDefinition.content.push({
        text: this.$t('instructive.importDataToExcelTitle'),
        style: 'h2',
        pageBreak: 'before'
      })
      for (let i = 1; i < 9; i++) {
        docDefinition.content.push(
          { text: i !== 8 ? this.$t('instructive.step' + i) : '', style: 'stepper' },
          { text: this.$t('instructive.step' + i + '_desc'), style: 'paragraph', alignment: 'justify' },
          { image: this.imagesBase64[i], width: 450, height: 250, margin: [ 40, 10, 30, 10 ] }
        )
      }
      */
      docDefinition.styles = {
        paragraph: {
          fontSize: 12,
          margin: [ 40, 0, 30, 10 ]
        },
        h1: {
          fontSize: 15,
          margin: [ 40, 10, 30, 10 ],
          bold: true
        },
        h2: {
          fontSize: 14,
          margin: [ 40, 10, 30, 0 ],
          bold: true,
          italics: true
        },
        h3: {
          fontSize: 13,
          margin: [ 40, 5, 30, 10 ]
        },
        stepper: {
          fontSize: 14,
          margin: [ 40, 20, 30, 10 ],
          bold: true
        }
      }
      docDefinition.pageMargins = [ 25, 35, 25, 35 ]
      docDefinition.pageSize = 'LETTER'
      pdfMake.createPdf(docDefinition).download('Instructivo')
    },
    /* end to download help */
    toDataURL (url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const fr = new FileReader()

        fr.onload = function () {
          callback(this.result)
        }

        fr.readAsDataURL(xhr.response)
      }
      xhr.send()
    }
    /* ,
    setDataToUrl (value, idx) {
      if (value) {
        this.toDataURL(value, (dataURL) => {
          this.imagesBase64[idx] = dataURL
        })
      }
    }
    */
  },
  mounted () {
    // this.imageStep1 = document.getElementById('excelImage1').src
    // this.imageStep2 = document.getElementById('excelImage2').src
    // this.imageStep3 = document.getElementById('excelImage3').src
    // this.imageStep4 = document.getElementById('excelImage4').src
    // this.imageStep5 = document.getElementById('excelImage5').src
    // this.imageStep6 = document.getElementById('excelImage6').src
    // this.imageStep7 = document.getElementById('excelImage7').src
    // this.imageStep8 = document.getElementById('excelImage8').src
  },
  watch: {
    /*
    imageStep1 (newVal, oldVal) {
      this.setDataToUrl(newVal, 1)
    },
    imageStep2 (newVal, oldVal) {
      this.setDataToUrl(newVal, 2)
    },
    imageStep3 (newVal, oldVal) {
      this.setDataToUrl(newVal, 3)
    },
    imageStep4 (newVal, oldVal) {
      this.setDataToUrl(newVal, 4)
    },
    imageStep5 (newVal, oldVal) {
      this.setDataToUrl(newVal, 5)
    },
    imageStep6 (newVal, oldVal) {
      this.setDataToUrl(newVal, 6)
    },
    imageStep7 (newVal, oldVal) {
      this.setDataToUrl(newVal, 7)
    },
    imageStep8 (newVal, oldVal) {
      this.setDataToUrl(newVal, 8)
    }
    */
  }
})
</script>
