
<template>
  <v-container fluid>
    <v-row wrap row>
      <v-col cols="12" class="pt-4 pl-4">
        <h4 class="display-1">{{ cardTitle }}</h4>
      </v-col>
      <v-col cols="12" mt-3>
        <v-card>
          <v-card-subtitle class="mt-1 text-justify"
            v-html="$t('settings.master_references_only_read')"
          ></v-card-subtitle>

          <v-data-iterator
            :items="getPrimaryItems"
            :items-per-page="10"
            :options.sync="pagination"
            :footer-props="footerProps"
            row wrap
          >
            <template v-slot:header>
              <v-container fluid v-if="headers.length">
                <v-row>
                  <v-col class="text-xs-center" v-for="(header, $index) in headers" :key="$index">
                    <v-card flat>
                      <v-card-text class="text-center font-weight-black  px-0">{{ $t(`settings.${header}`) }}</v-card-text>
                      <v-divider></v-divider>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template v-slot:item="props">
              <v-card-text v-if="!props.item.deletedAt">
                <v-container fluid>
                  <v-row row style="max-height: 2em;">
                      <slot name="before-input" v-bind:item="{
                        item: props.item,
                        language,
                      }"></slot>
                      <v-col class="pl-1 pr-1">
                        <v-text-field
                          light outlined readonly
                          :color="props.item.active ? 'blue' : 'grey lighten-2'"
                          :background-color="props.item.active ? '' : 'grey lighten-2'"
                          :placeholder="$t('settings.no_translation')"
                          v-model="props.item.trans[user.lang].label"
                        ></v-text-field>
                      </v-col>
                      <slot name="after-input" v-bind:item="{
                        item: props.item,
                        language,
                      }"></slot>
                  </v-row>
                </v-container>
              </v-card-text>
            </template>
            <template v-slot:footer.page-text="props">
              {{ props.pageStart }}-{{ props.pageStop }} {{ $t('page_text') }} {{ props.itemsLength }}
            </template>
          </v-data-iterator>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import resolver from '../../../utils/resolver'

import languagesService from '../../../services/languages'

export default {
  props: {
    pageTitle: String,
    cardTitle: String,
    itemServices: Object,
    inputs: {
      type: Object,
      default: () => ({
        label: true
      })
    }
  },
  data () {
    return {
      primaryItems: [],
      resLanguages: [],
      languages: [],
      language: '',
      add: false,
      pagination: {},
      modalEna: { open: false, item: {} },
      modalDis: { open: false, item: {} },
      modalDel: { open: false, item: {} },
      headers: [],
      isLanguage: false,
      defaultLang: 'es'
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    getPrimaryItems () {
      return this.primaryItems.map(pItem => {
        const trans = pItem.trans
        if (trans.en.label === '' && !!trans.es.label) {
          trans.en.label = trans.es.label
        }
        if (trans.es.label === '' && !!trans.en.label) {
          trans.es.label = trans.en.label
        }
        return pItem
      })
    },
    footerProps () {
      return {
        'items-per-page-text': this.$t('results_per_pages'),
        'items-per-page-options': [5, 10, 25, { 'text': this.$t('page_all'), 'value': -1 }]
      }
    }
  },
  methods: {
    mapItems (items) {
      this.primaryItems = items.map(item => {
        item.trans = {}

        this.resLanguages.forEach(language => {
          this.languages.push({
            value: language.code,
            text: language.translate.label
          })

          item.trans[language.code] = item.translations.find(lang => lang.lang === language.code) || { label: '' }
        })

        return item
      })
    }
  },
  created () {
    resolver.all({
      primaryItems: this.itemServices.listData().then((res) => res.items),
      languages: languagesService.list().then((res) => res.items)
    }).then((res) => {
      this.resLanguages = res.languages
      this.mapItems(res.primaryItems)

      this.language = this.user.lang
      for (const key in this.inputs) {
        this.headers.push(key)
      }
      this.isLanguage = JSON.stringify(this.itemServices === JSON.stringify(languagesService))
    })
  }
}
</script>

<style>
  #master-manager .container {
    padding: 0 24px;
  }
  #master-manager .v-card__text{
    padding: 0;
  }
</style>
