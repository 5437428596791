
<template>
  <read-wrapper
    :page-title="$t('settings.academic_degree_read')"
    :card-title="$t('settings.academic_degrees')"
    :item-services="academicDegreesService"
  ></read-wrapper>
</template>

<script>

import academicDegreesService from '../../services/academic-degrees'

import ReadWrapper from './components/read-wrapper.vue'

export default {
  components: {
    ReadWrapper
  },
  data () {
    return {
      academicDegreesService
    }
  }
}
</script>
