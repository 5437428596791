var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-card',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-card-title',{staticClass:"pl-5"},[_vm._v(" Co-"+_vm._s(_vm.$t('enterprises.admin').toLowerCase())+" "),_c('v-spacer'),(_vm.isEditing)?_c('span',{staticClass:"d-inline-block mt-1 mr-2 caption grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.$t('emails.active'))+" ")]):_vm._e(),(_vm.isEditing)?_c('v-switch',{staticClass:"mt-0",attrs:{"dense":"","hide-details":"","loading":_vm.loadingToggleActive},on:{"click":function($event){return _vm.toggleActive()}},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}}):_vm._e()],1),_c('v-card-subtitle',{staticClass:"pb-0 pl-5"},[_vm._v(" "+_vm._s(_vm.enterpriseName)+" ")]),_c('ValidationProvider',{attrs:{"rules":"email","vid":"adminemail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('v-card-text',{staticClass:"px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"prepend-icon":"fa-envelope","label":_vm.$t('input.email'),"error-messages":errors[0]},scopedSlots:_vm._u([{key:"message",fn:function(props){return [_c('span',{staticClass:"caption mt-n4"},[_vm._v(_vm._s(_vm._f("errorField")(props.message,'')))])]}}],null,true),model:{value:(_vm.admin.email),callback:function ($$v) {_vm.$set(_vm.admin, "email", $$v)},expression:"admin.email"}})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"type":"submit","color":_vm.isEditing ? 'error' : 'primary',"disabled":_vm.computedDisabled || !valid}},[_vm._v(" "+_vm._s(_vm.isEditing ? ((_vm.$t('input.change')) + " co-admin") : _vm.$t('input.create'))+" ")]),_c('v-btn',{on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t('input.close'))+" ")])],1)]}}],null,true)})],1)]}}],null,true)})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }