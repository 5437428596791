<template>
  <v-container fluid>
    <x-loading
      :display="loading"
    ></x-loading>
    <v-card v-if="!loading">
      <v-card flat>
        <v-row wrap row>
          <v-col cols="auto" class="mr-auto">
            <article class="mt-5 d-inline">
              <x-inputs-upload-image
                v-if="isEditing"
                :input="enterprise.logo"
                @updateInput="($event) => enterprise.logo = $event"
              ></x-inputs-upload-image>
              <section class="mini-logo enterprise-logo" v-else>
                <img :src="enterprise.logo || '/img/20200301_occ_solution_logo.png'" alt="Company Logo"/>
              </section>
            </article>
          </v-col>
          <v-col xs8 sm8>
          <v-list-item two-line justify="center" class="d-inline">
            <v-list-item-content>
              <v-list-item-title class="text-uppercase title">
                {{ $t('enterprises.enterprise_information') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </v-col>
          <v-col xs4 sm4>
            <v-list-item two-line justify="center" class="d-inline">
              <v-list-item-content align="center">
                <v-list-item-title class="text-uppercase title">
                  {{ $t('enterprises.available_tokens') }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-uppercase subtitle tokens-qty">
                  {{ customerBalance }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card>
      <v-divider class="my-1"></v-divider>
      <v-row>
        <v-col cols="12" sm="6">
          <v-row no-gutters v-if="user.role === 'customer' && (user.customer || {}).type === 'personal'">
            <v-col cols="12" class="pb-3 pr-3 text-right">
              <v-btn
                v-if="!isEditing && !isEditingCustomer"
                color="primary"
                Cblock
                @click="isEditing = !isEditing"
              >
                <v-icon left>mdi-border-color</v-icon> {{ $t('input.edit') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col md="12" v-if="enterprise.id">
              <v-card class="pl-3" flat v-if="!isEditing">
                <v-row>
                  <v-col cols="12" sm="6">
                    <span class="font-weight-regular caption">{{ $t('enterprises.company_name') }}</span><br/>
                    <span>{{ enterprise.name }}</span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="font-weight-regular caption">{{ $t('input.sector') }}</span><br/>
                    <span>{{ getTranslationLabel(enterprise.sector) }}</span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="font-weight-regular caption">{{ $t('input.country') }}</span><br/>
                    <span>{{ getTranslationLabel(enterprise.country) }}</span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="font-weight-regular caption">{{ $t('input.headquarter') }}</span><br/>
                    <span>{{ enterprise.headquarter ? enterprise.headquarter.name : null }}</span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="font-weight-regular caption">{{ $t('input.address') }}</span><br/>
                    <span>{{ enterprise.address }}</span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="font-weight-regular caption">{{ $t('input.enterprise_size') }}</span><br/>
                    <span>
                      {{ enterprise.enterpriseSize ? getTranslationLabel(enterprise.enterpriseSize) : ''}}
                    </span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="font-weight-regular caption">{{ $t('input.employees_number') }}</span><br/>
                    <span>{{ computedEnterpriseEmployeesNumber }}</span>
                  </v-col>
                </v-row>
              </v-card>
              <v-card flat v-else>
                <self-edit
                  :edit-enterprise="enterprise"
                  @switchf="changeEdit"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <!-- <v-divider class="hidden-sm-and-up mb-2"></v-divider> -->
          <v-row no-gutters v-if="user.role === 'customer' && (user.customer || {}).type === 'personal'">
            <v-col cols="12" class="pb-3 pr-3 text-right">
              <v-btn
                v-if="!isEditing && !isEditingCustomer"
                Cblock
                color="primary"
                @click="isEditingCustomer = !isEditingCustomer"
              >
                <v-icon left>mdi-border-color</v-icon> {{ $t('input.edit') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="pr-3" :class="{'pl-3': this.$vuetify.breakpoint.xs}" flat>
              <v-card flat v-if="!isEditingCustomer">
                <v-row>
                  <v-col cols="12" sm="6">
                    <span class="font-weight-regular caption">{{ $t('customers.admin_name') }}</span><br/>
                    <span>{{ enterprise.customer.name }}</span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="font-weight-regular caption">{{ $t('input.contact_information') }}</span><br/>
                    <div>
                      {{ getTranslationLabel(enterprise.customer.identifyType) | identification }} -
                      {{ enterprise.customer.identifyDocument }}
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="font-weight-regular caption">{{ $t('input.email') }}</span><br/>
                    <span>{{ enterprise.customer.user.email }}</span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="font-weight-regular caption">{{ $t('input.phone_number') }}</span><br/>
                    <span>{{ enterprise.customer.phoneNumber }}</span>
                  </v-col>
                </v-row>
              </v-card>
              <v-card flat v-else>
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(sendSimpleEdit)">
                    <v-row>
                      <v-col sm="6">
                        <x-inputs-autocomplete
                          :items="getFormattedIdentifyTypes"
                          :input="enterprise.customer.identifyTypeId"
                          @updateInput="($event) => enterprise.customer.identifyTypeId = $event"
                          light
                          :label="$t('input.identify_type')"
                          name="identify_type_id"
                          rules="required"
                        >
                        </x-inputs-autocomplete>
                      </v-col>
                      <v-col sm="6">
                        <x-inputs-input
                          :input="enterprise.customer.identifyDocument"
                          @updateInput="($event) => enterprise.customer.identifyDocument = $event"
                          light
                          :label="$t('input.identify_document')"
                          name="identify_document"
                          :append-outer-icon="$t('help.icon')"
                          :help-message="$t('help.employee.create.identifyDocument')"
                          rules="required|alpha_num"
                        ></x-inputs-input>
                      </v-col>
                      <v-col cols="12">
                        <x-inputs-input
                          :input="enterprise.customer.name"
                          @updateInput="($event) => enterprise.customer.name = $event"
                          light
                          :label="$t('input.first_name')"
                          name="first_name"
                          :append-outer-icon="$t('help.icon')"
                          :help-message="$t('help.enterprise.create.firstName')"
                          rules="required"
                        ></x-inputs-input>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <x-inputs-input
                          :input="enterprise.customer.user.email"
                          light
                          :label="$t('input.email')"
                          name="email"
                          disabled
                          rules="required"
                        ></x-inputs-input>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <x-inputs-input
                          :input="enterprise.customer.phoneNumber"
                          @updateInput="($event) => enterprise.customer.phoneNumber = $event"
                          light
                          :label="$t('input.phone_number')"
                          name="phone_number"
                          :append-outer-icon="$t('help.icon')"
                          :help-message="$t('help.enterprise.create.phoneNumber')"
                          rules="required|numeric"
                        ></x-inputs-input>
                      </v-col>
                    </v-row>
                    <v-row justify="end" align="end">
                      <v-col align="end">
                        <v-btn
                          small
                          @click="isEditingCustomer = !isEditingCustomer"
                          text
                          class="text-capitalize"
                        >{{ $t('input.cancel') }}</v-btn>
                        <v-btn
                          color="primary"
                          type="submit"
                          small
                          style="margin-left: 1em"
                          class="text-capitalize"
                        >{{ $t('input.save') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </form>
                </ValidationObserver>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3 pl-3 pr-2 pb-2" v-if="enterprise.id && !isEditing && !isEditingCustomer">
        <v-col cols="12">
          <v-text-field
            :label="$t('input.invitation_url')"
            :readonly="true"
            outlined
            v-model="tokenUrl"
            :messages="$t('input.copy_invitation_url')"
            id="tokenUrl"
          >
            <template v-slot:message="props">
              <span class="body-2">{{ props.message }}</span>
            </template>
            <template slot="append-outer">
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="copyUrl" class="mt-n2">
                    <v-icon>file_copy</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('input.invitation_url_copy') }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <v-row v-if="user.role !== 'admin' && (user.customer || {}).type !== 'commercial'" class="mt-3">
      <v-col>
        <change-password :user="user" :disabled="isEditing || isEditingCustomer"/>
      </v-col>
      <v-col>
        <change-email :user="user" :disabled="isEditing || isEditingCustomer"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import enterpriseService from '../../services/enterprises'
import customersService from '../../services/customers'
import enterpriseSizesService from '../../services/enterprise-sizes'
import identifyTypesService from '../../services/identify-types'
import employeesNumberService from '../../services/employees-number'
import formatItems from '../../utils/form-format-items-list'
import tokenAccountDetailService from '../../services/token-account-detail'

import SelfEdit from './self-edit.vue'
import ChangePassword from '../customers/change-password'
import ChangeEmail from '../customers/change-email'

export default {
  components: {
    SelfEdit,
    ChangePassword,
    ChangeEmail
  },
  data () {
    return {
      enterprise: {
        logo: null,
        customer: {}
      },
      enterpriseSize: null,
      isEditing: false,
      loading: true,
      image: {
        src: '',
        name: ''
      },
      customerBalance: 0,
      isEditingCustomer: false,
      identifyTypes: [],
      employeesNumber: [],
      tokenUrl: ''
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    getFormattedIdentifyTypes () {
      if (!this.identifyTypes.length) return []
      return formatItems(this.identifyTypes, (item) => {
        const iType = item.translations.find(i => i.lang === this.user.lang)
        return {
          text: iType.label,
          value: item.id,
          code: item.code
        }
      })
    },
    computedEnterpriseEmployeesNumber () {
      const found = this.employeesNumber.find(x => x.code === this.enterprise.employeeNumber.code)
      const translation = found.translations.find(x => x.lang === this.user.lang)
      return translation.label
    }
  },
  watch: {
    enterprise: {
      handler () {
        this.watchImage()
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    this.getIdentifyTypes()
    this.fetchEnterprise()
    this.fetchEnterpriseEmployeesNumber()
  },
  methods: {
    getTranslationLabel (item) {
      let label = item.translate.label
      if (item.translations) {
        const found = item.translations.find(t => t.lang === this.user.lang)
        if (found) {
          label = found.label
        }
      }

      return label
    },
    getIdentifyTypes () {
      identifyTypesService.list()
        .then((res) => {
          this.identifyTypes = res.items
          const it = this.identifyTypes.find(i => i.code === 'others')
          const index = this.identifyTypes.indexOf(it)
          this.identifyTypes.splice(index, 1)
          this.identifyTypes.splice(this.identifyTypes.length, 0, it)
        })
        .catch((error) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
        })
    },
    fetchEnterprise (id) {
      const enterpriseId = id || this.$route.params.id || this.user.enterprise.id
      return enterpriseService.getOne(enterpriseId)
        .then((res) => {
          this.enterprise = res
          this.fetchEnterpriseSizes(res.enterpriseSize.code)
          this.setUrl()
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    fetchEnterpriseSizes (sizeCode) {
      return enterpriseSizesService.list()
        .then((res) => {
          this.enterprise.enterpriseSize = res.items.find((size) => {
            return size.code === sizeCode
          })
          this.loading = false
          tokenAccountDetailService.getCustomerBalance(this.enterprise.customer.id)
            .then((res) => { this.customerBalance = res.balance })
        })
    },
    fetchEnterpriseEmployeesNumber () {
      employeesNumberService.list()
        .then(res => {
          this.employeesNumber = res.items
        })
    },
    watchImage () {
      if (this.enterprise.logo && typeof this.enterprise.logo === 'string') {
        this.image.src = this.enterprise.logo
        const logo = this.enterprise.logo.split('?')
        this.image.name = (logo[0].split('/').pop())
      }
    },
    changeEdit () {
      this.isEditing = !this.isEditing
      this.fetchEnterprise(this.enterprise.id)
    },
    toggleSimpleEdit () {
      this.isEditingCustomer = !this.isEditingCustomer
    },
    sendSimpleEdit () {
      const payload = JSON.parse(JSON.stringify(this.enterprise.customer))
      payload.email = this.enterprise.customer.user.email
      return customersService.edit(this.enterprise.customer.id, payload)
        .then((res) => {
          this.toggleSimpleEdit()
          this.$store.dispatch('alert/success', this.$t('enterprises.successfully_edited'))
          this.user.customer.identifyTypeId = res.identifyTypeId
          this.user.customer.identifyDocument = res.identifyDocument
          this.user.customer.name = res.name
          this.user.customer.phoneNumber = res.phoneNumber
          this.$store.dispatch('session/updateEnterprise', this.user)
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    copyUrl () {
      let input = document.getElementById('tokenUrl')
      input.select()
      document.execCommand('copy')
      this.$store.dispatch('alert/success', this.$t('input.url_copied'))
    },
    setUrl () {
      const host = process.env.VUE_APP_SUITE_BASE_WEB + 'auth/verify-polls/'
      this.tokenUrl = host + this.enterprise.invitationCode
    }
  }
}
</script>

<style>
  .mini-logo {
    width: 110px !important;
    height: 110px !important;
    margin-left: 1.2em !important;
    flex-grow: 0 !important;
  }
  .tokens-qty {
    color: #3899da !important;
    font-weight: bolder !important;
    font-size: 2em !important;
  }
</style>
