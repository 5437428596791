
<template>
  <v-container fluid>
    <v-row wrap row>
       <v-col cols="12" sm="6">
        <h4 class="display-1">{{$t('models.groups') }}</h4>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title primary-title>
            <div>
              <span class="subheading grey--text">Grupo</span>
              <h4 class="display-1">{{ group.name }}</h4>
            </div>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-row>
            <v-col cols="12" md="3" class="mb-3 my-2">
            <v-list-item style="background-color: #3898da" class="ml-5">
              <v-list-item-content>
                <v-list-item-title>
                  <h6 class="title text-center white--text">{{ $t('groups.group_tokens') }}: {{ group.balance}}</h6>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            </v-col>
          </v-row>

          <v-divider v-if="leader"></v-divider>
          <v-card-text class="pa-0">
            <v-row row wrap class="mt-4" v-if="leader">
              <v-col cols="12" md="4" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-office-building</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('groups.main_enterprise') }}</h6>
                    </v-list-item-title>
                    <v-list-item-title class="grey--text subheading">
                      {{ leader.customer.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="4" class="mb-3 my-2" >
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-checkbox-multiple-blank-circle-outline</v-icon>
                  </v-list-item-action>

                  <v-list-item-content style="background-color: #4d4f5b">
                    <v-list-item-title>
                      <h6 class="title text-left ml-5 white--text">{{ $t('groups.main_customer_tokens') }} : {{ leader.balance }}</h6>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="2" class="mt-6 ml-12" >
                <v-btn
                  @click="openChangeLeaderModal()"
                  small
                  block
                >{{ $t('groups.change_leader') }}</v-btn>
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <v-data-table
              :headers="headers"
              :items="customers"
              :no-data-text="$t('enterprises.no_data')"
              :footer-props="footerProps"
              :options.sync="pagination"
              :items-per-page="10"
            >
              <template v-slot:item="props">
                <tr>
                  <td class="text-center">{{ props.item.customer.name}}</td>
                  <td class="text-center">{{ props.item.balance}}</td>
                  <td class="text-center" style="margin-left: 12em;">

                    <v-tooltip bottom color="primary" v-if="user.role === user.view">
                      <template v-slot:activator="{ on }">
                        <v-btn
                        @click="loginAsCustomer(props.item.customer.id, props.item.customer.type)"
                        v-on="on"
                        text
                        icon>
                          <v-icon small>fa-sign-out-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('groups.login_as_customer') }}</span>
                    </v-tooltip>

                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="openModalDelete(props.item.customer)"
                          v-on="on"
                          icon>
                          <v-icon color="red" small>input</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('groups.delete_from_group') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
              <template v-slot:pageText="props">
                {{ props.pageStart }} - {{ props.pageStop }} {{ $t('page_text') }} {{ props.itemsLength }}
              </template>
            </v-data-table>

            <v-divider class="mt-3"></v-divider>
            <v-row>
              <v-col>
                <v-btn
                  text
                  large
                  color="rgba(0,0,0,.6)"
                  to="/groups"
                  class="text-capitalize"
                  style="margin-left: 1em"
                >
                  <v-icon left>input</v-icon>
                  {{ $t('go_back') }}
                </v-btn>
              </v-col>
              <v-col align-self="end" style="text-align: right">
                <v-btn
                        large
                        :to="`/groups/${group.id}/edit`"
                        style="background-color: #3898da; margin-right: 1em"
                        class="text-capitalize white--text"
                >
                  <v-icon left>mdi-pencil</v-icon>
                  {{ $t('input.edit') }}
                </v-btn>
                <v-btn
                  large
                  @click="openAddMemberModal()"
                  style="background-color: #3898da; margin-right: 1em"
                  class="text-capitalize white--text"
                >
                  <v-icon>fa-user-plus</v-icon>
                  {{ $t('groups.add_member') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <x-confirmation-modal
      :show.sync="modalDeleteCustomer.open"
      :reversible="true"
      title="Eliminar Miembro"
      :action="deleteMember"
      @close="modalDeleteCustomer.open = false"
      :btn-save="$t('groups.modal_save_btn')"
    >
      <template v-slot:question>{{ $t('groups.delete_member_question') }}</template>
    </x-confirmation-modal>

    <add-member-modal
      :customer="customerSelected"
      v-if="addMemberModal"
      :action="addMember"
      @close="hideAddMemberModal"
    />

    <change-leader-modal
      :members-available="customers"
      v-if="changeLeaderModal"
      :action="changeLeader"
      @close="hideChangeLeaderModal"
    />
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import groupsService from '../../services/groups'
import AddMemberModal from './add-member-modal.vue'
import ChangeLeaderModal from './change-leader-modal.vue'
import authService from '../../services/auth'

export default {
  components: {
    AddMemberModal,
    ChangeLeaderModal
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('input.customer'),
          align: 'center',
          class: 'text-uppercase',
          sortable: false
        }, {
          text: this.$t('groups.current_plan'),
          align: 'center',
          class: 'text-uppercase',
          sortable: false
        }, {
          text: this.$t('groups.actions'),
          align: 'center',
          class: 'text-uppercase',
          sortable: false
        }
      ],
      group: {},
      customers: [],
      modalDeleteCustomer: {
        open: false,
        item: {}
      },
      // Paginacion
      pagination: {},
      footerProps: {
        'items-per-page-text': this.$t('results_per_pages'),
        'items-per-page-options': [5, 10, 25, { 'text': this.$t('page_all'), 'value': -1 }]
      },
      customerSelected: null,
      groupBalance: 0,
      addMemberModal: false,
      changeLeaderModal: false
    }
  },
  methods: {
    getGroup () {
      groupsService.getOne(this.$route.params.id)
        .then((res) => {
          this.group = res
          this.groupBalance = this.group.balance
          this.customers = res.customers.filter((customer) => {
            return !customer.leader
          })
        })
        .catch((err) => { this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`)) })
    },
    openModalDelete (item) {
      this.modalDeleteCustomer.open = true
      this.modalDeleteCustomer.item = item
    },
    hideTokenModal (customer) {
      this.tokenCustomerModal = !this.tokenCustomerModal
    },
    openAddMemberModal () {
      this.addMemberModal = !this.addMemberModaL
    },
    openChangeLeaderModal (leaderSelected) {
      this.changeLeaderModal = !this.changeLeaderModal
    },
    hideAddMemberModal () {
      this.addMemberModal = !this.addMemberModal
    },
    hideChangeLeaderModal () {
      this.changeLeaderModal = !this.changeLeaderModal
    },
    loginAsCustomer (customerId, type) {
      return authService.loginAsCustomer(customerId, this.user)
        .then((res) => {
          return this.$store
            .dispatch('session/loginAs', res)
            .then((res) => {
              this.$router.push(res.user.customer.type === 'commercial' ? '/commercial-profile' : '/measuring-tools')
              window.location.reload(false)
            }).catch(err => (this.$store.dispatch('alert/error', err)))
        }).catch(err => (this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))))
    },
    addMember (memberId) {
      return groupsService.addmember(this.$route.params.id, memberId).then((res) => {
        this.$store.dispatch('alert/success', this.$t('groups.add_member_success'))
        this.getGroup()
      })
    },
    changeLeader (memberId) {
      return groupsService.changeLeader(this.$route.params.id, this.leader.customerId, memberId).then((res) => {
        this.$store.dispatch('alert/success', this.$t('groups.leader_changed_success'))
        this.getGroup()
      })
    },
    deleteMember () {
      return groupsService.deleteMember(this.modalDeleteCustomer.item.id).then((res) => {
        this.$store.dispatch('alert/success', this.$t('groups.delete_member_message'))
        this.getGroup()
      })
    }
  },
  computed: {
    leader () {
      return this.group.customers ? this.group.customers.find(customer => customer.leader) : null
    },
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.getGroup()
  }
}
</script>
