
import auth from './auth'
import customers from './customers'
import currencies from './currencies'
import countries from './countries'
import dashboard from './dashboard'
import employees from './employees'
import enterprises from './enterprises'
import errors from './errors'
import groups from './groups'
import emails from './emails'
import help from './help'
import input from './input'
import instructive from './instructive'
import languages from './languages'
import language from './language'
import navigation from './navigation'
import notifications from './notifications'
import outside from './outside'
import pluralization from './pluralization'
import settings from './settings'
import services from './services'
import tokens from './tokens'
import pricing from './pricing'
import calculatorView from './calculator_view'
import waiting from './waiting'
import components from './components'
import paymentResult from './payment_result'

export default {
  /* General Labels */
  title: 'OCC Solutions - Pulse',
  active: 'Active',
  inactive: 'Inactive',
  no_data: 'No records',
  registration_updated_successfully: 'Record successfully updated',
  delete_not_allowed: 'The value list cannot be empty',
  record_saved_successfully: 'Record successfully saved',
  error_trying_update: 'An error occurred while trying to update',
  error_trying_save: 'An error occurred while trying to save',
  copyright: 'All rights reserved 2019',
  go_back: 'Go back',
  results_per_pages: 'Results per page',
  manual: 'Help Manuals',
  go_to_manual: 'Click here to go to the manual section',
  page_all: 'All',
  /* Models */
  models: {
    enterprises: 'Companies',
    groups: 'Business Groups',
    pulse: 'Pulse'
  },
  maintenance: 'Page under construction',
  // tslint:disable-next-line:max-line-length
  maintenance_text: 'Every day we work to achieve the goals set<br/>and offer you better results and experiences.',
  /* Labels grouped by modules or type */
  auth,
  components,
  customers,
  currencies,
  countries,
  dashboard,
  emails,
  employees,
  enterprises,
  errors,
  help,
  input,
  instructive,
  navigation,
  notifications,
  outside,
  pluralization,
  languages,
  pricing,
  calculatorView,
  settings,
  services,
  tokens,
  groups,
  waiting,
  page_text: 'of',
  paymentResult
}
