
export default {
  'apology': '¡We’re sorry!',
  'not_found': 'The page you are looking for has not been found.',
  'forbidden': 'You are not authorized to access this information.',
  'back_home': 'Back to Home',
  'nothing_to_change': 'There is nothing to change',
  'auth/invalid-credentials': 'The data entered is incorrect.',
  'auth/invalid-email': 'Sorry, this email is not associated with any user within the platform. You can create your account with the "Sign up" button below.',
  'auth/invalid-password': 'The entered password is incorrect. If you have forgotten it, you can reset the password in the option "Forgot your password??"',
  'auth/user-is-disabled': 'The user entered is disabled.<br/>Please contact the administrator.',
  'auth/employee-no-exist': 'Employee not found.',
  'auth/enterprise-no-exist': 'Invalid company ID',
  'validator/verify_fields': 'PPlease verify the form fields.',
  'employees/enterprise-is-not-active': 'This company is not active.',
  'validator/invalid-input': 'One or more fields are not valid.',
  'validator/academicDegreeId-invalid-input': 'The level of education field is required by demographic cut.',
  'validator/countryId-invalid-input': 'The country field is mandatory due to demographics.',
  'validator/headquarterId-invalid-input': 'The location field is required due to demographics.',
  'validator/chargeId-invalid-input': 'The job title field is required due to demographics.',
  'validator/departmentId-invalid-input': 'The area/department field is required due to demographics.',
  'validator/genderId-invalid-input': 'The gender field is required due to demographics.',
  'validator/jobTypeId-invalid-input': 'The type of contract field is required due to demographics.',
  'validator/birthdate-invalid-input': 'The date of birth field is required due to demographics.',
  'validator/admission-invalid-input': 'The start date field is required due to demographics.',
  'employees/identify-document-not-in-headers': 'The header for the identification document is not in the file.',
  'employees/missing-required-headers-in-csv': 'The file must have all required headers.',
  'employees/empty-fields': 'All form fields must be filled.',
  'employees/employee-already-exists': 'This employee already exists.',
  'employees/employee-already-exists-another-enterprise': 'This employee already exists in another company.',
  'employees/employee-already-active-another-enterprise': 'This employee is already active in another company.',
  'employees/user-not-employee': 'This email is registered for a user who is not an employee.',
  'employees/invalid-date': 'An invalid date was found.',
  'employees/user-with-same-email-already-exist-in-enterprise': 'Another user with the same email already exists.',
  'employees/employee-with-same-identification-exist-in-enterprise': 'Another employee with the same identification already exists.',
  'employees/employee-is-not-active': 'This employee is not active.',
  'employees/mail-already-in-use-in-the-file': 'Another employee with the same email already exists in the file.',
  'email/unique:User,email,user_id': 'The email entered is already in use.',
  'validator/reminders': 'You can only select five reminders.',
  'validator/delete_reminder': 'You cannot delete this reminder.',
  'engagements/token-not-found': 'The supplied token could not be found.',
  'engagements/token-poll-completed': 'This assessment has already been completed.<br/><br/>Remember that once the assessment is completed, it is not possible to access it again.',
  'engagements/poll-not-found': 'The requested assessment does not exist.<br/><br/>Please check your access token.',
  'engagements/poll-has-ended': 'The requested assessment has ended.<br/><br/>Once the end date has been reached, you will not be able to access the assessment.',
  'engagements/poll-employee-not-found': 'The survey you are trying to access does not exist.<br/><br/>Please verify your participation and try again.',
  'engagements/missing-questions': 'All questions must be answered.',
  'engagements/missing-extra-questions': 'All additional questions must be answered.',
  'pulses/token-not-found': 'The supplied token could not be found.',
  'pulses/token-poll-completed': 'This assessment has already been completed.<br/><br/>Remember that once the assessment is completed, it is not possible to access it again.',
  'pulses/poll-not-found': 'La encuesta solicitada no existe.<br/><br/>Verifique su token de acceso.',
  'pulses/poll-has-ended': 'The requested assessment does not exist.<br/><br/>Please check your access token.',
  'pulses/poll-employee-not-found': 'The assessment you are trying to access does not exist.<br/><br/>Please verify your participation and try again.',
  'pulses/missing-extra-questions': 'All additional questions must be answered.',
  'undefined': 'A system error has occured.',
  'timezone': 'The selected time is not valid for that time zone.',
  'question_emply': 'The question cannot be empty.',
  'validator/select_one': 'Select at least one language.',
  'validator/empty-field': 'The field cannot be empty.',
  'validator/no_receivers': 'The assessment must have at least one respondent.',
  'image/size': 'Image size must be less than 2MB.',
  'employees_limit': 'You have already reached the limit of employees allowed by your license.',
  'engagements_limit': 'You have already reached the limit of Engagements allowed by your license.',
  'pulses_limit': 'You have already reached the limit of Pulses allowed by your license.',
  'modal_identify_type': 'Please select a valid ID type.',
  'modal_identify_document': 'Please enter a valid ID number.',
  'modal_first_name': 'Please enter a valid first name.',
  'modal_last_name': 'Please enter a valid last name.',
  'modal_exists_email': 'The email entered already exists.',
  'modal_user_not_employee': 'The email entered exists as another type of user',
  'modal_exist_identification': 'The ID number entered already exists.',
  'modal_email': 'Please enter a valid email.',
  'modal_gender': 'Please select a valid gender.',
  'modal_antiquity_range': 'Please enter a valid start date.',
  'modal_department': 'Please select a valid department.',
  'modal_charge': 'Please select a valid job title.',
  'modal_job_type': 'Please select a valid type of contract.',
  'modal_age_range': 'Please enter a valid date of birth.',
  'modal_country': 'Please select a valid country.',
  'modal_headquarter': 'Please select a valid location.',
  'modal_academic_degree': 'Please select a valid level of education.',
  'modal_validation_error': 'There are records to be corrected.',
  'email_already_taken': 'The email entered is already in use.',
  'groups/updated-plan-some': 'The change was not applied for all companies.',
  'groups/updated-plan-none': 'The change was not applied due to an error.',
  'video/size': 'Video size must be less than 20MB.',
  'error_disable_questionnaires': 'Not all questionnaires can be disabled',
  'auth/enterprise_disabled': 'The requested company is inactive',
  'auth/employee_disabled': 'The requested employee is inactive',
  'auth/password-not-match': 'The current password in incorrect',
  'auth/email-not-match': 'The current password in incorrect',
  'auth/email-already-registered': 'The new email is already assigned to another user',
  'auth/expired-token': 'Yor session time has expired',
  'currencies/01': 'The name is required',
  'currencies/02': 'The symbol is required',
  'currencies/03': 'The value is required',
  'currencies/04': 'The ID is required',
  'error_verify-identification/products-conection-fail': 'Errors were encountered while communicating with the following products',
  'error_verify-identification/products-conection-fail2': 'Please contact your service provider to resolve this issue',
  'tokens/not-found': 'The selected transaction cannot be found',
  'tokens/error_on_payment_gateway': 'Right now we can\'t redirect you to the payment button, please try again later',
  // reCAPTCHA
  'recaptcha/retry': 'The reCAPTCHA could not be verified. Try again',
  'recaptcha/must-verify': 'The reCAPTCHA must be verified',
  'recaptcha/missing-input-secret': 'The reCAPTCHA private key is missing.',
  'recaptcha/invalid-input-secret': 'The reCAPTCHA private key is invalid',
  'recaptcha/missing-input-response': 'The reCAPTCHA token is missing',
  'recaptcha/invalid-input-response': 'The reCAPTCHA token is invalid',
  'recaptcha/timeout-or-duplicate': 'The reCAPTCHA has expired. Try again',
  'recaptcha/bad-request': 'The reCAPTCHA could not be verified. Try again'
}
