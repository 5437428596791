
<template>
  <v-container>
    <v-row>
      <v-col class="ml-2 mr-2">
        <v-card min-height="17em">
          <v-toolbar dense light flat style="border: 1px solid green;">
            <v-toolbar-title class="text-uppercase">
              {{ $t('dashboard.active_clients') }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn icon light v-on="on" @click="$router.push({ name: 'AdminCustomers', params: { hasFilter: true, routeFilter: '1' } })">
                    <v-icon>fa-list-ul</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('input.list') }}</span>
              </v-tooltip>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-row v-if="info.activeCustomers.length">
              <v-col v-for="(customer, k) in info.activeCustomers" :key="k">
                <v-tooltip bottom color="primary" v-if="user.role === user.view">
                  <template v-slot:activator="{ on }">
                    <v-btn tile style="letter-spacing: 0" class="text-capitalize" color="primary" width="100%" @click="openModal(customer)" v-on="on">
                      {{ getName(customer) }}
                      <v-icon
                        class="ml-2"
                        small
                        right
                      >fa-sign-out-alt
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.login_as', {name: customer.name}) }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <span v-else>
              {{ $t('dashboard.register_not_found') }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="ml-2 mr-2">
        <v-card min-height="17em">
          <v-toolbar dense light flat style="border: 1px solid #3899da;">
            <v-toolbar-title class="text-uppercase">
              {{ $t('dashboard.potential_clients') }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn icon light v-on="on" to="/admin-customers">
                    <v-icon>fa-list-ul</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('input.list') }}</span>
              </v-tooltip>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-row v-if="info.potentialCustomers.length">
              <v-col v-for="(customer, k) in info.potentialCustomers" :key="k">
                <v-tooltip bottom color="primary" v-if="user.role === user.view">
                  <template v-slot:activator="{ on }">
                    <v-btn tile style="letter-spacing: 0" class="text-capitalize" color="primary" width="100%" @click="openModal(customer)" v-on="on">
                      {{ getName(customer) }}
                      <v-icon
                        class="ml-2"
                        small
                        right
                      >fa-sign-out-alt
                      </v-icon>
                    </v-btn>
                    </template>
                  <span>{{ $t('input.login_as', {name: customer.name}) }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <span v-else>
              {{ $t('dashboard.register_not_found') }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="ml-2 mr-2">
        <v-card min-height="17em">
          <v-toolbar dense light flat style="border: 1px solid red;">
            <v-toolbar-title class="text-uppercase">
              {{ $t('dashboard.inactive_clients') }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn icon light v-on="on" @click="$router.push({ name: 'AdminCustomers', params: { hasFilter: true, routeFilter: '0' } })">
                    <v-icon>fa-list-ul</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('input.list') }}</span>
              </v-tooltip>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-row v-if="info.disabledCustomers.length">
              <v-col v-for="(customer, k) in info.disabledCustomers" :key="k">
                <v-tooltip bottom color="primary" v-if="user.role === user.view">
                  <template v-slot:activator="{ on }">
                    <v-btn tile style="letter-spacing: 0" class="text-capitalize" color="primary" width="100%" @click="openModal(customer)" v-on="on">
                      {{ getName(customer) }}
                      <v-icon
                        small
                        class="ml-2"
                        right
                      >fa-sign-out-alt
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.login_as', {name: customer.name}) }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <span v-else>
              {{ $t('dashboard.register_not_found') }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-tabs
            v-model="tab"
            class="elevation-2"
            centered
            grow
            light
            elevation="0"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#1">
              {{ $t('dashboard.products_activity') }}
            </v-tab>
            <v-tab href="#2">
              {{ $t('dashboard.emails_activity') }}
            </v-tab>

            <v-tab-item value="1">
              <v-card flat>
                <v-card-text class="text-center">
                  <canvas id="chartCanvas"></canvas>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="2">
              <v-card flat>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            {{$t('emails.pending')}}
                          </th>
                          <th class="text-center warning--text">
                            {{$t('emails.inProgress')}}
                          </th>
                          <th class="text-center success--text">
                            {{$t('emails.success')}}
                          </th>
                          <th class="text-center error--text">
                            {{$t('emails.failed')}}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center">
                            {{ Number(info.emailsCount.pending[0].cnt)
                                .toString()
                                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") }}
                          </td>
                          <td class="text-center">
                            {{ Number(info.emailsCount.in_progress[0].cnt)
                                .toString()
                                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") }}
                          </td>
                          <td class="text-center">
                            {{ Number(info.emailsCount.success[0].cnt)
                                .toString()
                                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") }}
                          </td>
                          <td class="text-center">
                            {{ Number(info.emailsCount.failed[0].cnt)
                                .toString()
                                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider></v-divider>

        <v-card>
          <v-card-title>
            <div class="title text-uppercase">{{ $t('dashboard.activities_resume') }}</div>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-divider class="my-3"></v-divider>

          <v-row row wrap class="ml-3">
            <v-col xs="12" sm="6">
              <v-autocomplete
                :label="$t('dashboard.filter_by_product')"
                :items="filters"
                prepend-inner-icon="mdi-filter-outline"
                @change="filterItems"
              ></v-autocomplete>
            </v-col>
            <v-col xs="12" sm="6">
              <v-text-field
              v-model.lazy="search"
              append-icon="mdi-magnify"
              :label="$t('customers.look_for')"
              single-line
              hide-details
            ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col xs="12" sm="12">
              <h1 class="text-uppercase ml-4"> {{ $t('dashboard.activity_creation') }} </h1>
            </v-col>
            <v-col class="px-8" xs="12" sm="6">
              <x-date-picker
                @date-picked="changeOption({ startAt: $event })"
                :label="$t('emails.dateFrom')"
                name="date_delivery"
              />
            </v-col>
            <v-col class="px-8" xs="12" sm="6">
              <x-date-picker
                @date-picked="changeOption({ endAt: $event })"
                name="date_delivery"
                :label="$t('emails.dateTo')"
              />
            </v-col>
          </v-row>

          <x-data-table
          :headers="headers"
          :parent-fetch-data="getActivities"
          :options="options"
          no-data="customers.no_data"
        >
          <template v-slot:structure="prop">
            <td class="text-center text-capitalize">{{ prop.item.customer.name }}</td>
            <td class="text-center text-capitalize">{{ prop.item.enterprise.name }}</td>
            <td class="text-center text-capitalize">{{ prop.item.product.name }}</td>
            <td class="text-center text-capitalize">{{ prop.item.productService.name }}</td>
            <td class="text-center text-capitalize">{{ prop.item.createdAt | date }}</td>
          </template>
        </x-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="show" width="40em">
      <v-card>
        <v-card-text>
          <v-toolbar light dense flat>
            <v-toolbar-title class="text-uppercase">
              {{ $t('dashboard.login_as_customer') }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon @click="closeModal">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-row>
            <v-col align="center" cols="12">
              <h1 class="headline-2">
                {{ $t('dashboard.login_as_customer_body', { name: customerSelected.name }) }}
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="end">
              <v-btn
                @click="closeModal"
                class="mr-4"
              >
                {{ $t('input.cancel') }}
              </v-btn>
              <v-btn
                color="primary"
                @click="loginAsCustomer(customerSelected.id, customerSelected.type)"
              >
                {{ $t('input.confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import authService from '../../services/auth'
import dashboardService from '../../services/dashboard'
import productsService from '../../services/products'

const echarts = require('echarts')

export default Vue.extend({
  props: {
    info: Object
  },
  data () {
    return {
      customerSelected: {
        name: '',
        id: '',
        type: ''
      },
      show: false,
      tab: '1',
      chart: null,
      headers: [
        'dashboard.customer',
        'dashboard.enterprise',
        'dashboard.product',
        'dashboard.service',
        'dashboard.createdAt'
      ],
      filters: [],
      options: {
        filter: null,
        search: null,
        startAt: null,
        endAt: null
      },
      search: null,
      timer: null
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    getActivities (options) {
      return dashboardService.getActivities(options)
    },
    openModal (customer) {
      this.customerSelected = customer
      this.show = true
    },
    closeModal () {
      this.customerSelected = {
        name: '',
        id: '',
        type: ''
      }
      this.show = false
    },
    filterItems (value) {
      this.options = {
        ...this.options,
        filter: value
      }
    },
    changeOption (keyValue) {
      this.options = {
        ...this.options,
        ...keyValue
      }
    },
    loginAsCustomer (customerId, type) {
      return authService.loginAsCustomer(customerId, this.user)
        .then((res) => {
          return this.$store
            .dispatch('session/loginAs', res)
            .then((res) => {
              this.$router.push(type === 'commercial' ? '/commercial-profile' : '/measuring-tools')
              setTimeout(() => {
                window.location.reload(false)
              }, 150)
            })
            .catch(err => (this.$store.dispatch('alert/error', err)))
        }).catch(err => (this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))))
    },
    constructChart () {
      const canvas = document.getElementById('chartCanvas')
      canvas.width = window.innerWidth - 650 < 1024 ? (window.innerWidth - 650) : 1024
      canvas.height = (window.innerHeight - 330) < 300 ? 300 : (window.innerHeight - 330)
      this.chart = echarts.init(canvas)
      const pie = {
        backgroundColor: '#FFFFFF',
        title: {
          text: this.$t('dashboard.products_activity'),
          x: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          bottom: 'bottom',
          data: this.info.productsActivity.items
        },
        series: [
          {
            name: 'Serie',
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            color: [
              '#4fc6b0',
              '#eb604c',
              '#3899d9',
              '#3375d2',
              '#3b3b3b'
            ],
            data: this.info.productsActivity.values,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              position: 'outside',
              formatter: '{b}: {c} ({d}%)'
            }
          }
        ]
      }
      this.chart.setOption(pie)
    },
    getName (customer) {
      let name
      if (customer.type === 'personal') {
        name = customer.enterprises[0].name
      } else {
        name = customer.name
      }
      return name.length > 15 ? `${name.substring(0, 12)}...` : name
    }
  },
  mounted () {
    this.constructChart()
    if (this.user.role === 'admin' && this.user.role === this.user.view) {
      productsService.listActive()
        .then(res => {
          this.filters = res.map((product) => ({
            text: product.name,
            value: product.id
          }))
          this.filters.push({ text: this.$t('page_all'), value: null })
        })
    }
  },
  watch: {
    search () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.options = {
          ...this.options,
          search: this.search
        }
      }, 3000)
    }
  }
})
</script>

<style lang="css" scoped>
.text-center{
  text-align: center !important;
}
.info-data{
  font-size: 3em;
}
.minipercentage{
  font-size: 2em !important;
}
.padding-left-name{
  padding-left: .4em !important;
}
.expired-plan{
  color: red;
  font-weight: bold;
}
.padding-right-name{
  padding-right: .4em !important;
}
.text-success{
  color: #4caf50 !important
}
.text-error{
  color: #ff5252 !important
}
.text-progress{
  color: #ad7816 !important;
}
.no-polls{
  font-size: 1.8em;
  color: lightgray;
}
.padding-card{
  padding: 3em;
}
.no-color{
  color: #ffffff00;
}
.pulse {
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgb(56, 153, 218);
  animation: pulse 2s infinite;
}

.no-decoration{
  text-decoration: none;
}

.survey-title{
  font-size: 1.2em;
  font-weight: bold;
}
</style>
