<template>
  <v-container class="select-type">
    <v-card :flat="!user.id">
      <v-card-title primary-title class="justify-center">
        {{ $t('auth.view_select_title') }}
      </v-card-title>
      <v-row>
        <v-col md="6" @click="user.id ? $router.push('/customer/create-personal') : $router.push('/auth/sign-up-as-personal')">
          <v-card
            color="primary"
            dark
            style="padding: .5em;"
            class="text-center only-card"
          >
            <v-row justify="space-around" class="pa-2">
              <v-icon x-large color="white" class="mt-2">fa-building</v-icon>
            </v-row>
            <v-row justify="space-around" class="pa-1 mb-1">
              <h2 class="text-uppercase">
                {{ $t('auth.personal_account') }}
              </h2>
            </v-row>
            <v-row justify="space-around" class="px-4">
              <p>
                {{ $t('auth.customer_personal_d') }}
              </p>
            </v-row>
            <v-row justify="space-around" class="pa-2">
              <v-btn outlined color="white">
                {{ $t('input.select') }}
              </v-btn>
            </v-row>
          </v-card>
        </v-col>
        <v-col md="6" @click="user.id ? $router.push('/customer/create-commercial') : $router.push('/auth/sign-up-as-commercial')">
          <v-card
            color="primary"
            dark
            style="padding: .5em;"
            class="text-center only-card"
          >
            <v-row justify="space-around" class="pa-2">
              <v-icon x-large color="white" class="mt-2">fa-city</v-icon>
            </v-row>
            <v-row justify="space-around" class="pa-1 mb-1">
              <h2 class="text-uppercase">
                {{ $t('auth.commercial_account') }}
              </h2>
            </v-row>
            <v-row justify="space-around" class="px-4">
              <p>
                {{ $t('auth.customer_commercial_d') }}
              </p>
            </v-row>
            <v-row justify="space-around" class="pa-2">
              <v-btn outlined>
                {{ $t('input.select') }}
              </v-btn>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn outlined
            color="primary"
            class="mt-2"
            @click="$router.push(user.id ? '/admin-customers' : '/auth/sign-in')"
          >
            {{ user.id ? $t('input.back_to_list') : $t('input.back_to_login') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  }
})
</script>
<style>
.select-type .only-card {
  height: 15.6rem;
}
.select-type .v-card__title {
  word-break: break-word;
}
.select-type p {
  height: 60px;
}
@media screen and (max-width: 1200px) {
  .select-type .only-card {
    height: 18rem;
  }
  .select-type p {
    height: 90px;
  }
}
@media screen and (max-width: 500px) {
  .select-type p {
    height: 160px;
  }
  .select-type .only-card {
    height: 25rem;
  }
}
@media screen and (max-width: 330px) {
  .select-type p {
    height: 70px;
  }
  .select-type .only-card {
    height: 16rem;
  }
}
</style>
