<template>
  <v-container>

    <v-row justify="start">
      <v-col class="display-1">
        {{ $t('currencies.editar_title') }}
      </v-col>
    </v-row>

    <v-row class="child-flex">
      <v-col>
        <v-card class="mx-5 py-3 px-5">
          <v-container>
            <v-form>
              <v-row justify="center">
                <v-col cols="12" sm="8" md="7">
                  <v-text-field
                    :label="$t('currencies.name')"
                    v-model="data.name"
                    type="text"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-text-field
                    :label="$t('currencies.value')"
                    outlined
                    :disabled="data.isBase === 1"
                    v-model="data.value"
                    type="number"
                  ></v-text-field>
                </v-col>
                </v-row>
              <v-row justify="center">
                <v-col cols="12" sm="6" md="5" class="pt-0">
                  <v-text-field
                    :label="$t('currencies.code')"
                    v-model="data.paymentSymbol"
                    type="text"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5" class="pt-0">
                  <v-text-field
                    :label="$t('currencies.symbol')"
                    v-model="data.currencySymbol"
                    type="text"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="10" class="pt-0">
                  <v-select outlined multiple chips deletable-chips hide-details
                    v-model="computedSelectedCountries"
                    :items="computedCountries"
                    :label="$t(`countries.countries`)"
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" class="pb-0">
                  <p class="text-center"> {{ liveDescription }} </p>
                </v-col>
              </v-row>

              <v-row class="mt-0" align="center">
                <v-col class="text-right">
                  <v-btn
                    class="secondary"
                    :to="`/settings/currencies`">
                    {{ $t('input.cancel') }}
                  </v-btn>
                </v-col>
                <v-col class="text-left">
                  <x-async-btn
                    class="white--text"
                    :action="onSubmit"
                  ><v-icon></v-icon>&nbsp;{{ $t('input.edit') }}
                  </x-async-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import currencyService from '../../services/currencies'
import countryService from '../../services/countries'
import formatItems from '../../utils/form-format-items-list'

export default Vue.extend({
  data () {
    return {
      data: {},
      baseCurrency: {},
      countries: [],
      selectedCountries: [],
      loading: false
    }
  },
  mounted () {
    this.$store.dispatch('session/showSelectorCurrency', true)
  },
  beforeDestroy () {
    this.$store.dispatch('session/showSelectorCurrency', false)
  },
  created () {
    // Aca obtengo la moneda base
    currencyService.getBase()
      .then((res) => {
        this.baseCurrency = res.data
      }).catch((err) => {
        this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
      })

    currencyService.getById(this.$route.params.id, this.$i18n.locale)
      .then((res) => {
        this.data = res.data.currency[0]
        this.selectedCountries = res.data.countries
      }).catch((err) => {
        this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
      })

    countryService.list()
      .then((res) => {
        this.countries = res.items
      })
  },
  methods: {
    onSubmit () {
      this.loading = true
      return currencyService.update(this.data.id, { ...this.data, selectedCountries: this.selectedCountries })
        .then((res) => {
          this.loading = false
          this.$router.push('/settings/currencies')
          this.$store.dispatch('alert/success', this.$t('currencies.successfully_edited'))
        }).catch((err) => {
          this.loading = false
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    }),
    computedCountries () {
      if (!this.countries.length) return []
      return formatItems(this.countries, item => {
        const country = item.translations.find(i => i.lang === this.user.lang)
        return {
          value: item.id,
          text: country.label
        }
      }).sort((a, b) => b.text - a.text)
    },
    computedSelectedCountries: {
      get () {
        if (!this.selectedCountries.length) return []
        return formatItems(this.selectedCountries, item => {
          const country = item.translations.find(i => i.lang === this.user.lang)
          return {
            value: item.id,
            text: country.label
          }
        }).sort((a, b) => b.text - a.text)
      },
      set (val) {
        const found = this.countries.filter(x => val.includes(x.id))
        this.selectedCountries = found
      }
    },
    liveDescription () {
      if (this.data.name === '' || this.data.value === 0) return ''
      const result = this.data.value / this.baseCurrency.value
      return `${this.baseCurrency.value} ${this.baseCurrency.name} ${this.$t('currencies.equals')} ${result} ${this.data.name}`
    }
  }
})
</script>
