<template>
  <v-container>
    <v-row wrap row>
      <v-col cols="12" sm="6">
        <h4 class="display-1">{{ $t('customers.profile_information') }}</h4>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="pa-4">
          <v-row>
          <v-col cols=12 sm="6">
              <span class="text-capitalize" style="font-size: 1.4em">
                {{ (user.customer || {}).name }}
              </span> <br>
              <span class="text-uppercase" style="color: gray">
                {{ $t('customers.commercial_account') }}
              </span>
            </v-col>
            <v-col cols="12" sm="6" align="end" justify="end" class="text-center">
              <span class="text-uppercase" >{{ $t('customers.available_tokens') }}</span> <br>
              <span class="text-uppercase subtitle tokens-qty"> {{ customerBalance }} </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="space-around">
      <v-col>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              {{ $t('customers.registered_enterprises') }}
            </v-toolbar-title>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="enterprises"
            hide-default-footer
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col align="center">
        <v-card class="pa-4" v-if="!isSimpleEditing">
          <v-row>
            <v-col>
              <v-list>
                <v-list-item-content>
                  <span
                    class="font-weight-regular caption"
                  >{{ $t('customers.contact_information') }}</span>
                  <span>{{ (user.customer || { identifyType: { translate: {} } }).identifyType.translate.label | identification }} {{ (user.customer || {}).identifyDocument }}</span>
                </v-list-item-content>
              </v-list>
            </v-col>
            <v-col>
              <v-list>
                <v-list-item-content>
                  <span
                    class="font-weight-regular caption"
                  >{{ $t('customers.admin_name') }}</span>
                  <span>{{ (user.customer || {}).name }}</span>
                </v-list-item-content>
              </v-list>
            </v-col>
            <v-col>
              <v-list>
                <v-list-item-content>
                  <span
                    class="font-weight-regular caption"
                  >{{ $t('input.phone_number') }}</span>
                  <span>{{ (user.customer || {}).phoneNumber }}</span>
                </v-list-item-content>
              </v-list>
            </v-col>
            <v-col cols="12" sm="2" align-self="center">
              <v-btn color="primary" block @click="toggleSimpleEdit">
                <v-icon>edit</v-icon>
                {{ $t('input.edit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <v-card style="padding: 0 4em 0 4em" v-else>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(sendSimpleEdit)">
              <v-row>
                <v-col>
                  <v-row>
                    <v-col md="4">
                      <x-inputs-autocomplete
                        :items="getFormattedIdentifyTypes"
                        :input="form.identifyTypeId"
                        @updateInput="($event) => form.identifyTypeId = $event"
                        light
                        :label="$t('input.identify_type_s')"
                        name="identify_type_id"
                        rules="required"
                      >
                      </x-inputs-autocomplete>
                    </v-col>
                    <v-col md="8">
                      <x-inputs-input
                        :input="form.identifyDocument"
                        @updateInput="($event) => form.identifyDocument = $event"
                        light
                        :label="$t('input.identify_document')"
                        name="identify_document"
                        :append-outer-icon="$t('help.icon')"
                        :help-message="$t('help.enterprise.create.identifyDocument')"
                        rules="required"
                      ></x-inputs-input>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col style="margin-top: 0.7em">
                <x-inputs-input
                    :input="form.name"
                    @updateInput="($event) => form.name = $event"
                    light
                    :label="$t('input.first_name')"
                    name="first_name"
                    :append-outer-icon="$t('help.icon')"
                    :help-message="$t('help.enterprise.create.firstName')"
                    rules="required"
                  ></x-inputs-input>
                </v-col>
                <v-col style="margin-top: 0.7em">
                  <x-inputs-input
                    :input="form.phoneNumber"
                    @updateInput="($event) => form.phoneNumber = $event"
                    light
                    :label="$t('input.phone_number')"
                    name="phone_number"
                    :append-outer-icon="$t('help.icon')"
                    :help-message="$t('help.enterprise.create.phoneNumber')"
                    rules="required"
                  ></x-inputs-input>
                </v-col>
              </v-row>
               <v-row justify="end" align="end">
                <v-col md="6" cols="12" sm="6" align="end">
                  <v-btn
                    small
                    @click="isSimpleEditing = !isSimpleEditing"
                    text
                    class="text-capitalize"
                  >{{ $t('input.cancel') }}</v-btn>
                  <v-btn
                    color="primary"
                    type="submit"
                    small
                    style="margin-left: 1em"
                    class="text-capitalize"
                  >{{ $t('input.save') }}
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row style="padding: 0 4em 0 4em">
      <v-col>
        <change-password :user="user" :disabled="isSimpleEditing"/>
      </v-col>
      <v-col>
        <change-email :user="user" :disabled="isSimpleEditing"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import identifyTypesService from '../../services/identify-types'
import customersService from '../../services/customers'
import tokenAccountDetailService from '../../services/token-account-detail'
import formatItems from '../../utils/form-format-items-list'

import ChangePassword from './change-password'
import ChangeEmail from './change-email'

export default Vue.extend({
  components: {
    ChangePassword,
    ChangeEmail
  },
  data () {
    return {
      enterprise: null,
      isSimpleEditing: false,
      customerBalance: 0,
      form: {
        id: null,
        identifyTypeId: null,
        identifyDocument: null,
        name: null,
        phoneNumber: null
      },
      enterprises: []
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user || { customer: {} }
    }),
    headers () {
      return [
        { text: this.$t('customers.company_name'), value: 'name', sortable: false },
        { text: this.$t('customers.country'), value: 'country.translate.label', sortable: false },
        { text: this.$t('customers.headquarter'), value: 'headquarter.name', sortable: false },
        { text: this.$t('customers.address'), value: 'address', sortable: false }
      ]
    },
    getFormattedIdentifyTypes () {
      if (!this.identifyTypes.length) return []
      return formatItems(this.identifyTypes, (item) => {
        const iType = item.translations.find(i => i.lang === this.user.lang)
        return {
          text: iType.label,
          value: item.id,
          code: item.code
        }
      })
    }
  },
  created () {
    this.getCustomerBalance()
    this.setForm()
    this.getIdentifyTypes()
    this.getEnterprises()
  },
  methods: {
    getCustomerBalance () {
      if (this.user.role === 'enterprise_admin') return
      return tokenAccountDetailService.getCustomerBalance(this.user.customer.id)
        .then((res) => {
          this.customerBalance = res.balance
        })
    },
    setForm () {
      this.form.id = this.user.customer ? this.user.customer.id : null
      this.form.identifyTypeId = this.user.customer.identifyTypeId
      this.form.identifyDocument = this.user.customer.identifyDocument
      this.form.name = this.user.customer.name
      this.form.email = this.user.email
      this.form.phoneNumber = this.user.customer.phoneNumber
    },
    toggleSimpleEdit () {
      this.isSimpleEditing = !this.isSimpleEditing
    },
    getIdentifyTypes () {
      identifyTypesService.list()
        .then((res) => {
          this.identifyTypes = res.items
          const it = this.identifyTypes.find(i => i.code === 'others')
          const index = this.identifyTypes.indexOf(it)
          this.identifyTypes.splice(index, 1)
          this.identifyTypes.splice(this.identifyTypes.length, 0, it)
        })
        .catch((error) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
        })
    },
    getEnterprises () {
      customersService.fetchLastFiveEnterprisesByCustomer(this.user.customer.id)
        .then((res) => {
          this.enterprises = res
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    sendSimpleEdit () {
      return customersService.edit(this.form.id, this.form)
        .then((res) => {
          this.toggleSimpleEdit()
          this.$store.dispatch('alert/success', this.$t('customers.successfully_edited'))
          this.user.customer.identifyTypeId = this.form.identifyTypeId
          this.user.customer.identifyDocument = this.form.identifyDocument
          this.user.customer.name = this.form.name
          this.user.customer.phoneNumber = this.form.phoneNumber
          this.$store.dispatch('session/updateEnterprise', this.user)
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    }
  }
})
</script>
