
// import aclCondition from '../../utils/acl-condition'
import store from '../../store'

export default async (to: any, from: any, next: any) => {
  // if (aclCondition(store.state.acl.wolf)) {
  //   await store.dispatch('acl/getAcl')
  // }

  if (store.state.session.token) {
    if (to.query && to.query.product) {
      store.dispatch('session/findHost', to.query.product)
        .then((res: any) => {
          const token = store.state.session.token
          if (res && res.url) {
            location.replace(`${res.url}/auth/sso?ssoToken=${token}`)
          } else {
            next('/auth/sign-in')
          }
        })
    } else {
      next('/dashboard')
    }
    return
  }

  next()
}
