
export default {
  termsNotAcceptedDialog: {
    title: 'Hemos actualizado nuestros Términos y condiciones de uso',
    input: 'Aceptar'
  },
  dataProtectionPoliciesNotAcceptedDialog: {
    title: 'Hemos actualizado nuestras Políticas de Protección de Datos',
    input: 'Aceptar'
  },
  confirmationModal: {
    not_reversible_action: 'Recuerde que está acción es irreversible'
  },
  not_allowed: 'No permitido',
  not_allowed_to_enable: 'Esta opción no se puede activar'
}
