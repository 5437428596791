
<template>
  <span class="v-badge theme--light" :class="classes">
    <slot></slot>
    <span class="v-badge__wrapper" v-if="value && validContent">
      <span aria-atomic="true" aria-label="Badge" aria-live="polite" role="status" :class="spanClasses"
            :style="styles">
          {{ content }}
      </span>
    </span>
  </span>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    overlap: Boolean,
    value: {
      default: true
    },
    content: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
    validContent () {
      return this.content !== '' && this.content !== '0'
    },
    classes () {
      return {
        'v-badge--bottom': this.bottom,
        'v-badge--left': this.left,
        'v-badge--overlap': this.overlap
      }
    },
    styles () {
      return {
        left: this.left ? 'auto' : 'calc(100% - 12px)',
        right: this.left ? 'calc(100% - 12px)' : 'auto',
        bottom: this.bottom ? 'auto' : 'calc(100% - 12px)',
        top: this.bottom ? 'calc(100% - 12px)' : 'auto'
      }
    },
    spanClasses () {
      return 'v-badge__badge ' + this.color
    }
  }
})
</script>

<style>
  .v-badge {
    position: relative;
  }
  .v-badge, .v-badge__badge {
    display: inline-block;
    line-height: 1;
  }
  .v-badge__wrapper {
    flex: 0 1;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .v-application--is-ltr .v-badge__badge {
    right: auto;
  }
  .v-badge__badge {
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    height: 20px;
    letter-spacing: 0;
    min-width: 20px;
    padding: 4px 6px;
    pointer-events: auto;
    position: absolute;
    text-align: center;
    text-indent: 0;
    top: auto;
    transition: .3s cubic-bezier(.25,.8,.5,1);
    white-space: nowrap;
  }
</style>
