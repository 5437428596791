
<template>
  <trans-wrapper
    :page-title="$t('settings.academic_degree')"
    :add-label="$t('settings.add')"
    :card-title="$t('settings.academic_degrees')"
    :alert-info="$t('settings.academic_degree_info')"
    :modal-title-add="$t('settings.academic_degree_add')"
    :modal-title-ena="$t('settings.academic_degree_enable')"
    :modal-title-dis="$t('settings.academic_degree_disable')"
    :modal-title-del="$t('settings.academic_degree_delete')"
    :modal-question-ena="$t('settings.master_references_enable_des')"
    :modal-question-dis="$t('settings.master_references_disable_des')"
    :modal-question-del="$t('settings.master_references_delete_des')"
    :item-services="academicDegreesService"
    :is-enterprise-editable="false"
  ></trans-wrapper>
</template>

<script>

import academicDegreesService from '../../services/academic-degrees'

import TransWrapper from './components/trans-wrapper.vue'

export default {
  components: {
    TransWrapper
  },
  data () {
    return {
      academicDegreesService
    }
  }
}
</script>
