
import computer from '../base64/computer'
import computer2 from '../base64/computer2'

export default {
  methods: {
    generateHowWorks () {
      return {
        margin: [0, 7, 0, 0],
        table: {
          widths: ['21.5%', '*'],
          body: [
            [
              {
                margin: [this.data.mainChallenge === 3 ? -7 : 12, 17, 0, 0],
                image: this.data.mainChallenge === 3 ? computer2 : computer,
                width: this.data.mainChallenge === 3 ? 104 : 78,
                height: this.data.mainChallenge === 3 ? 74 : 78
              },
              {
                table: {
                  widths: ['50%', '*'],
                  body: [
                    [
                      {
                        text: 'Simplicidad',
                        color: '#1999DA',
                        fontSize: 10,
                        bold: true
                      },
                      {
                        text: 'Modelo Experto',
                        color: '#1999DA',
                        fontSize: 10,
                        bold: true
                      }
                    ],
                    [
                      {
                        text: 'Encuestas intuitivas, accesibles desde PC, móvil o tablet. Diseñadas para su comprensión.',
                        color: '#222222',
                        fontSize: 8
                      },
                      {
                        text: 'Modelos expertos perfeccionados durante más de\n30 años de experiencia en casos reales y ajustados\npara los desafíos actuales.',
                        color: '#222222',
                        fontSize: 8
                      }
                    ],
                    [
                      {
                        text: 'Analítica de datos',
                        margin: [0, 4, 0, 0],
                        color: '#1999DA',
                        fontSize: 10,
                        bold: true
                      },
                      {
                        text: 'Flexibilidad',
                        margin: [0, 4, 0, 0],
                        color: '#1999DA',
                        fontSize: 10,
                        bold: true
                      }
                    ],
                    [
                      {
                        text: 'Visualiza el estado de tu empresa en tiempo real, analiza tendencias e identifica los factores de mayor impacto.',
                        color: '#222222',
                        fontSize: 8
                      },
                      {
                        text: 'Encuestas personalizables al estilo y lenguaje de\ntu empresa.',
                        color: '#222222',
                        fontSize: 8
                      }
                    ]
                  ]
                },
                layout: 'noBorders'
              }
            ]
          ]
        },
        layout: 'noBorders'
      }
    },
    async $getConfiguration () {
      return {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [47, 44, 47, 20],
        info: {
          title: 'Cotización OCC',
          author: 'OCC Solutions',
          subject: 'Cotización OCC'
        },
        defaultStyle: {
          fontSize: 12,
          font: 'Roboto',
          lineHeight: 1.2,
          margin: [0, 25, 0, 0]
        },
        header: (currentPage) => {
          //
        },
        footer: (currentPage) => {
          if (currentPage === 2) {
            return [
              {
                margin: [40, -80, 30, 0],
                columns: [
                  {
                    width: '62%',
                    margin: [0, -3, 0, 0],
                    table: {
                      widths: ['*', '*'],
                      body: [
                        [
                          {
                            colSpan: 2,
                            text: 'Servicios Opcionales de Consultoría',
                            color: '#1999DA',
                            fontSize: 10,
                            bold: true
                          }, ''
                        ],
                        [
                          {
                            text: [
                              'Charla de sensibilización\n',
                              'Entrega de resultados\n',
                              'Construcción de plan de acción'
                            ],
                            color: '#333333',
                            fontSize: 9
                          },
                          {
                            text: this.data.mainChallenge === 3
                              ? ['Diseño de Programa de\nformación de líderes']
                              : ['Grupos Focales\n', 'Entrevistas'],
                            color: '#333333',
                            fontSize: 9
                          }
                        ]
                      ]
                    },
                    layout: 'noBorders'
                  },
                  {
                    width: '*',
                    text: [
                      {
                        text: 'Condiciones\n',
                        color: '#1999DA',
                        fontSize: 10,
                        lineHeight: 1.4,
                        bold: true
                      },
                      {
                        text: 'Esta es una cotización inicial estimada que puede tener variaciones.\nForma de pago: 50% al inicio, 50% al final.\nEl pago deberá realizarse dentro de los treinta días siguientes a la radicación de la factura.',
                        color: '#333333',
                        fontSize: 9
                      }
                    ]
                  }
                ]
              }
            ]
          }
        },
        background: (currentPage) => {
          //
        },
        content: [
          // 01 Page
          await this.$generatePageOne(),
          // 02 Page
          this.data.mainChallenge === 1
            ? await this.$generatePageTwoPulse()
            : this.data.mainChallenge === 2
              ? await this.$generatePageTwoCultura()
              : await this.$generatePageTwoDml(),
          // 03 Page
          await this.$generatePageThree()
        ]
      }
    }
  }
}
