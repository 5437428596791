
<template>
  <div>
    <v-container>
      <v-row>
        <ValidationObserver v-slot="{ handleSubmit }" v-if="validToken" style="width: 100%">
          <v-form @submit.prevent="handleSubmit(updatePassword)" >
            <v-col xs12>
              <h4
                class="display-1 text-center grey--text"
                style="width:100%"
              >{{ $t('auth.recover_password') }}</h4>
            </v-col>

            <v-col xs12>
              <v-alert :value="!!error" color="error" outlined>
                <span class="font-weight-bold">{{ $t('auth.password_not_updated') }}</span>
              </v-alert>
            </v-col>

            <v-col xs12>
              <x-inputs-input
                :input="password"
                @updateInput="($event) => password = $event"
                :label="$t('input.new_password')"
                rules="required"
                type="password"
                name="password"
                vid="password"
              ></x-inputs-input>
            </v-col>

            <v-col xs12>
              <x-inputs-input
                :input="confirmPassword"
                @updateInput="($event) => confirmPassword = $event"
                :label="$t('input.new_password_confirm')"
                rules="required|confirmed:password"
                type="password"
                name="confirmPassword"
              ></x-inputs-input>
            </v-col>

            <v-col xs12>
              <v-btn
                type="submit"
                color="primary"
                block
                large
                :disabled="!valid"
              >{{ $t('auth.create_password') }}</v-btn>
            </v-col>
          </v-form>

        </ValidationObserver>
        <v-card flat v-else>
          <v-card-title>
            <h5 class="headline text-center" style="width:100%">
              <div v-if="tokenError === 'auth/token-not-found'">{{ $t('auth.token_not_valid') }}</div>
              <div v-else-if="tokenError === 'auth/token-expired'">{{ $t('auth.token_expired') }}</div>
              <div v-else-if="tokenError === 'auth/token-used'">{{ $t('auth.token_used') }}</div>
            </h5>
          </v-card-title>
          <v-card-text>{{ $t('auth.verify_or_contact') }}</v-card-text>
          <div class="text-center">
            <v-btn color="primary" large :to="{ path: '/auth/sign-in' }">{{ $t('auth.go_sign_in') }}</v-btn>
          </div>
        </v-card>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title class="headline" style="word-break: break-word;">{{ $t('auth.password_update') }}</v-card-title>
        <v-card-text>{{ $t('auth.password_updated') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" to="/auth/sign-in">{{ $t('auth.accept') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Vue from 'vue'

import authService from '../../services/auth'

export default Vue.extend({
  data () {
    return {
      password: '',
      confirmPassword: '',
      error: '',
      valid: true,
      dialog: false,
      token: '',
      validToken: false,
      tokenError: ''
    }
  },
  methods: {
    updatePassword () {
      return authService
        .updatePassword(this.password, this.confirmPassword, this.token)
        .then((res) => (this.dialog = true))
        .catch(err => (this.error = err.code))
    },
    checkTokenStatus () {
      this.token = this.$route.params.token
      return authService
        .checkTokenStatus(this.token)
        .then((res) => {
          this.validToken = true
        })
        .catch(err => {
          this.validToken = false
          this.tokenError = err.code
        })
    }
  },
  created () {
    this.checkTokenStatus()
  }
})
</script>
