
<template>
  <v-container id="enterprise-create" fluid style="max-width: 55rem; margin-top: 6rem;">
    <x-loading
      :display="loading"
    ></x-loading>

    <v-tabs
      v-model="step"
      class="elevation-2"
      centered
      grow
      light
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        href="#1"
      >
        {{ $t('enterprises.enterprise_information') }}
      </v-tab>
      <v-tab
        href="#2"
        v-if="user.role === 'admin'"
      >
        {{ $t('auth.view_sign_up_personal_user') }}
      </v-tab>

      <v-tab-item
        value="1"
      >
        <x-step-information
          :enterprise="enterprise"
          step="1"
          :nextAction="user.role === 'admin' ? 'input.continue' : 'input.save'"
          prevAction="input.cancel"
          @changeStep="verifyStepChanged"
        ></x-step-information>
      </v-tab-item>
      <v-tab-item
        value="2"
      >
        <x-step-contact-employee
          v-if="user.role === 'admin'"
          :enterprise="enterprise"
          step="2"
          nextAction="input.save"
          prevAction="input.back"
          @changeStep="verifyStepChanged"
        ></x-step-contact-employee>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import XStepInformation from './steps/information.vue'
// import XStepDemographicCuts from './steps/demographic-cuts.vue'
import XStepContactEmployee from './steps/contact-employee.vue'

import enterprisesServices from '../../services/enterprises'

export default Vue.extend({
  components: {
    XStepInformation,
    // XStepDemographicCuts,
    XStepContactEmployee
  },
  data () {
    return {
      tab: null,
      loading: false,
      enterprise: {
        name: '',
        sectorId: '',
        countryId: '',
        address: '',
        enterpriseSizeId: '',
        languageId: '',
        logo: '',
        demographicItems: [],
        contact: {
          firstName: '',
          lastName: '',
          genderId: '',
          identifyTypeId: '',
          identifyDocument: '',
          email: '',
          phoneNumber: '',
          address: '',
          birthdate: ''
        },
        license: {
          planId: '',
          startAt: '',
          duration: ''
        },
        headquarter: ''
      },
      step: '1'
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    verifyStepChanged (data, step) {
      this.enterprise = Object.assign({}, this.enterprise, data)
      const lastCase = this.user.role === 'admin' ? 3 : '2'
      switch (step) {
        case '0': return this.$router.go(-1)
        case lastCase: return this.create()
        default: this.step = step
      }
    },
    create () {
      this.loading = true
      return enterprisesServices.create(this.enterprise)
        .then((res) => {
          this.loading = false
          this.$store.dispatch('alert/success', this.$t('enterprises.created_enterprise'))
          this.$router.push(this.user.role === 'admin' ? '/admin-customers' : '/enterprises')
        })
        .catch((err) => {
          const error = this.$t(`errors.${err.code}`)
          this.$store.dispatch('alert/error', error)
          this.loading = false
        })
    }
  }
})
</script>

<style>
  #enterprise-create div.v-stepper,
  #enterprise-create div.v-stepper div.v-stepper__items,
  #enterprise-create div.v-stepper div.v-stepper__items div.v-stepper__wrapper {
    overflow: visible !important;
  }
</style>
