
<template>
  <ranges-wrapper
    :items="items"
    :title="$t('settings.ages_title')"
    :subtitle="$t('settings.ages_subtitle')"
  />
</template>

<script>

import RangesWrapper from './components/ranges-wrapper.vue'

export default {
  components: {
    RangesWrapper
  },
  data () {
    return {
      items: {
        'es': [
          'Menos de 25 años',
          'De 25 a 35 años',
          'De 35 a 45 años',
          'De 45 a 55 años',
          'Más de 55 años'
        ],
        'en': [
          'Less than 25 years',
          'From 25 to 35 years',
          'From 35 to 45 years',
          'From 45 to 55 years',
          'More than 55 years'
        ]
      }
    }
  }
}
</script>
