
export default {
  no_data: 'No hay datos disponibles para este corte demográfico.',
  master_references_section: 'Los cortes demográficos son las variables con las puedes segmentar la información de los reportes. Recomendamos configurarlos conforme a tus necesidades, para poder generar reportes con alto valor.<br>En las mediciones de cultura y engagement, no es posible generar reportes de menos de 5 personas.<br>Los cambios que hagas en esta sección aplican para mediciones futuras y no afectan las mediciones realizadas previamente.',
  master_references_section_additionals: 'Los cortes demográficos adicionales son opcionales. Puedes usarlos según la necesidad que tengas para segmentar la información de tus colaboradores.<br>Estos cortes están disponibles para los reportes de subcultura y engagement por población.<br>Los cambios que hagas en esta sección aplican para mediciones futuras y no afectan las mediciones realizadas previamente.',
  master_references_action: 'Estas acciones son de caracter delicado, el uso indebido de una referencia maestra está sujeta a su responsabilidad.',
  master_references_enable_des: 'El corte estará disponibles nuevamente en los listados de selección. Se podrá registrar o actualizar información con este corte.',
  master_references_disable_des: 'Este corte no estará habilitado para futuras mediciones y no se podrán asignar a colaboradores ni generar reportes. Las mediciones realizadas previamente no se verán afectadas por este cambio.',
  master_references_delete_des: 'Este corte no estará disponible para futuras mediciones y no se podrán asignar a colaboradores ni generar reportes. Las mediciones realizadas previamente no se verán afectadas por este cambio.',
  master_references_only_read: 'Este corte demográfico no es editable. Esta es la lista de las opciones disponibles para utilizar en la información que debe diligenciar al momento de crear los colaboradores.',
  actions: 'Acciones',
  add: 'Agregar',
  label: 'Nombre o etiqueta',
  description: 'Descripción',
  no_translation: 'No hay traducción para este idioma.',
  academic_degree: 'Configuración de nivel académico',
  academic_degree_read: 'Nivel académico configurado',
  academic_degrees: 'Nivel académico',
  academic_degree_info: 'Indicar los niveles académicos que se serán asignados a los colaboradores de todas las empresas',
  academic_degree_add: 'Agregar niveles académicos',
  academic_degree_enable: 'Habilitar nivel académico',
  academic_degree_disable: 'Inhabilitar nivel académico',
  academic_degree_delete: 'Borrar nivel académico',
  language: 'Configuración de idiomas',
  languages: 'Idiomas',
  language_info: 'Indicar los idiomas que se serán asignados a colaboradores y empresas',
  language_add: 'Agregar idioma',
  language_enable: 'Habilitar idioma',
  language_disable: 'Inhabilitar idioma',
  language_delete: 'Borrar idioma',
  department: 'Configuración de área o departamento',
  departments: 'Área o departamento',
  department_info: 'Registre todas las áreas o departamentos que hacen parte de su organización',
  department_add: 'Agregar área o departamento',
  department_enable: 'Habilitar área o departamento',
  department_disable: 'Inhabilitar área o departamento',
  department_delete: 'Borrar área o departamento',
  sector: 'Configuración de sectores empresariales',
  sectors: 'Sectores empresariales',
  sector_info: 'Asígna todas las sectores empresariales que manejará el sistema',
  sector_add: 'Agregar sector',
  sector_enable: 'Habilitar sector',
  sector_disable: 'Inhabilitar sector',
  sector_delete: 'Borrar sector',
  gender: 'Configuración de género',
  genders: 'Género',
  gender_info: 'Utilice el botón agregar, para incluir géneros adicionales',
  gender_add: 'Agregar género',
  gender_enable: 'Habilitar género',
  gender_disable: 'Inhabilitar género',
  gender_delete: 'Borrar género',
  job_type: 'Configuración de tipo de contratación',
  job_types: 'Tipo de contratación',
  job_type_info: 'Registre todos los tipos de contratación que existen en su organización',
  job_type_add: 'Agregar tipo de contratación',
  job_type_enable: 'Habilitar tipo de contratación',
  job_type_disable: 'Inhabilitar tipo de contratación',
  job_type_delete: 'Borrar tipo de contratación',
  country: 'Configuración de país',
  country_read: 'País configurado',
  countries: 'País',
  country_info: 'Asígna el país que estará disponible en el sistema ',
  country_add: 'Agregar país',
  country_enable: 'Habilitar país',
  country_disable: 'Inhabilitar país',
  country_delete: 'Borrar país',
  enterprise_size: 'Configuración de los tamaños de empresas',
  enterprise_sizes: 'Tamaños de empresas',
  enterprise_size_info: 'Asígna todos tamaños de empresas que estarán disponibles en el sistema ',
  enterprise_size_add: 'Agregar tamaños de empresas',
  enterprise_size_enable: 'Habilitar tamaños de empresas',
  enterprise_size_disable: 'Inhabilitar tamaños de empresas',
  enterprise_size_delete: 'Borrar tamaños de empresas',
  headquarters_title: 'Configuración de sede',
  headquarters_info: 'Registre todas las sedes en las que opera su organización',
  headquarters_country: 'País',
  headquarters_head: 'Sede',
  headquarters_countries: 'País',
  headquarters_heads: 'Sede',
  headquarters_create: 'Crear sede',
  headquarters_actions: 'Acciones',
  headquarters_enable: 'Habilitar',
  headquarters_disable: 'Inhabilitar',
  headquarters_delete: 'Eliminar',
  headquarters_updated: '¡Sede actualizada correctamente!',
  headquarters_deleted: '¡Sede eliminada!',
  headquarters_delete_title: 'Eliminar sede',
  headquarters_toggle_true_title: 'Inhabilitar sede',
  headquarters_toggle_false_title: 'Habilitar sede',
  headquarters_add: 'Añadir nueva sede',
  headquarters_created: '¡Sede creada correctamente!',
  charges_title: 'Configuración de nivel de cargo',
  charges_info: 'Registre todos los niveles de cargo que hacen parte de su organización',
  charges_department: 'Departamento (Opcional)',
  charges_head: 'Nivel de Cargo',
  charges_departments: 'Departamentos (Opcional)',
  charges_heads: 'Niveles de Cargos',
  charges_create: 'Crear cargo',
  charges_actions: 'Acciones',
  charges_enable: 'Habilitar',
  charges_disable: 'Inhabilitar',
  charges_delete: 'Eliminar',
  charges_updated: '¡Cargo actualizado correctamente!',
  charges_deleted: '¡Cargo eliminado!',
  charges_delete_title: 'Eliminar cargo',
  charges_toggle_true_title: 'Inhabilitar cargo',
  charges_toggle_false_title: 'Habilitar cargo',
  charges_add: 'Añadir nuevo cargo',
  charges_created: '¡Cargo creado correctamente!',
  antiquity_title: 'Rango de antigüedad',
  antiquity_subtitle: 'Rangos de antigüedad disponible',
  ages_title: 'Rango de edad',
  ages_subtitle: 'Rangos de edad disponible',
  tooltip_edit: 'Debe presionar ENTER para editar',
  terms_and_conditions_settings: 'Configuración de Términos y Condiciones',
  terms_and_conditions: 'Términos y Condiciones',
  terms_and_conditions_for_personal: 'Terminos y condiciones para clientes Única Empresa',
  terms_and_conditions_for_commercial: 'Terminos y condiciones para clientes Multi Empresa',
  data_protection_policies_settings: 'Configuración de Políticas de Protección de Datos',
  data_protection_policies: 'Políticas de Protección de Datos',
  data_protection_policies_for_personal: 'Políticas de Protección de Datos para clientes Única Empresa',
  data_protection_policies_for_commercial: 'Políticas de Protección de Datos para clientes Multi Empresa',
  products_title: 'Configuración de Productos',
  products_logo: 'Logo del Producto',
  products_conf: 'Configuración de Producto',
  products_description: 'Descripción: ',
  products_status: 'Status',
  products_active_disabled: 'Este producto no se ha integrado, por lo que no se puede activar',
  products_active_disabled_title: 'Activación de producto',
  products_edit: 'Editar producto',
  products_modal_enable: '¿Desea habilitar el producto?',
  products_modal_disable: '¿Desea inhabilitar el producto?',
  products_modal_enable_des: 'Una vez habilitado, el producto estará disponible en el sistema',
  products_modal_disable_des: 'Una vez sea inhabilitado, el producto no podrá ser usado en el sistema',
  products_edited: 'El producto ha sido editado correctamente',
  products_description_max_limit: 'Máximo 230 caracteres',
  tokens_title: 'Configuración Precio Tokens',
  tokens_edit_price: 'editar precio',
  tokens_edit_minrange: 'Rango Mínimo',
  tokens_edit_maxrange: 'Rango Maximo',
  tokens_edit_price_amount: 'Precio en USD',
  tokens_price_edited: 'El precio ha sido editado correctamnete',
  customer_type: 'Tipo de cliente',
  service_edit: 'Editar servicio',
  product_settings: 'Ajustes del producto',
  public_product_title: '¿Desea hacer este producto público?',
  private_product_title: '¿Desea hacer este producto privado?',
  public_product_tip: 'Al hacer este producto público el sistema otorgará automáticamente accesos a este a cada uno de los clientes activos registrados que no lo posean desahibilitado',
  private_product_tip: 'Al hacer este producto privado el sistema mantendrá el acceso a cada uno de los clientes que ya lo tienen y los que no lo posean tendrán que solicitarlo',
  additional_demographics1_setting: 'Configuración de demográfico adicional 1',
  additional_demographics1: 'Demográfico adicional 1',
  additional_demographics1_info: 'Registre todos los posibles valores para el demográfico adicional 1',
  additional_demographics1_add: 'Agregar demográfico adicional 1',
  additional_demographics1_enable: 'Habilitar demográfico adicional 1',
  additional_demographics1_disable: 'Inhabilitar demográfico adicional 1',
  additional_demographics1_delete: 'Borrar demográfico adicional 1',
  additional_demographics2_setting: 'Configuración de demográfico adicional 2',
  additional_demographics2: 'Demográfico adicional 2',
  additional_demographics2_info: 'Registre todos los posibles valores para el demográfico adicional 2',
  additional_demographics2_add: 'Agregar demográfico adicional 2',
  additional_demographics2_enable: 'Habilitar demográfico adicional 2',
  additional_demographics2_disable: 'Inhabilitar demográfico adicional 2',
  additional_demographics2_delete: 'Borrar demográfico adicional 2',
  demographic_already_exists: '{0} ya existe',
  optional: 'opcional',
  // Segmentación Adicional
  additional_segmentation_head: 'Segmentación Adicional',
  additional_segmentation_info: 'Registre todas las segmentaciones adicionales que hacen parte de su organización',
  additional_segmentation_table_heads: 'Segmentaciones',
  additional_segmentation_table_actions: 'Acciones',
  additional_segmentation_no_data: 'No hay datos disponibles',
  additional_segmentation_description: 'La segmentación adicional es opcional. Puedes usarla según la necesidad que tengas para segmentar la información de tus colaboradores.<br>Estas segmentaciones están disponibles para los reportes de Energy Compass por población.<br>Los cambios que hagas en esta sección aplican para mediciones futuras y no afectan las mediciones realizadas previamente.',
  additional_segmentation_enable_des: 'La segmentación estará disponibles nuevamente en los listados de selección. Se podrá registrar o actualizar información con esta segmentación.',
  additional_segmentation_disable_des: 'Esta segmentación no estará habilitada para futuras mediciones y no se podrán asignar a colaboradores ni generar reportes. Las mediciones realizadas previamente no se verán afectadas por este cambio.',
  additional_segmentation_delete_des: 'Esta segmentación no estará disponible para futuras mediciones y no se podrán asignar a colaboradores ni generar reportes. Las mediciones realizadas previamente no se verán afectadas por este cambio.',
  additional_segmentation_options: 'Opciones',
  additional_segmentation_enable: 'Habilitar',
  additional_segmentation_disable: 'Deshabilitar',
  additional_segmentation_updated: '¡Segmentación actualizada correctamente!',
  additional_segmentation_deleted: '¡Segmentación eliminada!',
  additional_segmentation_delete_title: 'Eliminar segmentación',
  additional_segmentation_toggle_true_title: 'Deshabilitar segmentación',
  additional_segmentation_toggle_false_title: 'Habilitar segmentación',
  additional_segmentation_add: 'Añadir nueva segmentación',
  additional_segmentation_created: '¡Segmentación creada correctamente!',
  additional_segmentation_detail_n: 'opción {n}',
  additional_segmentation_insert_detail: 'Insertar opción {n} {o}',
  additional_segmentation_remove_detail: 'Eliminar opción',
  additional_segmentation_add_detail: 'Añadir opción',
  additional_segmentation_options_to: 'Opciones para',
  additional_segmentation_options_desc: 'Agrega las opciones para esta segmentación. El encuestado tendrá que elegir entre una de estas, al momento de comenzar la medición.',
  additional_segmentation_details_updated: '¡Opciones guardadas correctamente!'
}
