
import line from '../base64/line'
import dot from '../base64/dot'
import pieDml from '../base64/pie-dml'

export default {
  methods: {
    printDotsDml () {
      const arr = []
      for (var i = 0; i < 2; i++) {
        arr.push({
          image: dot,
          fit: [14, 14],
          absolutePosition: { x: 47, y: 82.5 + (i * 50) }
        })
      }

      return arr
    },
    $generatePageTwoDml () {
      return [
        {
          pageBreak: 'before',
          text: 'Nuestro modelo',
          fontSize: 12.5,
          bold: true
        },
        {
          image: line,
          margin: [0, 0, 0, 0],
          width: 90,
          height: 2.4
        },
        this.printDotsDml(),
        {
          text: 'El modelo DML mide 3 dimensiones: Dirección, Movilización y Logro, valora 55 conductas de liderazgo.',
          margin: [28, 20, 210, 0],
          color: '#222222',
          fontSize: 10
        },
        {
          text: 'La herramienta consolida las percepciones de diferentes personas al rededor del líder, quienes desempeñan cargos variados y se relacionan con ellos de diferentes maneras, para medir su desempeño y competencias y contrastarlas con su auto-percepción. Contarás con una encuesta completa que podrás ajustar a tus necesidades.',
          margin: [28, 22, 210, 0],
          color: '#222222',
          fontSize: 10
        },
        {
          image: pieDml,
          absolutePosition: { x: 377, y: 77 },
          width: 145
        },
        {
          text: '¿Cómo funciona DML 360°?',
          margin: [0, 30, 0, 0],
          fontSize: 12.5,
          bold: true
        },
        {
          image: line,
          margin: [0, 4, 0, 0],
          width: 155,
          height: 2.3
        },
        {
          margin: [0, 20, 0, 20],
          columns: [
            {
              width: '20%',
              ol: [
                { text: '\u200B\tMIDE', fontSize: 10, bold: true, color: '#1999DA' },
                { text: '\u200B\tACTÚA', fontSize: 10, bold: true, color: '#1999DA' }
              ],
              lineHeight: 2.8,
              markerColor: '#1999DA'
            },
            {
              width: '*',
              text: [
                'Mide el perfil del  líder basado en su auto-percepción y las de las personas que lo\nrodean Líder(es), Pares y Colaboradores.\n',
                { text: '\n', lineHeight: 0.4 },
                ' Identifica las fortalezas para reforzarlas y las debilidades para convertirlas en oportunidades de desarrollo y crea un plan de formación de alto impacto.'
              ],
              color: '#222222',
              fontSize: 10
            }
          ]
        },
        this.generateHowWorks(),
        {
          text: 'Propuesta Económica',
          margin: [0, 18, 0, 0],
          fontSize: 12.5,
          bold: true
        },
        {
          image: line,
          margin: [0, 4, 0, 0],
          width: 123,
          height: 2.5
        }
      ]
    }
  }
}
