<template>
  <v-col cols="12" :class="{'px-0': $vuetify.breakpoint.xs}">
    <v-row justify="center">
      <template v-if="info.length">
        <template v-for="(activity, k) in info">
          <v-col align="center" cols="12" :key="k">
            <v-card :flat="$vuetify.breakpoint.xs">
              <v-toolbar dense class="mb-3">
                <v-toolbar-title class="text-capitalize center--text">{{activity.displayName || activity.name}}</v-toolbar-title>
              </v-toolbar>
              <v-card-text :class="{'px-0': $vuetify.breakpoint.xs}">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <template v-if="activity.asEvaluated">
                        <tr><td class="text-center" colspan="2"><strong>{{ $t('dashboard.autoevaluation') }}</strong></td></tr>
                        <tr>
                          <td class="text-center">{{ $t('dashboard.autoevaluation') }}</td>
                          <td class="text-center">
                            <v-btn
                              class="text-capitalize"
                              rounded
                              outlined
                              :small="$vuetify.breakpoint.xs"
                              color="primary"
                              @click="goFill(activity.asEvaluated.evaluated.token)"
                              v-if="!activity.asEvaluated.evaluated.completed"
                            >
                              {{ $t('dashboard.start') }}
                            </v-btn>
                            <v-btn
                              v-else
                              disabled
                              rounded
                              outlined
                              :small="$vuetify.breakpoint.xs"
                              class="text-capitalize"
                            >
                              {{ $t('dashboard.evaluation_finished') }}
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                      <template v-if="activity.asEvaluator.length">
                        <tr><td class="text-center" colspan="2"><strong>{{ $t('dashboard.pending_activities') }}</strong></td></tr>
                        <tr v-for="(evaluator, i) in activity.asEvaluator" :key="`${evaluator.token}-${i}`">
                          <td class="text-center"> {{ evaluator.evaluated }}</td>
                          <td class="text-center">
                            <v-btn
                              class="text-capitalize"
                              rounded
                              outlined
                              :small="$vuetify.breakpoint.xs"
                              color="primary"
                              @click="goFill(evaluator.token)"
                              v-if="!evaluator.completed"
                            >
                              {{ $t('dashboard.start') }}
                            </v-btn>
                            <v-btn
                              v-else
                              disabled
                              rounded
                              outlined
                              :small="$vuetify.breakpoint.xs"
                              class="text-capitalize"
                            >
                              {{ $t('dashboard.evaluation_finished') }}
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </template>
      <v-col cols="12" align="centered" class="py-10" v-else>
        <h1 class="display-2 my-10 text-center" style="color: #90a4ae">
          {{ $t('dashboard.no_pending_activities') }}
        </h1>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    info: Array
  },
  data () {
    return {
      evaluatedToDisplay: null,
      i: 1
    }
  },
  methods: {
    goFill (token) {
      return this.$store
        .dispatch('session/findHost', 'COACHING Y CONVERSACIONES')
        .then((host) => {
          if (host && host.url) {
            location.replace(`${host.url}/outside/evaluations/cc180/${token}`)
          }
        })
        .catch(err => (this.error = err.code))
    }
  }
})
</script>

<style lang="css" scoped>
.text-center{
  text-align: center !important;
}
</style>
