
import Services from './base-services'
import mapParameters from '../utils/map-parameters'

const service = new Services('emails')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
  dateTo?: Date;
  dateFrom?: Date
}

export default {
  // list: () => {
  //   return service.get('list')
  // },
  list: (options: IOptionsList) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null,
      dateTo: options && options.dateTo ? options.dateTo : null,
      dateFrom: options && options.dateFrom ? options.dateFrom : null
    })
    return service.get('list', params)
  }
}
