
<template>
  <v-container>
    <div>
      <v-row class="justify-center mb-5">
        <h4 class="display-1">{{ $t('language.page_title') }}</h4>
      </v-row>
    </div>
    <div>
      <v-row class="justify-center child-flex mx-10">
        <v-col cols="10" md="6">
          <v-card>
            <v-row class="justify-center">
              <ul>
                <v-row v-for="language in languages" v-bind:key="language.id" class="d-flex justify-center">
                  <v-col>
                    <v-switch v-model="language.isActive" :disabled="language.isNative" inset @change="changeSwitch(language)"></v-switch>
                  </v-col>
                  <v-col class="mt-5">
                    {{ language.translate.label }}
                  </v-col>
                </v-row>
              </ul>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import languageServices from '../../services/languages'
import languageEnterpriseServices from '../../services/language-enterprises'

export default {
  data () {
    return {
      singleSelect: false,
      selected: [],
      headers: [
        { text: this.$t('language.header_lang'), sortable: false, align: 'center', value: 'langs' },
        { text: this.$t('language.header_code'), sortable: false, align: 'start', value: 'codes' }
      ],
      languages: [],
      languagesTab: [],
      languagesTable: [],
      languages_enterprises: [],
      emptyRegister: {
        enterpriseId: 0,
        languageId: 0
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    changeSwitch (language) {
      if (language.isActive) {
        languageEnterpriseServices.create(this.user.enterprise.id, language.id)
      } else {
        languageEnterpriseServices.delete(this.user.enterprise.id, language.id)
      }
    },
    getLanguages () {
      return languageServices.list()
        .then((res) => {
          this.languages = res.items.map(element => {
            element.isActive = false
            return element
          })
        })
    },
    getLanguageEnterprises () {
      return languageEnterpriseServices.list()
        .then((res) => {
          this.languages.forEach(lang => {
            lang.isActive = !!res.find((item) => lang.id === item.languageId)
            lang.isNative = !!res.find((item) => lang.id === this.user.enterprise.languageId)
          })
        })
    }
  },
  created () {
    this.getLanguages()
      .then(() => {
        this.getLanguageEnterprises()
      })
  }
}
</script>
