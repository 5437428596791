
<template>
  <v-container>
    <v-row justify="start">
      <v-col class="display-1">
        {{ $t('pricing.title') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-list class="pa-0">
            <v-list-group
              v-model="item.isDeploy"
              v-for="item in products"
              :key="item.id"
              style="min-height: 6em; border: 0.01rem #ccc solid"
            >
              <v-list-item slot="activator">
                <v-list-item-content class="mt-2 pa-0">
                  <v-col class="pa-0">
                    <v-row class="align-center">
                      <v-col sm="2" class="pa-0">
                        <v-img contain
                          id="logo"
                          :src="item.logo || item.defaultLogo"
                          :class="!item.name.includes('LIDERAZGO') ? 'my-1 ml-3' : 'mt-2 mb-1'"
                          :style="item.name.includes('LIDERAZGO') ?
                            'max-width:130px; height:92px;' :
                            'max-width:100px; height:auto;'
                          "
                        ></v-img>
                      </v-col>
                      <v-col sm="10">
                          <strong>{{ item.name }}</strong>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <!-------------- Product Services -------------->
                <v-row>
                  <v-col class="py-0">
                    <v-simple-table class="mt-2">
                      <template v-slot:default>
                        <thead>
                          <tr class="grey lighten-2">
                            <th class="subtitle-2 text-left">
                              {{ $t('pricing.services') }}
                            </th>
                            <th class="subtitle-2 text-right">
                              {{ $t('pricing.recomendations') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(subItem, i) in item.productServices"
                            :key="i"
                          >
                            <td class="text-left">
                              <span class="body-2">{{ subItem.name }}</span>
                              <span class="caption" style="color: #7E7E7E;">{{ $t('pricing.cost') }}</span>
                              <span class="caption" style="color: #7E7E7E;">{{ subItem.tokenPrice }}</span>
                              <span class="caption" style="color: #7E7E7E;">
                                {{ subItem.tokenPrice === 1 ? $t('pricing.rate') : $t('pricing.rates') }}
                              </span>
                              <span class="caption" style="color: #7E7E7E;">
                                {{ $t(`services.modalities_tag.${subItem.modality}`) }}
                              </span>
                            </td>
                            <td class="text-right">
                              <span class="caption" style="color: #7E7E7E;">
                                {{ $t('pricing.recomendation') }}
                              </span>
                              <span v-if="subItem.periocity !== 'OneTime' && subItem.periocity !== 'ByDemand'" class="caption" style="color: #7E7E7E;">
                                {{ subItem.recomendation }}
                              </span>
                              <span class="caption" style="color: #7E7E7E;">&nbsp;
                                {{ $t(`services.periocities.${subItem.periocity}`) }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <!---------------------------------------------->
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- Token Price Range -->
    <v-row class="child-flex">
      <v-col>
        <v-card>
          <v-row justify="center" class="mx-1">
            <v-col>
              <x-token-price-table />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import productsService from '../../services/products'

import XTokenPriceTable from '@/components/token-price-range-table'

export default {
  components: {
    XTokenPriceTable
  },
  data () {
    return {
      products: [],
      lang: ''
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  mounted () {
    this.$store.dispatch('session/showSelectorCurrency', true)
  },
  beforeDestroy () {
    this.$store.dispatch('session/showSelectorCurrency', false)
  },
  created () {
    return productsService.lisWithServices()
      .then((res) => {
        this.products = res.filter(product => product.active === true)
        this.products.map(product => {
          product.isDeploy = false
        })
        this.lang = this.user.lang
        let pos = 1
        this.products.forEach(product => {
          let counter = 1
          product.itemPos = pos
          pos = pos + 1
          product.productServices.forEach(service => {
            service.pos = counter
            counter = counter + 1
          })
        })
      })
  }
}
</script>
