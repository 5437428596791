
<template>
  <trans-wrapper
    :page-title="$t('settings.sector')"
    :add-label="$t('settings.add')"
    :card-title="$t('settings.sectors')"
    :alert-info="$t('settings.sector_info')"
    :modal-title-add="$t('settings.sector_add')"
    :modal-title-ena="$t('settings.sector_enable')"
    :modal-title-dis="$t('settings.sector_disable')"
    :modal-title-del="$t('settings.sector_delete')"
    :modal-question-ena="$t('settings.master_references_enable_des')"
    :modal-question-dis="$t('settings.master_references_disable_des')"
    :modal-question-del="$t('settings.master_references_delete_des')"
    :item-services="sectorsService"
  ></trans-wrapper>
</template>

<script>

import sectorsService from '../../services/sectors'

import TransWrapper from './components/trans-wrapper.vue'

export default {
  components: {
    TransWrapper
  },
  data () {
    return {
      sectorsService
    }
  }
}
</script>
