
export default {
  termsNotAcceptedDialog: {
    title: 'We have updated our Terms and Conditions of Use',
    input: 'Accept'
  },
  dataProtectionPoliciesNotAcceptedDialog: {
    title: 'We have updated our Data Protection Policies',
    input: 'Accept'
  },
  confirmationModal: {
    not_reversible_action: 'Remember that this action cannot be undone'
  },
  not_allowed: 'Not allowed',
  not_allowed_to_enable: 'This option cannot be enabled'
}
