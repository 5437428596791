
export default {
  insert_document: 'Ingrese su número de documento',
  insert_document2: 'Sólo se permiten letras y números',
  verify: 'Verificar encuestas',
  polls_list: 'Encuestas disponibles',
  no_pending_polls: 'No tiene encuestas disponibles',
  pending_polls_header: [
    { text: 'Nombre', value: '', sortable: false, class: 'text-xs-left' },
    { text: 'Tipo', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Finalización', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Acciones', value: '', sortable: false, class: 'text-xs-center' }
  ],
  fill_poll: 'Realizar encuesta',
  back_to_verify_polls: 'Regresar a la verificación de encuestas'
}
