
export default {
  title: 'Tarifas y recomendaciones',
  cost: ' Tiene un costo de ',
  rate: ' Token por ',
  rates: ' Tokens por ',
  table_ranges: 'Rango de Tokens',
  table_prices: 'Valor por Token',
  medition: 'medición',
  download: 'descarga',
  person: 'participante',
  recomendation: 'Se recomienda ',
  onetime: ' una única ejecución',
  annual: ' Anual',
  ondemand: ' bajo demanda',
  bydemand: ' bajo demanda',
  ormore: 'en adelante',
  services: 'SERVICIOS',
  recomendations: 'RECOMENDACIONES'
}
