
import Services from './base-services'

const service = new Services('departments')

export default {
  list: () => {
    return service.get('list')
  },
  listData: () => {
    return service.get('list-data')
  },
  create: (data: any) => {
    return service.post('', data)
  },
  update: (id: number, locale: string, data: {label: string}) => {
    return service.put(`${id}`, { locale, ...data })
  },
  toggle: (id: number, action: string) => {
    return service.put(`${id}/${action}`)
  }
}
