
import Services from './base-services'

const service = new Services('auth')

export default {
  signIn: (credentials: { email: string, password: string }) => {
    return service.post('sign-in', credentials)
  },
  signOut: () => {
    return service.post('sign-out')
  },
  updatedInfo: () => {
    return service.post('updated-info')
  },
  requestPasswordReset: (email: string) => {
    return service.post('request-password-reset', { email })
  },
  resetPassword: (userId: number) => {
    return service.post('reset-password', { userId })
  },
  updatePassword: (password: string, confirmPassword: string, token: string) => {
    return service.post('update-password', { password, confirmPassword, token })
  },
  checkTokenStatus: (token: string) => {
    return service.post('check-reset-password', { token })
  },
  changeLocale: (locale: string, user: any) => {
    return service.post('change-locale', { locale, user })
  },
  verifyIdentification: (identification: object) => {
    return service.post('verify-identification', identification)
  },
  getPollList: (identification: object) => {
    return service.post('get-poll-list', identification)
  },
  getEnterpriseByInvitationCode: (token: string) => {
    return service.post('get-enterprise-by-token', { token })
  },
  verifyPollToken: (token: string) => {
    return service.post('verify-poll-token', { token })
  },
  fetchEmployeeAnswers: (token: string) => {
    return service.post('fetch-employee-answers', { token })
  },
  saveEmployeeAnswers: (answers: string, token: string) => {
    return service.post('save-employee-answers', { answers, token })
  },
  saveAnswers: (answers: any, pollId: number, token: string) => {
    return service.post('save-answers', { answers, pollId, token })
  },
  verifyPollTokenEngagement: (token: string) => {
    return service.post('verify-poll-token-engagement', { token })
  },
  fetchEmployeeAnswersEngagement: (token: string) => {
    return service.post('fetch-employee-answers-engagement', { token })
  },
  saveEmployeeAnswersEngagement: (answers: string, token: string) => {
    return service.post('save-employee-answers-engagement', { answers, token })
  },
  saveAnswersEngagement: (answers: any, pollId: number, token: string) => {
    return service.post('save-answers-engagement', { answers, pollId, token })
  },
  loginAsEnterprise: (enterpriseId: number, user: any) => {
    return service.post(`login-as-enterprise/${enterpriseId}`, { user })
  },
  loginAsCustomer: (customerId: number, user: any) => {
    return service.post(`login-as-customer/${customerId}`, { user })
  },
  signOutAs: (user: any, role?: string) => {
    role = role ? `/${role}` : ''
    return service.post(`logout-as${role}`, { user })
  },
  loginAsEmployee: (id: number, user: any) => {
    return service.post(`login-as-employee/${id}`, { user })
  },
  getLastTerms: (type: string) => {
    return service.get(`get-last-terms/${type}`)
  },
  getLastPolicies: (type: string) => {
    return service.get(`get-data-protection-policies/${type}`)
  },
  createCustomer: (customer: any) => {
    return service.post('create-customer', customer)
  },
  findHost: (product: string) => {
    return service.get(`product-host/${product}`)
  },
  getAcl: () => {
    return service.get('wolf-products')
  }
}
