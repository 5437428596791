
<template>
  <trans-wrapper
    :page-title="$t('settings.additional_demographics1_setting')"
    :add-label="$t('settings.add')"
    :card-title="$t('settings.additional_demographics1')"
    :alert-info="$t('settings.additional_demographics1_info')"
    :modal-title-add="$t('settings.additional_demographics1_add')"
    :modal-title-ena="$t('settings.additional_demographics1_enable')"
    :modal-title-dis="$t('settings.additional_demographics1_disable')"
    :modal-title-del="$t('settings.additional_demographics1_delete')"
    :modal-question-ena="$t('settings.master_references_enable_des')"
    :modal-question-dis="$t('settings.master_references_disable_des')"
    :modal-question-del="$t('settings.master_references_delete_des')"
    :item-services="additionalDemographics1Service"
    is-optional
  ></trans-wrapper>
</template>

<script>

import additionalDemographics1Service from '../../services/additional-demographics1'

import TransWrapper from './components/trans-wrapper.vue'

export default {
  components: {
    TransWrapper
  },
  data () {
    return {
      additionalDemographics1Service
    }
  }
}
</script>
