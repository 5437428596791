
<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title v-if="title">
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-row align-center justify-center row fill-height wrap v-for="(items, index) in formItems" :key="index">
            <v-col cols="12">
              <v-card flat>
                {{ $t(`settings.${items[0].key}`) }}
                <v-divider></v-divider>
              </v-card>
            </v-col>
            <template v-for="(item, $idx) in items">
              <v-col :key="$idx">
                <v-text-field
                  light outlined
                  color="blue"
                  v-model="item.value"
                  :label="item.trans || ''"
                  name="master_record"
                  :required="item.required"
                 ></v-text-field>
                <!-- <v-text-field
                  light outlined
                  color="blue"
                  v-model="item.value"
                  :label="item.trans || ''"
                  name="master_record"
                  :required="item.required"
                  v-validate="item.required ? 'required' : ''"
                 ></v-text-field> -->
              </v-col>
            </template>
            <v-col cols="12" v-if="currency">
              <v-select
                :items="currencies"
                outlined
                item-text="name"
                item-value="id"
                :label="$t('currencies.currency')"
                v-model="selectedCurrencyId"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" dark @click="hideModal">
          <v-icon v-if="icon && $vuetify.breakpoint.smAndUp">close</v-icon>&nbsp;{{ $t('input.cancel') }}
        </v-btn>
        <x-async-btn
          class="white--text"
          :action="confirm"
        ><v-icon v-if="icon && $vuetify.breakpoint.smAndUp">{{ icon }}</v-icon>&nbsp;{{ $t('input.save') }}
        </x-async-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState } from 'vuex'
import currencyService from '../../../services/currencies'

export default {
  props: {
    show: Boolean,
    trans: Boolean,
    currency: Boolean,
    title: String,
    label: String,
    icon: String,
    action: Function,
    languages: Array,
    inputs: {
      type: Object,
      default: () => ({
        label: true
      })
    }
  },
  data: () => ({
    langDefault: 'es',
    formItems: [],
    labels: [],
    currencies: [],
    selectedCurrencyId: 1
  }),
  watch: {
    show () {
      if (this.show) {
        this.updateInputs()
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    confirm () {
      const requiredEmpty = this.formItems.reduce((last, actual) => {
        const required = actual.find(obj => obj.required && !obj.value)
        return last || Boolean(required)
      }, false)

      if (requiredEmpty) {
        return this.$store.dispatch('alert/error', this.$t('errors.validator/empty-field'))
      }

      return this.action({ currencyId: this.selectedCurrencyId, data: this.formItems.length === 1 ? this.formItems[0] : this.formItems })
    },
    hideModal () {
      this.$emit('close')
    },
    updateInputs () {
      this.formItems = []
      for (const key in this.inputs) {
        if (this.trans) {
          const idx = this.formItems.push([]) - 1
          this.languages.forEach(lang => {
            this.formItems[idx].push({
              key,
              value: '',
              required: lang.code === this.user.lang,
              code: lang.code,
              trans: lang.code !== this.user.lang
                ? `${lang.translate.label} (${this.$t('settings.optional')})`
                : lang.translate.label
            })
          })
        } else {
          this.formItems.push([{ key, value: '', required: this.inputs[key] }])
        }
      }
    }
  },
  created () {
    this.labels = Object.keys(this.inputs)
    currencyService.getActives()
      .then(res => {
        this.currencies = res.data
      })
  }
}
</script>
