
import Services from './base-services'

const service = new Services('identify-types')

export default {
  list: () => {
    return service.get('list')
  },
  listOutside: (language: string) => {
    return service.bGet('auth/list-identify-types', { params: language })
  }
}
