
export default {
  no_data: 'There is no data available for this demographic cut.',
  master_references_section: 'Demographic cuts are the variables with which you can segment the information in the reports. We recommend configuring them according to your needs, in order to generate reports with high value.<br>In culture and engagement assessments, it is not possible to generate reports of less than 5 people.<br>The changes you make in this section apply to future assessments and do not affect the assessments previously carried out.',
  master_references_section_additionals: 'Additional demographic cuts are optional. You can use them according to your needs to segment the information of your collaborators.<br>These cuts are available for the reports of subculture and engagement by population.<br>The changes you make in this section apply to future assessments and do not affect the assessments previously carried out.',
  master_references_action: 'These actions are of a sensitive nature, the improper use of a master reference is subject to your responsibility.',
  master_references_enable_des: 'The cut will be available again in the selection lists. You can register or update information with this cut.',
  master_references_disable_des: 'This cut will not be enabled for future assessments and they cannot be assigned to collaborators or generate reports. Previously completed assessments will not be affected by this change.',
  master_references_delete_des: 'This cut will not be available for future assessments and they cannot be assigned to collaborators or generate reports. Previous measurements will not be affected by this change.',
  master_references_only_read: 'This demographic cut is not editable. This is the list of options available to use in the information that you must fill out when creating collaborators.',
  actions: 'Actions',
  add: 'Add',
  label: 'Name or label',
  description: 'Description',
  no_translation: 'There is no translation for this language.',
  academic_degree: 'Settings for Level of Education',
  academic_degree_read: 'Level of Education configured',
  academic_degrees: 'Level of education',
  academic_degree_info: 'Select the education levels that will be assigned to employees of all companies',
  academic_degree_add: 'Add levels of education',
  academic_degree_enable: 'Enable leve lof education',
  academic_degree_disable: 'Disable leve lof education',
  academic_degree_delete: 'Delete leve lof education',
  language: 'Language settings',
  languages: 'Languages',
  language_info: 'Select the languages that will be assigned to collaborators and companies',
  language_add: 'Add language',
  language_enable: 'Enable language',
  language_disable: 'Disable language',
  language_delete: 'Delete language',
  department: 'Area or department configuration',
  departments: 'Area or department',
  department_info: 'Register all the areas or departments that are part of your organization',
  department_add: 'Add area or department',
  department_enable: 'Enable area or department',
  department_disable: 'Disable arear or department',
  department_delete: 'Delete area or department',
  sector: 'Configuration of business sectors',
  sectors: 'Business sectors',
  sector_info: 'Assign all the business sectors that the system will manage',
  sector_add: 'Add sector',
  sector_enable: 'Enable sector',
  sector_disable: 'Disable sector',
  sector_delete: 'Delete sector',
  gender: 'Gender configuration',
  genders: 'Gender',
  gender_info: 'Use the add button to include additional genders',
  gender_add: 'Add gender',
  gender_enable: 'Enable gender',
  gender_disable: 'Disable gender',
  gender_delete: 'Delete gender',
  job_type: 'Type of contract settings',
  job_types: 'Type of contract',
  job_type_info: 'Register all types of hiring that exist in your organization',
  job_type_add: 'Add typr of contract',
  job_type_enable: 'nable type of contract',
  job_type_disable: 'Disable type of contract',
  job_type_delete: 'Delete type of contract',
  country: 'Country settings',
  country_read: 'Country configured',
  countries: 'Country',
  country_info: 'Assign the country that will be available in the system ',
  country_add: 'Add country',
  country_enable: 'Enable country',
  country_disable: 'Disable country',
  country_delete: 'Delete country',
  enterprise_size: 'Company sizes settings',
  enterprise_sizes: 'Company sizes',
  enterprise_size_info: 'Assign all company sizes that will be available in the system ',
  enterprise_size_add: 'Add company sizes',
  enterprise_size_enable: 'Enable company sizes',
  enterprise_size_disable: 'Disable company sizes',
  enterprise_size_delete: 'Delete company sizes',
  headquarters_title: 'Location settings',
  headquarters_info: 'Register all the locations in which your organization operates',
  headquarters_country: 'Country',
  headquarters_head: 'Location',
  headquarters_countries: 'Country',
  headquarters_heads: 'Location',
  headquarters_create: 'Create location',
  headquarters_actions: 'Actions',
  headquarters_enable: 'Enable',
  headquarters_disable: 'Disable',
  headquarters_delete: 'Delete',
  headquarters_updated: 'Location successfully updated!',
  headquarters_deleted: 'Location deleted!',
  headquarters_delete_title: 'Delete location',
  headquarters_toggle_true_title: 'Disable location',
  headquarters_toggle_false_title: 'Enable location',
  headquarters_add: 'Add new location',
  headquarters_created: 'Location successfully cretaed!',
  charges_title: 'Position level settings',
  charges_info: 'Register all the position levels that are part of your organization',
  charges_department: 'Department (Optional)',
  charges_head: 'Position level',
  charges_departments: 'Departments (Optional)',
  charges_heads: 'Position Levels',
  charges_create: 'Crete position',
  charges_actions: 'Actions',
  charges_enable: 'nable',
  charges_disable: 'Disable',
  charges_delete: 'Delete',
  charges_updated: '¡Job title successfully updated!',
  charges_deleted: '¡Job title elilinated!',
  charges_delete_title: 'Delete job title',
  charges_toggle_true_title: 'Disable job title',
  charges_toggle_false_title: 'Enable job title',
  charges_add: 'Add job title',
  charges_created: 'Job title successfully created!',
  antiquity_title: 'Seniority range',
  antiquity_subtitle: 'Seniority ranges available',
  ages_title: 'Seniority range',
  ages_subtitle: 'Seniority ranges avaiulable',
  tooltip_edit: 'You must press ENTER to edit',
  terms_and_conditions_settings: 'Terms and Conditions Settings',
  terms_and_conditions: 'Terms and Conditions',
  terms_and_conditions_for_personal: 'Terms and conditions for Single Company clients',
  terms_and_conditions_for_commercial: 'Terms and conditions for Multi Company clients',
  data_protection_policies_settings: 'Data Protection Policy Settings',
  data_protection_policies: 'Data Protection Policies',
  data_protection_policies_for_personal: 'Data Protection Policies for Single Company Clients',
  data_protection_policies_for_commercial: 'Data Protection Policies for Multi-Company clients',
  products_title: 'Product settings',
  products_logo: 'Product Logo',
  products_conf: 'Product settings',
  products_description: 'Description: ',
  products_status: 'Status',
  products_active_disabled: 'This product has not been integrated, so it cannot be activated',
  products_active_disabled_title: 'Product activation',
  products_edit: 'Edit product',
  products_modal_enable: 'Do you want to enable the product?',
  products_modal_disable: '¿ Do you want to disebale the product?',
  products_modal_enable_des: 'Once enabled, the product will be available in the system',
  products_modal_disable_des: 'Once disabled, the product cannot be used in the system',
  products_edited: 'The producto has been successfully edited',
  products_description_max_limit: 'Maximum 230 characters',
  tokens_title: 'Token Price settings',
  tokens_edit_price: 'edit price',
  tokens_edit_minrange: 'Minimum range',
  tokens_edit_maxrange: 'Maximum range',
  tokens_edit_price_amount: 'Price in USD',
  tokens_price_edited: 'The Price has been successfully edited',
  customer_type: 'Type client',
  service_edit: 'Edit service',
  product_settings: 'Product settings',
  public_product_title: 'Do you want to make this product public?',
  private_product_title: 'Do you want to make this product private?',
  public_product_tip: 'By making this product public, the system will automatically grant access to it to each of the active registered customers who do not have it disabled',
  private_product_tip: 'y making this product private, the system will maintain access to each of the clients who already have it and those who do not have it will have to request it',
  additional_demographics1_setting: 'Additional demographics 1 settings1',
  additional_demographics1: 'Additional demographics 1',
  additional_demographics1_info: 'Register all possible values for additional demographics 1',
  additional_demographics1_add: 'Add additional demographics 1',
  additional_demographics1_enable: 'Enable additional demographics 1',
  additional_demographics1_disable: 'Disable additional demographics 1',
  additional_demographics1_delete: 'Delete additional demographics 1 1',
  additional_demographics2_setting: 'Additonal demographics 2 Settings',
  additional_demographics2: 'Additional demographics 2',
  additional_demographics2_info: 'Register all possible values for additional demographics 2',
  additional_demographics2_add: 'Add additional demographics 2',
  additional_demographics2_enable: 'Enable additional demographics 2',
  additional_demographics2_disable: 'Disable additional demographics 2',
  additional_demographics2_delete: 'Delete additional demographics 2',
  demographic_already_exists: '{0} already exists',
  optional: 'optional',
  // Additional Segmentation
  additional_segmentation_head: 'Additional segmentation',
  additional_segmentation_info: 'Register all the additional segmentations that are part of your organization',
  additional_segmentation_table_heads: 'Segmentations',
  additional_segmentation_table_actions: 'Actions',
  additional_segmentation_no_data: 'No data available',
  additional_segmentation_description: 'Additional segmentation is optional. You can use it according to your needs to segment the information of your collaborators.<br>These segmentations are available for the Energy Compass reports by population.<br>The changes you make in this section apply to future assessments and do not affect the assessments previously made.',
  additional_segmentation_enable_des: 'Segmentation will be available again in selection lists. You can register or update information with this segmentation.',
  additional_segmentation_disable_des: 'This segmentation will not be enabled for future assessments and they cannot be assigned to collaborators or generate reports. Previous assessments will not be affected by this change.',
  additional_segmentation_delete_des: 'This segmentation will not be available for future assessments and cannot be assigned to collaborators or generated reports. Previous assessments will not be affected by this change.',
  additional_segmentation_options: 'Options',
  additional_segmentation_enable: 'Enable',
  additional_segmentation_disable: 'Disable',
  additional_segmentation_updated: 'Segmentation successfully updated!',
  additional_segmentation_deleted: 'Segmentation deleted!',
  additional_segmentation_delete_title: 'Delete segmentation',
  additional_segmentation_toggle_true_title: 'Disable segmentation',
  additional_segmentation_toggle_false_title: 'Enable segmentation',
  additional_segmentation_add: 'Add new segmentation',
  additional_segmentation_created: 'Segmentation successfully created!',
  additional_segmentation_detail_n: 'option {n}',
  additional_segmentation_insert_detail: 'Insert option {n} {o}',
  additional_segmentation_remove_detail: 'Delete option',
  additional_segmentation_add_detail: 'Add option',
  additional_segmentation_options_to: 'Options for',
  additional_segmentation_options_desc: 'Add the options for this segmentation. The respondent will have to choose between one of these, when starting the assessment.',
  additional_segmentation_details_updated: 'Options successfully saved!'
}
