
<template>
  <div>
    <v-container>
      <v-row>
        <ValidationObserver v-slot="{ handleSubmit }" style="width: 100%">
          <v-form @submit.prevent="handleSubmit(requestPasswordReset)">
            <v-col xs="12">
              <h4
                class="display-1 text-center grey--text"
                style="width:100%"
              >
                {{ $t('auth.recover_password') }}
              </h4>
              <p class="mt-5 mb-0 text-center">
                {{ $t('auth.enter_real_email') }}
              </p>
            </v-col>

            <v-col xs12>
              <v-alert class="font-weight-bold" :value="!!error" color="error">
                <span v-if="error === 'auth/user-not-found'">{{ $t('auth.email_not_found', { email }) }}</span>
                <span v-else>{{ $t('auth.unexpected_error') }}</span>
              </v-alert>
            </v-col>

            <v-col xs="12">
              <x-inputs-input
                :input="email"
                @updateInput="($event) => email = $event"
                :label="$t('input.email')"
                rules="required|email"
                type="text"
                name="email"
                autofocus
                :prepend-inner-icon="'mdi-account-outline'"
              ></x-inputs-input>
            </v-col>

            <v-col xs="12" class="text-center">
              <v-btn
                width="300"
                color="primary"
                type="submit"
              >
                {{ $t('auth.recover_password') }}
              </v-btn>
            </v-col>

            <v-col xs="12" class="text-center">
              <v-btn outlined
                width="300"
                color="primary"
                to="/auth/sign-in"
              >
                {{ $t('auth.go_back') }}
              </v-btn>
            </v-col>

          </v-form>
        </ValidationObserver>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title class="headline" style="word-break: break-word;">{{ $t('auth.password_reset_requested') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6">{{ $t('auth.check_your_email') }}</v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="goToSignIn">{{ $t('auth.accept') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Vue from 'vue'

import authService from '../../services/auth'

export default Vue.extend({
  data () {
    return {
      email: '',
      valid: true,
      dialog: false,
      error: ''
    }
  },
  methods: {
    requestPasswordReset () {
      this.clearError()
      return authService
        .requestPasswordReset(this.email)
        .then((res) => {
          this.dialog = true
        })
        .catch(err => (this.error = err.code))
    },
    goToSignIn () {
      this.$router.push('/auth/sign-in')
    },
    clearError () {
      this.error = ''
    }
  }
})
</script>
