
<template>
  <v-card flat>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-container>
          <v-row row wrap>
            <template v-if="!outside">
              <v-col cols="12" sm="6">
                <x-inputs-autocomplete
                  :items="getFormattedIdentifyTypes"
                  :input="form.contact.identifyTypeId"
                  @updateInput="($event) => form.contact.identifyTypeId = $event"
                  light
                  :label="$t('input.identify_type_s')"
                  name="identify_type_id"
                  rules="required"
                >
                </x-inputs-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <x-inputs-input
                  :input="form.contact.identifyDocument"
                  @updateInput="($event) => form.contact.identifyDocument = $event"
                  light
                  :label="$t('input.identify_document')"
                  name="identify_document"
                  :append-outer-icon="$t('help.icon')"
                  :help-message="$t('help.enterprise.create.identifyDocument')"
                  rules="required"
                ></x-inputs-input>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" sm="6" >
                <x-inputs-autocomplete
                  :items="getFormattedIdentifyTypes"
                  :input="form.contact.identifyTypeId"
                  @updateInput="($event) => form.contact.identifyTypeId = $event"
                  light
                  :label="$t('input.identify_type')"
                  name="identify_type_id"
                  rules="required"
                >
                </x-inputs-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" >
                <x-inputs-input
                  :input="form.contact.identifyDocument"
                  @updateInput="($event) => form.contact.identifyDocument = $event"
                  light
                  :label="$t('input.identify_document')"
                  name="identify_document"
                  :append-outer-icon="$t('help.icon')"
                  :help-message="$t('help.enterprise.create.identifyDocument')"
                  rules="required"
                ></x-inputs-input>
              </v-col>
            </template>

            <v-col cols="12">
              <x-inputs-input
                :input="form.contact.name"
                @updateInput="($event) => form.contact.name = $event"
                light
                :label="$t('input.complete_name')"
                name="complete_name"
                :append-outer-icon="$t('help.icon')"
                :help-message="$t('help.enterprise.create.firstName')"
                rules="required"
              ></x-inputs-input>
            </v-col>

            <v-col cols="12" sm="7">
              <x-inputs-input
                :input="form.contact.email"
                @updateInput="($event) => form.contact.email = $event"
                light
                :label="$t('input.email')"
                name="email"
                :append-outer-icon="$t('help.icon')"
                :help-message="$t('help.enterprise.create.email')"
                rules="required|email"
              ></x-inputs-input>
            </v-col>

            <v-col cols="12" sm="5">
              <x-inputs-input
                :input="form.contact.phoneNumber"
                @updateInput="($event) => form.contact.phoneNumber = $event"
                light
                :label="$t('input.phone_number')"
                name="phone_number"
                :append-outer-icon="$t('help.icon')"
                :help-message="$t('help.enterprise.create.phoneNumber')"
                rules="required"
              ></x-inputs-input>
            </v-col>
            <v-col md="12" class="text-center pb-0" align="center" justify="center" v-if="outside">
              <x-inputs-checkbox
                :label="$t('auth.accept_terms_and_conditions')"
                :input="check"
                @updateInput="($event) => check = $event"
              />
              <x-inputs-input
                :input="termsChecked"
                rules="required"
                :show="false"
              ></x-inputs-input>
            </v-col>
            <v-col cols="12" class="text-center pt-0" align="center" justify="center" v-if="outside">
              <x-inputs-checkbox
                :label="$t('auth.accept_data_protection_policies')"
                :input="check2"
                @updateInput="($event) => check2 = $event"
              />
              <x-inputs-input
                :input="policiesChecked"
                rules="required"
                :show="false"
              ></x-inputs-input>
            </v-col>
          </v-row>
          <v-row v-if="outside" justify="center" align="center">
            <v-col cols="12" align="center" id="recaptcha-container">
              <!-- ReCAPTCHA -->
              <div id="occ-recaptcha"></div>
            </v-col>
          </v-row>
          <v-row justify="end" align="end">
            <v-col cols="12" align="end">
              <v-btn outlined
                color="primary"
                @click="changeStep(+step - 1)"
              >
                {{ $t(prevAction) }}
              </v-btn>
              <v-btn
                color="primary"
                type="submit"
                style="margin-left: 1em"
                :disabled="(!termsChecked || !policiesChecked || !reCaptchaUserToken) && outside"
              >
                {{ $t(nextAction) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>

    <!-- Terms & COnditions Dialog -->
    <x-terms-and-conditions
      v-if="outside"
      type="terms"
      :dialog="dialog"
      :data="termsAndConditions"
      @closeDialog="termsAccepted"
    />

    <!-- Data Protection Policies Dialog -->
    <x-terms-and-conditions
      v-if="outside"
      type="policies"
      :dialog="dialog2"
      :data="dataProtectionPolicies"
      @closeDialog="policiesAccepted"
    />
  </v-card>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import authService from '../../../services/auth'
import validate from '../../../utils/validate-form'
import identifyTypesService from '../../../services/identify-types'
import countriesService from '../../../services/countries'
import formatItems from '../../../utils/form-format-items-list'

export default Vue.extend({
  props: {
    reCaptchaReset: {
      type: Boolean,
      default: false
    },
    reCaptchaRenderAgain: {
      type: Boolean,
      default: false
    },
    enterprise: Object,
    step: String,
    nextAction: String,
    prevAction: String,
    outside: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      reCaptchaURL: process.env.VUE_APP_SUITE_RECAPTCHA_SITE_URL,
      reCaptchaSiteKey: process.env.VUE_APP_SUITE_RECAPTCHA_SITE_KEY,
      reCaptchaUserToken: null,
      termsAndConditions: { id: 0, data: null },
      dataProtectionPolicies: { id: 0, data: null },
      dialog: false,
      dialog2: false,
      form: {
        contact: {
          name: '',
          identifyDocument: '',
          identifyTypeId: '',
          email: '',
          phoneNumber: '',
          address: '',
          countryId: ''
        }
      },
      valid: false,
      demographicItemsSeleted: [],
      demographicItems: [],
      genders: [],
      identifyTypes: [],
      countries: [],
      check: false,
      check2: false,
      policiesChecked: '',
      termsChecked: ''
    }
  },
  watch: {
    reCaptchaUserToken (val) {
      this.$emit('reCaptchaTokenChanged', val)
    },
    reCaptchaReset (val) {
      if (val) {
        this.reCaptchaRefresh()
      }
    },
    reCaptchaRenderAgain (val) {
      if (val) {
        this.reCaptchaUserToken = null
        const elem = document.getElementById('occ-recaptcha')
        elem.parentNode.removeChild(elem)
        const g = document.createElement('div')
        g.id = 'occ-recaptcha'
        document.getElementById('recaptcha-container').appendChild(g)
        setTimeout(() => {
          this.initReCaptcha()
        }, 40)
      }
    },
    'enterprise.contact': {
      handler () {
        this.updateDataForm()
      },
      immediate: true,
      deep: true
    },
    check (val) {
      if (val && this.outside) {
        this.dialog = true
      } else {
        this.termsChecked = ''
        this.dialog = false
      }
    },
    check2 (val) {
      if (val && this.outside) {
        this.dialog2 = true
      } else {
        this.policiesChecked = ''
        this.dialog2 = false
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    getFormattedIdentifyTypes () {
      if (!this.identifyTypes.length) return []
      return formatItems(this.identifyTypes, (item) => {
        const iType = item.translations.find(i => i.lang === this.user.lang)
        return {
          text: iType.label,
          value: item.id,
          code: item.code
        }
      })
    }
  },
  methods: {
    getTermsAndConditions () {
      return authService.getLastTerms('personal')
        .then((res) => {
          this.termsAndConditions = res
          this.form.termsAndConditionsId = this.termsAndConditions.id
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    getDataProtectionPolicies () {
      return authService.getLastPolicies('personal')
        .then((res) => {
          this.dataProtectionPolicies = res
          this.form.dataProtectionPoliciesId = this.dataProtectionPolicies.id
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    onSubmit () {
      this.changeStep(+this.step + 1)
    },
    changeStep (step) {
      this.$emit('changeStep', this.form, step)
    },
    validateForm (scope) {
      return validate(this, scope)
        .then(valid => valid ? this.changeStep(+this.step + 1) : undefined)
    },
    runValidate () {
      return this.validateForm('contact-employee')
    },
    updateDataForm () {
      this.form.contact.name = this.enterprise.contact.name
      this.form.contact.identifyDocument = this.enterprise.contact.identifyDocument
      this.form.contact.email = this.enterprise.contact.email
      this.form.contact.phoneNumber = this.enterprise.contact.phoneNumber
      this.form.contact.address = this.enterprise.contact.address
      this.form.contact.id = this.enterprise.contact.id
      this.form.contact.identifyTypeId = this.enterprise.contact.identifyTypeId
      this.form.contact.countryId = this.enterprise.contact.countryId
    },
    getCountries () {
      countriesService.listOutside()
        .then((res) => {
          this.countries = formatItems(res.items)
          this.loading = false
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    termsAccepted () {
      this.dialog = false
      this.termsChecked = 'checked'
    },
    policiesAccepted () {
      this.dialog2 = false
      this.policiesChecked = 'checked'
    },
    getIdentifyTypes () {
      if (!this.outside) {
        identifyTypesService.list()
          .then((res) => {
            this.identifyTypes = res.items
            const it = this.identifyTypes.find(i => i.code === 'others')
            const index = this.identifyTypes.indexOf(it)
            this.identifyTypes.splice(index, 1)
            this.identifyTypes.splice(this.identifyTypes.length, 0, it)
          })
          .catch((error) => {
            this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
          })
      } else {
        identifyTypesService.listOutside()
          .then((res) => {
            this.identifyTypes = res.items
            const it = this.identifyTypes.find(i => i.code === 'others')
            const index = this.identifyTypes.indexOf(it)
            this.identifyTypes.splice(index, 1)
            this.identifyTypes.splice(this.identifyTypes.length, 0, it)
          })
          .catch((error) => {
            this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
          })
      }
    },
    initReCaptcha () {
      setTimeout(() => {
        if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render === 'undefined') {
          this.initReCaptcha()
        } else {
          grecaptcha.render('occ-recaptcha', {
            hl: this.$i18n.locale,
            sitekey: this.reCaptchaSiteKey,
            callback: this.reCaptchaSuccess,
            'expired-callback': this.reCaptchaExpired,
            'error-callback': this.reCaptchaError
          })
        }
      }, 100)
    },
    reCaptchaSuccess (token) {
      this.reCaptchaUserToken = token
    },
    reCaptchaExpired () {
      this.reCaptchaUserToken = null
    },
    reCaptchaError () {
      this.reCaptchaUserToken = null
      this.$store.dispatch('alert/error', this.$t('errors.recaptcha/retry'))
    },
    reCaptchaRefresh () {
      setTimeout(() => {
        if (typeof grecaptcha === 'undefined' || typeof grecaptcha.reset === 'undefined') {
          this.initReCaptcha()
        } else {
          grecaptcha.reset()
        }
      }, 100)
    }
  },
  created () {
    this.getTermsAndConditions()
    this.getDataProtectionPolicies()
    this.getIdentifyTypes()
    this.getCountries()
    this.updateDataForm()
    if (!this.outside) {
      this.check = true
      this.check2 = true
      this.termsChecked = 'checked'
      this.policiesChecked = 'checked'
    }
  },
  mounted () {
    if (this.outside) {
      const recaptchaScript = document.createElement('script')
      recaptchaScript.src = this.reCaptchaURL
      recaptchaScript.async = true
      recaptchaScript.defer = true
      document.head.appendChild(recaptchaScript)
      this.initReCaptcha()
    }
  }
})
</script>

<style lang="css">
  .customMarginLeft {
    margin-left: 2.2em;
  }
</style>
