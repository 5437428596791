
<template>
  <ranges-wrapper
    :items="items"
    :title="$t('settings.antiquity_title')"
    :subtitle="$t('settings.antiquity_subtitle')"
  />
</template>

<script>

import RangesWrapper from './components/ranges-wrapper.vue'

export default {
  components: {
    RangesWrapper
  },
  data () {
    return {
      items: {
        'es': [
          'Menos de 6 meses',
          'De 6 meses a 1 año',
          'De 1 año a 3 años',
          'De 3 años a 5 años',
          'De 5 años a 10 años',
          'De 10 años a 20 años',
          'Más de 20 años'
        ],
        'en': [
          'Less than 6 months',
          'From 6 months to 1 year',
          'From 1 year to 3 years',
          'From 3 years to 5 years',
          'From 5 years to 10 years',
          'From 10 years to 20 years',
          'More than 20 years'
        ]
      }
    }
  }
}
</script>
