<template>
  <v-container>
    <x-loading
      v-if="loading"
      :display="loading"
    ></x-loading>
    <v-card flat v-else>
      <v-card-title primary-title class="text-center" color="primary">
        <h2 class="text-uppercase" style="width: 100%; color: #3375d2; font-size: 1.3em"> {{ $t('auth.user_data') }} </h2>
      </v-card-title>
      <v-card-text>
        <ValidationObserver v-slot="{ handleSubmit }">
          <v-form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <v-row>
              <v-col cols="12" sm="6">
                <x-inputs-autocomplete
                  :items="getFormattedIdentifyTypes"
                  :input="customer.identifyTypeId"
                  @updateInput="($event) => customer.identifyTypeId = $event"
                  light
                  :label="$t('input.identify_type')"
                  name="identify_type_id"
                  rules="required"
                >
                </x-inputs-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <x-inputs-input
                  :input="customer.identifyDocument"
                  @updateInput="($event) => customer.identifyDocument = $event"
                  light
                  :label="$t('input.identify_document')"
                  name="identify_document"
                  :append-outer-icon="$t('help.icon')"
                  :help-message="$t('help.enterprise.create.identifyDocument')"
                  rules="required"
                ></x-inputs-input>
              </v-col>
              <v-col cols="12">
                <x-inputs-input
                  :input="customer.name"
                  @updateInput="($event) => customer.name = $event"
                  light
                  :label="$t('input.complete_name')"
                  name="complete_name"
                  :append-outer-icon="$t('help.icon')"
                  :help-message="$t('help.enterprise.create.firstName')"
                  rules="required"
                />
              </v-col>
              <v-col cols="12" sm="7">
                <x-inputs-input
                  :input="customer.email"
                  @updateInput="($event) => customer.email = $event"
                  light
                  :label="$t('input.email')"
                  name="email"
                  :append-outer-icon="$t('help.icon')"
                  :help-message="$t('help.enterprise.create.email')"
                  rules="required|email"
                />
              </v-col>
              <v-col cols="12" sm="5">
                <x-inputs-input
                  :input="customer.phoneNumber"
                  @updateInput="($event) => customer.phoneNumber = $event"
                  light
                  :label="$t('input.phone_number')"
                  name="phone_number"
                  :append-outer-icon="$t('help.icon')"
                  :help-message="$t('help.enterprise.create.phoneNumber')"
                  rules="required"
                />
              </v-col>
              <v-col cols="12" class="text-center pb-0" align="center" justify="center">
                <x-inputs-checkbox
                  :label="$t('auth.accept_terms_and_conditions')"
                  :input="check"
                  @updateInput="($event) => check = $event"
                />
                <x-inputs-input
                  :input="termsChecked"
                  rules="required"
                  :show="false"
                ></x-inputs-input>
              </v-col>
              <v-col cols="12" class="text-center pt-0" align="center" justify="center">
                <x-inputs-checkbox
                  :label="$t('auth.accept_data_protection_policies')"
                  :input="check2"
                  @updateInput="($event) => check2 = $event"
                />
                <x-inputs-input
                  :input="policiesChecked"
                  rules="required"
                  :show="false"
                ></x-inputs-input>
              </v-col>
            </v-row>
            <v-row justify="end" align="end">
              <v-col cols="12" align="end">
                <v-btn outlined
                  color="primary"
                  style="margin-left: 1em"
                  @click="$router.go(-1)"
                >
                  {{ $t('input.cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  type="submit"
                  style="margin-left: 1em"
                  :disabled="!termsChecked || !policiesChecked"
                >
                  {{ $t('input.create_account') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-card-text>

      <!-- Terms & COnditions Dialog -->
      <x-terms-and-conditions
        type="terms"
        :dialog="dialog"
        :data="termsAndConditions"
        @closeDialog="termsAccepted"
      />

      <!-- Data Protection Policies Dialog -->
      <x-terms-and-conditions
        type="policies"
        :dialog="dialog2"
        :data="dataProtectionPolicies"
        @closeDialog="policiesAccepted"
      />
    </v-card>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import authService from '../../services/auth'
import identifyTypesService from '../../services/identify-types'
import formatItems from '../../utils/form-format-items-list'

export default Vue.extend({
  data () {
    return {
      termsAndConditions: { data: null },
      dataProtectionPolicies: { data: null },
      dialog: false,
      dialog2: false,
      termsChecked: '',
      policiesChecked: '',
      identifyTypes: [],
      countries: [],
      customer: {
        identifyTypeId: null,
        identifyDocument: '',
        name: '',
        email: '',
        phoneNumber: '',
        termsAndConditionsId: null,
        type: 'commercial',
        countryId: null
      },
      check: false,
      check2: false,
      loading: true
    }
  },
  created () {
    this.getTermsAndConditions()
    this.getDataProtectionPolicies()
    this.getIdentifyTypes()
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    getFormattedIdentifyTypes () {
      if (!this.identifyTypes.length) return []
      return formatItems(this.identifyTypes, (item) => {
        const iType = item.translations.find(i => i.lang === this.user.lang)
        return {
          text: iType.label,
          value: item.id,
          code: item.code
        }
      })
    }
  },
  methods: {
    getTermsAndConditions () {
      return authService.getLastTerms('commercial')
        .then((res) => {
          this.termsAndConditions = res
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    getDataProtectionPolicies () {
      return authService.getLastPolicies('commercial')
        .then((res) => {
          this.dataProtectionPolicies = res
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    getIdentifyTypes () {
      identifyTypesService.listOutside()
        .then((res) => {
          this.identifyTypes = res.items
          const it = this.identifyTypes.find(i => i.code === 'others')
          const index = this.identifyTypes.indexOf(it)
          this.identifyTypes.splice(index, 1)
          this.identifyTypes.splice(this.identifyTypes.length, 0, it)
          this.loading = false
        })
        .catch((error) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
          this.loading = false
        })
    },
    termsAccepted () {
      this.dialog = false
      this.termsChecked = 'checked'
    },
    policiesAccepted () {
      this.dialog2 = false
      this.policiesChecked = 'checked'
    },
    onSubmit () {
      if (this.termsChecked && this.policiesChecked) {
        this.loading = true
        this.customer.termsAndConditionsId = this.termsAndConditions.id
        this.customer.dataProtectionPoliciesId = this.dataProtectionPolicies.id
        return authService.createCustomer(this.customer)
          .then((res) => {
            this.loading = false
            this.$router.push({ name: 'SignUpFinished', params: { email: res.email } })
          }).catch((err) => {
            this.loading = false
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          })
      }
    }
  },
  watch: {
    check (val) {
      if (val) {
        this.dialog = true
      } else {
        this.termsChecked = ''
        this.dialog = false
      }
    },
    check2 (val) {
      if (val) {
        this.dialog2 = true
      } else {
        this.policiesChecked = ''
        this.dialog2 = false
      }
    }
  }
})
</script>
