
<template>
  <v-container fluid>
    <v-row wrap row>
      <v-col cols="12">
        <h4 class="display-1">{{$t('emails.emails') }}</h4>
      </v-col>

      <v-col cols="12">
        <v-divider></v-divider>
        <v-card>
          <v-card-title>
            <div class="title">{{ $t('emails.emails_list') }}</div>
            <v-spacer></v-spacer>
          </v-card-title>
            <v-divider class="my-3"></v-divider>

            <v-row row wrap class="ml-3">
              <v-col cols="12" sm="4">
                <v-autocomplete
                  :label="$t('emails.filter_by')"
                  :items="filters"
                  prepend-inner-icon="mdi-filter-outline"
                  clearable
                  @change="changeOption({ filter: $event })"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <x-date-picker
                  @date-picked="changeOption({ dateFrom: $event })"
                  :label="$t('emails.dateFrom')"
                  name="date_delivery"
                  :help="{ ...$t('emails.help_dateFrom') }"
                ></x-date-picker>
              </v-col>
              <v-col cols="12" sm="4">
                <x-date-picker
                  @date-picked="changeOption({ dateTo: $event })"
                  name="date_delivery"
                  :label="$t('emails.dateTo')"
                  :help="{ ...$t('emails.help_dateTo') }"
                ></x-date-picker>
              </v-col>
            </v-row>

          <v-divider class="my-3"></v-divider>
          <x-data-table
            :headers="headers"
            :parent-fetch-data="getEmails"
            :options="options"
            no-data="emails.no_data"
          >
            <template v-slot:structure="prop">
              <td class="text-center">{{ prop.item.to }}</td>
              <td class="text-center">
                {{ prop.item.subject ? prop.item.subject : formatSubject(prop.item.data) || '--' }}
              </td>
              <td class="text-center">{{ prop.item.createdAt | date({date: true, hour:false}) }}</td>
              <td class="text-center">{{ getStatus(prop.item.status)}}</td>
              <td class="text-center">{{ prop.item.notes ? prop.item.notes : '--' }}</td>
            </template>
          </x-data-table>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import emailService from '../../services/emails'

export default {
  data () {
    return {
      headers: [
        'emails.sentTo',
        'emails.subject',
        'emails.date',
        'emails.status',
        'emails.message'
      ],
      options: {
        filter: null,
        search: null,
        dateTo: null,
        dateFrom: null
      },
      search: null,
      timer: null,
      dateTo: null,
      dateFrom: null
    }
  },
  computed: {
    filters () {
      const translate = (option) => this.$t(`emails.${option}`)
      return [
        { text: translate('success'), value: 'success' },
        { text: translate('pending'), value: 'pending' },
        { text: translate('inProgress'), value: 'in_progress' },
        { text: translate('failed'), value: 'failed' }
      ]
    }
  },
  methods: {
    getEmails (options) {
      return emailService.list(options)
        .then((res) => ({
          items: res.items,
          total: res.total
        }))
    },
    getStatus (inputStatus) {
      switch (inputStatus) {
        case 'success':
          return this.$t('emails.success')
        case 'pending':
          return this.$t('emails.pending')
        case 'in_progress':
          return this.$t('emails.inProgress')
        case 'failed':
          return this.$t('emails.failed')
      }
    },
    formatSubject (data) {
      let subject
      try {
        const parsedData = JSON.parse(data)
        subject = parsedData.subject
      } catch (err) {
        subject = '--'
      }

      return subject
    },
    changeOption (keyValue) {
      this.options = {
        ...this.options,
        ...keyValue
      }
    }
  }
}
</script>
