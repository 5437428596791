
export default {
  profile_information: 'Información de perfil',
  has_not_enterprise: '(No posee empresa asignada)',
  commercial_account: 'Cuenta Multi Empresas',
  available_tokens: 'Tokens disponibles',
  contact_information: 'DI - Información de contacto',
  registered_enterprises: 'Empresas registradas',
  changed_password_msg: 'Su contraseña se ha cambiado de forma satisfactoria. </br>Para ingresar nuevamente a su cuenta por favor inicie sesión',
  changed_email_msg: ' Hemos enviado un correo a <span style="color: #3899da">{email}</span>,<br> para verificar tu cuenta.Por favor revisa tu bandeja de entrada o la carpeta de <i>spam</ i >. Confirma tu cuenta y vuelve a iniciar sesión',
  customers_list: 'Listado de clientes',
  resumen: 'Resumen de clientes',
  look_for: 'Buscar un cliente',
  filter_by: 'Filtrar por',
  no_data: 'No hay clientes para mostrar',
  customer_name: 'Nombre',
  admin_name: 'Nombre del administrador',
  register_date: 'Fecha de registro',
  balance: 'Saldo',
  status: 'Estado',
  actions: 'Acciones',
  modal_enable: '¿Desea habilitar el cliente?',
  modal_disable: '¿Desea inhabilitar el cliente?',
  modal_enable_des: 'Una vez habilitado, el cliente podrá acceder nuevamente al sistema',
  modal_disable_des: 'Una vez sea inhabilitado, el cliente no podrá acceder al sistema hasta ser habilitado nuevamente',
  add_customer: 'Agregar cliente',
  type: 'Tipo',
  commercial: 'Multi Empresas',
  personal: 'Única Empresa',
  to_access: 'Acceder',
  list_registered_enterprise: 'Empresas registradas',
  request_product: 'Solicitar producto',
  request_message_description: 'Escriba a continuación su mensaje para realizar la solicitud del producto seleccionado',
  message: 'Mensaje',
  request_sent: 'Solicitud enviada exitosamente',
  message_not_found: 'El mensaje no puede estar vacio',
  client_info: 'información del cliente',
  filters: {
    all: 'Todos',
    active: 'Activos',
    inactive: 'Inactivos'
  },
  company_name: 'Nombre',
  country: 'Pais',
  headquarter: 'Sede',
  address: 'Dirección',
  successfully_registered: 'Registro exitoso',
  successfully_edited: 'Modificado exitosamente'
}
