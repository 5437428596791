
export default {
  title: 'Rates and recommendations',
  cost: ' Costs ',
  rate: ' Token for ',
  rates: ' Tokens for ',
  table_ranges: 'Token Range',
  table_prices: 'Value per Token',
  medition: 'assessment',
  download: 'download',
  person: 'respondent',
  recomendation: 'Recommendation ',
  onetime: ' a one-time execution',
  annual: ' Annual',
  ondemand: ' on demand',
  bydemand: ' on demand',
  ormore: 'onwards',
  services: 'SERVICES',
  recomendations: 'RECOMMENDATIONS'
}
