<template>
   <v-row justify="center">
    <template v-if="progressPolls.length">
      <template v-for="activity in progressPolls">
        <v-col align="center" xs="12" sm="" :key="`${activity.id}-${activity.type}`">
          <v-card>
            <v-toolbar dense class="mb-3">
              <v-toolbar-title class="text-capitalize center--text">{{activity.displayName || activity.name}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">
                        {{ $t('dashboard.employee_activities_table_header[1]') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">
                        <v-btn
                          class="text-capitalize my-3"
                          rounded
                          color="primary"
                          @click="goFill(activity.onePollEmployee.token, activity.type)"
                          v-if="!['completed', 'incompleted', 'saving_answers'].includes(activity.onePollEmployee.status)"
                        >
                          {{ $t('dashboard.reply') }}
                        </v-btn>
                        <v-btn
                          v-else
                          disabled
                          rounded
                          outlined
                          class="text-capitalize my-3"
                        >
                          {{ $t('dashboard.evaluation_finished') }}
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </template>
    <v-col cols="12" align="centered" class="py-10" v-else>
      <h1 class="display-2 my-10 text-center" style="color: #90a4ae">
        {{ $t('dashboard.no_pending_activities') }}
      </h1>
    </v-col>
  </v-row>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    info: Array
  },
  data () {
    return {
      i: 1
    }
  },
  computed: {
    progressPolls () {
      return this.info.filter(p => ['progress', 'launching', 'editing'].includes(p.status))
    }
  },
  methods: {
    goFill (token, module) {
      return this.$store
        .dispatch('session/findHost', 'PULSE')
        .then((host) => {
          if (host && host.url) {
            location.replace(`${host.url}/outside/${module || 'pulse'}/fill/${token}`)
          }
        })
        .catch(err => (this.error = err.code))
    }
  }
})
</script>

<style lang="css" scoped>
.text-center{
  text-align: center !important;
}
</style>
