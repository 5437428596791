var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"777","persistent":"","scrollable":""},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('settings.additional_segmentation_options_to'))+" "),_c('p',{staticClass:"d-inline mb-0 ml-1 font-italic title font-weight-regular"},[_vm._v(" "+_vm._s(_vm.editingDetails.id ? _vm.editingDetails.trans[_vm.lang].label : '')+" ")])]),_c('v-card-subtitle',{staticClass:"pt-3 text-justify"},[_vm._v(" "+_vm._s(_vm.$t('settings.additional_segmentation_options_desc'))+" ")]),_c('v-card-text',{staticClass:"pt-3"},[_c('ValidationObserver',{ref:"options_rf"},_vm._l((_vm.editingDetails.details),function(option,$i){return _c('v-row',{key:$i,staticClass:"pl-4"},[_vm._l((_vm.languages),function(lng,$j){return _c('v-col',{key:$j,attrs:{"cols":"12","sm":"5"}},[($i === 0)?_c('p',{staticClass:"body-2 font-weight-bold text-center text-uppercase"},[_vm._v(_vm._s(lng.text))]):_vm._e(),_c('ValidationProvider',{attrs:{"vid":("d-" + $i + "-" + $j),"name":_vm.$t('settings.additional_segmentation_detail_n', {n: $i + 1}),"rules":{
                required: lng.value == _vm.user.lang
              },"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"light":"","outlined":"","label":_vm.$t('settings.additional_segmentation_insert_detail', {
                  n: $i + 1,
                  o: lng.value !== _vm.user.lang ? ("(" + (_vm.$t('settings.optional')) + ")") : ''
                }),"name":("detail" + $i + $j),"error-messages":errors[0]},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',{staticClass:"mt-n1 mb-0 body-2"},[_vm._v(" "+_vm._s(errors[0])+" ")])]},proxy:true}],null,true),model:{value:(option.trans[lng.value].label),callback:function ($$v) {_vm.$set(option.trans[lng.value], "label", $$v)},expression:"option.trans[lng.value].label"}})]}}],null,true)})],1)}),($i === _vm.editingDetails.details.length - 1)?_c('v-col',{staticClass:"text-center",class:$i === 0 ? 'pt-11' : 'pt-6',attrs:{"cols":"12","sm":"2"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.editingDetails.details.length === _vm.minOptions,"color":"error lighten-1"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"fab":"","x-small":"","color":"error lighten-1","disabled":_vm.editingDetails.details.length === _vm.minOptions},on:{"click":function($event){return _vm.removeDetail(_vm.editingDetails, $i)}}},on),[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('settings.additional_segmentation_remove_detail')))])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.editingDetails.details.length === _vm.maxOptions,"color":"success lighten-1"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","color":"success lighten-1","disabled":_vm.editingDetails.details.length === _vm.maxOptions},on:{"click":function($event){return _vm.addDetail(_vm.editingDetails, $i)}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('settings.additional_segmentation_add_detail')))])])],1):_vm._e()],2)}),1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.saveDetails()}}},[_vm._v(" "+_vm._s(_vm.$t('input.save'))+" ")]),_c('v-btn',{attrs:{"depressed":"","color":"grey lighten-2","disabled":_vm.loading},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t('input.close'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }