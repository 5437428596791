import mapParameters from '../utils/map-parameters'
import Services from './base-services'

const service = new Services('dashboard')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
  startAt?: Date;
  endAt?: Date;
}

export default {
  getInfo: () => {
    return service.get('get-info')
  },
  getActivities: (options: IOptionsList) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null,
      startAt: options && options.startAt ? options.startAt : null,
      endAt: options && options.endAt ? options.endAt : null
    })

    return service.get('activities', params)
  }
}
