
<template>
  <v-container id="enterprise-create" fluid style="max-width: 55rem; margin-top: 6rem;">
    <x-loading
      :display="loading"
    ></x-loading>
     <v-tabs
      v-model="step"
      class="elevation-2"
      centered
      grow
      light
    >
      <v-tabs-slider></v-tabs-slider>
       <v-tab
        href="#1"
      >
        {{ $t('enterprises.enterprise_information') }}
      </v-tab>
      <v-tab
        href="#2"
        v-if="user.role === 'admin'"
      >
        {{ $t('enterprises.contact_employee') }}
      </v-tab>

      <v-tab-item
        value="1"
      >
        <x-step-information
          :enterprise="enterprise"
          step="1"
          :nextAction="user.role === 'admin' ? 'input.continue' : 'input.save'"
          prevAction="input.cancel"
          @changeStep="verifyStepChanged"
        ></x-step-information>
      </v-tab-item>
      <v-tab-item
        value="2"
      >
        <x-step-contact-employee
          v-if="user.role === 'admin'"
          :enterprise="enterprise"
          step="2"
          nextAction="input.save"
          prevAction="input.back"
          @changeStep="verifyStepChanged"
        ></x-step-contact-employee>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import XStepInformation from './steps/information.vue'
// import XStepDemographicCuts from './steps/demographic-cuts.vue'
import XStepContactEmployee from './steps/contact-employee.vue'

import enterprisesServices from '../../services/enterprises'

export default Vue.extend({
  components: {
    XStepInformation,
    // XStepDemographicCuts,
    XStepContactEmployee
  },
  data () {
    return {
      tab: null,
      loading: false,
      enterprise: {
        name: '',
        userId: '',
        sectorId: '',
        countryId: '',
        address: '',
        enterpriseSizeId: '',
        languageId: '',
        logo: '',
        demographicItems: [],
        contact: {
          firstName: '',
          lastName: '',
          identifyTypeId: '',
          identifyDocument: '',
          email: '',
          phoneNumber: '',
          address: '',
          id: ''
        },
        license: {
          planId: '',
          startAt: '',
          duration: ''
        },
        headquarter: ''
      },
      step: '1',
      demographicItems: []
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    verifyStepChanged (data, step) {
      this.enterprise = Object.assign({}, this.enterprise, data)
      const lastCase = this.user.role === 'admin' ? 3 : '2'
      switch (step) {
        case '0': return this.$router.go(-1)
        case lastCase: return this.edit()
        default: this.step = step
      }
    },
    edit () {
      this.loading = !this.loading
      return enterprisesServices.edit(this.$route.params.id, this.enterprise)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('enterprises.enterprise_edited'))
          this.$router.go(-1)
          this.loading = !this.loading
        })
        .catch((err) => {
          const error = this.$t(`errors.${err.code}`)
          this.$store.dispatch('alert/error', error)
          this.loading = !this.loading
        })
    }
  },
  created () {
    enterprisesServices.getOne(this.$route.params.id)
      .then((res) => {
        this.enterprise.userId = res.customer.userId
        this.enterprise.name = res.name
        this.enterprise.sectorId = res.sectorId
        this.enterprise.countryId = res.countryId
        this.enterprise.employeesNumberId = res.employeesNumberId
        this.enterprise.address = res.address
        this.enterprise.enterpriseSizeId = res.enterpriseSizeId
        this.enterprise.languageId = res.languageId
        this.enterprise.logo = res.logo
        // this.enterprise.demographicItems = res.demographicItemsIds.map(item => item.demographicItemId)
        this.enterprise.customerId = res.customer.id
        this.enterprise.contact.name = res.customer.name
        this.enterprise.contact.identifyDocument = res.customer.identifyDocument
        this.enterprise.contact.email = res.customer.user.email
        this.enterprise.contact.phoneNumber = res.customer.phoneNumber
        this.enterprise.contact.address = res.customer.address
        this.enterprise.contact.id = res.customer.id
        this.enterprise.contact.identifyTypeId = res.customer.identifyTypeId
        this.enterprise.contact.countryId = res.customer.countryId

        this.enterprise.headquarter = res.headquarter.name
      })
  }
})
</script>

<style>
  #enterprise-create div.v-stepper,
  #enterprise-create div.v-stepper div.v-stepper__items,
  #enterprise-create div.v-stepper div.v-stepper__items div.v-stepper__wrapper {
    overflow: visible !important;
  }
</style>
