
export default {
  title: 'Calculadora',
  leaders: 'líderes',
  persons: 'personas',
  participant_qty: 'Número de {0} a medir',
  participant_qty_desc1: 'Recomendamos hacer la medición<br>a las personas que tengan mínimo<br>3 meses en la compañía.',
  participant_qty_desc2: 'Cada líder puede ser evaluado<br>por sus jefes, pares o colegas,<br>y colaboradores.',
  required_culture_desc: 'Te ayudamos a definir<br>la cultura requerida<br>a través de un taller.',
  consolidated_dml_report_desc: 'El reporte consolidado agrupa<br>a todos los líderes que hacen<br>parte de la medición.',
  consolidated_report_qty_desc1: 'Recomendamos generar<br>reportes segmentados de<br>mínimo 5 personas.',
  consolidated_report_qty_desc2: 'Para generar reportes segmentados,<br>se requiere mínimo 5 personas<br>en el segmento.'
}
