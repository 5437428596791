
export default {
  profile_information: 'Profile information',
  has_not_enterprise: '(Does not have an assigned company)',
  commercial_account: 'multi-Company account',
  available_tokens: 'Tokens available',
  contact_information: 'ID - Contact information',
  registered_enterprises: 'Registered companies',
  changed_password_msg: 'Your password has been changed successfully. </br>To access your account again please log in',
  changed_email_msg: ' We have sent an email to <span style="color: #3899da">{email}</span>,<br> to verify your account. Please check your inbox or spam folder <i>spam</ i >. Please confirm your account and log in again',
  customers_list: 'Client list',
  resumen: 'Client summary',
  look_for: 'Find a client',
  filter_by: 'Sort by',
  no_data: 'No clients to display',
  customer_name: 'Name',
  admin_name: 'Administrator name',
  register_date: 'Register date',
  balance: 'Balance',
  status: 'Status',
  actions: 'Actions',
  modal_enable: 'Do you want to enable the client?',
  modal_disable: 'Do you want to disable the client?',
  modal_enable_des: 'Once enabled, the client will be able to log in again',
  modal_disable_des: 'Once disabled, the client will not be able to log in until enabled again',
  add_customer: 'Add client',
  type: 'Type',
  commercial: 'multi-Companies',
  personal: 'Single Company',
  to_access: 'Access',
  list_registered_enterprise: 'Registered companies',
  request_product: 'Request product',
  request_message_description: 'Write your message below to request the selected product',
  message: 'Message',
  request_sent: 'Request sent successfully',
  message_not_found: 'The message cannot be empty',
  client_info: 'client information',
  filters: {
    all: 'All',
    active: 'Active',
    inactive: 'Inactive'
  },
  company_name: 'Name',
  country: 'Country',
  headquarter: 'Headquarter',
  address: 'Address',
  successfully_registered: 'Successfully registered',
  successfully_edited: 'Successfully edited'
}
