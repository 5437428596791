
export default {
  add_enterprise: 'Add Company',
  admin_left_table_title: 'Companies',
  admin_bottom_table_title: 'Recent assessments',
  product: 'Product',
  service: 'Service',
  customer: 'Client',
  enterprise: 'Company',
  status: 'Status',
  createdAt: 'Creation date',
  activities_resume: 'Activity summary',
  filter_by_product: 'Sort by Tool',
  pending: 'Pending',
  progress: 'In progress',
  completed: 'Completed',
  closed: 'Closed',
  create_poll: 'Create assessment',
  continue_poll: 'Continue assessment',
  continue: 'Continue',
  employee_left_table_title: 'Assessments in progress',
  employee_progress: 'Completed on:',
  employee_right_table_title: 'Assigned assessments',
  enterprise_left_table_title: 'Current plan',
  enterprise_right_table_title: 'Assessments',
  expired_plan: '(Expired plan)',
  general_list: 'General list',
  general_title: 'Dashboard',
  has_started: 'Has been started',
  not_started: 'To be started',
  init: 'Start:',
  end: 'End',
  ended: 'Ended on:',
  left_days: 'Remaining days',
  left_questions: 'Remaining questions:',
  finished: 'Finished',
  period_divisor: 'to',
  poll: 'Assessment:',
  reply: 'Answer',
  poll_completed: 'Yes',
  poll_incompleted: 'No',
  poll_init_date: 'Start:',
  poll_end_date: 'Valid until:',
  qty_enterprises: 'Active companies',
  qty_employees: 'Employees',
  qty_licesenses: 'Licenses',
  team_trends: 'Team trends',
  trend_chart: 'Trend graph',
  update_plan: 'Update plan',
  dimension_leaders: 'Bosses',
  dimension_inspiration: 'My inspiration',
  dimension_job: 'My job',
  dimension_environment: 'Positive work environment',
  dimension_team: 'My team',
  dimension_development: 'My development and learning',
  view_poll_details: 'View assessment details',
  has_no_polls: 'You need to create at least 2 assessments in the same period to see the graph.',
  start: 'Start',
  start_poll: 'Start assessment',
  potential_clients: 'Potential clients',
  inactive_clients: 'Inactive clients',
  active_clients: 'Active clients',
  register_not_found: 'No records were found',
  login_as_customer: 'Login as client',
  login_as_customer_body: 'Do you want to log in as {name}?',
  emails_activity: 'Email summary',
  products_activity: 'Activity in products',
  pending_activities: 'pending assessments',
  pending_activity: 'pending assessment',
  no_pending_activities: 'No pending activities.',
  activity_creation: 'Activity creation',
  employee_activities_table_header: [
    'Assessment (Role)',
    'Action'
  ],
  autoevaluation: 'Self-assessment',
  evaluators: 'Assessors',
  leader: 'Boss',
  pair: 'Peer',
  dependent: 'Employee',
  name: 'Assessment name',
  dates: 'Dates',
  actions: 'Actions',
  to: 'until',
  last_polls: 'Last assessments',
  see_evaluated: 'View assessors',
  peding: 'Pending',
  evaluations_resume: 'Assessment summary',
  no_created_evaluations: 'No measurements created',
  go_create_evaluation: 'Go to assessment creation',
  see_details: 'See details',
  evaluation_finished: 'Assessment finished'
}
