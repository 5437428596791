
export default {
  login: 'INGRESAR A LA PLATAFORMA',
  problems_to_login: '¿Olvidaste tu contraseña?',
  recover_password: 'Recuperar contraseña',
  go_back: 'Volver',
  email_not_found: 'Dirección de correo {email} no encontrada.',
  unexpected_error: 'Ocurrió un error inesperado. Si el problema persiste contacte al administrador.',
  password_successfully_restablish: 'Restablecimiento de contraseña exitoso',
  password_sent_to_email: 'Su nueva contraseña ha sido enviada a su correo electrónico.',
  password_notification_sent: 'Notificación enviada al administrador para seguir con el restablecimiento de su contraseña.',
  accept: 'Aceptar',
  password_not_updated: 'Lo sentimos, su contraseña no fue actualizada.',
  password_confirm: 'Confirmar contraseña',
  password_confirmation: 'Confirmación de contraseña',
  new_password: 'Nueva contraseña',
  new_password_confirm: 'Confirmar nueva contraseña',
  save_new_password: 'Guardar nueva contraseña',
  password_update: 'Actualización de contraseña.',
  password_updated: 'Su contraseña ha sido actualizada correctamente.',
  password_reset_requested: 'Recuperación de contraseña solicitada',
  check_your_email: 'Hemos enviado un correo electrónico a la dirección especificada con los pasos a seguir para recuperar tu contraseña.',
  create_password: 'Crear contraseña',
  change_password: 'Cambiar contraseña',
  save_password: 'Guardar contraseña',
  password_create: 'Creación de contraseña',
  password_created: 'Contraseña creada',
  password_not_created: 'Lo sentimos, su contraseña no pudo se creada. Recuerde que las contraseñas deben cumplir los requisito listados a la derecha:',
  password_created_greet_title: 'Su contraseña fue creada exitosamente',
  password_created_greet_text: 'Ahora puede utilizar su correo electrónico y esta nueva contraseña para ingresar al sistema',
  token_not_valid: 'El código de verificación es inválido',
  token_expired: 'Este código expiró, recuerde que por su seguridad nuestos códigos de acceso solo poseen un máximo de tres días de vigencia.',
  token_used: 'Este código ya ha sido utilizado.',
  verify_or_contact: 'Verifique el código o enlace que ha utilizado e intente nuevamente. También puede ponerse en contacto con OCC Solutions para más información.',
  go_sign_in: 'Volver al inicio de sesión',
  logged_as_enterprise: 'En estos momentos usted se encuentra identificado como una de las empresas asociadas, puede cerrar su sesión temporal si desea elegir otra empresa o adquirir más tokens',
  logged_as_enterprise_superadmin: 'En estos momentos usted se encuentra identificado como una de las empresas asociadas a este cliente, puede cerrar su sesión temporal como empresa si desea elegir otra empresa; o puede cerrar su sesión temporal como cliente para volver al dashboard del Super Administrador',
  logged_as_employee: 'Sesión temporal como colaborador',
  logged_as_customer: 'En estos momentos usted se encuentra con una Sesión temporal de Usuario Administrador, puede cerrar su sesión temporal para volver al dashboard del Super Administrador',
  close_session: 'Cerrar sesión',
  close_session_as_customer: 'Cerrar sesión cliente',
  close_session_as_enterprise: 'Cerrar sesión empresa',
  sign_up: 'Registrarme',
  select_customer_title: 'Seleccione la cuenta que mejor se adapte a sus necesidades.',
  select_customer_personal: 'Cuenta Única empresa',
  select_customer_personal_text: 'Puedes registrarte como administrador y registrar a tu empresa para gestionarla de forma personal',
  select_customer_personal_select: 'Seleccionar',
  select_customer_commercial: 'Cuenta Multi Empresas',
  select_customer_commercial_text: 'Puedes registrarte como administrador para gestionar y administrar multilpes empresas',
  select_customer_commercial_select: 'Seleccionar',
  has_no_account: '¿Aún no tienes cuenta?',
  customer_personal_d: 'Selecciona esta opción si vas a administrar y gestionar una cuenta empresarial.',
  customer_commercial_d: 'Selecciona esta opción si eres un grupo empresarial o un consultor que administra y gestiona varias empresas.',
  customer_personal_d_2: 'Registra un usuario que es un grupo empresarial o un consultor que administra y gestiona varias empresas.',
  customer_commercial_d_2: 'Registra un usuario como administrador de multilpes empresas.',
  personal_account: 'Cuenta Única Empresa',
  commercial_account: 'Cuenta Multi Empresas',
  success_register: '¡Tu registro ha sido exitoso!',
  has_sent_to: 'Hemos enviado un correo a <span style="color: #3375d2;">{0}</span> con un link para que puedas crear tu contraseña y finalizar el registro en la plataforma.',
  please_verify_account: 'Por favor revisa tu bandeja de entrada o la carpeta <span class="font-italic">{0}</span>',
  spam: 'spam',
  view_select_title: 'Selecciona la cuenta que mejor se adapte a tus necesidades',
  view_sign_up_personal_enterprise: 'Información de la empresa',
  view_sign_up_personal_user: 'Datos del administrador',
  user_data: 'datos de usuario',
  leader: 'Jefe',
  leaders: 'Jefes',
  dependent: 'Colaborador',
  dependents: 'Colaboradores',
  pair: 'Par',
  pairs: 'Pares',
  accept_terms_and_conditions: 'Acepto los Términos y Condiciones',
  accept_data_protection_policies: 'Acepto las Políticas de Protección de Datos',
  enter_real_email: 'Por favor ingresa el correo electrónico con el que creaste tu cuenta.',
  confirm_logout: '¿Estás seguro que deseas cerrar sesión?',
  password_rules: [
    'Mínimo 8 caracteres',
    'Máximo 100 caracteres',
    'Al menos 1 letra mayúscula (A-Z)',
    'Al menos 1 letra minúscula (a-z)',
    'Al menos 1 número (0-9)',
    'Sin espacios en blanco'
  ]
}
