
export default {
  title: 'Calculator',
  leaders: 'leaders',
  persons: 'persons',
  participant_qty: 'Number of {0} to assess',
  participant_qty_desc1: 'We recommend doing the assessment<br>for people who have been with the company<br>for at least 3 months.',
  participant_qty_desc2: 'Each leader can be evaluated<br>by their bosses, peers or colleagues,<br>and collaborators.',
  required_culture_desc: 'We help you define<br>the required culture<br>through a workshop.',
  consolidated_dml_report_desc: 'The consolidated report brings together<br>all the leaders who are<br>part of the assessment.',
  consolidated_report_qty_desc1: 'We recommend generating<br>segmented reports<br>of at least 5 people.',
  consolidated_report_qty_desc2: 'To generate segmented reports,<br>a minimum of 5 people are<br>required in the segment.'
}
