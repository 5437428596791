import tokenAccountDetailService from '@/services/token-account-detail'
import paymentService from '@/services/payments'

export default {
  data () {
    return {
      showPendingTransactionAlert: false,
      acceptedPendingTransactionAlert: false
    }
  },
  methods: {
    checkPending () {
      if (this.acceptedPendingTransactionAlert) {
        return Promise.resolve(true)
      }
      return tokenAccountDetailService.getPendingTransaction()
        .then(res => {
          if (res) {
            this.showPendingTransactionAlert = true
            this.loading = false
          }
          return !res
        })
        .catch((err) => {
          const error = err.code ? this.$t(`errors.${err.code}`) : err
          this.$store.dispatch('alert/error', error)
          this.loading = false
        })
    },
    cancelMove (onCancelMove, ReturnCode) {
      tokenAccountDetailService.cancelMove(this.currentTransactionId)
        .then(() => {
          if (ReturnCode === 'FAIL_EXCEEDEDLIMIT') {
            this.$store.dispatch('alert/error', this.$t('tokens.payment_exceed_limit'))
          } else {
            this.$store.dispatch('alert/error', this.$t('tokens.error_on_payment_gateway'))
            onCancelMove()
          }
          this.loading = false
        })
    },
    startTransaction (mount, totalCost, iva, paymentCurrency, onCancelMove) {
      return tokenAccountDetailService.preBuy(mount)
        .then((res) => {
          if (res.id) {
            this.currentTransactionId = res.id
            return paymentService.setTransaction(totalCost, iva, paymentCurrency, this.currentTransactionId)
          } else {
            return Promise.resolve(res)
          }
        })
        .then(transactionResponse => {
          if (transactionResponse.ReturnCode === 'SUCCESS') {
            window.location = transactionResponse.eCollectUrl
          } else if (transactionResponse.ReturnCode === 'PAYMENT_INACTIVE') {
            this.$router.push('/tokens/maintenance')
          } else {
            this.cancelMove(onCancelMove, transactionResponse.ReturnCode)
          }
        })
        .catch((err) => {
          const error = err.code ? this.$t(`errors.${err.code}`) : err
          this.$store.dispatch('alert/error', error)
          this.loading = false
        })
    }
  }
}
