<template>
  <v-row>
    <template v-if="info.products.length">
      <v-col sm="4" cols="12" v-for="product in info.products" :key="product.id">
        <v-card
          class="px-4 py-4"
          style="cursor: pointer"
          :disabled="!product.activitiesByProduct.length"
          :href="product.webHostPort && product.webHostPort !== 80 ? `${product.webHostUrl}:${product.webHostPort}/dashboard` : product.webHostUrl"
        >
          <v-row>
            <v-col cols="12" align="center">
              <img :src="product.logo || product.defaultLogo" :alt="product.name" width="180em" height="180em">
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center">
              <h1 class="headline">{{ product.name }}</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center">
              <v-chip color="primary">
                {{product.activitiesByProduct.length}} {{ product.activitiesByProduct.length === 1 ? $t('dashboard.pending_activity') : $t('dashboard.pending_activities') }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </template>
    <template v-else>
      <v-col cols="12" align="centered" class="py-10">
        <h1 class="display-2 my-10 text-center" style="color: #90a4ae">
          {{ $t('dashboard.no_pending_activities') }}
        </h1>
      </v-col>
    </template>
  </v-row>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    info: Object
  },
  data () {
    return {}
  }
})
</script>

<style lang="css" scoped>
.text-center{
  text-align: center !important;
}
.info-data{
  font-size: 3em;
}
.minipercentage{
  font-size: 2em !important;
}
.padding-left-name{
  padding-left: .4em !important;
}
.padding-right-name{
  padding-right: .4em !important;
}
.text-success{
  color: #4caf50 !important
}
.text-error{
  color: #ff5252 !important
}
.no-polls{
  font-size: 1.8em;
  color: lightgray;
}
.padding-card{
  padding: 3em;
}
.pulse {
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgb(56, 153, 218);
  animation: pulse 2s infinite;
}
.no-decoration{
  text-decoration: none;
}
.survey-title{
  font-size: 1.2em;
  font-weight: bold;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(56, 153, 218);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgb(56, 153, 218);
    box-shadow: 0 0 0 0 rgb(56, 153, 218);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
</style>
