
export default {
  title: 'Cofigurando Servicio',
  name: 'Nombre del Servicio',
  modality: 'Modalidad',
  periocity: 'Periocidad',
  periocities: {
    Annual: 'Anual',
    Biannual: 'Semestral',
    Quarterly: 'Trimestral',
    Monthly: 'Mensual',
    Biweekly: 'Bimensual',
    Weekly: 'Semanal',
    Diary: 'Diario',
    OneTime: 'Una vez',
    ByDemand: 'Bajo demanda'
  },
  recomendation: 'Recomendación',
  price: 'Precio en Tokens',
  actions: 'Acciones',
  modalities: {
    Download: 'Descarga',
    Person: 'Persona',
    Workshop: 'Taller',
    Medition: 'Medición'
  },
  modalities_tag: {
    Download: 'Descarga',
    Workshop: 'Taller',
    Person: 'Lider',
    Medition: 'Colaborador'
  },
  product_service_desc: {
    'OCC PULSE': {
      'ENGAGEMENT': 'Medición completa a toda la organización.<br>Debe ser realizada anualmente.',
      'PULSE': 'Medición sobre dimensiones específicas a cortes demográficos específicos.<br>Debe ser realizada de forma periódica (trimestral idealmente).',
      'REPORTE POR POBLACIÓN': 'Reporte detallado de poblaciones especificas.'
    },
    'OCC CULTURA': {
      'CULTURA ACTUAL ORGANIZACIONAL': 'Medición completa a toda la organización.<br>Debe ser realizada anualmente.',
      'CULTURA REQUERIDA': 'Taller con el equipo directivo para definir la cultura que<br>requiere de acuerdo con la estrategia de la organización.',
      'CULTURA CORTE DEMOGRÁFICO': 'Reporte detallado de poblaciones específicas.',
      'PULSOS EN CULTURA': 'Medición sobre dimensiones específicas a cortes demográficos específicos.<br>Debe ser realizada de forma periódica (trimestral idealmente).',
      'RESUMEN CORTES DEMOGRÁFICOS': 'Reporte resumen de todas las variables de los cortes demográficos.'
    },
    'OCC DML 360°': {
      'OCC DML 360° INDIVIDUAL': 'Reporte detallado individual por cada líder que se quiera evaluar.',
      'REPORTE POR CORTE DEMOGRÁFICO': 'Reporte detallado de poblaciones especificas.',
      'REPORTE ORGANIZACIONAL OCC DML 360°': 'Reporte agrupado de todos los líderes incluidos en la medición.'
    },
    'COACHING Y CONVERSACIONES': {
      'COACHING Y CONVERSACIONES REPORTE INDIVIDUAL': 'Reporte detallado individual por cada líder que se quiera evaluar.',
      'REPORTE ORGANIZACIONAL COACHING Y CONVERSACIONES': 'Reporte agrupado de todos los líderes incluidos en la medición.'
    },
    'OCC POR': {
      'OCC POR REPORTE INDIVIDUAL': 'Reporte detallado individual de cada miembro del equipo de trabajo.',
      'REPORTE ORGANIZACIONAL OCC POR': 'Reporte agrupado de todos los miembros del equipo incluidos en la medición.'
    },
    'LIDERAZGO 360° A LA MEDIDA': {
      'LIDERAZGO 360° A LA MEDIDA INDIVIDUAL': 'Reporte detallado individual por cada líder que se quiera evaluar.',
      'REPORTE POR POBLACION LIDERAZGO': 'Reporte detallado de poblaciones especificas.',
      'REPORTE ORGANIZACIONAL LIDERAZGO': 'Reporte agrupado de todos los líderes incluidos en la medición.'
    },
    'ENERGY COMPASS': {
      'ENERGY COMPASS INDIVIDUAL': 'Reporte detallado individual de cada colaborador.',
      'ENERGY COMPASS POR POBLACION': 'Reporte agrupado de poblaciones especificas.',
      'ENERGY COMPASS ORGANIZACIONAL': 'Reporte agrupado de todos los colaboradores incluidos en la medición.'
    }
  }
}
