var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-3",staticStyle:{"min-height":"calc(100vh - 300px)"}},[_c('v-row',{staticClass:"px-12",class:_vm.toAddNewLines && !_vm.itemsBeingCalculated.length ? 'fill-height justify-center' : 'justify-left',staticStyle:{"min-height":"23px"},attrs:{"no-gutters":"","align":_vm.toAddNewLines && !_vm.itemsBeingCalculated.length ? 'center' : 'start'}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"3"}},[(_vm.toAddNewLines)?_c('v-btn',{staticClass:"px-2",attrs:{"color":_vm.toAddNewLines ? 'primary' : 'secondary',"disabled":(!_vm.toAddNewLines && !_vm.itemsBeingCalculated.length) || _vm.editable},on:{"click":_vm.toggleForm}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.signed))]),(_vm.toAddNewLines)?_c('h5',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(!_vm.itemsBeingCalculated.length ? _vm.$t('tokens.quote_new') : _vm.$t('tokens.quote_another'))+" ")]):_c('h5',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t('input.cancel')))])],1):_vm._e()],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"3"}}),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"2"}}),_c('v-col',{staticClass:"pa-0 text-center",attrs:{"cols":"12","sm":"4"}},[(_vm.variableItemInForm.tokensQty > 0)?_c('span',{staticClass:"caption info--text lighten-2",domProps:{"innerHTML":_vm._s(_vm.$t('tokens.points_to_next_range', [
            _vm.$options.filters.formatCurrency(_vm.nextTokenRange.minRange - _vm.totalTokens, 0),
            _vm.currency.paymentSymbol,
            _vm.currency.currencySymbol,
            _vm.$options.filters.formatCurrency(_vm.currentTokenRange.price * _vm.currency.value),
            _vm.$options.filters.formatCurrency(_vm.findNextTokenRange())
        ]))}}):_vm._e()])],1),(_vm.calculatorForm)?_c('v-row',{staticClass:"no-gutters"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"required":"","items":_vm.productList,"item-text":"name","label":_vm.$t('tokens.tool'),"data-vv-name":"select"},on:{"change":_vm.filterServices},model:{value:(_vm.variableItemInForm.product),callback:function ($$v) {_vm.$set(_vm.variableItemInForm, "product", $$v)},expression:"variableItemInForm.product"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"required":"","items":_vm.productServiceFiltered,"disabled":!_vm.variableItemInForm.product,"menu-props":{ top: false, offsetY: true },"item-text":"name","label":_vm.$t('tokens.type'),"data-vv-name":"select"},on:{"change":_vm.updateCosts},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-layout',_vm._g(_vm._b({attrs:{"wrap":""}},'v-layout',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(data.item.name))])],1)],1)]}}],null,true)},[_c('span',{staticClass:"caption",domProps:{"innerHTML":_vm._s(
                      _vm.$t(("services.product_service_desc." + (_vm.variableItemInForm.product) + "." + (data.item.name)))
                    )}})])]}}],null,false,2738762953),model:{value:(_vm.variableItemInForm.service),callback:function ($$v) {_vm.$set(_vm.variableItemInForm, "service", $$v)},expression:"variableItemInForm.service"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"2"}},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center ma-0 body-2"},[_vm._v(_vm._s(_vm.modality || _vm.$t('tokens.modality')))]),_c('x-inputs-token',{attrs:{"input":_vm.variableItemInForm.qty,"min":1,"max":!_vm.variableItemInForm.service || _vm.variableItemInForm.service === 'CULTURA REQUERIDA'
                    ? 1
                    : 999999},on:{"updateInput":function ($event) { _vm.variableItemInForm.qty = $event; _vm.changeQty() }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{attrs:{"required":"","label":_vm.$t('tokens.points_equivalence')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","dark":"","small":"","color":"primary"}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-information")])],1)]}}],null,false,3558113278)},[_c('p',{staticClass:"mb-0 caption",domProps:{"innerHTML":_vm._s(_vm.$t('tokens.points_desc'))}})])]},proxy:true}],null,false,3489871369),model:{value:(_vm.variableItemInForm.tokensQty),callback:function ($$v) {_vm.$set(_vm.variableItemInForm, "tokensQty", $$v)},expression:"variableItemInForm.tokensQty"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{attrs:{"required":"","readonly":"","label":_vm.$t('currencies.value'),"prefix":((_vm.currency.paymentSymbol) + " " + (_vm.currency.currencySymbol))},model:{value:(_vm.variableItemInForm.tokensCostConverted),callback:function ($$v) {_vm.$set(_vm.variableItemInForm, "tokensCostConverted", $$v)},expression:"variableItemInForm.tokensCostConverted"}})],1)],1),_c('v-row',{staticClass:"justify-end mr-0 mb-4"},[(_vm.itemsBeingCalculated.length || _vm.editable)?_c('v-btn',{staticClass:"mr-4",on:{"click":_vm.toggleForm}},[_vm._v(" "+_vm._s(_vm.$t('input.cancel'))+" ")]):_vm._e(),_c('v-btn',{staticClass:"primary",attrs:{"disabled":_vm.isDisabled || _vm.limitReached},on:{"click":_vm.addToCalculated}},[_vm._v(" "+_vm._s(_vm.$t('input.next'))+" ")])],1)],1)],1)],1):_vm._e(),(!_vm.showEmpty && _vm.itemsBeingCalculated.length)?_c('v-divider',{staticClass:"mt-3"}):_vm._e(),(!_vm.showEmpty)?_c('v-row',{staticClass:"justify-center child-flex mx-9"},[_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headersCalculated,"items":_vm.itemsBeingCalculated,"items-per-page":100,"hide-default-footer":"","hide-default-header":"","no-data-text":""},scopedSlots:_vm._u([{key:"item.products",fn:function(ref){
                    var item = ref.item;
return [_c('p',{staticClass:"body-2 pt-3"},[_c('strong',[_vm._v(_vm._s(item.product))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.capitalize(_vm.getMeditionType(item.service))))]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('tokens.of'))+" "+_vm._s(item.service)+", "),_c('i',[_vm._v(_vm._s(item.qty)+" "+_vm._s(_vm.getMeditionUnit(item.service, item.qty)))])])])]}},{key:"item.qty",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"150px"}},[_c('strong',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.tokensQty,0)))]),_vm._v(" "+_vm._s(_vm.$t('tokens.points'))+" ")])]}},{key:"item.amounts",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"150px"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.currency.paymentSymbol)+" "+_vm._s(_vm.currency.currencySymbol)+_vm._s(_vm._f("formatCurrency")((item.tokensCost * _vm.currency.value),2))+" ")])])]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"70px"}},[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","icon":"","disabled":_vm.calculatorForm},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1),_c('v-btn',{attrs:{"small":"","icon":"","color":"red","disabled":_vm.calculatorForm},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close-circle")])],1)],1)]}}],null,false,3380418936)})],1):_vm._e(),(_vm.totalFields)?_c('v-container',{staticClass:"py-0 px-4"},[_c('v-row',{staticClass:"justify-end ma-5"},[_c('v-col',{staticClass:"blue lighten-5 rounded",attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"text-uppercase totalItem"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('tokens.total'))+" "+_vm._s(_vm.$t('tokens.points'))+": ")]),_c('strong',{staticClass:"totalValue"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.totalTokens,0))+" ")])]),_c('div',{staticClass:"text-uppercase totalItem"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('currencies.value'))+" "+_vm._s(_vm.$t('tokens.by'))+" "+_vm._s(_vm.$t('tokens.point'))+": ")]),_c('strong',{staticClass:"totalValue"},[_vm._v(" "+_vm._s(_vm.currency.paymentSymbol)+" "+_vm._s(_vm.currency.currencySymbol)+_vm._s(_vm._f("formatCurrency")(_vm.currentTokenRange.price * _vm.currency.value,2))+" ")])]),_c('div',{staticClass:"text-uppercase totalItem"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('tokens.if_you_buy_points', [
            _vm.$options.filters.formatCurrency(_vm.currentTokenRange.maxRange + 1, 0)
          ]))}}),_c('strong',{staticClass:"totalValue"},[_vm._v(" "+_vm._s(_vm.currency.paymentSymbol)+" "+_vm._s(_vm.currency.currencySymbol)+_vm._s(_vm._f("formatCurrency")(_vm.findNextTokenRange(),2))+" ")])])]),_c('v-col',{staticClass:"py-0 pr-0",attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"pa-3 green lighten-5 rounded"},[_c('div',{staticClass:"text-uppercase totalItem"},[_c('span',[_vm._v(_vm._s(_vm.$t('tokens.value'))+":")]),_c('strong',{staticClass:"totalValue"},[_vm._v(" "+_vm._s(_vm.currency.paymentSymbol)+" "+_vm._s(_vm.currency.currencySymbol)+_vm._s(_vm._f("formatCurrency")(_vm.totalCostByCurrency,2))+" ")])]),_c('div',{staticClass:"text-uppercase totalItem"},[_c('span',[_vm._v(_vm._s(_vm.$t('tokens.iva'))+":")]),_c('strong',{staticClass:"totalValue"},[_vm._v(" "+_vm._s(_vm.currency.paymentSymbol)+" "+_vm._s(_vm.currency.currencySymbol)+_vm._s(_vm._f("formatCurrency")(_vm.ivaByCurrency,2))+" ")])]),_c('div',{staticClass:"text-uppercase totalItem"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('tokens.total_value'))+": ")]),_c('strong',{staticClass:"totalValue"},[_vm._v(" "+_vm._s(_vm.currency.paymentSymbol)+" "+_vm._s(_vm.currency.currencySymbol)+_vm._s(_vm._f("formatCurrency")(_vm.totalToPayByCurrency,2))+" ")])])])])],1),_c('v-row',{staticClass:"justify-end mr-2"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 caption info--text text-right font-weight-medium"},[_vm._v(" Al hacer click en el botón Cotizar, ??? ")])]),_c('v-col',{staticClass:"pr-0 text-right",attrs:{"cols":"12","md":"2"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white"}},on),[_vm._v(" "+_vm._s(_vm.$t('input.cancel'))+" ")])]}}],null,false,1224524963),model:{value:(_vm.dialogCancel),callback:function ($$v) {_vm.dialogCancel=$$v},expression:"dialogCancel"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center py-5"},[_c('v-icon',{attrs:{"large":"","color":"orange"}},[_vm._v("mdi-alert-circle")])],1),_c('v-card-text',[_c('p',{staticClass:"text-center body-1"},[_vm._v(_vm._s(_vm.$t('tokens.calculator_closing_message')))])]),_c('v-card-actions',{staticClass:"d-flex justify-end pb-5 mr-10"},[_c('v-btn',{staticClass:"text-capitalize mr-2",attrs:{"color":"blue-grey lighten-2","dark":""},on:{"click":function($event){_vm.dialogCancel = false}}},[_vm._v(_vm._s(_vm.$t('tokens.return')))]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"blue darken-1","dark":""},on:{"click":_vm.cancelCalc}},[_vm._v(" "+_vm._s(_vm.$t('tokens.confirm_close_calculator'))+" ")])],1)],1)],1)],1),_c('v-col',{staticClass:"pl-0 text-right",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"px-7",attrs:{"color":"primary","disabled":_vm.disableAcquire || _vm.calculatorForm}},[_vm._v(" Cotizar ")])],1)],1)],1):_vm._e(),_c('x-loading',{attrs:{"display":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }