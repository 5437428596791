
<template>
  <v-container fluid>
    <x-loading
      :display="loading"
    ></x-loading>
    <v-row wrap row v-if="!loading">
      <v-col cols="12">
        <v-card>
          <v-card-title primary-title>
            <div>
              <h4 class="display-1">{{ `${employee.firstName} ${employee.lastName}` }}</h4>
              <span class="subheading grey--text">
                <template v-if="employee.employment">
                  {{ $t('employees.employment_at', { title: employee.employment }) }}
                </template>
                <template v-if="employee.enterprise">{{ employee.enterprise.name }}</template>
              </span>
            </div>
            <v-spacer></v-spacer>
            <x-chip-status
              outlined
              label
              :status="employee.active"
              classes="pa-4 subheading"
            ></x-chip-status>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-row wrap row>
              <v-col cols="12" md="6" >
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-account-card-details</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.identify_document') }}</h6>
                    </v-list-item-title>
                    <v-list-item-title class="grey--text subheading">
                      {{ employee.identifyType | identification }} - {{ employee.identifyDocument }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" >
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-calendar-export</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.admission') }}</h6>
                    </v-list-item-title>
                    <v-list-item-title class="grey--text subheading">
                      {{ employee.admission | date({date: true, hour:false}) | checkDate }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" >
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-briefcase</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.jobType') }}</h6>
                    </v-list-item-title>
                    <v-list-item-title class="grey--text subheading">
                      <span v-if="employee.jobType">{{ employee.jobType | translate }}</span>
                      <span v-else>--</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" >
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-account-multiple</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.gender') }}</h6>
                    </v-list-item-title>
                    <v-list-item-title class="grey--text subheading">
                      <span v-if="employee.gender">{{ employee.gender | translate }}</span>
                      <span v-else>--</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" >
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-email</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.email') }}</h6>
                    </v-list-item-title>
                    <v-list-item-title class="grey--text subheading">
                      {{ employee.employee.email || '--' }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" >
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-earth</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.country') }}</h6>
                    </v-list-item-title>
                    <v-list-item-title class="grey--text subheading">
                      <span v-if="employee.country">{{ employee.country | translate }}</span>
                      <span v-else>--</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" >
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-map-marker</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.headquarter') }}</h6>
                    </v-list-item-title>
                    <v-list-item-title class="grey--text subheading">
                      {{ employee.headquarter ? employee.headquarter.name : '--' }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-cake</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.birthdate') }}</h6>
                    </v-list-item-title>
                    <v-list-item-title class="grey--text subheading">
                      {{ employee.birthdate | date({date: true, hour:false}) | checkDate }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" >
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-school</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.academic_degree') }}</h6>
                    </v-list-item-title>
                    <v-list-item-title class="grey--text subheading">
                      <span v-if="employee.academicDegree">{{ employee.academicDegree | translate }}</span>
                      <span v-else>--</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-row justify="end">
              <v-col cols="12" class="text-right mb-5 pr-12">
                <v-btn
                  to="/employees"
                  large
                ><v-icon left>input</v-icon>
                {{ $t('go_back') }}</v-btn>

                <v-btn
                  :to="`/employees/${employee.employeeId}/edit`"
                  small
                  large
                  class="ml-2"
                  color="primary"
                ><v-icon left>mdi-pencil</v-icon>
                {{ $t('input.edit') }}</v-btn>

                <v-btn
                  @click="modalEna = true"
                  large
                  class="ml-2"
                  color="success"
                  v-if="!employee.active"
                >{{ $t('input.enable') }}</v-btn>

                <v-btn
                  @click="modalDis = true"
                  large
                  class="ml-2"
                  color="error"
                  v-else
                >{{ $t('input.disable') }}</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <x-confirmation-modal
      :show.sync="modalEna"
      :reversible="true"
      :title="$t('employees.enable_title')"
      :action="enableEmployee"
      color="success"
      :btn-save="$t('input.enable')"
      @close="modalEna = false"
    >
      <template v-slot:question>{{ $t('employees.enable_text') }}</template>
    </x-confirmation-modal>

    <x-confirmation-modal
      :show.sync="modalDis"
      :reversible="true"
      :title="$t('employees.disable_title')"
      :action="disableEmployee"
      color="error"
      :btn-save="$t('input.disable')"
      @close="modalDis = false"
    >
      <template v-slot:question>{{ $t('employees.disable_text') }}</template>
    </x-confirmation-modal>
  </v-container>
</template>

<script>

import Vue from 'vue'

import employeesService from '../../services/employees'

export default Vue.extend({
  data () {
    return {
      employee: {
        headquarter: {
          name: ''
        }
      },
      modalDis: false,
      modalEna: false,
      loading: true
    }
  },
  watch: {
    '$i18n.locale': {
      handler () {
        this.getEmployee()
      }
    }
  },
  methods: {
    getEmployee () {
      employeesService.getOneById(this.$route.params.id, this.$i18n.locale)
        .then((res) => {
          delete res.item.email
          const employee = res.item
          employee.email = res.item.employee.email
          employee.birthdate = (employee.birthdate || '').split('T')[0]
          employee.admission = (employee.admission || '').split('T')[0]
          employee.createdAt = (employee.createdAt || '').split('T')[0]
          employee.identifyType = {
            text: employee.identifyType.translate.label,
            code: employee.identifyType.code
          }

          this.employee = employee
          this.loading = false
        })
        .catch((err) => this.$store.dispatch('alert/error', this.$t(`errors.${(err.details || [])[0]}`)))
    },
    disableEmployee () {
      return employeesService.disable(this.employee.id)
        .then((res) => {
          this.getEmployee()
          this.modalDis = false
        })
        .catch((err) => this.$store.dispatch('alert/error', this.$t(`errors.${(err.details || [])[0]}`)))
    },
    enableEmployee () {
      return employeesService.enable(this.employee.id)
        .then((res) => {
          this.getEmployee()
          this.modalEna = false
        })
        .catch((err) => this.$store.dispatch('alert/error', this.$t(`errors.${(err.details || [])[0]}`)))
    }
  },
  filters: {
    checkDate (val) {
      return val === '' ? '--' : val
    }
  },
  created () {
    this.getEmployee()
  }
})
</script>
