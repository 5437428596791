
<template>
  <v-container fluid>
    <v-row wrap row>
      <v-col cols="12" sm="8">
        <h4 class="display-1">{{ $t('enterprises.enterprises_list') }}</h4>
      </v-col>
    </v-row>
    <v-row wrap row>
      <v-col cols="12" sm="4" class="text-right">
        <v-btn
          color="primary"
          to="enterprises/create"
          class="text-capitalize"
        >
          <v-icon left>mdi-plus-circle-outline</v-icon>
          {{ $t('enterprises.add_enterprise') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row wrap row>
      <v-col cols="12">
        <v-divider></v-divider>

        <v-card>
          <v-card-title>
            <div class="title text-uppercase">{{ $t('enterprises.resumen') }}</div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="mdi-magnify"
              :label="$t('enterprises.look_for')"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-divider class="my-2"></v-divider>

          <v-row row wrap class="mx-3">
            <v-col cols="12">
              <v-autocomplete
                :label="$t('enterprises.filter_by')"
                :items="filters"
                prepend-inner-icon="mdi-filter-outline"
                @change="filterItems"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <x-data-table
            :headers="headers"
            :parent-fetch-data="getEnterprises"
            :options="options"
            no-data="enterprises.no_data"
          >
            <template v-slot:structure="prop">
              <td class="text-center">{{ prop.item.name }}</td>
              <td class="text-center">{{ prop.item.createdAt | date({date: true, hour:false}) }}</td>
              <td class="text-center">{{ prop.item.customer.name }}</td>
              <td class="text-center">{{ prop.item.balance }}</td>
              <td class="text-center">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`enterprises/profile/${prop.item.id}`"
                      v-on="on"
                      text icon>
                      <v-icon small>remove_red_eye</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.enterprise_profile') }}</span>
                </v-tooltip>
              </td>
            </template>
          </x-data-table>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import enterpriseService from '../../services/enterprises'

export default {
  data () {
    return {
      headers: [
        'enterprises.enterprise_name',
        'enterprises.register_date',
        'enterprises.customer',
        'enterprises.balance',
        'enterprises.actions'
      ],
      options: {
        filter: null,
        search: null
      },
      search: null,
      timer: null,
      demoRequests: []
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    filters () {
      const translation = (option) => this.$t(`customers.filters.${option}`)
      return [
        { text: translation('all'), value: null },
        { text: translation('active'), value: '1' },
        { text: translation('inactive'), value: '0' }
      ]
    }
  },
  methods: {
    getEnterprises (options) {
      return enterpriseService.listWithBalance(options)
        .then((res) => ({
          items: res.items.map(item => {
            item.licenses = item.licenses ? item.licenses[0] : undefined
            return item
          })
        }))
    },
    filterItems (value) {
      this.options = {
        ...this.options,
        filter: value
      }
    }
  },
  watch: {
    search () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.options = {
          ...this.options,
          search: this.search
        }
      }, 3000)
    }
  }
}
</script>
