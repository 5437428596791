
<template>
  <v-container fluid>
    <v-row wrap row >
      <v-col cols="12" sm="8">
        <h4 class="display-1">{{$t('models.groups') }}</h4>
      </v-col>

      <v-col cols="12" sm="4">
        <v-card class="d-flex flex-column justify-end">
          <v-row wrap row v-if="showGroup">
            <v-col cols="5" justify="center" class="pl-5" v-if="canBuy">
              <h5 class="caption font-weight-light text-center text-uppercase">{{ $t('tokens.balance') }}</h5>
              <h5 class="headline font-weight-black text-center">{{ group.balance }}</h5>
            </v-col>
            <v-col cols="12" justify="center" class="pl-5" v-else>
              <h5 class="caption font-weight-light text-center text-uppercase">{{ $t('tokens.balance') }}</h5>
              <h5 class="headline font-weight-black text-center">{{ group.balance }}</h5>
            </v-col>
            <v-col cols="7" class="py-2" v-if="canBuy">
              <v-row justify="center">
                <v-col cols="4" class="text-right">
                  <v-tooltip bottom color="blue">
                    <template v-slot:activator="{ on }">
                      <v-btn color="blue darken-1" icon v-on="on" @click="dialogAdd = true">
                        <v-icon large>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('tokens.acquire') }}
                    </span>
                  </v-tooltip>
                  <v-dialog v-model="dialogAdd" persistent max-width="600">
                    <v-card>
                      <v-card-title class="d-flex justify-end pr-1 pt-1">
                        <v-btn large icon @click="resetEntry">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-row wrap row class="d-flex justify-space-around mx-12">
                          <v-col xs="12" sm="6">
                            <v-card class="text-center" color="blue-grey lighten-5">
                              <v-col>
                                <h4 class="subtitle-1 font-weight-black text-uppercase">{{ $t('tokens.tokens_list') }}</h4>
                                <v-card class="mx-6 my-6" color="white">
                                  <v-row wrap row class="d-flex justify-center">
                                    <x-inputs-token
                                      :input="inputValue"
                                      :min="1"
                                      :max="9999999"
                                      @updateInput="($event) => inputValue = $event"
                                    />
                                  </v-row>
                                </v-card>
                              </v-col>
                            </v-card>
                          </v-col>
                          <v-col xs="12" sm="6">
                            <v-card class="text-center" color="blue-grey lighten-5">
                              <v-col class="subtitle-1 font-weight-black">
                                <h4 class="text-uppercase">{{ $t('tokens.total_amount') }}</h4>
                                <div class="my-7">{{ $t('tokens.money_unit') }} {{ outputValue }}</div>
                              </v-col>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="d-flex justify-center pb-5">
                        <v-btn color="blue-grey lighten-2" class="text-capitalize" dark @click="resetEntry">{{ $t('input.cancel') }}</v-btn>
                        <v-btn color="blue darken-1" class="text-capitalize" dark @click="acquire">{{ $t('input.confirm') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>

                <v-col cols="4">
                  <v-tooltip bottom color="blue">
                    <template v-slot:activator="{ on }">
                      <v-btn color="blue darken-1" icon v-on="on" @click="dialogCalculate = true">
                        <v-icon large>mdi-calculator</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('tokens.calculator') }}
                    </span>
                  </v-tooltip>
                  <v-dialog v-model="dialogCalculate" persistent max-width="1200px">
                    <v-card>
                      <v-card-title>
                        <v-row>
                          <v-col class="title pl-6 col-md-10 col-10 py-0 text-uppercase">
                            {{ $t('tokens.calculator') }}
                          </v-col>
                          <v-col class="d-flex justify-end pa-0">
                            <v-btn icon class="mr-6" @click="dialogCalculate = false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-divider class="mx-10"></v-divider>
                      <modal-calculator
                        :is-group="true"
                        @refresh="getGroup"
                      >
                      </modal-calculator>
                    </v-card>
                  </v-dialog>
                </v-col>

                <v-col cols="4">
                  <v-tooltip bottom color="blue">
                    <template v-slot:activator="{ on }">
                      <v-btn color="blue darken-1" icon v-on="on" :to="`/groups/operations`">
                        <v-icon large>fa-clipboard-list</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('tokens.operations') }}
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="showGroup">
        <v-card>
          <v-card-title primary-title>
            <div>
              <span class="subheading grey--text">Grupo</span>
              <h4 class="display-1">{{ group.name }}</h4>
            </div>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-divider v-if="leader"></v-divider>
          <v-card-text class="pa-0">
            <v-row row wrap class="mt-4" v-if="leader">
              <v-col cols="12" md="4" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-office-building</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('groups.main_enterprise') }}</h6>
                    </v-list-item-title>
                    <v-list-item-title class="grey--text subheading">
                      {{ leader.customer.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="4" class="mb-3 my-2" >
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-checkbox-multiple-blank-circle-outline</v-icon>
                  </v-list-item-action>

                  <v-list-item-content style="background-color: #4d4f5b">
                    <v-list-item-title>
                      <h6 class="title text-left ml-5 white--text">{{ $t('groups.main_customer_tokens') }} : {{ leader.balance }}</h6>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <v-data-table
              v-if="showGroup"
              :headers="headers"
              :items="customers"
              :no-data-text="$t('enterprises.no_data')"
              :footer-props="footerProps"
              :options.sync="pagination"
              :items-per-page="10"
            >
              <template v-slot:item="props">
                <tr>
                  <td class="text-center">{{ props.item.customer.name}}</td>
                  <td class="text-center">{{ props.item.balance}}</td>
                  <td class="text-center" style="margin-left: 12em;">
                    <v-tooltip
                      bottom
                      color="primary"
                    >
                      <template v-if="canBuy" v-slot:activator="{ on }">
                        <v-btn
                          @click="openTokensModal(props.item)"
                          v-on="on"
                          text
                          icon>
                          <v-icon small>fa-coins</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('input.manage_token') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom color="primary" v-if="user.role === user.view">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="openTokenAuthorizationModal(props.item)"
                          v-on="on"
                          text
                          :color="colorIconAuthorization(props.item)"
                          icon>
                          <v-icon small>mdi-account-switch</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('input.authorizations') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom color="primary" v-if="user.role === user.view">
                      <template v-slot:activator="{ on }">
                        <v-btn
                        @click="loginAsCustomer(props.item.customer.id, props.item.customer.type)"
                        v-on="on"
                        text
                        icon>
                          <v-icon small>fa-sign-out-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('groups.login_as_customer') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
              <template v-slot:pageText="props">
                {{ props.pageStart }} - {{ props.pageStop }} {{ $t('page_text') }} {{ props.itemsLength }}
              </template>
            </v-data-table>

            <v-divider class="mt-3"></v-divider>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" class="offset-3" v-if="!showGroup">
        <v-card >
          <v-card-title align="center">
            <v-row>
              <v-col class="title pl-6 col-md-12 col-12 py-0 text-uppercase">
                {{$t('groups.customer_no_group')}}
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <token-customer-modal
      :customer="customerSelected"
      :group-balance="groupBalance"
      v-if="tokenCustomerModal"
      :action="handleTokens"
      @close="hideTokenModal"
    />

    <token-authorization-modal
      :customer="customerSelected"
      v-if="tokenAuthorizationModal"
      :action="authorizeTokens"
      @close="hideTokenAuthorizationModal"
    />
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import groupsService from '../../services/groups'
import TokenCustomerModal from './token-customer-modal.vue'
import authService from '../../services/auth'
import tokenAccountDetailService from '../../services/token-account-detail'
import ModalCalculator from '../token/calculator'
import tokenPriceService from '../../services/token-price'
import TokenAuthorizationModal from '../customers/token-authorization-modal.vue'
import tokenAuthorizationService from '../../services/token-authorization'

export default {
  components: {
    TokenAuthorizationModal,
    TokenCustomerModal,
    ModalCalculator
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('input.customer'),
          align: 'center',
          class: 'text-uppercase',
          sortable: false
        }, {
          text: this.$t('groups.current_plan'),
          align: 'center',
          class: 'text-uppercase',
          sortable: false
        }, {
          text: this.$t('groups.actions'),
          align: 'center',
          class: 'text-uppercase',
          sortable: false
        }
      ],
      group: {},
      customers: [],
      modalDeleteCustomer: {
        open: false,
        item: {}
      },
      // Paginacion
      pagination: {},
      footerProps: {
        'items-per-page-text': this.$t('results_per_pages'),
        'items-per-page-options': [5, 10, 25, { 'text': this.$t('page_all'), 'value': -1 }]
      },
      tokenAuthorizationModal: false,
      tokenCustomerModal: false,
      customerSelected: null,
      groupBalance: 0,
      canBuy: false,
      dialogAdd: false,
      dialogDetails: false,
      dialogCalculate: false,
      inputValue: 0,
      showGroup: false
    }
  },
  mounted () {
    this.$store.dispatch('session/showSelectorCurrency', true)
  },
  beforeDestroy () {
    this.$store.dispatch('session/showSelectorCurrency', false)
  },
  methods: {
    colorIconAuthorization (item) {
      if (JSON.parse(JSON.stringify(item)).isAuthorized) {
        return 'green'
      }
      return ''
    },
    hideTokenAuthorizationModal (enterprise) {
      this.tokenAuthorizationModal = !this.tokenAuthorizationModal
    },
    getGroup () {
      groupsService.profile()
        .then((res) => {
          this.group = res
          if (res.customers) {
            this.showGroup = true
            this.groupBalance = this.group.balance
            let customerList = res.customers.filter((customer) => {
              return !customer.leader
            })
            if (customerList.length) {
              this.getAuthorizations(customerList)
            }
            for (const groupCustomer of res.customers) {
              if (this.user.customer.id === groupCustomer.customerId && groupCustomer.leader) {
                this.canBuy = true
              }
            }
          } else {
            this.$store.dispatch('alert/error', this.$t('groups.customer_no_group'))
          }
        })
        .catch((err) => { console.log(err) })
    },
    getAuthorizations (customerList) {
      tokenAuthorizationService.listByCustomers(customerList)
        .then((res) => {
          customerList.map(item => {
            let authorization = res.find(authorization => authorization.customerId === item.id)
            item.isAuthorized = authorization
            return item
          })
          this.customers = customerList
        })
    },
    openTokenAuthorizationModal (customerSelected) {
      this.customerSelected = customerSelected
      this.tokenAuthorizationModal = !this.tokenAuthorizationModal
    },
    openTokensModal (customerSelected) {
      this.tokenCustomerModal = !this.tokenCustomerModal
      this.customerSelected = customerSelected
    },
    handleTokens (customerId, value) {
      return tokenAccountDetailService.move(customerId, value, true)
        .then((res) => {
          this.customerSelected.balance = res.balance
          this.hideTokenModal()
          this.$store.dispatch('alert/success', this.$t('tokens.success_transaction'))
          this.getGroup()
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    hideTokenModal (customer) {
      this.tokenCustomerModal = !this.tokenCustomerModal
    },
    loginAsCustomer (customerId, type) {
      return authService.loginAsCustomer(customerId, this.user)
        .then((res) => {
          return this.$store
            .dispatch('session/loginAs', res)
            .then((res) => {
              this.$router.push(res.user.customer.type === 'commercial' ? '/commercial-profile' : '/measuring-tools')
              window.location.reload(false)
            }).catch(err => (this.$store.dispatch('alert/error', err)))
        }).catch(err => (this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))))
    },
    resetEntry () {
      this.inputValue = 0
      this.dialogAdd = false
    },
    acquire () {
      this.loading = true
      const mount = Number(this.inputValue)
      if (mount === 0) {
        this.$store.dispatch('alert/error', this.$t('tokens.invalid_quantity'))
        return
      }
      this.resetEntry()
      return tokenAccountDetailService.buy(mount, true)
        .then((res) => {
          this.loading = false
          this.$store.dispatch('alert/success', this.$t('tokens.created_movement'))
          this.getGroup()
        })
        .catch((err) => {
          const error = this.$t(`errors.${err.code}`)
          this.$store.dispatch('alert/error', error)
          this.loading = false
        })
    },
    getCurrentTokenPrices () {
      tokenPriceService.getActives()
        .then((res) => {
          this.currentTokenPrices = res
        })
    },
    authorizeTokens (customerId, tokenAccountId, value) {
      return tokenAuthorizationService.toggle({ customerId, tokenAccountId, value })
        .then((res) => {
          this.hideTokenAuthorizationModal()
          if (value) {
            this.$store.dispatch('alert/success', this.$t('tokens.created_authorization'))
          } else {
            this.$store.dispatch('alert/success', this.$t('tokens.eliminated_authorization'))
          }
          this.getGroup()
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    }
  },
  computed: {
    leader () {
      return this.group.customers ? this.group.customers.find(customer => customer.leader) : null
    },
    outputValue () {
      const input = Number(this.inputValue)

      for (const price of this.currentTokenPrices) {
        if (input >= price.minRange && (input <= price.maxRange || price.maxRange === 0)) {
          return (input * price.price).toFixed(2)
        }
      }
      return (input * 0).toFixed(2)
    },
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.getCurrentTokenPrices()
    this.getGroup()
  }
}
</script>
