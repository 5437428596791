<template>
  <v-container>
    <v-card flat>
      <v-card-title color="primary" class="pt-0">
        <h2 class="text-center"  style="width: 100%; color: #3375d2; font-size: 1.3em; letter-spacing: 4px">
          {{ $t('auth.success_register') }}
        </h2>
      </v-card-title>
      <v-card-text class="text-center pt-4">
        <p
          v-html="$t('auth.has_sent_to', [email])"
        ></p>
        <p
          v-html="$t('auth.please_verify_account', [$t('auth.spam')])"
        ></p>
      </v-card-text>
      <v-row justify="end" align="end">
        <v-col md="6" xs="12" sm="6" align="end">
          <v-btn
            color="primary"
            style="margin-left: 1em"
            @click="$router.push('/auth/sign-in')"
          >
            {{ $t('auth.go_sign_in') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    email: {
      type: String,
      default: ''
    }
  },
  created () {
    if (!this.email) {
      this.$router.push('/auth/sign-in')
    }
  }
})
</script>
