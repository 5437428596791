
<template>
  <v-container fluid>
    <x-loading
      :display="loading"
    ></x-loading>
    <v-row class="mt-1">
      <v-col cols="12" sm="3" class="pt-4 pb-2 text-xs-center text-sm-left">
        <h4 class="display-1 mb-3">{{ $t('employees.massive_upload') }}</h4>
      </v-col>

      <v-col cols="12" sm="9"  class="text-xs-center text-sm-right" v-if="step === 1">
        <v-btn large outlined
          class="mr-1 mt-1 pl-4 pr-2"
          color="primary"
          href="https://www.youtube.com/watch?v=fnmJNSsVw2E"
          target="_blank"
        >
          <v-icon left>mdi-eye</v-icon>
          {{ $t('input.view_tutorial_video') }}
        </v-btn>
        <generate-instructive></generate-instructive>
        <v-btn
          large
          class="mr-1 mt-1 pl-3 pr-2"
          color="primary"
          @click="downloadTemplate"
        >
          <v-icon left>mdi-download</v-icon>
          {{ $t('employees.download_template') }}
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-card class="mt-3">
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step :complete="validOne" step="1">{{ $t('employees.select_file') }}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="validTwo" step="2">{{ $t('employees.summary') }}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="3">
                {{ $t('employees.error_fix') }}
                <small>{{ $t('employees.if_any') }}</small>
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <div class="pb-3 pl-5 pr-6 text-justify body-2 error--text text--darken-1">
                  <b class="caption font-weight-bold">{{ $t('pricing.recomendations') }}</b>:
                  <p class="text-recomendations mb-1">
                    {{ $t('employees.massive_upload_recomendations') }}
                  </p>
                  <ul>
                    <li v-for="(item, i ) in $t('employees.massive_upload_recomendations_items')"
                      :key="i"
                      class="text-recomendations"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
                <ValidationObserver v-slot="{ handleSubmit }">
                  <v-form
                    @submit.prevent="handleSubmit(onSubmit)"
                  >
                    <v-card>
                      <v-card-text>
                        <x-file-upload
                          class="mt-1"
                          v-model="file"
                          :label="$t('employees.select_file_to_upload')"
                          reff="employees-massive-upload"
                          :extensions="extensions"
                          name="employees-file"
                          :help="{ ...$t('help.enterprise.massive.file_input') }"
                          error-messages="error"
                          :rules="'ext:csv,xls,xlsx'"
                          @file-picked="filePicked"
                          @not-file-picked="filePicked"
                        ></x-file-upload>
                      </v-card-text>

                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-btn to="/employees" large>{{ $t('input.back') }}</v-btn>

                        <x-async-btn
                          class="white--text"
                          :action="importFile"
                          large
                          :disabled="!validFile"
                        >
                          {{ $t('input.load') }}
                        </x-async-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </ValidationObserver>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card v-if="result">
                  <v-card-text>
                    <div class="black--text" v-if="result.unchanged && result.unchanged.length">
                      <v-icon color="black">mdi-check</v-icon>
                      {{ $t('employees.total_unchanged', { total: ( Array.isArray(result.unchanged) ? result.unchanged.length : 0 ) }) }}
                    </div>

                    <v-divider class="my-3"></v-divider>

                    <div class="success--text">
                      <v-icon color="success">mdi-check</v-icon>
                      {{ $t('employees.total_updated', { total: ( Array.isArray(result.updated) ? result.updated.length : 0 ) }) }}
                      <v-btn
                        @click="downloadLog('updated')"
                        color="primary"
                        outlined
                        small
                      >{{ $t('employees.download_log') }}</v-btn>
                    </div>

                    <v-divider class="my-3"></v-divider>

                    <div class="success--text">
                      <v-icon color="success">mdi-check</v-icon>
                      {{ $t('employees.total_inserted', { total: ( Array.isArray(result.inserted) ? result.inserted.length : 0 ) }) }}
                      <v-btn
                        @click="downloadLog('inserted')"
                        color="primary"
                        outlined
                        small
                      >{{ $t('employees.download_log') }}</v-btn>
                    </div>

                    <v-divider class="my-3"></v-divider>

                    <div class="grey--text">
                      <v-icon color="gray">mdi-check</v-icon>
                      {{ $t('employees.total_deleted', { total: ( Array.isArray(result.deleted) ? result.deleted.length : 0 ) }) }}
                      <v-btn
                        @click="downloadLog('deleted')"
                        color="primary"
                        outlined
                        small
                      >{{ $t('employees.download_log') }}</v-btn>
                    </div>

                    <v-divider class="my-3"></v-divider>

                    <div class="error--text" v-if="result.failed.length > 0">
                      <v-icon color="error">mdi-close</v-icon>
                      {{ $t('employees.total_failed', { total: ( Array.isArray(result.failed) ? result.failed.length : 0 ) }) }}
                      <v-btn
                        @click="downloadLog('failed')"
                        color="primary"
                        outlined
                        small
                      >{{ $t('employees.download_log') }}</v-btn>
                    </div>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn @click="cancelProcess" large>{{ $t('input.cancel') }}</v-btn>
                    <x-async-btn
                      class="white--text"
                      :action="nextStep"
                      large
                    >
                      <span
                        v-if="(Array.isArray(result.failed) && result.failed.length !== 0)"
                      >{{ $t('input.error_fix') }}</span>
                      <span v-else>{{ $t('input.load') }}</span>
                    </x-async-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="3">
                <fix-errors
                  v-if="step === 3"
                  :items="items"
                  :result="result"
                  :desserts="desserts"
                  :headers-list="dataTypes || []"
                  :cancel-process="cancelProcess"
                  @updateDataTypes="($event) => { this.dataTypes = $event }"
                  @updateResult="($event) => {
                    this.result = $event;
                    this.step = 2;
                    this.loading = false;
                  }"
                  @updateLoading="(e) => { this.loading = e }"
                ></fix-errors>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
  .text-recomendations {
    font-size: 13.5px;
    text-align: justify;
  }
</style>

<script>

import Vue from 'vue'

import fileDownload from '../../utils/file-download'
import xlsxDownload from '../../utils/xlsx-download'
import employeesService from '../../services/employees'
import GenerateInstructive from './massive-upload/generate-instructive.vue'
import FixErrors from './massive-upload/fix-errors.vue'

export default Vue.extend({
  components: {
    GenerateInstructive,
    FixErrors
  },
  data () {
    return {
      step: 1,
      validFile: false,
      file: '',
      extensions: ['.xls', '.xslx', '.csv'],
      validOne: false,
      validTwo: false,
      result: null,
      dataTypes: null,
      errorsForm: {},
      enterpriseId: null,
      mDialog: {
        show: false,
        msg: ''
      },
      editedIndex: -1,
      defaultItem: {},
      desserts: [],
      items: {},
      columnCount: 0,
      modal_error: false,
      error_dialog: false,
      loading: false
    }
  },
  computed: {
    minHeaders: function () {
      if (!this.result || this.result.failed.length === 0) {
        return []
      }
      const firsts = this.result.failed[0]
      const headers = []
      for (const [key, value] of Object.entries(firsts)) {
        headers.push(key)
      }
      return headers
    }
  },
  methods: {
    filePicked (e) {
      if (e) {
        this.file = e
        this.validFile = true
      } else {
        this.file = ''
        this.validFile = false
      }
    },
    indexInHeaders (test) {
      if (this.minHeaders.length === 0) {
        return -1
      }
      return this.minHeaders.indexOf(test)
    },
    fieldKey (label) {
      return this.dataTypes.find(e => e[2] === label)[0]
    },
    getMasterReferences () {
      return employeesService
        .getMasterReferences()
        .then((res) => {
          this.items = res.result
        })
        .catch(err =>
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        )
        .finally(() => {
          this.loading = false
        })
    },
    downloadTemplate () {
      this.loading = true
      return employeesService.generateTemplate()
        .then((res) => {
          xlsxDownload(res, 'Plantilla', 'plantilla.xlsx')
        })
        .catch(err =>
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        )
        .finally(() => {
          this.loading = false
        })
    },
    downloadLog (logType) {
      this.loading = true
      return employeesService
        .generateLog(logType)
        .then((res) => {
          const blob = new Blob([res.log])
          fileDownload(blob, `log-${logType}.txt`)
        })
        .catch(err =>
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        )
        .finally(() => {
          this.loading = false
        })
    },
    importFile () {
      this.loading = true
      return employeesService
        .massiveUpload(this.file)
        .then((res) => {
          // this.validateEmployeeNumber()
          if (this.error_dialog === true) {
            this.enterpriseId = err.enterpriseId
            this.mDialog.show = true
            this.mDialog.msg = this.$t(`errors.employees_limit`)
          } else {
            this.$store.dispatch(
              'alert/success',
              this.$t('employees.file_updated')
            )
            if (res.result['attr']) {
              this.dataTypes = JSON.parse(JSON.stringify(res.result['attr']))
              delete res.result['attr']
            }
            this.result = res.result
            this.validOne = true
            this.validTwo = true
            this.step = 2
            /**/
            this.desserts = this.result && this.result.failed.length > 0 ? JSON.parse(JSON.stringify(this.result.failed)) : []
          }
        })
        .catch(err =>
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        )
        .finally(() => {
          this.loading = false
        })
    },
    cancelProcess () {
      this.loading = true
      return employeesService
        .cancelProcess()
        .then((res) => {
          this.$store.dispatch(
            'alert/success',
            this.$t('employees.process_cancelled')
          )
          this.step = 1
          this.validTwo = false
        })
        .catch(err =>
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        )
        .finally(() => {
          this.loading = false
        })
    },
    nextStep () {
      if (this.result.failed.length !== 0) {
        this.step = 3
        return Promise.resolve()
      } else {
        this.loading = true
        return employeesService
          .massiveSummary()
          .then((res) => {
            this.$router.push('/employees')
            this.$store.dispatch(
              'alert/success',
              this.$t('employees.massive_success')
            )
          })
          .catch(err =>
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          )
          .finally(() => {
            this.loading = false
          })
      }
    }
  },
  created () {
    this.loading = true
    return employeesService
      .massiveFindActual()
      .then((res) => {
        if (res.hasOwnProperty('result')) {
          if (res.result['attr']) {
            this.dataTypes = JSON.parse(JSON.stringify(res.result['attr']))
          }
          this.result = res.result
          this.desserts = this.result.failed
          this.validOne = true
          this.validTwo = true
          this.step = 2
        }
        this.getMasterReferences()
      })
      .catch(err => {
        this.loading = false
        this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
      })
  }
})
</script>
