
import Services from './base-services'

const service = new Services('additional-segmentation')

export default {
  listByEnterprise: () => {
    return service.get('list-by-enterprise')
  },
  create: (data: any) => {
    return service.post('', data)
  },
  update: (id: number, data: any) => {
    return service.put(`${id}`, data)
  },
  delete: (id: number) => {
    return service.get(`delete/${id}`)
  },
  toggleActive: (id: number, active: boolean) => {
    return service.post(`toggle-active/${id}`, { active })
  },
  storeDetails: (id: number, details: any) => {
    return service.post(`save-details/${id}`, { details })
  }
}
