
export default {
  notifications: 'Notifications',
  see_more: 'See all notifications.',
  plan_expires_today: 'Your license expires today, please go to the update plan module to renew your license.',
  one_month_left: 'You have one month left until your license expires, go to the plan update ' +
  'panel to continue using our services.',
  one_week_left: 'You have one week left until your license expires, go to the an update ' +
  'panel to continue using our services.',
  one_month_expired: 'Your license expired a month ago, we invite you to upgrade your plan to use our' +
  'services.',
  demo_expired_yesterday: 'Your demo plan expired yesterday, we invite you to update your plan to use' +
  'each and every of our services.',
  pulse_month_ago: 'You haven\'t done a Pulse for a month. If you do not have the permissions, we invite you to go to the plan ' +
  'extension panel.',
  engagement_closed: 'Your Engagement assessment has been closed',
  pulse_closed: 'Your Pulse assessment has been closed.',
  admin_plan_expires_today: 'The {enterprise} license expires today.',
  admin_one_month_left: 'The company {enterprise} has one month left until its license expires.',
  admin_one_week_left: 'The company {enterprise} has one week left until its license expires.',
  admin_one_month_expired: 'The {enterprise} company\'s license expired a month ago.',
  admin_demo_expired_yesterday: 'The {enterprise} company\'s demo plan expired yesterday.',
  admin_enterprise_enabled: 'The company {enterprise} has been enabled',
  admin_enterprise_disabled: 'The company {enterprise} has been disabled',
  admin_engagement_closed: 'The {enterprise} company\'s Engagement assessment {poll} has been closed.',
  admin_pulse_closed: 'The {enterprise} company\'s Pulse assessment {poll} has been closed .'
}
