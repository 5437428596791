import { render, staticRenderFns } from "./auth-layout.vue?vue&type=template&id=6ffe8b65&scoped=true&"
import script from "./auth-layout.vue?vue&type=script&lang=js&"
export * from "./auth-layout.vue?vue&type=script&lang=js&"
import style0 from "./auth-layout.vue?vue&type=style&index=0&id=6ffe8b65&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ffe8b65",
  null
  
)

export default component.exports