
<template>
  <ValidationObserver v-slot="{ valid }">
    <v-form ref="login_form" @submit.prevent="signIn">
      <v-alert color="error" outlined :value="!!error">
        <span class="font-weight-bold" v-if="error" v-html="$t(`errors.${error}`)"></span>
      </v-alert>
      <x-inputs-input
        :input="credentials.email"
        @updateInput="($event) => credentials.email = $event"
        prepend-inner-icon="person"
        :label="$t('input.email')"
        rules="required|email"
        name="email"
      ></x-inputs-input>
      <x-inputs-input
        :input="credentials.password"
        @updateInput="($event) => credentials.password = $event"
        prepend-inner-icon="lock"
        :label="$t('input.password')"
        rules="required"
        type="password"
        name="password"
      ></x-inputs-input>
      <div class="mt-6 text-center">
        <v-btn
          color="primary"
          class="white--text"
          :disabled="!valid"
          :loading="loadingBtn"
          type="submit"
        >
          {{ $t('auth.login') }}
        </v-btn>
        <br/><br/>
        <v-row>
          <v-col align="center" justify="center">
            <router-link to="/auth/reset-password">{{ $t('auth.problems_to_login') }}</router-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center" jistify="center">
            <span>{{ $t('auth.has_no_account') }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center" jistify="center">
            <v-btn ref="rock" color="primary" width="300" outlined to="/auth/sign-up">{{ $t('auth.sign_up') }}</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </ValidationObserver>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  data () {
    return {
      loadingBtn: false,
      credentials: {
        email: '',
        password: ''
      },
      valid: false,
      error: ''
    }
  },
  watch: {
    credentials: {
      handler () {
        if (this.error !== '') {
          this.error = ''
        }
      },
      deep: true
    }
  },
  methods: {
    signIn () {
      this.loadingBtn = true
      return this.$store.dispatch('session/getRefreshState')
        .then(state => {
          let stateParsed = {
            token: state.token,
            user: JSON.parse(state.user)
          }
          if (stateParsed.token && stateParsed.user && stateParsed.user.id) {
            document.location.reload()
            return Promise.resolve(false)
          }
          return this.$store
            .dispatch('session/signIn', this.credentials)
            .then((res) => {
              if (this.$route.query && this.$route.query.product) {
                return this.$store
                  .dispatch('session/findHost', this.$route.query.product)
                  .then((host) => {
                    if (host && host.url) {
                      location.replace(`${host.url}/auth/sso?ssoToken=${this.$store.state.session.token}`)
                    }
                  })
                  .catch(err => (this.error = err.code))
              }
              this.doRedirect(res)
            })
            .catch(err => (this.error = err.code))
        })
        .finally(() => {
          this.loadingBtn = false
        })
    },
    doRedirect (res) {
      if ((res.user.role === 'customer' && res.user.customer.type === 'commercial')) {
        return this.$router.push('/commercial-profile')
      } else if (res.user.role === 'customer' && res.user.customer.type === 'personal') {
        return this.$router.push(res.user.lastActivity ? '/measuring-tools' : '/setting/enterprise')
      } else if (res.user.role === 'enterprise_admin') {
        return this.$router.push('/customer/show-profile')
      }
      this.$router.push('/dashboard')
    }
  }
})
</script>
