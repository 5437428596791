
<template>
  <v-container fluid >
    <v-row wrap row>
      <v-col cols="12" class="pt-4 pl-4">
        <h4 class="display-1">{{ title }}</h4>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-subtitle class="mt-1 text-justify"
            v-html="$t('settings.master_references_only_read')"
          ></v-card-subtitle>

          <v-data-iterator
            :items="computedItems"
            :footer-props="footerProps"
            row wrap
          >
            <template v-slot:item="props">
              <v-card-text>
                <v-container fluid>
                  <v-row row style="max-height: 2em;">
                      <v-col class="pl-1 pr-1">
                        <v-text-field
                          light outlined readonly
                          color="blue"
                          :placeholder="$t('settings.no_translation')"
                          v-model="props.item"
                        ></v-text-field>
                      </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </template>
            <template v-slot:footer.page-text="props">
              {{ props.pageStart }}-{{ props.pageStop }} {{ $t('page_text') }} {{ props.itemsLength }}
            </template>
          </v-data-iterator>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
  props: {
    items: Object,
    title: String,
    subtitle: String
  },
  data () {
    return {
      language: 'es'
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    computedItems () {
      return this.items[this.$i18n.locale]
    },
    footerProps () {
      return {
        'items-per-page-text': this.$t('results_per_pages'),
        'items-per-page-options': [5, 10, 25, { 'text': this.$t('page_all'), 'value': -1 }]
      }
    }
  },
  created () {
    this.language = this.user.lang
  }
}
</script>

<style>
  #master-manager .container {
    padding: 0 24px;
  }
  #master-manager .v-card__text{
    padding: 0;
  }
</style>
