
import mapParameters from '../utils/map-parameters'
import Services from './base-services'

const service = new Services('customers')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
}

export default {
  create: (data: any) => {
    return service.post('', data)
  },
  edit: (id: number, data: any) => {
    return service.post(`edit/${id}`, data)
  },
  changeCurrency: (data: any) => {
    return service.put(`change-currency`, data)
  },
  changePassword: (id: number, data: any) => {
    return service.post(`change-password/${id}`, data)
  },
  changeEmail: (id: number, data: any) => {
    return service.post(`change-email/${id}`, data)
  },
  fetchEnterprisesByCustomer: (id: number) => {
    return service.get(`get-enterprises/${id}`)
  },
  fetchLastFiveEnterprisesByCustomer: (id: number) => {
    return service.get(`get-latest-enterprises/${id}`)
  },
  list: (options: IOptionsList) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null
    })
    return service.get('list', params)
  },
  disable: (id: number) => {
    return service.put('disable', { id })
  },
  enable: (id: number) => {
    return service.put('enable', { id })
  },
  getOne: (id: number) => {
    return service.get(`get-one/${id}`)
  },
  listByGroupAndNoGrouped: (id: number) => {
    return service.get(`list-by-group-and-no-grouped/${id}`)
  },
  listNoGrouped: () => {
    return service.get('list-no-grouped')
  },
  assignProducts: (products: any, customerId: number, deletedProducts: any) => {
    return service.post('assign-products', { products, customerId, deletedProducts })
  },
  assignDashboards: (customerId: number, products: number[]) => {
    return service.post(`assign-dashboards/${customerId}`, { products })
  },
  requestProduct: (product: string, message: string) => {
    return service.post('request-product', { product, message })
  }
}
