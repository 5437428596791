
import mapParameters from '../utils/map-parameters'
import Services from './base-services'

const service = new Services('quotations')

export default {
  list: () => {
    return service.get('list')
  },
  create: (data: any) => {
    return service.post('', data)
  },
  getOne: (uuid: string) => {
    return service.get(`${uuid}`)
  },
  update: (uuid: string, data: any) => {
    return service.put(`${uuid}`, data)
  }
}
