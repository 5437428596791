
<template>
  <v-card>
    <v-dialog v-model="dialog" max-width="1000px">
      <v-card>
        <v-card-text>
          <v-card-title>
            <span class="headline">{{ $t('input.modal_title') }}</span>
          </v-card-title>
          <ValidationObserver v-slot="{ handleSubmit }">
            <v-form  @submit.prevent="handleSubmit(save_modal)">
              <v-container v-if="result">
                <v-row row wrap v-if="editedItem.errors">
                  <v-alert type="error" dense :icon="false">
                    <span style="font-weight: bold;">{{ $t('employees.detected_errors') }}</span>
                    <ul>
                      <li v-for="error in editedItem.errors" :key="error">{{ error }}</li>
                    </ul>
                  </v-alert>
                </v-row>
                <v-row row wrap>
                  <template v-for="head of allHeaders">
                    <v-col cols="12" sm="6" xs="6" :key="head.code" v-if="inHeaders(head.code)">
                      <x-inputs-input
                        v-if="['value', 'email'].includes(head.type)"
                        :input="editedItem[ head.prop ]"
                        @updateInput="($event) => editedItem[ head.prop ] = $event"
                        light
                        :label="$t('input.' + head.label)"
                        :placeholder="$t('input.' + head.label) | lowerCase"
                        :name="`field-${ head.code }`"
                        :rules="head.type === 'email' ? 'email' : 'required'"
                      ></x-inputs-input>

                      <x-date-picker
                        v-if="head.type === 'date'"
                        v-model="editedItem[ head.prop ]"
                        @date-picked="editedItem[ head.prop ] = $event"
                        :label="$t('input.' + head.label)"
                        :name="`field-${ head.code }`"
                        rules="required"
                      ></x-date-picker>

                      <x-inputs-autocomplete
                        v-if="head.type === 'master_reference'"
                        :items="items[head.item]"
                        :input="editedItem[ head.prop ]"
                        @updateInput="($event) => editedItem[ head.prop ] = $event"
                        :label="$t('input.' + head.label)"
                        :rules="head.rules"
                        :clearable="head.clearable"
                      ></x-inputs-autocomplete>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
              <v-row justify="end" align="end">
                <v-col md="6" xs="12" sm="6" align="end">
                  <v-btn text small class="text-capitalize" @click="close_modal">{{ $t('input.cancel') }}</v-btn>
                  <v-btn text small
                    color="red"
                    class="text-capitalize"
                    @click="confirmDeletion = true"
                  >
                    {{ $t('input.delet') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    type="submit"
                    style="margin-left: 1em"
                    class="text-capitalize"
                    text small
                  >
                    {{ $t('input.save') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- TABLE -->
    <v-data-table
      :headers="headers"
      :items="desserts"
      class="elevation-1"
    >
      <template slot="item" slot-scope="props">
        <tr>
          <template v-for="(header, i) in headers">
            <td v-if="header.value !== 'actions'"
              :key="i"
              class="text-xs-right"
            >
              {{ getValue(props.item, header.value) }}
            </td>
            <td v-else :key="i" class="text-center">
              <v-btn icon small @click="editItem(props.item)">
                <v-icon size="20">edit</v-icon>
              </v-btn>
            </td>
          </template>
        </tr>
      </template>
      <template v-slot:pageText="props">
        {{ props.pageStart }} - {{ props.pageStop }} {{ $t('page_text') }} {{ props.itemsLength }}
      </template>
    </v-data-table>

    <!-- Delete COnfirmation Modal -->
    <x-confirmation-modal
      :show.sync="confirmDeletion"
      :reversible="false"
      :title="$t('input.delet')"
      :action="deleteRecord"
      color="error"
      icon="delete"
      :btn-save="$t('input.delet')"
      @close="confirmDeletion = false"
    >
      <template v-slot:question>{{ $t('employees.massive_upload_confirm_delete') }}</template>
    </x-confirmation-modal>

    <v-card-actions>
      <v-btn @click="cancelProcess" large>{{ $t('input.cancel') }}</v-btn>
      <x-async-btn
        class="white--text"
        :action="validateErrors"
        large
      >
        {{ $t('input.confirm_changes') }}
      </x-async-btn>
    </v-card-actions>
  </v-card>

</template>

<style type="text/css">
  .small-header span {
    font-size: 12px !important;
  }
</style>

<script>

import Vue from 'vue'

import employeesService from '../../../services/employees'

export default Vue.extend({
  props: {
    items: Object,
    desserts: Array,
    cancelProcess: Function,
    headersList: Array,
    dataTypes: {
      type: Object,
      required: false
    },
    result: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      confirmDeletion: false,
      allHeaders: [
        { code: 'identifyType', prop: 'identifyTypeId', item: 'identify_type', type: 'master_reference', label: 'identify_type', rules: 'required' },
        { code: 'identifyDocument', prop: 'identifyDocument', type: 'value', label: 'identify_document', rules: 'required' },
        { code: 'firstName', prop: 'firstName', type: 'value', label: 'first_name', rules: 'required' },
        { code: 'lastName', prop: 'lastName', type: 'value', label: 'last_name', rules: 'required' },
        { code: 'email', prop: 'email', type: 'email', label: 'email', rules: 'required' },
        { code: 'gender', prop: 'genderId', item: 'gender', type: 'master_reference', label: 'gender', rules: 'required' },
        { code: 'country', prop: 'countryId', item: 'country', type: 'master_reference', label: 'country', rules: 'required' },
        { code: 'headquarter', prop: 'headquarterId', item: 'headquarter', type: 'master_reference', label: 'headquarter', rules: 'required' },
        { code: 'departments', prop: 'departmentId', item: 'department', type: 'master_reference', label: 'department', rules: 'required' },
        { code: 'charge', prop: 'chargeId', item: 'charge', type: 'master_reference', label: 'charge', rules: 'required' },
        { code: 'jobTypes', prop: 'jobTypeId', item: 'job_type', type: 'master_reference', label: 'job_type', rules: 'required' },
        { code: 'antiquity', prop: 'admission', type: 'date', label: 'admission', rules: 'required' },
        { code: 'age', prop: 'birthdate', type: 'date', label: 'birthdate', rules: 'required' },
        { code: 'academicDegree', prop: 'academicDegreeId', item: 'academic_degree', type: 'master_reference', label: 'academic_degree', rules: 'required' },
        { code: 'optionalDemo1', prop: 'additionalDemographic1Id', item: 'additional_demographic1', type: 'master_reference', label: 'additionalDemographics1', rules: '', clearable: true },
        { code: 'optionalDemo2', prop: 'additionalDemographic2Id', item: 'additional_demographic2', type: 'master_reference', label: 'additionalDemographics2', rules: '', clearable: true }
      ],
      modal_warning: {
        jobTypes: false,
        identifyType: false,
        academicDegree: false,
        charge: false,
        gender: false,
        country: false,
        headquarter: false,
        departments: false,
        antiquity: false,
        age: false,
        identifyDocument: false,
        firstName: false,
        lastName: false,
        email: false,
        exists_email: false,
        user_not_employee: false,
        exist_identification: false
      },
      allTypes: {
        identify_type: 'master_reference',
        identify_document: 'value',
        first_name: 'value',
        last_name: 'value',
        email: 'email',
        gender: 'master_reference',
        department: 'master_reference',
        charge: 'master_reference',
        job_type: 'master_reference',
        age_range: 'date',
        antiquity_range: 'date',
        country: 'master_reference',
        headquarter: 'master_reference',
        academic_degree: 'master_reference'
      },
      dialog: false,
      email_error: false,
      editedItem: {},
      validForm: false
    }
  },
  computed: {
    headers () {
      let hdrs = []
      if (!this.result || this.result.failed.length === 0) {
        return hdrs
      }
      if (this.headersList instanceof Array) {
        hdrs = this.headersList.map(header => ({
          text: header[2], value: header[0], sortable: false, width: 180, class: 'small-header'
        }))
        hdrs.push({
          text: this.$t('settings.actions'), value: 'actions', sortable: false, class: 'small-header'
        })
      }
      return hdrs
    }
  },
  methods: {
    getValue (item, value) {
      const header = this.allHeaders.find(h => h.code === value)
      if (header && !(['value', 'email'].includes(header.type))) {
        const prop = header.prop
        const valor = item[prop]
        if (!valor) {
          return valor
        }
        if (header.type === 'date') {
          return new Date(valor).toISOString().split('T')[0]
        }
        return this.items[header.item].find(g => g.value === valor).text
      }
      return item[value]
    },
    inHeaders (code) {
      for (const head of this.headers) {
        if (head.value === code) {
          return true
        }
      }
      return false
    },
    save_modal () {
      this.modal_error = false
      this.email_error = false
      this.deleteWarnings()
      if (!this.modal_error && !this.email_error) {
        const obj = {
          oldRow: this.desserts.find(it => it.id === this.editedItem.id),
          newRow: this.editedItem
        }
        this.deleteWarnings()
        this.$emit('updateLoading', true)
        return employeesService
          .sendRow(obj)
          .then((res) => {
            this.editedItem = res
            if (this.editedItem.status !== 'failed') {
              this.desserts.splice(this.editedIndex, 1)
              this.close_modal()
            }
          })
          .catch(err => {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          })
          .finally(() => {
            this.$emit('updateLoading', false)
          })
      }
      return Promise.resolve()
    },
    validateEmail (email, identification, identificationType) {
      this.$emit('updateLoading', true)
      return employeesService.validateEmail(email, identification, identificationType)
        .then((res) => {
          this.setValidateModalResult(res.header, !res.val)
          this.email_error = res.val
        }).catch((err) => {
          return err
        })
        .finally(() => {
          this.$emit('updateLoading', false)
        })
    },
    setValidateModalResult (header, valid) {
      this.modal_warning[header] = !valid
      if (!valid) {
        this.modal_error = true
      }
    },
    validateAll () {
      /* validate form data */
      for (const i of this.headers) {
        const header = this.allHeaders.find((h) => i['text'] === this.$t('input.' + h))
        if (!header) {
          continue
        }

        if (this.allTypes[header] === 'master_reference') {
          this.setValidateModalResult(header, this.editedItem[i['text']] && this.items[header].includes(this.editedItem[i['text']]))
        } else if (this.allTypes[header] === 'value') {
          this.setValidateModalResult(header, this.editedItem[i['text']] && this.editedItem[i['text']] !== '')
          if (i['text'] === this.$t('input.first_name') || i['text'] === this.$t('input.last_name')) {
            this.setValidateModalResult(header, /^[a-záéíóúñA-ZÁÉÍÓÚÑ ]*$/.test(this.editedItem[i['text']]))
          }
        } else if (this.allTypes[header] === 'date') {
          this.setValidateModalResult(header, this.editedItem[i['text']] !== 'Invalid Date')
          if (!this.modal_warning[header]) {
            this.setValidateModalResult(header, this.editedItem[i['text']].indexOf('-') !== -1)
          }
          if (!this.modal_warning[header]) {
            this.setValidateModalResult(header, new Date(this.editedItem[i['text']]) < new Date(new Date().toDateString()))
          }
        } else if (this.allTypes[header] === 'email') {
          if (!this.editedItem[i['text']]) {
            this.modal_warning[header] = false
          } else {
            this.setValidateModalResult(
              header,
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.editedItem[i['text']])
            )
          }
        } else {
          this.validateEmail(this.editedItem[i['text']], this.editedItem[this.$t('input.identify_document')], this.editedItem[this.$t('input.identify_type')])
        }
      }
    },
    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {
      const index = this.desserts.indexOf(item)
      confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
    },
    close_modal () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    deleteWarnings () {
      for (const i in this.modal_warning) {
        this.$set(this.modal_warning, i, false)
      }
    },
    validateErrors () {
      if (this.desserts.length !== 0) {
        this.$store.dispatch('alert/error', this.$t(`errors.modal_validation_error`))
        return Promise.resolve()
      }

      this.$emit('updateLoading', true)
      return employeesService.fixMassive(this.result.failed)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('employees.file_updated'))
          if (res.result['attr']) {
            this.$emit('updateDataTypes', Object.assign({}, res.result['attr']))
            delete res.result['attr']
          }
          this.$emit('updateResult', res.result)
        })
        .catch(err => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          this.$emit('updateLoading', false)
        })
    },
    deleteRecord () {
      this.$emit('updateLoading', true)
      return employeesService
        .deleteRow({
          id: this.editedItem.id
        })
        .then((res) => {
          if (res.result === 1) {
            this.desserts.splice(this.editedIndex, 1)
            this.close_modal()
          } else {
            throw new Error()
          }
        })
        .catch(err => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
        .finally(() => {
          this.$emit('updateLoading', false)
        })
    }
  }
})
</script>
