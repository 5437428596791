
export default {
  add_enterprise: 'Agregar Empresa',
  admin_left_table_title: 'Empresas',
  admin_bottom_table_title: 'Encuestas recientes',
  product: 'Producto',
  service: 'Servicio',
  customer: 'Cliente',
  enterprise: 'Empresa',
  status: 'Estado',
  createdAt: 'Fecha de creacion',
  activities_resume: 'Resumen de actividades',
  filter_by_product: 'Filtrar por Herramienta',
  pending: 'Pendiente',
  progress: 'En progreso',
  completed: 'Completada',
  closed: 'Cerrada',
  create_poll: 'Crear encuesta',
  continue_poll: 'Continuar encuesta',
  continue: 'Continuar',
  employee_left_table_title: 'Encuestas en progreso',
  employee_progress: 'Completada al:',
  employee_right_table_title: 'Encuestas asignadas',
  enterprise_left_table_title: 'Plan actual',
  enterprise_right_table_title: 'Encuestas',
  expired_plan: '(Plan vencido)',
  general_list: 'Listado general',
  general_title: 'Tablero',
  has_started: 'Comenzada',
  not_started: 'Por comenzar',
  init: 'Inicio:',
  end: 'Finalización',
  ended: 'Finalizado el:',
  left_days: 'Días restantes',
  left_questions: 'Preguntas restantes:',
  finished: 'Finalizado',
  period_divisor: 'al',
  poll: 'Encuesta:',
  reply: 'Responder',
  poll_completed: 'Si',
  poll_incompleted: 'No',
  poll_init_date: 'Inicio:',
  poll_end_date: 'Válida hasta:',
  qty_enterprises: 'Empresas activas',
  qty_employees: 'Colaboradores',
  qty_licesenses: 'Licencias',
  team_trends: 'Tendencias de equipo',
  trend_chart: 'Gráfico de tendencias',
  update_plan: 'Actualizar Plan',
  dimension_leaders: 'Los líderes',
  dimension_inspiration: 'Mi inspiración',
  dimension_job: 'Mi trabajo',
  dimension_environment: 'Ambiente laboral positivo',
  dimension_team: 'Mi equipo',
  dimension_development: 'Mi desarrollo y aprendizaje',
  view_poll_details: 'Ver detalles de la encuesta',
  has_no_polls: 'Necesita crear al menos 2 encuestas en el mismo periodo para ver la gráfica.',
  start: 'Comenzar',
  start_poll: 'Comenzar encuesta',
  potential_clients: 'Clientes potenciales',
  inactive_clients: 'Clientes inactivos',
  active_clients: 'Clientes activos',
  register_not_found: 'No se encontraron registros',
  login_as_customer: 'Inicio de sesión como cliente',
  login_as_customer_body: '¿Desea iniciar sesión como {name}?',
  emails_activity: 'Resumen de correos electrónicos',
  products_activity: 'Actividad en productos',
  pending_activities: 'evaluaciones pendientes',
  pending_activity: 'evaluación pendiente',
  no_pending_activities: 'No posee actividades pendientes.',
  activity_creation: 'Creacion de la actividad',
  employee_activities_table_header: [
    'Medicion (Rol)',
    'Accion'
  ],
  autoevaluation: 'Autoevaluacion',
  evaluators: 'Evaluadores',
  leader: 'Jefe',
  pair: 'Par',
  dependent: 'Colaborador',
  name: 'Nombre de evaluacion',
  dates: 'Fechas',
  actions: 'Acciones',
  to: 'hasta',
  last_polls: 'Ultimas mediciones',
  see_evaluated: 'Ver evaluadores',
  peding: 'Pendiente',
  evaluations_resume: 'Resumen de mediciones',
  no_created_evaluations: 'No posee mediciones creadas',
  go_create_evaluation: 'Ir a creació de medición',
  see_details: 'Ver detalles',
  evaluation_finished: 'Valoración finalizada'
}
