
import mapParameters from '../utils/map-parameters'
import Services from './base-services'

const service = new Services('products')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
}

export default {
  lisWithServices: () => {
    return service.get('list-with-services')
  },
  list: (options: IOptionsList) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null
    })
    return service.get('list', params)
  },
  listActive: () => {
    return service.get('list-active')
  },
  create: (data: object) => {
    return service.form('', data, {
      contact: 'object',
      license: 'object',
      demographicItems: 'array'
    })
  },
  edit: (id: number, data: object) => {
    return service.form(`edit/${id}`, data)
  },
  getOneById: (id: Number) => {
    const params = mapParameters({ id })
    return service.get('get-one-by-id', params)
  },
  disable: (id: number) => {
    return service.put('disable', { id })
  },
  enable: (id: number) => {
    return service.put('enable', { id })
  },
  uploadLogo: (data: string, transf: object) => {
    return service.form('upload-logo', transf)
  },
  listByCustomer: () => {
    return service.get('list-by-customer')
  },
  updateSettings: (product: any) => {
    return service.post(`settings/${product.id}`, { product })
  },
  listFiltered: (products: any) => {
    return service.post('list-filtered', { products })
  }
}
