<template>
  <div class="text-center">
    <v-dialog
      style="margin-left: -20px"
      v-model="dialog"
      width="700"
      persistent
    >

      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          style="padding: 1em"
          primary-title
        >
          {{
            type === 'terms'
              ? $t('settings.terms_and_conditions')
              : $t('settings.data_protection_policies')
          }}
        </v-card-title>

        <v-card-text>
          <div class="ql-editor text-md-left" v-html="data.description"/>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-uppercase"
            large
            @click="close"
          >
            {{ $t('input.accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  props: {
    type: String,
    dialog: Boolean,
    data: Object
  },
  data () {
    return {
      //
    }
  },
  methods: {
    close () {
      this.$emit('closeDialog')
    }
  }
}
</script>
