
<template>
  <v-container fluid >
    <x-loading
      :display="loading || loadingPersonal || loadingEmployment"
    ></x-loading>

    <v-alert
      v-if="massiveLoadActive"
      elevation="11"
      type="warning"
    >
      <v-row align="center">
        <v-col class="grow">
          {{ $t('employees.massive_load_in_progress') }}
        </v-col>
        <v-col class="shrink">
          <v-btn @click="$router.back()">Regresar</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <ValidationObserver v-else ref="form">
      <v-form
        @submit.prevent="onSubmit"
      >
        <v-tabs
          v-model="step"
          class="elevation-2"
          centered
          grow
          light
        >

          <v-tabs-slider></v-tabs-slider>
          <v-tab
            href="#1"
            v-if="isVisible('1')"
          >
            {{ $t('employees.personal_data') }}
          </v-tab>
          <v-tab
            href="#2"
            v-if="isVisible('2')"
          >
            {{ $t('employees.employment_data') }}
          </v-tab>

          <v-tab-item
            value="1"
          >
            <ValidationObserver ref="step1">
              <x-step-personal-data
                :employee="employee"
                :fields-required="fieldsRequired"
                step="1"
                nextAction="input.next"
                prevAction="input.cancel"
                @changeStep="verifyStepChanged"
                @loading="($event) => { this.loadingPersonal = $event }"
              ></x-step-personal-data>
            </ValidationObserver>
          </v-tab-item>

          <v-tab-item
            value="2"
          >
            <ValidationObserver ref="step2">
              <x-step-employment-data
                :employee="employee"
                :fields-required="fieldsRequired"
                step="2"
                nextAction="input.save"
                prevAction="input.back"
                @changeStep="verifyStepChanged"
                @loading="($event) => { this.loadingEmployment = $event }"
              ></x-step-employment-data>
            </ValidationObserver>
          </v-tab-item>
        </v-tabs>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import Vue from 'vue'

import resolver from '../../utils/resolver'
import formatItems from '../../utils/form-format-items-list'
import XStepPersonalData from './steps/personal-data.vue'
import XStepEmploymentData from './steps/employment-data.vue'

import chargesService from '../../services/charges'
import employeesService from '../../services/employees'
import headquartersService from '../../services/headquarters'

export default Vue.extend({
  components: {
    XStepPersonalData,
    XStepEmploymentData
  },
  data () {
    return {
      loading: false,
      loadingPersonal: false,
      loadingEmployment: false,
      stepperHeaders: [
        'employees.personal_data',
        'employees.employment_data'
      ],
      employee: {
        academicDegreeId: '',
        countryId: '',
        headquarterId: '',
        chargeId: '',
        departmentId: '',
        genderId: '',
        jobTypeId: '',
        identifyDocument: '',
        identifyTypeId: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        birthdate: '',
        admission: '',
        additionalDemographic1Id: '',
        additionalDemographic2Id: ''
      },
      fieldsRequired: {
        academicDegreeId: 'required|integer',
        countryId: 'required|integer',
        headquarterId: 'required|integer',
        chargeId: 'required|integer',
        departmentId: 'required|integer',
        genderId: 'required|integer',
        jobTypeId: 'required|integer',
        identifyDocument: 'required',
        birthdate: 'required|dateBefore',
        admission: 'required|dateBefore'
      },
      validOne: false,
      validTwo: false,
      countries: [],
      headquarters: [],
      charges: [],
      departments: [],
      academicDegrees: [],
      genders: [],
      jobTypes: [],
      identifyTypes: [],
      step: '1',
      dialog: {
        show: false,
        msg: ''
      },
      enterpriseId: null,
      demographicsItems: [],
      massiveLoadActive: false
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    onSubmit (step) {
      if (step && step < this.step) {
        this.step = step.toString()
        return
      }
      if (this.step === '1') {
        this.$refs.step1.validate().then(success => {
          if (!success) {
            const error = this.$t('errors.validator/invalid-input')
            this.$store.dispatch('alert/error', error)
            return
          }
          this.step = step.toString()
        })
      } else {
        this.$refs.form.validate().then(success => {
          if (!success) {
            const error = this.$t('errors.validator/invalid-input')
            this.$store.dispatch('alert/error', error)
            return
          }
          return this.create()
        })
      }
    },
    verifyStepChanged (data, step) {
      if (step === 0) {
        return this.$router.push('/employees')
      }
      this.employee = Object.assign({}, this.employee, data)
      this.onSubmit(step)
    },
    updateCharges (department) {
      chargesService.list(department)
        .then((res) => { this.charges = formatItems(res.items) })
    },
    updateheadquarters (country) {
      headquartersService.list(country)
        .then((res) => {
          this.headquarters = formatItems(res.items, (headquarter) => ({
            text: headquarter.name,
            value: headquarter.id
          }))
        })
    },
    checkCreatedEmployees () {
      return employeesService.checkCreatedEmployees(this.user.id)
        .then((res) => {

        }).catch((err) => {
          this.enterpriseId = err.enterpriseId
          this.dialog.show = true
          this.dialog.msg = this.$t(`errors.${err.code}`)
        })
    },
    create () {
      this.loading = true
      return employeesService.create(this.employee)
        .then((res) => {
          this.loading = false
          this.$router.push('/employees')
          this.$store.dispatch('alert/success', this.$t('employees.created_employee'))
        })
        .catch((err) => {
          const error = this.$t(`errors.${err.code}`)
          this.$store.dispatch('alert/error', error)
          this.loading = false
        })
    },
    isVisible (step) {
      return (step === this.step || !this.$vuetify.breakpoint.xsOnly)
    }
  },
  created () {
    this.loading = true
    resolver
      .all({
        massiveLoad: employeesService.massiveFindActual()
      })
      .then((res) => {
        this.massiveLoadActive = Boolean(res.massiveLoad)
      })
      .finally(() => {
        this.loading = false
      })
  }
})
</script>
