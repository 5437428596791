
<template>
  <v-dialog v-model="openModal" width="777" persistent scrollable>
    <v-card>
      <v-card-title>
        {{ $t('settings.additional_segmentation_options_to') }}
        <p class="d-inline mb-0 ml-1 font-italic title font-weight-regular">
          {{ editingDetails.id ? editingDetails.trans[lang].label : '' }}
        </p>
      </v-card-title>
      <v-card-subtitle class="pt-3 text-justify">
        {{ $t('settings.additional_segmentation_options_desc') }}
      </v-card-subtitle>
      <v-card-text class="pt-3">
        <ValidationObserver ref="options_rf">
          <v-row v-for="(option, $i) in editingDetails.details" :key="$i" class="pl-4">
            <v-col cols="12" sm="5" v-for="(lng, $j) in languages" :key="$j">
              <!-- Available Languages -->
              <p v-if="$i === 0" class="body-2 font-weight-bold text-center text-uppercase">{{ lng.text }}</p>

              <!-- Options fields -->
              <ValidationProvider v-slot="{ errors }"
                :vid="`d-${$i}-${$j}`"
                :name="$t('settings.additional_segmentation_detail_n', {n: $i + 1})"
                :rules="{
                  required: lng.value == user.lang
                }"
                mode="eager"
              >
                <v-text-field light outlined
                  v-model="option.trans[lng.value].label"
                  :label="$t('settings.additional_segmentation_insert_detail', {
                    n: $i + 1,
                    o: lng.value !== user.lang ? `(${$t('settings.optional')})` : ''
                  })"
                  :name="`detail${$i}${$j}`"
                  :error-messages="errors[0]"
                >
                  <template v-slot:message>
                    <p class="mt-n1 mb-0 body-2">
                      {{ errors[0] }}
                    </p>
                  </template>
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols=12 sm="2"
              v-if="$i === editingDetails.details.length - 1"
              class="text-center" :class="$i === 0 ? 'pt-11' : 'pt-6'"
            >
              <!-- Remove Detail -->
              <v-tooltip bottom
                :disabled="editingDetails.details.length === minOptions"
                color="error lighten-1"
              >
                <template v-slot:activator="{ on }">
                  <v-btn fab x-small
                    v-on="on"
                    color="error lighten-1"
                    class="mr-1"
                    :disabled="editingDetails.details.length === minOptions"
                    @click="removeDetail(editingDetails, $i)"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('settings.additional_segmentation_remove_detail') }}</span>
              </v-tooltip>
              <!-- Add Detail -->
              <v-tooltip bottom
                :disabled="editingDetails.details.length === maxOptions"
                color="success lighten-1"
              >
                <template v-slot:activator="{ on }">
                  <v-btn fab x-small
                    v-on="on"
                    color="success lighten-1"
                    :disabled="editingDetails.details.length === maxOptions"
                    @click="addDetail(editingDetails, $i)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('settings.additional_segmentation_add_detail') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          :loading="loading"
          @click="saveDetails()"
        >
          {{ $t('input.save') }}
        </v-btn>
        <v-btn depressed
          color="grey lighten-2"
          :disabled="loading"
          @click="closeModal()"
        >
          {{ $t('input.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState } from 'vuex'
import additionalSegmentationService from '../../../services/additional-segmentation'

export default {
  props: {
    openModal: Boolean,
    editingDetails: Object,
    languages: Array,
    lang: String
  },
  data: () => ({
    loading: false,
    minOptions: 2,
    maxOptions: 15
  }),
  watch: {
    openModal (val) {
      if (val && !this.editingDetails.details.length) {
        this.initMinOptions()
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    closeModal () {
      this.$emit('closeModal', false)
    },
    initMinOptions () {
      for (var i = 0; i < this.minOptions; i++) {
        this.addLangsOption()
      }
    },
    addLangsOption () {
      const trans = {}
      for (const lng of this.languages) {
        trans[lng.value] = { label: '' }
      }
      this.editingDetails.details.push({ trans })
    },
    addDetail () {
      this.$refs.options_rf.validate()
        .then((valid) => {
          if (valid) {
            this.addLangsOption()
          }
        })
    },
    removeDetail () {
      this.editingDetails.details.pop()
    },
    saveDetails () {
      this.$refs.options_rf.validate()
        .then((valid) => {
          if (valid) {
            this.loading = true
            additionalSegmentationService.storeDetails(this.editingDetails.id, this.editingDetails.details)
              .then((res) => {
                this.$store.dispatch('alert/success',
                  this.$t('settings.additional_segmentation_details_updated')
                )
                this.$emit('refreshList')
                this.closeModal()
              })
              .catch((err) => {
                this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
              })
              .finally(() => {
                this.loading = false
              })
          }
        })
    }
  }
}
</script>
