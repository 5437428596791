
<template>
  <v-app>
    <v-main class="background">
      <v-container :fill-height="this.$vuetify.breakpoint.mdAndUp">
        <v-row align-center justify-center row>
          <v-col align-self="center" class="text-xs-center" cols="12" sm="10" offset-sm="1" md="8" offset-md="2">
            <v-card class="elevation-12 mt-2 pt-6" outlined>
              <v-col>
                <v-select dense
                  v-model="language"
                  :items="languages"
                  :menu-props="{ top: false, offsetY: true }"
                  class="styled-select mr-3"
                  prepend-icon="translate"
                  @change="changeLocale"
                ></v-select>
              </v-col>
              <img :src="`${baseUrl}/img/20200301_occ_solution_logo.png`" alt="OCC - Solutions Logo" style="width: 35%; display:block; margin-left: 32%;" />
              <v-card-text>
                <router-view />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <x-alert></x-alert>
    <x-help-modal></x-help-modal>
  </v-app>
</template>

<script>
import Vue from 'vue'
import { localize } from 'vee-validate'

function loadLocale (code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    locale.messages.required = code === 'es' ? 'Este campo es obligatorio' : 'This field is required'
    localize(code, locale)
  })
}

export default Vue.extend({
  data () {
    return {
      changeClass: false,
      baseUrl: process.env.VUE_APP_SUITE_BASE_WEB,
      isRegisterPersonal: false,
      languages: [],
      language: this.$store.state.session.user.lang || this.$i18n.locale
    }
  },
  watch: {
    '$i18n.locale': {
      handler () {
        const languages = this.$t('languages')
        this.languages = []
        for (let key in languages) {
          this.languages.push({ value: key, text: languages[key] })
        }
        this.$i18n.locale = this.language
      }
    },
    $route (to, from) {
      this.isRegisterPersonal = to.name === 'SignUpPersonal'
    }
  },
  created () {
    const languages = this.$t('languages')
    for (let key in languages) {
      this.languages.push({ value: key, text: languages[key] })
    }
    // this._i18n.locale = this.language
    this.changeLocale()
    this.isRegisterPersonal = this.$route.name === 'SignUpPersonal'
  },
  methods: {
    changeLocale () {
      this.$store
        .dispatch('session/changeOutsideLocale', this.language)
        .then((res) => {
          this.$i18n.locale = res.user.lang
        })
      loadLocale(this.language)
    }
  }
})
</script>

<style scoped>

.styled-select,
.styled-select label[for] {
  max-width: 140px;
  font-size: 10pt;
  float: right;
}

</style>
