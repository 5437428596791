
import mapParameters from '../utils/map-parameters'
import Services from './base-services'

const service = new Services('employees')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
  charge?: string;
  department?: string;
}

export default {
  create: (data: object) => {
    return service.post('create', data)
  },
  massiveUpload: (file: File) => {
    return service.form('massive-upload', { massive: file })
  },
  cancelProcess: () => {
    return service.post('cancel-process')
  },
  generateTemplate: () => {
    return service.get('generate-template')
  },
  validateEmployeeNumber: () => {
    return service.get('massive-validate-limit')
  },
  generateLog: (logType: string) => {
    return service.post('generate-log', { logType })
  },
  massiveSummary: () => {
    return service.get('massive-summary')
  },
  sendRow: (data: object) => {
    return service.post('massive-add-row', { data })
  },
  deleteRow: (data: object) => {
    return service.post('massive-del-row', { data })
  },
  fixMassive: (data: object) => {
    return service.post('massive-fix', { data })
  },
  getMasterReferences: () => {
    return service.get('get-master-references')
  },
  massiveFindActual: () => {
    return service.get('massive-find-actual')
  },
  list: (options: IOptionsList) => {
    const params = mapParameters({
      page: options.page,
      rowsPerPage: options.itemsPerPage,
      filter: options.filter,
      charge: options.charge,
      department: options.department,
      search: options.search
    })
    return service.get('list', params)
  },
  disable: (id: number) => {
    return service.put('disable', { id })
  },
  enable: (id: number) => {
    return service.put('enable', { id })
  },
  getOneById: (id: number, lang?: string) => {
    return service.get(lang ? `${id}?lang=${lang}` : `${id}`)
  },
  update: (id: number, data: object) => {
    return service.put(`${id}`, data)
  },
  listByOptions: (options: object) => {
    return service.post('list/options', { options })
  },
  getByEnterpriseId: () => {
    return service.get('by-enterprise')
  },
  checkCreatedEmployees: (id: number) => {
    return service.get(`check-created-employees/${id}`)
  },
  getDataForPdf: () => {
    return service.get('get-data-to-instructive')
  },
  validateEmail: (email: string, identification: string, identificationType: string) => {
    return service.post('validate-email', { email, identification, identificationType })
  }
}
