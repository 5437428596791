<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="750"
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-card>
          <v-toolbar light flat>
            <v-toolbar-title>{{ $t('input.manage_token')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon light @click="closeModal">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-row class="text-center" color="blue-grey lighten-5">
              <v-col cols="12" sm="4">
                <v-card height="120" raised>
                  <h4 class="subtitle-1 font-weight-black text-uppercase pt-3">{{ $t('tokens.customer_balance') }}</h4>
                  <v-card-text>
                    <h2 class="mt-3">
                      {{ tokensCustomer }}
                    </h2>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="4">
                <v-card class="mx-6 my-6" height="95" flat>
                  <h4 class="subtitle-1 font-weight-black text-uppercase">{{ $t('tokens.tokens_list') }}</h4>
                  <v-row wrap row class="d-flex justify-center">
                    <x-inputs-token
                      :input="inputValue"
                      :min="min"
                      :max="max"
                      @updateInput="($event) => inputValue = $event"
                    />
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" sm="4">
                <v-card height="120" raised>
                  <h4 class="subtitle-1 font-weight-black text-uppercase pt-3">{{ $t('tokens.enterprise_balance') }}</h4>
                  <v-card-text>
                    <h2 class="mt-3">
                      {{ tokensEnterprise }}
                    </h2>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="end" align="end">
              <v-col justify="end" align="end">
                <v-btn class="mr-3" color="secondary" dark @click="closeModal">
                  &nbsp;{{ $t('input.cancel') }}
                </v-btn>
                <br class="hidden-sm-and-up"/>
                <br class="hidden-sm-and-up"/>
                <v-btn color="primary" type="submit" :disabled="inputValue===0">
                  &nbsp;{{ $t('input.save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  props: {
    enterprise: Object,
    customerBalance: Number,
    action: Function
  },
  data () {
    return {
      tokensCustomer: 0,
      tokensEnterprise: 0,
      show: true,
      inputValue: 0,
      min: 0,
      max: 0
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.tokensEnterprise = this.enterprise.balance
    this.tokensCustomer = this.customerBalance
    this.min = this.tokensEnterprise * -1
    this.max = this.tokensCustomer
  },
  watch: {
    inputValue (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.tokensEnterprise = this.tokensEnterprise + parseInt(newVal)
        this.tokensCustomer = this.tokensCustomer - parseInt(newVal)
      } else if (newVal) {
        this.tokensEnterprise = this.tokensEnterprise + parseInt(newVal)
        this.tokensCustomer = this.tokensCustomer - parseInt(newVal)
        this.tokensEnterprise = this.tokensEnterprise - parseInt(oldVal)
        this.tokensCustomer = this.tokensCustomer + parseInt(oldVal)
      } else {
        this.tokensEnterprise = this.min * -1
        this.tokensCustomer = this.max
      }
    }
  },
  methods: {
    closeModal () {
      this.show = !this.show
      this.$emit('close', this.enterprise)
    },
    onSubmit () {
      return this.action(this.enterprise.id, this.inputValue)
        .then(() => this.closeModal())
    }
  }
})
</script>
