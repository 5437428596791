
<template>
  <v-container fluid >
    <v-row>
      <v-col cols="12" md="6">
        <h4 class="display-1 mt-1">{{ $t('employees.employees') }}</h4>
      </v-col>
      <v-col cols="12" md="6">
        <v-row justify="end">
          <v-btn color="primary" class="mr-1 mt-1"
            to="/employees/massive-upload"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>
            {{ $t('employees.massive_upload') }}
          </v-btn>
          <v-btn color="primary" class="mr-1 mt-1"
            to="/employees/create"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>
            {{ $t('employees.add_employee') }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider></v-divider>

        <v-card>
          <v-card-title>
            <div class="title">{{ $t('employees.list_employees') }}</div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="mdi-magnify"
              :label="$t('employees.look_for')"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-divider class="my-3"></v-divider>

          <v-row row wrap class="mx-3">
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                v-model="chargeSelect"
                :label="$t('settings.charges_head')"
                :items="computedCharges"
                :loading="loadingCharges"
                prepend-inner-icon="mdi-filter-outline"
                @change="filterItems('charge', $event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <v-autocomplete
                v-model="departmentSelect"
                :label="$t('input.department')"
                :items="computedDepartments"
                :loading="loadingDepartments"
                prepend-inner-icon="mdi-filter-outline"
                @change="filterItems('department', $event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="2" class="pb-0 pb-sm-3">
              <v-autocomplete
                v-model="filterSelect"
                :label="$t('employees.filter_by')"
                :items="filters"
                prepend-inner-icon="mdi-filter-outline"
                @change="filterItems('filter', $event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="1" class="text-center text-md-right pt-0 pt-sm-3">
              <v-tooltip top :disabled="$vuetify.breakpoint.smAndDown">
                <template v-slot:activator="{ on }">
                  <v-btn text :outlined="$vuetify.breakpoint.smAndDown"
                    v-on="on"
                    color="secondary"
                    class="mt-4"
                    :disabled="computedDisabled"
                    @click="clearFilters()"
                  >
                    <span v-if="$vuetify.breakpoint.smAndDown" class="mr-2">
                      Limpiar Filtro
                    </span>
                    <v-icon>mdi-filter-remove</v-icon>
                  </v-btn>
                </template>
                <span>Limpiar Filtro</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <x-data-table
            :headers="headers"
            :parent-fetch-data="getEmployees"
            :options="options"
            no-data="employees.no_data"
          >
            <template v-slot:structure="prop">
              <td class="text-center">{{ prop.item.firstName }}</td>
              <td class="text-center">{{ prop.item.lastName }}</td>
              <td class="text-center">{{ getTranslation(prop.item.charge) }}</td>
              <td class="text-center">{{ getTranslation(prop.item.department) }}</td>
              <td class="text-center">{{ prop.item.employee.email || '--' }}</td>
              <td class="text-center">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <x-state-switch
                        :value="prop.item.active"
                        :item="prop.item"
                        class="ma-2"
                        :enable="{
                          title: $t('employees.enable_title'),
                          action: enableEmployee,
                          color: 'success',
                          btn_text: $t('input.enable'),
                          question: $t('employees.enable_text')
                        }"
                        :disable="{
                          title: $t('employees.disable_title'),
                          action: disableEmployee,
                          color: 'error',
                          btn_text: $t('input.disable'),
                          question: $t('employees.disable_text')
                        }"
                        :can-enable="!massiveLoadActive"
                        :can-disable="!massiveLoadActive"
                        :alert="{
                          text: $t('employees.massive_load_in_progress'),
                        }"
                      />
                    </div>
                  </template>
                  <span>
                    {{ prop.item.active ? $t('input.disable') : $t('input.enable') }}
                  </span>
                </v-tooltip>
              </td>
              <td class="text-center px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`/employees/${prop.item.employeeId}/edit`"
                      v-on="on"
                      text icon>
                      <v-icon small >edit</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('input.edit') }}
                  </span>
                </v-tooltip>

                <v-tooltip bottom color="green">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`/employees/${prop.item.employeeId}/show`"
                      v-on="on"
                      text icon >
                      <v-icon larger>remove_red_eye</v-icon>
                    </v-btn>
                  </template>
                  <span>
                   {{ $t('input.view_details') }}
                  </span>
                </v-tooltip>
              </td>
            </template>
          </x-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import Vue from 'vue'

import employeesService from '../../services/employees'
import authService from '../../services/auth'
import chargesService from '../../services/charges'
import departmentsService from '../../services/departments'

import formatItems from '../../utils/form-format-items-list'

export default Vue.extend({
  data () {
    return {
      filterSelect: 0,
      chargeSelect: 0,
      departmentSelect: 0,
      headers: [
        'employees.name',
        'employees.last_name',
        'settings.charges_head',
        'input.department',
        'employees.email',
        'employees.status',
        'employees.action'
      ],
      loadingCharges: false,
      loadingDepartments: false,
      charges: [],
      departments: [],
      options: {
        filter: null,
        search: null,
        charge: null,
        department: null
      },
      search: null,
      timer: null,
      massiveLoadActive: false
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    filters () {
      return [
        { text: this.$t('active'), value: '1' },
        { text: this.$t('inactive'), value: '0' }
      ]
    },
    computedDisabled () {
      return !this.options.filter && !this.options.charge && !this.options.department
    },
    computedCharges () {
      if (!this.charges.length) return
      return formatItems(this.charges, item => {
        const translation = item.translations.find(i => i.lang === this.user.lang)
        const label = !translation || !translation.label ? item.translate.label : translation.label

        return {
          value: item.id,
          text: label
        }
      }).sort((a, b) => b.text - a.text)
    },
    computedDepartments () {
      if (!this.departments.length) return
      return formatItems(this.departments, item => {
        const translation = item.translations.find(i => i.lang === this.user.lang)
        const label = !translation || !translation.label ? item.translate.label : translation.label

        return {
          value: item.id,
          text: label
        }
      }).sort((a, b) => b.text - a.text)
    }
  },
  created () {
    employeesService.massiveFindActual()
      .then(res => {
        this.massiveLoadActive = Boolean(res)
      })
      .finally(() => {
        this.getCharges()
      })
  },
  methods: {
    getCharges () {
      this.loadingCharges = true
      return chargesService.list()
        .then(res => {
          this.charges = res.items
        })
        .finally(() => {
          this.loadingCharges = false
          this.getDepartments()
        })
    },
    getDepartments () {
      this.loadingDepartments = true
      return departmentsService.listData()
        .then(res => {
          this.departments = res.items
        })
        .finally(() => {
          this.loadingDepartments = false
        })
    },
    getEmployees (options) {
      return employeesService.list(options)
    },
    disableEmployee (item) {
      return employeesService.disable(item.id)
        .then(() => {
          item.active = false
        })
    },
    enableEmployee (item) {
      return employeesService.enable(item.id)
        .then(() => {
          item.active = true
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    filterItems (type, value) {
      this.options = {
        ...this.options,
        [type]: value
      }
    },
    clearFilters () {
      this.filterSelect = 0
      this.chargeSelect = 0
      this.departmentSelect = 0
      this.options = {
        ...this.options,
        filter: null,
        charge: null,
        department: null
      }
    },
    getTranslation (field) {
      if (!field.translations) {
        return '--'
      } else {
        const found = field.translations.find(t => t.lang === this.user.lang)
        if (!found) {
          return '--'
        } else {
          return found.label || '--'
        }
      }
    }
    /*,
    loginAsEmployee (userId) {
      return authService.loginAsEmployee(userId, this.user)
        .then((res) => {
          return this.$store
            .dispatch('session/loginAs', res)
            .then((res) => {
              this.$router.push('/dashboard')
              window.location.reload(false)
            })
            .catch(err => (this.$store.dispatch('alert/error', err)))
        }).catch(err => (this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))))
    }
    */
  },
  watch: {
    search () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.options = {
          ...this.options,
          search: this.search
        }
      }, 2000)
    }
  }
})
</script>
