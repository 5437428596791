
export default {
  title: 'Cofiguring Service',
  name: 'Service Name',
  modality: 'Modality',
  periocity: 'Periodicity',
  periocities: {
    Annual: 'Annual',
    Biannual: 'Biannual',
    Quarterly: 'Quarterly',
    Monthly: 'Monthly',
    Biweekly: 'Bimonthly',
    Weekly: 'Weekly',
    Diary: 'Dialy',
    OneTime: 'Single-time',
    ByDemand: 'On demand'
  },
  recomendation: 'Recomendation',
  price: 'Price in Tokens',
  actions: 'Actions',
  modalities: {
    Download: 'Download',
    Person: 'Person',
    Workshop: 'Workshop',
    Medition: 'Assessment'
  },
  modalities_tag: {
    Download: 'Download',
    Workshop: 'Workshop',
    Person: 'Leader',
    Medition: 'Collaborator'
  },
  product_service_desc: {
    'OCC PULSE': {
      'ENGAGEMENT': 'Complete assessment of the entire organization.<br>It must be carried out annually.',
      'PULSE': 'Assessment on specific dimensions to specific demographic cuts.<br>It must be carried out periodically (ideally quarterly).',
      'REPORTE POR POBLACIÓN': 'Detailed report of specific populations.'
    },
    'OCC CULTURA': {
      'CULTURA ACTUAL ORGANIZACIONAL': 'Complete assessment of the entire organization.<br>It must be carried out annually.',
      'CULTURA REQUERIDA': 'Workshop with the management team to define the culture that<br>requires in accordance with the organization\'s strategy.',
      'CULTURA CORTE DEMOGRÁFICO': 'Detailed report of specific populations.',
      'PULSOS EN CULTURA': 'Assessment of specific dimensions to specific demographic cuts.<br>It must be carried out periodically (ideally quarterly).',
      'RESUMEN CORTES DEMOGRÁFICOS': 'Summary report of all variables of demographic cuts.'
    },
    'OCC DML 360°': {
      'OCC DML 360° INDIVIDUAL': 'Individual detailed report for each leader to be evaluated.',
      'REPORTE POR CORTE DEMOGRÁFICO': 'Detailed report of specific populations.',
      'REPORTE ORGANIZACIONAL OCC DML 360°': 'Grouped report of all the leaders included in the assessment.'
    },
    'COACHING Y CONVERSACIONES': {
      'COACHING Y CONVERSACIONES REPORTE INDIVIDUAL': 'Individual detailed report for each leader to be evaluated.',
      'REPORTE ORGANIZACIONAL COACHING Y CONVERSACIONES': 'Grouped report of all the leaders included in the assessment.'
    },
    'OCC POR': {
      'OCC POR REPORTE INDIVIDUAL': 'Individual detailed report of each member of the work team.',
      'REPORTE ORGANIZACIONAL OCC POR': 'Grouped report of all team members included in the assessment.'
    },
    'LIDERAZGO 360° A LA MEDIDA': {
      'LIDERAZGO 360° A LA MEDIDA INDIVIDUAL': 'Individual detailed report for each leader to be evaluated.',
      'REPORTE POR POBLACION LIDERAZGO': 'Detailed report of specific populations.',
      'REPORTE ORGANIZACIONAL LIDERAZGO': 'Grouped report of all the leaders included in the assessment.'
    },
    'ENERGY COMPASS': {
      'ENERGY COMPASS INDIVIDUAL': 'Individual detailed report of each collaborator.',
      'ENERGY COMPASS POR POBLACION': 'Group report of specific populations.',
      'ENERGY COMPASS ORGANIZACIONAL': 'Grouped report of all collaborators included in the assessment.'
    }
  }
}
