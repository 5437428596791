
<template>
  <v-container>

    <v-row justify="start">
      <v-col class="display-1 pt-4">
        {{ $t('calculatorView.title') }}
        <p class="mb-0 pt-1 body-2">{{ $t('tokens.subtitle') }}</p>
      </v-col>

      <!-- Rates / Recomendations -->
      <v-col md="4" lg="3" class="pl-12 pr-0">
        <v-card>
          <v-row no-gutters>
            <v-col class="py-3 text-center">
              <h5 class="caption font-weight-light text-center text-uppercase">
                {{ $t('tokens.equivalences_rates') }}
              </h5>
              <v-btn small dark
                color="primary"
                class="mt-1"
                @click="showTokenRangeDialog = true"
              >
                <v-icon size="19" class="mr-2">mdi-eye</v-icon>
                {{ $t('input.see') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Balance -->
      <v-col md="4" lg="3" v-if="hasBalance">
        <v-card>
          <v-row>
            <v-col>
              <h5 class="caption font-weight-light text-center text-uppercase">{{ $t('tokens.balance') }}</h5>
              <h5 class="headline font-weight-black text-center">
                {{ balance | formatCurrency(0) }}
                <span class="caption text-uppercase">tokens</span>
              </h5>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row  class="child-flex">
      <v-card class="mt-3">
        <v-row>
          <modal-calculator @refresh="($event) => { getBalance() }"></modal-calculator>
        </v-row>
      </v-card>
    </v-row>

    <v-dialog
      v-model="showTokenRangeDialog"
      max-width="900px"
    >
      <v-card>
        <x-token-price-table />
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import tokenAccountDetailService from '../../services/token-account-detail'
import ModalCalculator from './calculator'
import XTokenPriceTable from '@/components/token-price-range-table'

export default {
  components: {
    XTokenPriceTable,
    ModalCalculator
  },
  data () {
    return {
      showTokenRangeDialog: false,
      balance: 0,
      hasBalance: false
    }
  },
  mounted () {
    this.$store.dispatch('session/showSelectorCurrency', true)
  },
  beforeDestroy () {
    this.$store.dispatch('session/showSelectorCurrency', false)
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    getBalance () {
      tokenAccountDetailService.getBalance()
        .then((res) => {
          this.balance = res.balance === undefined ? 0 : res.balance
        })
    }
  },
  created () {
    this.getBalance()
    if (['customer', 'enterprise_admin'].includes(this.user.role)) {
      this.hasBalance = true
    }
  }
}
</script>
