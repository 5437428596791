var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"wrap":"","row":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"display-1 mt-1"},[_vm._v(_vm._s(_vm.$t('models.groups')))])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","to":"groups/create"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t('groups.add_group'))+" ")],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider'),_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('groups.groups_list')))])]),_c('v-divider',{staticClass:"my-3"}),_c('x-data-table',{attrs:{"headers":_vm.headers,"parent-fetch-data":_vm.getGroups,"no-data":"groups.no_data"},scopedSlots:_vm._u([{key:"structure",fn:function(prop){return [_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(prop.item.name))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(prop.item.leaderName? prop.item.leaderName : '--'))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(prop.item.enterprisesCount))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(prop.item.balance ? prop.item.balance : '--'))]),_c('td',{staticClass:"text-center"},[_c('v-switch',{staticClass:"ma-2",on:{"click":function($event){return _vm.openModal(prop.item.active, prop.item)}},model:{value:(prop.item.active),callback:function ($$v) {_vm.$set(prop.item, "active", $$v)},expression:"prop.item.active"}})],1),_c('td',{staticClass:"text-xs-center px-0"},[(prop.item.updatePlanRequestCount > 0)?_c('v-tooltip',{attrs:{"bottom":"","color":"green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("groups/" + (prop.item.id) + "/admin-update"),"icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("restore_page")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('input.admin_update')))])]):_vm._e(),(prop.item.extendPlanRequestCount > 0)?_c('v-tooltip',{attrs:{"bottom":"","color":"green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("groups/" + (prop.item.id) + "/admin-extend"),"icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("restore_page")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('input.admin_extension')))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("/groups/" + (prop.item.id) + "/show"),"icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("remove_red_eye")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('input.view_details'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("groups/" + (prop.item.id) + "/edit"),"icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('input.edit')))])])],1)]}}])})],1)],1)],1),_c('x-confirmation-modal',{attrs:{"show":_vm.modalEna.open,"reversible":true,"title":_vm.$t('groups.modal_enable'),"action":_vm.enableGroup,"btn-save":_vm.$t('groups.modal_save_btn')},on:{"update:show":function($event){return _vm.$set(_vm.modalEna, "open", $event)},"close":function($event){_vm.modalEna.open = false}},scopedSlots:_vm._u([{key:"question",fn:function(){return [_vm._v(_vm._s(_vm.$t('groups.modal_enable_des')))]},proxy:true}])}),_c('x-confirmation-modal',{attrs:{"show":_vm.modalDis.open,"reversible":true,"title":_vm.$t('groups.modal_disable'),"action":_vm.disableGroup,"btn-save":_vm.$t('groups.modal_save_btn')},on:{"update:show":function($event){return _vm.$set(_vm.modalDis, "open", $event)},"close":function($event){_vm.modalDis.open = false}},scopedSlots:_vm._u([{key:"question",fn:function(){return [_vm._v(_vm._s(_vm.$t('groups.modal_disable_des')))]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }