
<template>
  <v-app>
    <v-main>
      <v-container style="backgorund-coler: grey;">
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-center">
            <img class="hidden-xs-only" src="/img/20200301_occ_solution_logo.png" alt="OCC - Solutions Logo" style="width: 25em; height: 25em;"/>
            <img class="hidden-sm-and-up" src="/img/20200301_occ_solution_logo.png" alt="OCC - Solutions Logo" style="width: 250px;"/>
          </v-col>
          <v-col cols="12"  class="text-center">
            <v-progress-circular
              indeterminate
              :size="70"
              :width="7"
              :color="colors[this.second % 3]"
            ></v-progress-circular>
          </v-col>
          <v-col cols="12" class="mt-2">
            <h2 class="display-3 text-center">{{ $t('waiting.title')}}</h2>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  data () {
    return {
      second: 0,
      colors: ['#4999db', '#60c7af', '#e95f4c']
    }
  },
  methods: {
    runSeconds () {
      setInterval(() => {
        this.second++
      }, 2000)
    },
    closeFalseSession () {
      const role = this.$route.params.role
      const data = role ? { user: this.user, logOutAs: role } : this.user
      this.$store
        .dispatch('session/logoutAs', data)
        .then(() => {
          this.showSnackbar = false
          setTimeout(() => {
            this.gotoAfterCloseFalseSession()
            window.location.reload(false)
          }, 150)
        })
        .catch(err => {
          if (err.code === 'auth/not-login-as') {
            this.gotoAfterCloseFalseSession()
          } else {
            this.$router.push('/404')
          }
        })
      this.showSnackbar = false
    },
    gotoAfterCloseFalseSession () {
      if (this.user.role === 'customer') {
        this.$router.push('/commercial-profile')
      } else {
        this.$router.push('/dashboard')
      }
    },
    signOut () {
      this.$store
        .dispatch('session/signOut')
        .then(() => this.$router.push('/auth/sign-in'))
    },
    checkProgress () {
      switch (this.$route.params.progress) {
        case 'cfs':
          this.closeFalseSession()
          break
        case 'cs':
          this.signOut()
          break
        default: this.$router.push('/404')
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.runSeconds()
    this.checkProgress()
  }
})
</script>
