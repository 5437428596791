
<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <x-loading
      :display="loading"
    ></x-loading>
    <form style="padding-left: .7em; padding-right: .7em" @submit.prevent="handleSubmit(edit)" v-if="!loading">
      <v-row>
        <v-col cols="12" md="6">
          <x-inputs-input
            :input="enterprise.name"
            @updateInput="($event) => enterprise.name = $event"
            :append-outer-icon="$t('help.icon')"
            :label="$t('enterprises.company_name')"
            rules="required"
            autofocus
            light
            name="name"
            :help-message="$t('help.enterprise.create.name')"
          >
          </x-inputs-input>
        </v-col>
        <v-col cols="12" md="6">
          <x-inputs-autocomplete
            :items="getSectors"
            :input="enterprise.sectorId"
            @updateInput="($event) => enterprise.sectorId = $event"
            light
            :label="$t('input.sector')"
            name="sectorId"
            :append-outer-icon="$t('help.icon')"
            :help-message="$t('help.enterprise.create.sector')"
            rules="required"
          >
          </x-inputs-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <x-inputs-autocomplete
            :items="getCountries"
            :input="enterprise.countryId"
            @updateInput="($event) => enterprise.countryId = $event"
            light
            :label="$t('input.country')"
            name="countryId"
            :append-outer-icon="$t('help.icon')"
            :help-message="$t('help.enterprise.create.country')"
            rules="required"
          >
          </x-inputs-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <x-inputs-input
            :input="enterprise.headquarter.name"
            @updateInput="($event) => enterprise.headquarter.name = $event"
            light
            :label="$t('input.headquarter')"
            name="headquarter"
            :append-outer-icon="$t('help.icon')"
            :help-message="$t('help.enterprise.create.headquarter')"
            rules="required"
          ></x-inputs-input>
        </v-col>
        <v-col cols="12" md="6">
          <x-inputs-input
            :input="enterprise.address"
            @updateInput="($event) => enterprise.address = $event"
            light
            :label="$t('input.address')"
            name="address"
            :append-outer-icon="$t('help.icon')"
            rules="required"
            :help-message="$t('help.enterprise.create.address')"
          ></x-inputs-input>
        </v-col>
        <v-col cols="12" md="6">
          <x-inputs-autocomplete
            :items="getEnterpriseSizes"
            :input="enterprise.enterpriseSizeId"
            @updateInput="($event) => enterprise.enterpriseSizeId = $event"
            light
            :label="$t('input.enterprise_size')"
            name="enterpriseSizeId"
            :append-outer-icon="$t('help.icon')"
            :help-message="$t('help.enterprise.create.size')"
            rules="required"
          >
          </x-inputs-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <x-inputs-autocomplete
            :items="getEmployeesNumber"
            :input="enterprise.employeesNumberId"
            @updateInput="($event) => enterprise.employeesNumberId = $event"
            light
            :label="$t('input.employees_number')"
            name="employeesNumberId"
            :append-outer-icon="$t('help.icon')"
            :help-message="$t('help.enterprise.create.employeesNumber')"
            rules="required"
          >
          </x-inputs-autocomplete>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-btn
          class="d-inline text-capitalize"
          text
          small
          @click="stopEdit"
        > {{ $t('input.cancel') }}
        </v-btn>
        <v-btn
          class="d-inline text-capitalize"
          small
          color="primary"
          type="submit"
        > {{ $t('input.save') }}
        </v-btn>
      </v-row>
    </form>
  </ValidationObserver>
</template>

<script>

import { mapState } from 'vuex'

import resolver from '../../utils/resolver'
import formatItems from '../../utils/form-format-items-list'

import authServices from '../../services/auth'
import enterprisesServices from '../../services/enterprises'
import countriesService from '../../services/countries'
import sectorsService from '../../services/sectors'
import employeesNumberService from '../../services/employees-number'
import enterpriseSizesService from '../../services/enterprise-sizes'

export default {
  props: {
    editEnterprise: null,
    switchf: Function
  },
  data () {
    return {
      enterprise: {},
      sectors: [],
      countries: [],
      employeesNumber: [],
      enterpriseSizes: [],
      loading: true
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    getEnterpriseSizes () {
      if (!this.enterpriseSizes.length) return
      return formatItems(this.enterpriseSizes, item => {
        const eSize = item.translations.find(i => i.lang === this.user.lang)
        return {
          value: item.id,
          text: eSize.label,
          description: `${eSize.label}: ${eSize.description}`,
          translations: item.translations
        }
      }).sort((a, b) => b.text - a.text)
    },
    getSectors () {
      if (!this.sectors.length) return
      return formatItems(this.sectors, item => {
        const sector = item.translations.find(i => i.lang === this.user.lang)
        return {
          value: item.id,
          text: sector.label
        }
      }).sort((a, b) => b.text - a.text)
    },
    getCountries () {
      if (!this.countries.length) return []
      return formatItems(this.countries, item => {
        const country = item.translations.find(i => i.lang === this.user.lang)
        return {
          value: item.id,
          text: country.label
        }
      }).sort((a, b) => b.text - a.text)
    },
    getEmployeesNumber () {
      if (!this.employeesNumber) return
      return formatItems(this.employeesNumber, (item) => {
        const epNumber = item.translations.find(ep => ep.lang === this.user.lang)
        return {
          value: item.id,
          text: epNumber.label
        }
      }, false)
    }
  },
  methods: {
    stopEdit () {
      this.$emit('switchf', null)
    },
    edit () {
      return enterprisesServices.selfEdit(this.enterprise)
        .then((res) => {
          this.user.enterprise = res.enterprise
          this.$store.dispatch('session/updateEnterprise', this.user)
            .then(() => this.$emit('switchf'))
          this.$store.dispatch('alert/success', this.$t('enterprises.section_edited'))
          authServices.updatedInfo()
        })
        .catch((err) => {
          const error = this.$t(`errors.${err.code}`)
          this.$store.dispatch('alert/error', error)
        })
    }
  },
  created () {
    resolver
      .all({
        countries: countriesService.list(),
        sectors: sectorsService.list(),
        enterpriseSizes: enterpriseSizesService.list(),
        employeesNumber: employeesNumberService.list()
      })
      .then((res) => {
        this.countries = res.countries.items
        this.sectors = res.sectors.items
        this.employeesNumber = res.employeesNumber.items
        this.enterpriseSizes = res.enterpriseSizes.items
        this.enterprise = this.editEnterprise
        this.loading = false
      })
  }
}
</script>
