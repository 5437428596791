
<template>
  <read-wrapper
    :page-title="$t('settings.country_read')"
    :card-title="$t('settings.countries')"
    :item-services="countriesService"
  ></read-wrapper>
</template>

<script>

import countriesService from '../../services/countries'

import ReadWrapper from './components/read-wrapper.vue'

export default {
  components: {
    ReadWrapper
  },
  data () {
    return {
      countriesService
    }
  }
}
</script>
