
<template>
  <v-container fluid >
    <x-loading v-if="!fetched" :display="true"/>
    <v-row row wrap v-else>
      <v-col cols="12" sm="9" md="8" class="pt-4 pl-4">
        <h4 class="display-1">{{ $t('settings.charges_head') }}</h4>
      </v-col>
      <v-col cols="12" sm="3" md="4" class="text-right">
        <v-btn large
          color="primary"
          @click="createModal = true"
        >
          <v-icon left>mdi-plus-circle-outline</v-icon>
          {{ $t('settings.add') }}
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-subtitle class="mt-1 text-justify"
            v-html="$t('settings.master_references_section')"
          ></v-card-subtitle>

          <v-divider class="mb-1"></v-divider>

          <v-card-text v-if="fetched && charges.length" class="px-0 pt-0">
            <v-col>
              <x-info :text="$t('settings.charges_info')"></x-info>
            </v-col>

            <v-row row wrap class="mt-3 px-3">
              <!-- Cabeceras -->
              <!--
              <v-col cols="4" class="text-center font-weight-black">
                {{ $t('settings.charges_departments') }}
              </v-col>
              -->
              <v-col cols="10" class="text-center pr-0">
                <v-card flat>
                  <v-card-text class="text-center font-weight-black px-0 mb-2">
                    {{ $t('settings.charges_heads') }}
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card>
              </v-col>
              <v-col cols="2" class="text-center pl-6">
                <v-card flat>
                  <v-card-text class="text-center font-weight-black px-0 mb-2">
                    {{ $t('settings.charges_actions') }}
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card>
              </v-col>
            </v-row>

            <v-data-iterator
              :items="charges"
              :items-per-page="10"
              :options.sync="pagination"
              :footer-props="footerProps"
              row wrap
            >
            <!-- <template v-for="charge in charges"> -->
              <template v-slot:item="charge">
              <v-form ref="form-1" @submit.prevent :key="charge.item.id" data-vv-scope="form-1" name="form-1">
                <v-container fluid class="pr-0">
                  <!-- Elementos -->
                  <v-row row wrap class="mt-1" style="max-height: 3.5em;">
                    <!--
                    <v-col cols="4">
                      <x-inputs-autocomplete
                        :items="departmentsSelect[language]"
                        :input="charge.item.departmentId"
                        @updateInput="($event) => updateCharge($event, charge.item)"
                        :label="$t('settings.charges_department')"
                        :color="!charge.item.active ? 'grey lighten-2' : 'primary'"
                        :background-color="!charge.item.active ? 'grey lighten-2' : ''"
                        :disabled="!charge.item.active"
                        outlined light clearable
                      ></x-inputs-autocomplete>
                    </v-col>
                    -->

                    <v-col cols="10" class="">
                      <v-tooltip bottom color="blue">
                        <template v-slot:activator="{ on }" >
                          <ValidationProvider rules="required" v-slot="{ errors }" :name="' '">
                            <v-text-field outlined
                              v-model="charge.item.trans[user.lang].label"
                              v-on="on"
                              :placeholder="$t('settings.charges_head')"
                              :color="!charge.item.active ? 'grey lighten-2' : 'primary'"
                              :background-color="!charge.item.active ? 'grey lighten-2' : ''"
                              :disabled="!charge.item.active"
                              :error="!!errors[0]"
                              @keypress.enter="validateName(charge.item)"
                              @blur="validateName(charge.item)"
                            ></v-text-field>
                          </ValidationProvider>
                        </template>
                        {{ $t('settings.tooltip_edit') }}
                      </v-tooltip>
                    </v-col>

                    <v-col cols="2" class="text-center">
                      <!-- Deshabilitar -->
                      <v-tooltip bottom v-if="charge.item.active">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="handleToggleActive(charge.item, false)"
                            icon
                            large
                            color="primary"
                            v-on="on"
                          >
                            <v-icon medium>mdi-eye-off</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('settings.charges_disable') }}</span>
                      </v-tooltip>

                      <!-- Habilitar -->
                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="handleToggleActive(charge.item, true)"
                            icon
                            large
                            color="success"
                            v-on="on"
                          >
                            <v-icon medium>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('settings.charges_enable') }}</span>
                      </v-tooltip>

                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="red"
                            @click="handleDelete(charge.item.id)">
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('input.trash') }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
            <template v-slot:footer.page-text="props">
              {{ props.pageStart }}-{{ props.pageStop }} {{ $t('page_text') }} {{ props.itemsLength }}
            </template>
            </v-data-iterator>
          </v-card-text>

          <v-card-text v-else>
            <v-row row wrap>
              <v-col cols="12" class="text-xs-center">
                <h5 class="headline grey--text">{{ $t('settings.no_data') }}</h5>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Modal de eliminación -->
    <x-confirmation-modal
      :show.sync="deleteModal"
      :title="$t('settings.charges_delete_title')"
      :action="deleteCharge"
      :btn-save="$t('input.trash')"
      color="error"
      @close="deleteModal = false"
    >
      <template v-slot:question>{{ $t('settings.master_references_delete_des') }}</template>
    </x-confirmation-modal>

    <!-- Modal de habilitación -->
    <x-confirmation-modal
      :show.sync="toggleTrueModal"
      :reversible="true"
      :title="$t('settings.charges_toggle_false_title')"
      :action="toggleActiveCharge"
      @close="toggleTrueModal = false"
      :btn-save="$t('input.enable')"
      color="success"
    >
      <template v-slot:question>{{ $t('settings.master_references_enable_des') }}</template>
    </x-confirmation-modal>

    <!-- Modal de inhabilitación -->
    <x-confirmation-modal
      :show.sync="toggleFalseModal"
      :reversible="true"
      :title="$t('settings.charges_toggle_true_title')"
      :action="toggleActiveCharge"
      @close="toggleFalseModal = false"
      :btn-save="$t('input.disable')"
      color="error"
    >
      <template v-slot:question>{{ $t('settings.master_references_disable_des') }}</template>
    </x-confirmation-modal>

    <!-- Modal de creación -->
    <v-dialog v-model="createModal" width="500">
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-form ref="form-2"
          @submit.prevent="handleSubmit(createCharge)"
        >
        <v-card>
          <v-card-title class="headline" primary-title>
            {{ $t('settings.charges_add') }}
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <v-container fluid>
              <v-row row wrap>
                <!--
                <v-col cols="12">
                  <x-inputs-autocomplete
                    :input="newCharge.departmentId"
                    @updateInput="($event) => newCharge.departmentId= $event"
                    :items="departmentsSelect[user.lang]"
                    :label="$t('settings.charges_department')"
                  ></x-inputs-autocomplete>
                </v-col>
                -->

                <v-col cols="6" v-for="(lang, i) in resLanguages" :key ="lang.id">
                  <x-inputs-input outlined
                    :input="newCharge.name[i].value"
                    @updateInput="($event) => newCharge.name[i].value= $event"
                    :label="lang.code !== user.lang
                      ? `${lang.translate.label} (${$t('settings.optional')})`
                      : lang.translate.label
                    "
                    :name="'name' + lang.id"
                  ></x-inputs-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              @click="createModal = false"
            >
              {{ $t('input.cancel') }}
            </v-btn>

            <v-btn type="submit"
              color="primary"
              :disabled="computedDisabledNewCharge"
            >
              {{ $t('input.save') }}
            </v-btn>
          </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import resolver from '../../utils/resolver'
import departmentsService from '../../services/departments'
import chargesService from '../../services/charges'
import languagesService from '../../services/languages'

export default {
  data () {
    return {
      // Variables de control
      fetched: false,
      validOne: false,
      validTwo: false,
      deleteModal: false,
      toggleTrueModal: false,
      toggleFalseModal: false,
      createModal: false,
      // Paginacion
      pagination: {},
      // Data
      departments: [],
      charges: [],
      originalCharges: [],
      departmentsSelect: [],
      newCharge: {
        enterpriseId: '',
        departmentId: '',
        name: []
      },
      resLanguages: null,
      languages: [],
      language: 'es',
      toggleable: {},
      deleteable: {}
    }
  },
  created () {
    this.fetchData()
    this.newCharge.enterpriseId = this.user.enterprise.id
  },
  computed: {
    computedDisabledNewCharge () {
      return this.newCharge.name.find(x => x.code === this.user.lang).value === ''
    },
    footerProps () {
      return {
        'items-per-page-text': this.$t('results_per_pages'),
        'items-per-page-options': [5, 10, 25, { 'text': this.$t('page_all'), 'value': -1 }]
      }
    },
    ...mapState({
      user: state => state.session.user
    })
  },
  methods: {
    fetchData () {
      resolver
        .all({
          departments: departmentsService.listData(),
          charges: chargesService.fetchByEnterprise(),
          languages: languagesService.list()
        })
        .then(({ departments, charges, languages }) => {
          this.departments = departments.items
          this.charges = charges.items
          this.originalCharges = JSON.parse(JSON.stringify(this.charges))
          this.resLanguages = languages.items
          this.mapItems(this.charges, this.departments)
          this.language = this.user.lang
          this.fetched = true
        })
        .catch((error) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
        })
    },
    mapItems (items, departments) {
      this.charges = items.map(item => {
        item.trans = {}

        this.resLanguages.forEach(language => {
          this.languages.push({
            value: language.code,
            text: language.translate.label
          })

          const exists = this.newCharge.name.find((x) => x.code === language.code)
          if (!exists) {
            this.newCharge.name.push({
              code: language.code,
              value: ''
            })
          }

          item.trans[language.code] = item.translations.find(lang => lang.lang === language.code) || { label: '' }
        })

        return item
      })

      this.departments = departments.map(item => {
        item.trans = {}
        this.resLanguages.forEach(language => {
          this.departmentsSelect[language.code] = []
        })
        this.resLanguages.forEach(language => {
          item.trans[language.code] = item.translations.find(lang => lang.lang === language.code) || { label: '' }
        })
        return item
      })

      this.departments.forEach((department) => {
        for (const k of Object.keys(department.trans)) {
          this.departmentsSelect[k].push(
            {
              value: department.id,
              text: department.trans[k].label
            }
          )
        }
      })
    },
    validateName (charge) {
      const oldCharge = this.originalCharges.find(c => c.id === charge.id)
      const oldLabel = oldCharge.translations.find(t => t.lang === this.user.lang).label
      const newLabel = charge.trans[this.user.lang].label
      if (newLabel && newLabel !== oldLabel) {
        this.updateCharge(charge.departmentId, charge)
      }
    },
    updateCharge (value, charge) {
      const originalValue = this.originalCharges.find((x) => x.id === charge.id)
      const originalValueTs = originalValue.translations.filter((l) => l.lang === this.language)
      const originalValueLabel = originalValueTs[0].label

      chargesService.update(charge.id, { lang: this.language, label: charge.trans[this.language].label, departmentId: value })
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('settings.charges_updated'))
          const oldCharge = this.originalCharges.find(c => c.id === charge.id)
          const oldTranslation = oldCharge.translations.find(t => t.lang === this.user.lang)
          const newLabel = charge.trans[this.user.lang].label
          oldTranslation.label = newLabel
        })
        .catch((err) => {
          if (err.code && err.code === 'already_exists') {
            charge.trans[this.language].label = originalValueLabel
            this.$store.dispatch('alert/error',
              this.$t('settings.demographic_already_exists', [this.$t('settings.charges_head')])
            )
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
        })
    },
    handleToggleActive (charge, active) {
      active ? this.toggleTrueModal = true : this.toggleFalseModal = true
      this.toggleable = { charge, active }
    },
    toggleActiveCharge () {
      const { charge, active } = this.toggleable

      return chargesService.toggleActive(charge.id, active)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('settings.charges_updated'))
          charge.active = !charge.active
          active ? this.toggleTrueModal = false : this.toggleFalseModal = false
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    handleDelete (id) {
      this.deleteModal = true
      this.deleteable = { id }
    },
    deleteCharge () {
      const { id } = this.deleteable

      return chargesService.delete(id)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('settings.charges_deleted'))
          this.charges = this.charges.filter((h) => h.id !== id)
          this.deleteModal = false
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    createCharge () {
      chargesService.create(this.newCharge)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('settings.charges_created'))
          this.newCharge.name = []
          this.resLanguages.forEach(language => {
            this.newCharge.name.push({
              code: language.code,
              value: ''
            })
          })
          this.newCharge.departmentId = ''
          this.fetchData()
          this.createModal = false
        })
        .catch((err) => {
          this.fetched = true
          if (err.code && err.code === 'already_exists') {
            this.$store.dispatch('alert/error',
              this.$t('settings.demographic_already_exists', [this.$t('settings.charges_head')])
            )
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
        })
    }
  }
}
</script>
