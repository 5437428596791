
import mapParameters from '../utils/map-parameters'
import Services from './base-services'

const service = new Services('token-account')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
}

export default {
  list: (options: IOptionsList, userId: number) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null
    })
    return service.get('list', params)
  },
  create: (data: object) => {
    return service.post('create', data)
  },
  getOneById (id: number) {
    const params = mapParameters({ id })
    return service.get('', params)
  },
  getOneByUser () {
    return service.get('by-user')
  },
  getOneByGroup () {
    return service.get('by-group')
  }
}
