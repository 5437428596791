
import ICommit from '../contracts/commit'
import auth from '../../services/auth'
import users from '../../services/users'

const TOKEN_KEY = 'occ-key-token'
const USER_KEY = 'occ-key-user'
const CURRENCY_KEY = 'occ-key-currency'
const SELECTOR_CURRENCY_KEY = 'occ-key-selector-currency'

const baseState = {
  user: JSON.parse(localStorage.getItem(USER_KEY) || '{}'),
  token: localStorage.getItem(TOKEN_KEY),
  currency: JSON.parse(localStorage.getItem(CURRENCY_KEY) || '{}'),
  showSelectorCurrency: JSON.parse(localStorage.getItem(SELECTOR_CURRENCY_KEY) || 'false')
}

const persistAndCommitSession = (commit: any, session: any) => {
  if (session.token) {
    localStorage.setItem(TOKEN_KEY, session.token)
  }
  localStorage.setItem(USER_KEY, JSON.stringify(session.user))

  commit('setSession', session)
  return session
}
const actions = {
  signIn ({ commit }: ICommit, credentials: any) {
    return auth.signIn(credentials)
      .then((session) => persistAndCommitSession(commit, session))
  },
  signOut ({ commit }: ICommit) {
    return auth.signOut()
      .then(() => {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(USER_KEY)
        localStorage.removeItem(CURRENCY_KEY)
        localStorage.removeItem(SELECTOR_CURRENCY_KEY)
        commit('clearSession')
      })
  },
  changeLocale ({ commit }: ICommit, locale: any) {
    const usr = localStorage.getItem(USER_KEY)
    return auth.changeLocale(locale, usr)
      .then((session) => persistAndCommitSession(commit, session))
  },
  changeOutsideLocale ({ commit }: ICommit, locale: any) {
    return persistAndCommitSession(commit, { user: { lang: locale }, token: null })
  },
  updateEnterprise ({ commit }: ICommit, user: any) {
    const token = localStorage.getItem(TOKEN_KEY)
    return persistAndCommitSession(commit, { user, token })
  },
  loginAs ({ commit }: ICommit, session: any) {
    return persistAndCommitSession(commit, session)
  },
  logoutAs ({ commit }: ICommit, data: any) {
    let user = data.logOutAs ? data.user : data
    return auth.signOutAs(user, data.logOutAs)
      .then((session) => {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(USER_KEY)
        commit('clearSession')
        persistAndCommitSession(commit, session)
      })
  },
  findHost ({ commit }: ICommit, product: string) {
    return auth.findHost(product)
  },
  sso ({ commit }: ICommit, session: any) {
    persistAndCommitSession(commit, session)
    return users.user()
      .then((res) => {
        session.user = res
        persistAndCommitSession(commit, session)
      })
  },
  isValid ({ commit }: ICommit) {
    return users.user()
      .catch(err => {
        if (err) return false
      })
  },
  getRefreshState () {
    return Promise.resolve({
      token: localStorage.getItem(TOKEN_KEY),
      user: localStorage.getItem(USER_KEY)
    })
  },
  setCurrency ({ commit }: ICommit, currency: any) {
    localStorage.setItem(CURRENCY_KEY, JSON.stringify(currency))
    commit('setCurrency', currency)
    return currency
  },
  showSelectorCurrency ({ commit }: ICommit, show: boolean) {
    localStorage.setItem(SELECTOR_CURRENCY_KEY, JSON.stringify(show))
    commit('showSelectorCurrency', show)
    return show
  }

}

const mutations = {
  setSession (state: any, session: any) {
    state.user = session.user
    state.token = session.token
  },
  clearSession (state: any) {
    state.user = {}
    state.token = null
  },
  setCurrency (state: any, currency: any) {
    state.currency = currency
    if (state.user.customer) {
      state.user.customer.currencyId = currency.id
    }
  },
  showSelectorCurrency (state: any, currency: boolean) {
    state.showSelectorCurrency = currency
  }
}

export default {
  namespaced: true,
  state: baseState,
  actions,
  mutations
}
