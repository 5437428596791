
import store from '../../store'

export default async (to: any, from: any, next: any) => {
  if (!store.state.session.token) {
    next('/auth/sign-in')
    return
  }

  if (store.state.session.user.role === 'enterprise_admin') {
    next('/customer/show-profile')
    return
  }

  next()
}
