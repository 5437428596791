<template>
  <v-simple-table>
    <template v-slot:default>
      <thead class="blue lighten-1">
        <tr>
          <th class="pl-5 text-left text-uppercase white--text" width="340px">
            {{ isPublic ? $t('tokens.points_range') : $t('pricing.table_ranges') }}
          </th>
          <th></th>
          <th class="pr-5 text-right text-uppercase white--text" width="440px">
            {{ isPublic ? $t('tokens.points_value') : $t('pricing.table_prices') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item of prices" v-bind:key="item.id">
          <td style="padding: 0px">
            <v-row class="text-right">
              <v-col sm="3"><span>{{ item.minRange | formatCurrency(0) }}</span></v-col>
              <v-col v-if="item.maxRange !== '~'" sm="3"><span>{{ item.maxRange | formatCurrency(0) }}</span></v-col>
              <v-col v-else sm="4" class="text-center"><span>{{ $t('pricing.ormore') }}</span></v-col>
            </v-row>
          </td>
          <td></td>
          <td>
            <v-row justify="end" class="text-right">
              <v-col sm="2">{{ currency.paymentSymbol }}</v-col>
              <v-col sm="3">{{ currency.currencySymbol }}{{ (item.price * currency.value) | formatCurrency(2) }}</v-col>
            </v-row>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>

import { mapState } from 'vuex'
import tokenPriceService from '../services/token-price'
import publicService from '../services/public'

export default {
  name: 'token-price-range-table',
  props: {
    isPublic: Boolean
  },
  data () {
    return {
      prices: []
    }
  },
  computed: {
    ...mapState({
      currency: state => state.session.currency
    })
  },
  created () {
    const url = this.isPublic ? publicService.getTokenRanges() : tokenPriceService.getActives()
    url
      .then((res) => {
        this.prices = res.sort((a, b) => {
          return a.minRange - b.minRange
        })
        this.prices.map(range => {
          range.price = Number(range.price).toFixed(2)
        })
        this.prices[res.length - 1].maxRange = '~'
      })
  }
}
</script>
