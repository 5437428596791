
<template>
  <v-row class="text-center">
    <v-col>
      <div class="text-uppercase headline">
        {{ $t('maintenance') }}
      </div>
      <img src="/img/20200301_occ_solution_logo.png" alt="Building" :style="stylingImg"/>
      <div
        class="headline"
        v-html="$t('tokens.maintenance')"
      ></div>
      <div
        class="text-subtitle-1"
        v-html="$t('tokens.acquire_info')"
      ></div>
      <v-btn
        class="mt-3"
        color="primary"
        href="mailto:info@occsolutions.org?subject=Solicitud de OCC Tokends"
        target="_blank">{{ $t('input.send_email') }}</v-btn>
      <v-btn class="mt-3 mx-1" color="primary" to="/">{{ $t('input.back_home') }}</v-btn>
    </v-col>
    <x-alert :alert="alert"></x-alert>
  </v-row>
</template>

<script>

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    stylingImg () {
      return this.$vuetify.breakpoint.xs ? 'width: 50%;' : 'width: 30%;'
    }
  },
  methods: {

  }
}
</script>
