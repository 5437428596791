
<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col cols="12" sm="6">
        <h4 class="display-1 mb-3">{{ $t('groups.add_group') }}</h4>
      </v-col>

      <v-col cols="12">
        <v-card>
          <ValidationObserver v-slot="{ handleSubmit }">
          <v-form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <v-container>
              <v-row row wrap>
                <v-col cols="12" sm="6">
                  <x-inputs-input
                    :input="group.name"
                    @updateInput="($event) => group.name = $event"
                    light
                    :label="$t('groups.group_name')"
                    autofocus
                    name="name"
                    rules="required"
                    :append-outer-icon="$t('help.icon')"
                    @click:append-outer="$store.dispatch('help/display', $t('help.enterprise.group.name'))"
                  ></x-inputs-input>
                </v-col>

                <v-col cols="12" sm="6">
                  <x-inputs-autocomplete
                    :items="customers"
                    :input="group.mainCustomerId"
                    @updateInput="($event) => group.mainCustomerId = $event"
                    light
                    :label="$t('groups.main_enterprise')"
                    name="main_enterprise_id"
                    clearable
                    rules="required"
                    :append-outer-icon="$t('help.icon')"
                    @click:append-outer="$store.dispatch('help/display', $t('help.enterprise.group.leader'))"
                  ></x-inputs-autocomplete>
                </v-col>
              </v-row>

              <v-row justify="end" align="end">
                <v-col md="6" xs="12" sm="3" align="end">
                  <v-btn
                    text
                    small
                    @click="$router.go(-1)"
                    class="text-capitalize"
                  >{{ $t('input.cancel') }}</v-btn>
                  <v-btn
                    color="primary"
                    type="submit"
                    small
                    style="margin-left: 1em"
                    class="text-capitalize"
                  >Crear</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import groupService from '../../services/groups'
import customerService from '../../services/customers'
import formatItems from '../../utils/form-format-items-list'

export default {
  data () {
    return {
      group: {
        name: '',
        mainCustomerId: null
      },
      customers: []
    }
  },
  methods: {
    onSubmit () {
      return groupService.create(this.group).then(res => {
        this.$store.dispatch(
          'alert/success',
          this.$t('groups.created_group')
        )
        this.$router.push({ path: '/groups' })
      }).catch(err => {
        this.$store.dispatch(
          'alert/error',
          this.$t(`errors.${err.code}`)
        )
      })
    },
    listEnterprises () {}
  },
  created () {
    customerService
      .listNoGrouped()
      .then(res => {
        this.customers = formatItems(res.items, item => ({
          value: item.id,
          text: item.name
        }))
      })
      .catch(err => {
        this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
      })
  }
}
</script>
