
import Services from './base-services'

const service = new Services('terms-and-conditions')

export default {
  listActive: () => {
    return service.get('list-active')
  },
  getOne: (id: number) => {
    return service.get(`get-one/${id}`)
  },
  create: (data: any) => {
    return service.post('create', data)
  },
  getActiveByType: (type: string) => {
    return service.get(`get-active/${type}`)
  },
  acceptByCustomer: (data: any) => {
    return service.post('accept-by-customer', data)
  }
}
