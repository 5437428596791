
<template>
  <v-card flat class="enterprise-information">
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-container>
          <v-row>
            <x-inputs-upload-image
              :key="form.name"
              :title="$t('enterprises.load_company_logo')"
              :input="String(form.logo || '')"
              @updateInput="($event) => form.logo = $event"
            ></x-inputs-upload-image>
            <v-col cols="12" sm="6">
              <x-inputs-input
                :input="form.name"
                @updateInput="($event) => form.name = $event"
                :append-outer-icon="$t('help.icon')"
                :label="$t('input.enterprise_name')"
                rules="required"
                autofocus
                light
                name="name"
                :help-message="$t('help.enterprise.create.name')"
              ></x-inputs-input>
            </v-col>

            <v-col cols="12" sm="6">
              <x-inputs-autocomplete
                :items="getSectors"
                :input="form.sectorId"
                @updateInput="($event) => form.sectorId = $event"
                light
                :label="$t('input.sector')"
                name="sectorId"
                :append-outer-icon="$t('help.icon')"
                :help-message="$t('help.enterprise.create.sector')"
                rules="required"
              >
              </x-inputs-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <x-inputs-autocomplete
                :items="getCountries"
                :input="form.countryId"
                @updateInput="($event) => form.countryId = $event"
                light
                :label="$t('input.country')"
                name="countryId"
                :append-outer-icon="$t('help.icon')"
                :help-message="$t('help.enterprise.create.country')"
                rules="required"
              >
              </x-inputs-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <x-inputs-input
                :input="form.headquarter"
                @updateInput="($event) => form.headquarter = $event"
                light
                :label="$t('input.headquarter')"
                name="headquarter"
                :append-outer-icon="$t('help.icon')"
                :help-message="$t('help.enterprise.create.headquarter')"
                :disabled="selfEdit"
                rules="required"
              ></x-inputs-input>
            </v-col>

            <v-col cols="12" sm="6">
              <x-inputs-input
                :input="form.address"
                @updateInput="($event) => form.address = $event"
                light
                :label="$t('input.address')"
                name="address"
                :append-outer-icon="$t('help.icon')"
                rules="required"
                :help-message="$t('help.enterprise.create.address')"
                :disabled="selfEdit"
              ></x-inputs-input>
            </v-col>

            <v-col cols="12" sm="6">
              <x-inputs-autocomplete
                :items="getEnterpriseSizes"
                :input="form.enterpriseSizeId"
                @updateInput="($event) => form.enterpriseSizeId = $event"
                light
                :label="$t('input.enterprise_size')"
                name="enterpriseSizeId"
                :append-outer-icon="$t('help.icon')"
                :help-message="$t('help.enterprise.create.size')"
                rules="required"
                :persistent-hint="!(!getEnterpriseDescription)"
                :messages="getEnterpriseDescription"
                :disabled="selfEdit"
              >
              </x-inputs-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" v-if="!outside">
              <x-inputs-autocomplete
                :items="getEmployeesNumber"
                :input="form.employeesNumberId"
                @updateInput="($event) => form.employeesNumberId = $event"
                light
                :label="$t('input.employees_number')"
                name="employeesNumberId"
                :append-outer-icon="$t('help.icon')"
                :help-message="$t('help.enterprise.create.employeesNumber')"
                rules="required"
                :disabled="selfEdit"
              >
              </x-inputs-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="end" align="end">
            <v-col cols="12" sm="6" align="end">
              <v-btn outlined
                color="primary"
                @click="changeStep(+step - 1)"
              >
                {{ $t(prevAction) }}
              </v-btn>
              <v-btn
                color="primary"
                type="submit"
                style="margin-left: 1em"
              >
                {{ $t(nextAction) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import resolver from '../../../utils/resolver'
import formatItems from '../../../utils/form-format-items-list'
import validate from '../../../utils/validate-form'

import countriesService from '../../../services/countries'
import sectorsService from '../../../services/sectors'
import employeesNumberService from '../../../services/employees-number'
import enterpriseSizesService from '../../../services/enterprise-sizes'
import languagesService from '../../../services/languages'

export default Vue.extend({
  props: {
    enterprise: Object,
    step: String,
    nextAction: String,
    prevAction: String,
    outside: Boolean,
    selfEdit: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      form: {
        name: '',
        logo: '',
        sectorId: '',
        countryId: '',
        address: '',
        enterpriseSizeId: '',
        employeesNumberId: '',
        languageId: '',
        headquarter: ''
      },
      valid: false,
      sectors: [],
      countries: [],
      employeesNumber: [],
      enterpriseSizes: [],
      languages: [],
      image: {
        src: '',
        name: ''
      },
      imageColor: 'primary'
    }
  },
  watch: {
    enterprise: {
      handler () {
        this.updateDataForm()
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    getEnterpriseSizes () {
      if (!this.enterpriseSizes.length) return
      return formatItems(this.enterpriseSizes, item => {
        const eSize = item.translations.find(i => i.lang === this.user.lang)
        return {
          value: item.id,
          text: eSize.label,
          description: `${eSize.label}: ${eSize.description}`,
          translations: item.translations
        }
      }).sort((a, b) => b.text - a.text)
    },
    getSectors () {
      if (!this.sectors.length) return
      return formatItems(this.sectors, item => {
        const sector = item.translations.find(i => i.lang === this.user.lang)
        return {
          value: item.id,
          text: sector.label
        }
      }).sort((a, b) => b.text - a.text)
    },
    getCountries () {
      if (!this.countries.length) return []
      return formatItems(this.countries, item => {
        const country = item.translations.find(i => i.lang === this.user.lang)
        return {
          value: item.id,
          text: country.label
        }
      }).sort((a, b) => b.text - a.text)
    },
    getEnterpriseDescription () {
      if (!this.getEnterpriseSizes) return
      const item = this.getEnterpriseSizes.find(item => item.value === this.form.enterpriseSizeId)
      return item ? item.translations.find(it => it.lang === this.user.lang).description : ''
    },
    getEmployeesNumber () {
      if (!this.employeesNumber) return
      return formatItems(this.employeesNumber, (item) => {
        const epNumber = item.translations.find(ep => ep.lang === this.user.lang)
        return {
          value: item.id,
          text: epNumber.label
        }
      }, false)
    }
  },
  methods: {
    changeStep (step) {
      this.$emit('changeStep', this.form, step.toString())
    },
    validateForm (scope) {
      return validate(this, scope)
        .then(valid => valid ? this.changeStep(+this.step + 1) : undefined)
    },
    runValidate () {
      return this.validateForm('information-form')
    },
    updateDataForm () {
      this.form.name = this.enterprise.name
      this.form.sectorId = this.enterprise.sectorId
      this.form.countryId = this.enterprise.countryId
      this.form.employeesNumberId = this.enterprise.employeesNumberId
      this.form.address = this.enterprise.address
      this.form.enterpriseSizeId = this.enterprise.enterpriseSizeId
      this.form.languageId = this.enterprise.languageId
      this.form.logo = this.enterprise.logo
      this.form.headquarter = this.enterprise.headquarter
      if (this.form.logo && typeof this.form.logo === 'string') {
        this.image.src = this.form.logo
        const logo = this.form.logo.split('?')
        this.image.name = (logo[0].split('/').pop())
      } else if (this.form.logo && typeof this.form.logo === 'object') {
        this.image.name = this.form.logo.name
      }
    },
    onSubmit () {
      this.changeStep(+this.step + 1)
    }
  },
  created () {
    if (this.outside) {
      resolver
        .all({
          countries: countriesService.listOutside(),
          sectors: sectorsService.listOutside(),
          employeesNumber: employeesNumberService.listOutside(),
          enterpriseSizes: enterpriseSizesService.listOutside(),
          languages: languagesService.listOutside()
        })
        .then((res) => {
          this.countries = res.countries.items
          this.languages = formatItems(res.languages.items)
          this.employeesNumber = res.employeesNumber.items
          this.sectors = res.sectors.items
          this.enterpriseSizes = res.enterpriseSizes.items
        })
    } else {
      resolver
        .all({
          countries: countriesService.list(),
          sectors: sectorsService.list(),
          employeesNumber: employeesNumberService.list(),
          enterpriseSizes: enterpriseSizesService.list(),
          languages: languagesService.list()
        })
        .then((res) => {
          this.countries = res.countries.items
          this.employeesNumber = res.employeesNumber.items
          this.languages = formatItems(res.languages.items)
          this.updateDataForm()
          this.sectors = res.sectors.items
          this.enterpriseSizes = res.enterpriseSizes.items
        })
    }
  }
})
</script>

<style>
  .mini-logo-detail {
    width: 110px !important;
    height: 110px !important;
    border: 3px solid #3899da !important;
  }

  .enterprise-information .v-messages__message {
    line-height: initial;
  }
</style>
