<template>
  <v-card>
    <v-toolbar flat color="primary" style="color: white">
      <v-toolbar-title class="text-capitalize">
        <strong>
          {{ $t('input.email') }}
        </strong>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined class="text-capitalize hidden-xs-only" style="color: white" @click="showDialog = !showDialog" v-if="!disabled">
        <v-icon small>edit</v-icon>
        &nbsp;&nbsp;{{ $t('input.change') }}
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-list>
        <v-list-item-content>
          <span class="font-weight-regular caption">
            <v-icon small>fa-envelope</v-icon>
            &nbsp;&nbsp;{{ $t('input.actual_email') }}
          </span>
          <span style="padding-left: 1.2em">{{ user.email }}</span>
        </v-list-item-content>
        <v-list-item-content class="hidden-sm-and-up" v-if="!disabled">
          <v-btn color="primary" class="text-capitalize" @click="showDialog = !showDialog">
            <v-icon small>edit</v-icon>
            &nbsp;&nbsp;{{ $t('input.change') }}
          </v-btn>
        </v-list-item-content>
      </v-list>
    </v-card-text>
     <v-dialog v-model="showDialog" max-width="500" persistent>
      <v-card>
        <v-toolbar light flat>
          <v-toolbar-title>
            <h2>
              <strong>
                {{ $t('input.email_change') }}
              </strong>
            </h2>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon light @click="showDialog = !showDialog">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text style="text-align:justify">
          <ValidationObserver v-slot="{ handleSubmit }">
            <v-form @submit.prevent="handleSubmit(changeEmail)">
              <x-inputs-input
                :input="form.password"
                @updateInput="($event) => form.password = $event"
                light
                :label="$t('input.actual_password')"
                name="actual_password"
                :append-outer-icon="$t('help.icon')"
                :help-message="$t('help.enterprise.create.intoCurrentPassword')"
                type="password"
                rules="required"/>
              <x-inputs-input
                :input="form.email"
                @updateInput="($event) => form.email = $event"
                light
                :label="$t('input.actual_email')"
                name="actual_email"
                :append-outer-icon="$t('help.icon')"
                :help-message="$t('help.enterprise.create.intoEmail')"
                rules="required|email"/>
              <x-inputs-input
                :input="form.newEmail"
                @updateInput="($event) => form.newEmail = $event"
                light
                :label="$t('input.new_email')"
                name="newEmail"
                :append-outer-icon="$t('help.icon')"
                :help-message="$t('help.enterprise.create.intoConfirmEmail')"
                rules="required|email"/>
                <v-row justify="space-around">
                  <v-btn
                  type="submit"
                  color="primary"
                  class="text-center text-capitalize"
                > {{ $t('input.change') }} </v-btn>
                </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
     <v-dialog v-model="showDialog2" max-width="500" persistent>
      <v-card>
        <v-card-title class="text-center" style="padding: 1.5em">
          <h2 style="width: 100%">
            <v-icon large color="primary">fa-check</v-icon>
          </h2>
        </v-card-title>
        <v-card-text class="text-center" style="padding: 1em">
          <p v-html="$t('customers.changed_email_msg', {email: form.newEmail})">
          </p>
          <v-btn
          @click="closeSecondDialog"
          color="primary"
          class="text-center text-capitalize"
          style="margin-top: 1.5em"
        >{{ $t('input.got_it') }}</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>

import Vue from 'vue'

import customersService from '../../services/customers'

export default Vue.extend({
  props: {
    user: Object,
    disabled: Boolean
  },
  data () {
    return {
      showDialog: false,
      form: {
        password: '',
        email: '',
        newEmail: ''
      },
      showDialog2: false
    }
  },
  methods: {
    changeEmail () {
      return customersService.changeEmail(this.user.id, this.form)
        .then((res) => {
          this.showDialog = !this.showDialog
          this.showDialog2 = !this.showDialog2
          this.$store.dispatch('session/signOut')
            .then(() => setTimeout(() => this.$router.push('/auth/sign-in'), 5000))
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    closeSecondDialog () {
      this.$router.push('/auth/sign-in')
    }
  }
})
</script>
