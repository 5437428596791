<template>
  <v-container>
    <v-card class="px-8 py-4">
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-form
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <h1 class="display-1">
            {{$t('settings.products_conf')}} {{product.name}}
          </h1>
          <v-row justify="center">
            <v-col sm="12" align="center" class="hidden-xs-only">
              <img :src="product.logo || product.defaultLogo || ''" height="200" width="200">
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="product.apiHostUrl"
                filled readonly
                :label="$t('input.api_host')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="product.apiHostPort"
                filled readonly
                :label="$t('input.api_port')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="product.webHostUrl"
                filled readonly
                :label="$t('input.web_host')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="product.webHostPort"
                filled readonly
                :label="$t('input.web_port')"
              />
            </v-col>
            <v-col sm="6" cols="12" align="center">
              <v-switch
                v-model="product.isPublic" class="ml-10 pl-10"
                :label="product.isPublic ? $t('settings.private_product_title') : $t('settings.public_product_title')"
                :loading="loadingSwitch"
                @click="modalConf = true"
              >
              </v-switch>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-btn to="/settings/products" block> {{ $t('input.cancel') }} </v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn color="primary" type="submit" block> {{ $t('input.save') }} </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-card>
    <x-confirmation-modal
      :show.sync="modalConf"
      :reversible="true"
      :title="product.isPublic ? $t('settings.public_product_title') : $t('settings.private_product_title')"
      @togglePublicProduct="switchPublicProduct"
      @close="modalClosedOrCancelled"
    >
      <template v-slot:question>{{ product.isPublic ? $t('settings.public_product_tip') : $t('settings.private_product_tip') }}</template>
    </x-confirmation-modal>
  </v-container>
</template>

<script>
import Vue from 'vue'
import productsService from '../../services/products'

export default Vue.extend({
  data () {
    return {
      product: {
        name: ''
      },
      modalConf: false,
      loadingSwitch: false
    }
  },
  created () {
    this.getProduct()
  },
  methods: {
    getProduct () {
      return productsService.getOneById(this.$route.params.id)
        .then((res) => {
          this.product = res
        })
    },
    switchPublicProduct () {
      this.modalConf = false
    },
    onSubmit () {
      return productsService.updateSettings(this.product)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('record_saved_successfully'))
          this.$router.push('/settings/products')
        })
    },
    modalClosedOrCancelled () {
      this.modalConf = false
      this.product.isPublic = !this.product.isPublic
    }
  },
  watch: {
    modalConf (val) {
      this.loadingSwitch = val
    }
  }
})
</script>
