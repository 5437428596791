
<template>
  <v-container>
    <v-row wrap row>
      <v-col x12>
        <v-card>
          <v-card-title primary-title>
            <div>
              <span class="subheading grey--text">Grupo</span>
              <h4 class="display-1">{{ group.name }}</h4>
            </div>

            <v-spacer></v-spacer>

            <x-chip-status
              outlined
              label
              :status="group.active"
              classes="pa-4 subheading"
            ></x-chip-status>
          </v-card-title>

          <v-divider v-if="hasLeader"></v-divider>

          <v-card-text class="pa-0">
            <v-row row wrap class="mt-4" v-if="hasLeader">
              <v-col xs12 md6 class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-office-building</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('groups.main_enterprise') }}</h6>
                    </v-list-item-title>
                    <v-list-item-sub-title class="grey--text subheading">
                      {{ group.enterprises[0].enterprise.name }}
                    </v-list-item-sub-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col xs12 md6 class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-briefcase</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('groups.current_plan') }}</h6>
                    </v-list-item-title>
                    <v-list-item-sub-title class="grey--text subheading">
                      {{ group.enterprises[0].enterprise.license.plan.translate.label }}
                    </v-list-item-sub-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <v-data-table
              :headers="headers"
              :items="enterprises"
              :no-data-text="$t('enterprises.no_data')"
              :rows-per-page-text="$t('results_per_pages')"
            >
              <template v-slot:items="props">
                <tr>
                  <td>{{ props.item.enterprise.name }}</td>
                  <td>{{ props.item.enterprise.license.plan.translate.label }}</td>
                  <td>{{ props.item.enterprise.license.endAt | date({date: true, hour: false}) }}</td>
                  <td class="text-xs-center px-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          :to="`/enterprises/${props.item.enterprise.userId}/update-plan`"
                          v-on="on"
                          flat icon>
                          <v-icon small >mdi-login-variant</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('dashboard.update_plan') }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          :to="`/enterprises/${props.item.enterpriseId}/extend-plan`"
                          v-on="on"
                          flat icon>
                          <v-icon small>mdi-square-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('input.extend_plan') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom color="primary" v-if="user.role === 'enterprise' && user.view === 'enterprise'">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="loginAsEnterprise(props.item.enterprise.userId)"
                          v-on="on"
                          flat icon>
                          <v-icon small>play_arrow</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('input.login_as_enterprise') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
              <template v-slot:pageText="props">
                {{ props.pageStart }} - {{ props.pageStop }} {{ $t('page_text') }} {{ props.itemsLength }}
              </template>
            </v-data-table>

            <v-divider class="mt-3"></v-divider>

            <v-row row wrap>
              <v-spacer></v-spacer>
              <v-col xs4 sm2 :class="{ 'mx-2': user.role !== 'admin' }">
                <v-btn
                  :to="`/groups/${group.id}/update-plan`"
                  large
                  block
                  color="primary"
                >{{ $t('dashboard.update_plan') }}</v-btn>
              </v-col>

              <v-col xs4 sm2 class="mx-2">
                <v-btn
                  :to="`/groups/${group.id}/extend-plan`"
                  large
                  block
                  color="primary"
                >{{ $t('input.extend_plan') }}</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import groupsService from '../../services/groups'

import authService from '../../services/auth'

export default {
  data () {
    return {
      headers: [
        {
          text: this.$t('groups.associated_enterprises'),
          sortable: false
        }, {
          text: this.$t('groups.current_plan'),
          sortable: false
        }, {
          text: this.$t('enterprises.expiration_date'),
          sortable: false
        }, {
          text: this.$t('enterprises.actions'),
          sortable: false
        }
      ],
      group: {},
      modalDis: {
        open: false,
        item: {}
      },
      modalEna: {
        open: false,
        item: {}
      }
    }
  },
  methods: {
    getGroup () {
      groupsService.getOneByLeader()
        .then((res) => {
          this.group = res
        })
        .catch((err) => { this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`)) })
    },
    disableGroup () {
      return groupsService.disable(this.modalDis.item.id)
        .then((res) => {
          this.modalDis.item.active = false
          this.modalDis.open = false
        })
    },
    enableGroup () {
      return groupsService.enable(this.modalEna.item.id)
        .then((res) => {
          this.modalEna.item.active = true
          this.modalEna.open = false
        })
    },
    openModal (type, item) {
      if (type === 'disable') {
        this.modalDis.item = item
        this.modalDis.open = true
      } else {
        this.modalEna.item = item
        this.modalEna.open = true
      }
    },
    loginAsEnterprise (userId) {
      return authService.loginAsEnterprise(userId, this.user)
        .then((res) => {
          return this.$store
            .dispatch('session/loginAs', res)
            .then((res) => {
              this.$router.push('/dashboard')
              window.location.reload(false)
            })
            .catch(err => (this.$store.dispatch('alert/error', err)))
        }).catch(err => (this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))))
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    enterprises () {
      if (this.group && this.group.enterprises && this.group.enterprises.length > 0) {
        return this.group.enterprises.filter((it) => (!it.leader))
      } else {
        return []
      }
    },
    hasLeader () {
      return this.group && this.group.enterprises && this.group.enterprises.length > 0 && this.group.enterprises[0].leader
    }
  },
  created () {
    this.getGroup()
  }
}
</script>
