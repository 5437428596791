
<template>
  <div>
    <x-loading
      :display="loading"
    ></x-loading>

    <v-tabs
      v-model="step"
      class="elevation-2"
      centered
      grow
      light
      elevation="0"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#1">
        {{ $t('auth.view_sign_up_personal_enterprise') }}
      </v-tab>
      <v-tab href="#2" :disabled="!tabOneCompleted">
        {{ $t('auth.view_sign_up_personal_user') }}
      </v-tab>

      <v-tab-item value="1">
        <x-step-information
          :outside="true"
          :enterprise="enterprise"
          step="1"
          nextAction="input.next"
          prevAction="input.cancel"
          @changeStep="verifyStepChanged"
        ></x-step-information>
      </v-tab-item>
      <v-tab-item value="2">
        <x-step-contact-employee
          :outside="true"
          :enterprise="enterprise"
          step="2"
          nextAction="input.create_account"
          prevAction="input.back"
          :reCaptchaReset="reCaptchaReset"
          :reCaptchaRenderAgain="reCaptchaRenderAgain"
          @reCaptchaTokenChanged="reCaptchaTokenSet"
          @changeStep="verifyStepChanged"
        ></x-step-contact-employee>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>

import Vue from 'vue'

import XStepInformation from '../../views/enterprises/steps/information.vue'
import XStepContactEmployee from '../../views/enterprises/steps/contact-employee.vue'

import enterprisesServices from '../../services/enterprises'
import authService from '../../services/auth'

export default Vue.extend({
  components: {
    XStepInformation,
    XStepContactEmployee
  },
  data () {
    return {
      reCaptchaUsrToken: null,
      reCaptchaReset: false,
      reCaptchaRenderAgain: false,
      tabOneCompleted: false,
      tab: null,
      loading: false,
      enterprise: {
        name: '',
        sectorId: '',
        countryId: '',
        address: '',
        enterpriseSizeId: '',
        languageId: '',
        groupId: null,
        logo: '',
        demographicItems: [],
        contact: {
          name: '',
          genderId: '',
          identifyTypeId: '',
          identifyDocument: '',
          email: '',
          phoneNumber: '',
          address: '',
          countryId: ''
        },
        license: {
          startAt: '',
          duration: ''
        },
        headquarter: '',
        type: 'personal'
      },
      step: '1',
      termsAndConditions: null
    }
  },
  watch: {
    step (val) {
      if (val === '2' && !this.tabOneCompleted) {
        this.tabOneCompleted = true
      }
    },
    '$i18n.locale': {
      handler (val) {
        this.reCaptchaRenderAgain = true
        setTimeout(() => {
          this.reCaptchaRenderAgain = false
        }, 140)
      }
    }
  },
  created () {
    this.getTermsAndConditions()
  },
  methods: {
    reCaptchaTokenSet (token) {
      this.reCaptchaUsrToken = token
    },
    getTermsAndConditions () {
      return authService.getLastTerms('commercial')
        .then((res) => {
          this.termsAndConditions = res
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    verifyStepChanged (data, step) {
      this.enterprise = Object.assign({}, this.enterprise, data)
      switch (step) {
        case '0': return this.$router.go(-1)
        case 3: return this.create()
        default: this.step = step
      }
    },
    create () {
      this.loading = true
      this.enterprise.recaptcha = this.reCaptchaUsrToken
      return enterprisesServices.createOutside(this.enterprise)
        .then((res) => {
          this.loading = false
          this.$router.push({ name: 'SignUpFinished', params: { email: this.enterprise.contact.email } })
        })
        .catch((err) => {
          if (err.details) {
            const uniqueEmail = err.details.indexOf('contact-email/unique:User,email') !== -1
            const reCaptchaMissing = err.details.indexOf('recaptcha/required') !== -1
            if (uniqueEmail) {
              this.$store.dispatch('alert/error', this.$t('errors.email/unique:User,email,user_id'))
            }
            if (reCaptchaMissing) {
              this.$store.dispatch('alert/error', this.$t('errors.recaptcha/must-verify'))
            }
          } else {
            if (err.code === 'recaptcha/timeout-or-duplicate') {
              this.reCaptchaReset = true
              setTimeout(() => {
                this.reCaptchaReset = false
              }, 400)
            }
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
          this.loading = false
        })
    }
  }
})
</script>
