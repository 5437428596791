
<template>
  <v-dialog persistent
    v-model="show"
    max-width="500"
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-card>
          <ValidationObserver v-slot="{ handleSubmit }">
            <v-form @submit.prevent="handleSubmit(onSubmit)">
              <v-card-title class="pl-5">
                Co-{{ $t('enterprises.admin').toLowerCase() }}
                <v-spacer></v-spacer>
                <span v-if="isEditing" class="d-inline-block mt-1 mr-2 caption grey--text text--darken-1">
                  {{ $t('emails.active') }}
                </span>
                <v-switch dense hide-details
                  v-if="isEditing"
                  v-model="isActive"
                  class="mt-0"
                  :loading="loadingToggleActive"
                  @click="toggleActive()"
                />
              </v-card-title>
              <v-card-subtitle class="pb-0 pl-5">
                {{enterpriseName}}
              </v-card-subtitle>
              <ValidationProvider rules="email" v-slot="{ valid, errors }" vid="adminemail">
                <v-card-text class="px-6">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="admin.email"
                        prepend-icon="fa-envelope"
                        :label="$t('input.email')"
                        :error-messages="errors[0]"
                      >
                        <template v-slot:message="props">
                          <span class="caption mt-n4">{{ props.message | errorField('') }}</span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    type="submit"
                    :color="isEditing ? 'error' : 'primary'"
                    :disabled="computedDisabled || !valid"
                  >
                    {{ isEditing ? `${$t('input.change')} co-admin` : $t('input.create') }}
                  </v-btn>
                  <v-btn @click="closeModal">
                    {{ $t('input.close') }}
                  </v-btn>
                </v-card-actions>
              </ValidationProvider>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'
import enterprisesServices from '../../../services/enterprises'

export default Vue.extend({
  props: {
    show: Boolean,
    enterpriseId: Number,
    enterpriseName: String,
    enterpriseAdmin: Object
  },
  data () {
    return {
      loadingToggleActive: false,
      isEditing: false,
      isActive: true,
      admin: {
        email: ''
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    computedDisabled () {
      if (this.isEditing) {
        return this.admin.email === this.enterpriseAdmin.email
      } else {
        return !this.enterpriseName || !this.admin.email
      }
    }
  },
  watch: {
    enterpriseAdmin: {
      handler (val) {
        if (val && val.id) {
          this.isEditing = true
          this.admin.email = val.email
          this.isActive = this.enterpriseAdmin.status === 'active'
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
      this.admin.email = ''
      this.isEditing = false
      this.isActive = true
    },
    toggleActive () {
      this.loadingToggleActive = true
      enterprisesServices.toggleActiveEnterpriseAdmin(this.enterpriseId)
        .then((res) => {
          this.isActive = res
          this.$emit('refreshList')
        })
        .catch((err) => {
          const error = this.$t(`errors.${err.code}`)
          this.$store.dispatch('alert/error', error)
        })
        .finally(() => {
          this.loadingToggleActive = false
        })
    },
    onSubmit () {
      if (!this.isEditing) {
        enterprisesServices.saveEnterpriseAdmin(this.enterpriseId, this.admin.email)
          .then((res) => {
            this.$emit('refreshList')
            this.closeModal()
          })
          .catch((err) => {
            const error = this.$t(`errors.${err.code}`)
            this.$store.dispatch('alert/error', error)
          })
      } else {
        enterprisesServices.changeEnterpriseAdmin(this.enterpriseId, this.admin.email)
          .then((res) => {
            this.$emit('refreshList')
            this.closeModal()
          })
          .catch((err) => {
            const error = this.$t(`errors.${err.code}`)
            this.$store.dispatch('alert/error', error)
          })
      }
    }
  }
})
</script>
