
export default {
  tokens_list: 'OCC Tokens',
  subtitle: 'Cotiza y compra tus mediciones',
  tokens_desc: 'Los tokens son los créditos que consume cada<br>medición o reporte.<br>Dependiendo del volumen de Tokens que se adquiera,<br>su valor será diferente.',
  points_desc: 'Los Puntos OCC son los créditos que<br>consume cada medición o reporte.<br>A mayor cantidad de puntos adquiridos,<br>su valor unitario es menor.',
  acquire: 'Adquirir Tokens',
  total_amount: 'Monto Total',
  tool: 'Herramienta',
  calculator: 'Calculadora',
  operations: 'Operaciones',
  operations_group: 'Operaciones de Grupo',
  operation_detail: 'Detalle operación',
  operation_details: 'Detalles de la operación',
  transaction_number: 'N° de transacción',
  success_transaction: 'Transacción realizada correctamente.',
  created_movement: 'La adquisición fue realizada con éxito.',
  created_transfer: 'La transferencia fue realizada con éxito.',
  created_authorization: 'Se creó la autorización con éxito.',
  eliminated_authorization: 'Se eliminó la autorización con éxito.',
  authorization_message: 'autoriza a',
  authorization_message_p1: 'Al habilitar esta opción',
  authorization_message_p2: 'de utilizar sus OCC Tokens de ser requerido al realizar un consumo y no contar con el balance suficiente.',
  invalid_quantity: 'Cantidad incorrecta.',
  balance: 'Saldo',
  add_new: 'Agregar Nueva',
  quote_new: 'Cotizar Medición / Reporte',
  quote_another: 'Cotizar otra Medición / Reporte',
  date: 'Fecha',
  token_qty: 'Cantidad de OCC Tokens',
  actions: 'Acciones',
  no_data: 'No hay operaciones para mostrar',
  purchase: 'Adquisición de OCC Tokens',
  create: 'Creación de la cuenta',
  transfer: 'Traspaso',
  receiving: 'Recibiendo',
  sending: 'Enviando',
  received: 'recibido',
  received_female: 'recibida',
  sended: 'enviado',
  sended_female: 'enviada',
  spend: 'Consumo',
  consumption: 'Consumo',
  by: ' por ',
  of: ' de ',
  user: 'Usuario',
  authorization: 'Autorización',
  authorized: 'autorizado',
  medition: 'Medición',
  download: 'Descarga',
  workshop: 'Taller',
  downloads: 'Descargas',
  person: 'Persona',
  persons: 'Personas',
  poblation: 'Población',
  annual: 'Anualmente',
  biannual: 'Semestralmente',
  quarterly: 'Trimestralmente',
  monthly: 'Mensualmente',
  biweekly: 'Quincenalmente',
  weekly: 'Semanalmente',
  diary: 'Diario',
  one_time: 'Una vez',
  products: 'Productos',
  creation: 'Creación',
  upgrade: 'Actualización',
  adjustment: 'Ajuste realizado por el Administrador',
  employees_lowercase: 'colaboradores',
  collaborators_lowercase: 'colaboradores',
  filter_by: 'Filtrar por...',
  look_for: 'Buscar por número de transacción',
  look_for_mix: 'Buscar por número o propietario de la transacción',
  consumer_transactions: 'Transacciones de consumo',
  authorization_transactions: 'Transacciones por autorización',
  movements_between_accounts: 'Movimientos entre cuentas',
  customer_balance: 'Saldo Cliente',
  enterprise_balance: 'Saldo Empresa',
  group_balance: 'Saldo Grupo',
  administrator: 'Administrador',
  since: 'desde',
  to: 'hacia',
  for: 'para',
  see_all_companies: 'Ver todas las empresas',
  see_all_members: 'Ver todos los miembros',
  no_operations: 'No hay operaciones para mostrar.',
  cost: 'Costo',
  calculator_closing_message: '¿Está seguro que desea cancelar la operación?',
  return: 'Volver',
  confirm_close_calculator: 'Si, estoy seguro',
  if_you_buy: 'Si compras <i>{0}</i> tokens el valor por token será:',
  if_you_buy_points: 'Si compras <i>{0}</i> Puntos OCC, el valor por Punto será:',
  to_buy: 'a comprar',
  buy_msg: 'Al hacer click en el botón comprar será redirigido a la pasarela de pago para terminar el proceso.',
  required: 'Requeridos',
  times: 'veces',
  leaders: 'líderes',
  evaluation: 'Evaluación',
  total: 'Total',
  value: 'Valor inversión',
  total_value: 'Valor total de la inversión',
  points_to_next_range: '¡Faltan {0} puntos para que el valor unitario disminuya<br />de {1} {2}{3} a {1} {2}{4}!',
  type: 'Tipo de Medición o Reporte',
  services: 'Servicios',
  modality: 'Modalidad',
  modalities: {
    report: 'reporte|reportes',
    poll: 'encuesta|encuestas',
    workshop: 'taller|talleres',
    leader: 'líder|líderes'
  },
  equivalences_rates: 'Equivalencias y Tarifas',
  num_leaders: 'Líderes a evaluar',
  num_polls: 'N° de encuestas',
  num_reports: 'N° de reportes',
  num_workshop: 'Taller',
  recomendation: 'Recomendación',
  unit: 'OCC Tokens',
  point: 'Punto OCC',
  points: 'Puntos OCC',
  points_range: 'Rango de Puntos OCC',
  points_value: 'Valor por Punto OCC',
  points_equivalence: 'Equivalencia Puntos OCC',
  money_unit: 'USD',
  group: 'Grupo',
  error_on_payment_gateway: 'En este momento no podemos redireccionar al boton de pago, por favor intente mas tarde',
  maintenance: 'Estamos trabajando para usted, en las siguientes actualizaciones podrá adquirir tokens directamente.',
  acquire_info: 'Por favor comunique con OCC Solutions por medio del correo info@occsolutions.org para adquirir sus tokens.',
  currency_required: 'Debe seleccionar la divisa con la cual se realizará los cálculos en la barra superior.',
  iva: 'IVA 19%',
  make_payment: 'Realizar Pago',
  payment_exceed_limit: 'El monto de la transacción excede los límites establecidos en PSE para la empresa, por favor comuníquese con nuestras líneas de atención al cliente al teléfono +57 (1) 642 1300 o al correo electronico info@occsolutions.org',
  pending_transaction_title: 'Transacción Pendiente',
  pending_transaction_text: 'En este momento usted presenta un proceso de pago cuya transacción se encuentra PENDIENTE de recibir confirmación por parte de su entidad financiera. Si aún así desea generar una nueva operación de compra presione nuevamente el botón para iniciar el proceso, de lo contrario espere unos minutos para que su transacción sea confirmada.'
}
