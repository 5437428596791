
import ICommit from '../contracts/commit'
import auth from '../../services/auth'

const ACL_KEY = 'occ-key-wolf'

const baseState = {
  wolf: JSON.parse(localStorage.getItem(ACL_KEY) || '{}')
}

const persistAndCommitSession = (commit: any, session: any) => {
  localStorage.setItem(ACL_KEY, JSON.stringify({ ...session.wolfProduct, tm: session.wolfTimes }))
  commit('setSession', session)
  return session
}

const actions = {
  getAcl ({ commit }: ICommit) {
    return auth.getAcl()
      .then((session) => persistAndCommitSession(commit, session))
  }
}

const mutations = {
  setSession (state: any, session: any) {
    state.wolf = { ...session.wolfProduct, tm: session.wolfTimes }
  }
}

export default {
  namespaced: true,
  state: baseState,
  actions,
  mutations
}
