
export default {
  tokens_list: 'OCC Tokens',
  subtitle: 'Quote and buy your assessments',
  tokens_desc: 'The tokens are the credits consumed by each<br>assessment or report.<br>Depending on the volume of Tokens acquired,<br>their value will be different.',
  points_desc: 'The OCC Points are the credits consumed <br>by each assessment or report.<br>The greater the number of points acquired,<br>the lower their unit value.',
  acquire: 'Get tokens',
  total_amount: 'Total amount',
  tool: 'Tool',
  calculator: 'Calculator',
  operations: 'Operations',
  operations_group: 'Group operations',
  operation_detail: 'Operation detail',
  operation_details: 'Operation details',
  transaction_number: 'Transaction number',
  success_transaction: 'Transaction successful.',
  created_movement: 'Acquisition successful.',
  created_transfer: 'Transfer successful.',
  created_authorization: 'Authorization successfully created.',
  eliminated_authorization: 'Authorization successfully deleted.',
  authorization_message: 'authrizes',
  authorization_message_p1: 'When enabling this option',
  authorization_message_p2: 'to use your OCC Tokens if required when making a consumption and not having enough balance.',
  invalid_quantity: 'Incorrect quantity.',
  balance: 'Balance',
  add_new: 'Add new',
  quote_new: 'Quote Measurement / Report',
  quote_another: 'Quote another Measurement / Report',
  date: 'Date',
  token_qty: 'Quantity of OCC Tokens',
  actions: 'Actions',
  no_data: 'No operations to display',
  purchase: 'OCC Token Acquisition',
  create: 'Account creation',
  transfer: 'Trasfer',
  receiving: 'Receiving',
  sending: 'Sending',
  received: 'received',
  received_female: 'received',
  sended: 'sent',
  sended_female: 'sent',
  spend: 'Use',
  consumption: 'Use',
  by: ' by ',
  of: ' of ',
  user: 'User',
  authorization: 'Authorization',
  authorized: 'authorized',
  medition: 'Assessment',
  download: 'Download',
  workshop: 'Workshop',
  downloads: 'Downloads',
  person: 'Person',
  persons: 'People',
  poblation: 'Population',
  annual: 'Annualy',
  biannual: 'Biannualy',
  quarterly: 'Quaterly',
  monthly: 'Monthly',
  biweekly: 'Biweekly',
  weekly: 'Weekly',
  diary: 'Daily',
  one_time: 'Once',
  products: 'Products',
  creation: 'Creation',
  upgrade: 'Update',
  adjustment: 'Adjusted by the administrator',
  employees_lowercase: 'collaborators',
  collaborators_lowercase: 'collaborators',
  filter_by: 'Sort by...',
  look_for: 'Search by transaction number',
  look_for_mix: 'Search by transaction number or owner',
  consumer_transactions: 'Consumer transactions',
  authorization_transactions: 'Transactions by authorization',
  movements_between_accounts: 'Movements between accounts',
  customer_balance: 'Customer balance',
  enterprise_balance: 'Company balance',
  group_balance: 'Group balance',
  administrator: 'Administrator',
  since: 'since',
  to: 'to',
  for: 'for',
  see_all_companies: 'See all companies',
  see_all_members: 'See all members',
  no_operations: 'No operations to display.',
  cost: 'Cost',
  calculator_closing_message: 'Are you sure you want to cancel the operation?',
  return: 'Back',
  confirm_close_calculator: 'Yes, I am sure',
  if_you_buy: 'If you buy <i>{0}</i> tokens the value per token will be:',
  if_you_buy_points: 'If you buy <i>{0}</i> OCC Points the value per point will be:',
  to_buy: 'to buy',
  buy_msg: 'By clicking on the buy button you will be redirected to the payment gateway to finish the process.',
  required: 'Required',
  times: 'times',
  leaders: 'leaders',
  evaluation: 'Assessment',
  total: 'Total',
  value: 'Investment value',
  total_value: 'Total investment value',
  points_to_next_range: 'There are {0} points left until the unit value decreases<br />from {1} {2}{3} to {1} {2}{4}!',
  type: 'Type of Assessment or Report',
  services: 'Services',
  modality: 'Modality',
  modalities: {
    report: 'report|reports',
    poll: 'poll|polls',
    workshop: 'workshop|workshops',
    leader: 'leader|leaders'
  },
  equivalences_rates: 'Equivalences and Rates',
  num_leaders: 'Leaders to evaluate',
  num_polls: 'N° of polls',
  num_reports: 'N° of reports',
  num_workshop: 'Workshop',
  recomendation: 'Recomendation',
  unit: 'OCC Tokens',
  point: 'OCC Point',
  points: 'OCC Points',
  points_range: 'OCC Points range',
  points_value: 'OCC Points value',
  points_equivalence: 'OCC Points equivalence',
  money_unit: 'USD',
  group: 'Group',
  error_on_payment_gateway: 'At this time we can\'t redirect you to the payment button, please try again later',
  maintenance: 'We are working for you, in the following updates you will be able to purchase tokens directly.',
  acquire_info: 'Please contact OCC Solutions via email info@occsolutions.org to acquire your tokens.',
  currency_required: 'You must select the currency with which the calculations will be made in the upper bar.',
  iva: 'VAT 19%',
  make_payment: 'Pay',
  payment_exceed_limit: 'The amount of the transaction exceeds the limits established in PSE for the company, please contact our customer service lines at +57 (1) 642 1300 or email info@occsolutions.org',
  pending_transaction_title: 'Transaction Pending',
  pending_transaction_text: 'At this time you have a payment process whose transaction is PENDING receiving confirmation from your financial institution. If you still want to generate a new purchase operation, press the button again to start the process, otherwise wait a few minutes for your transaction to be confirmed.'
}
