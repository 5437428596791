
<template>
  <v-container fluid >
    <v-row row wrap>
      <v-col cols="12" sm="6">
        <h4 class="display-1 mb-3">{{ $t('groups.edit_group') }}</h4>
      </v-col>

      <v-col cols="12">
        <v-card>
          <ValidationObserver v-slot="{ handleSubmit }">
            <v-form
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <v-container>
                <v-row row wrap>
                  <v-col cols="12" sm="6">
                    <x-inputs-input
                      :input="group.name"
                      @updateInput="($event) => group.name = $event"
                      light
                      :label="$t('groups.group_name')"
                      autofocus
                      name="name"
                      :append-outer-icon="$t('help.icon')"
                      @click:append-outer="$store.dispatch('help/display', $t('help.enterprise.group.name'))"
                    ></x-inputs-input>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <x-inputs-autocomplete
                      :items="customers"
                      :input="group.mainCustomerId"
                      @updateInput="($event) => group.mainCustomerId = $event"
                      light
                      :label="$t('groups.main_enterprise')"
                      name="main_enterprise_id"
                      :append-outer-icon="$t('help.icon')"
                      @click:append-outer="$store.dispatch('help/display', $t('help.enterprise.group.leader'))"
                    ></x-inputs-autocomplete>
                  </v-col>
                </v-row>

                <v-row justify="end" align="end" >
                  <v-col  md="6" xs="12" sm="3" align="end">
                    <v-btn
                      text
                      small
                      @click="$router.go(-1)"
                      class="text-capitalize"
                    >{{ $t('input.cancel') }}</v-btn>
                    <x-async-btn
                      class="white--text text-capitalize"
                      small
                      text
                      :action="update"
                      type="submit"
                    >
                      {{ $t('input.save') }}
                    </x-async-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import groupService from '../../services/groups'
import customerService from '../../services/customers'

export default {
  data () {
    return {
      group: {
        id: null,
        name: '',
        mainCustomerId: null
      },
      customers: []
    }
  },
  methods: {
    update () {
      groupService.update(this.group.id, this.group).then((res) => {
        this.$store.dispatch('alert/success', this.$t('groups.updated_group'))
        this.$router.go(-1)
      }).catch((err) => {
        this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
      })
    },
    getGroup () {
      groupService.getOne(this.$route.params.id)
        .then((res) => {
          this.group = res
          const firstCustomer = res.customers.length ? res.customers[0] : null
          if (firstCustomer && firstCustomer.leader) {
            this.group.mainCustomerId = firstCustomer.customerId
          } else {
            this.group.mainCustomerId = null
          }
          this.getCustomers(this.group.id)
        })
        .catch((err) => { this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`)) })
    },
    getCustomers (groupId) {
      customerService.listByGroupAndNoGrouped(groupId)
        .then((res) => {
          this.customers = res.items
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    }
  },
  created () {
    this.getGroup()
  }
}
</script>
