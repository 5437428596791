
import * as XLSX from 'xlsx'

export default (data: any, sheetname: string, fileName: string) => {
  // Calculate max width for all columns
  let objectMaxLength: any = []
  for (const value of data) {
    for (let j = 0; j < value.length; j++) {
      if (typeof value[j] === 'number') {
        objectMaxLength[j] = 10
      } else {
        objectMaxLength[j] = objectMaxLength[j] >= value[j].length ? objectMaxLength[j] : value[j].length
      }
    }
  }

  const wscols: any = []
  for (const width of objectMaxLength) {
    wscols.push({ width: width })
  }

  // Generate file
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { skipHeader: true })
  ws['!cols'] = wscols
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, sheetname)

  XLSX.writeFile(wb, fileName)
}
