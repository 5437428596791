<template>
  <v-container>
    <v-row row wrap>
      <v-col cols="12" class="pb-5 px-5">
        <ul>
          <li v-for="(item, i) in $t('employees.employee_data_recomendations')" :key="i"
            class="body-2 grey--text text--darken-1"
          >
            {{ item }}
          </li>
        </ul>
      </v-col>
      <v-col cols="12" sm="6">
        <x-date-picker
          v-model="employeeForm.admission"
          @date-picked="employeeForm.admission = $event"
          :label="$t('input.admission')"
          name="admission"
          :help="{ ...$t('help.employee.create.admission') }"
          :max="new Date().toISOString()"
          :rules="fieldsRequired.admission"
        ></x-date-picker>
      </v-col>

      <v-col cols="12" sm="6">
        <x-inputs-autocomplete
          :items="getFormattedJobTypes"
          :input="employeeForm.jobTypeId"
          @updateInput="($event) => employeeForm.jobTypeId = $event"
          light
          :label="$t('input.jobType')"
          name="jobType_id"
          :append-outer-icon="$t('help.icon')"
          :help-message="$t('help.employee.create.jobType')"
          :rules="fieldsRequired.jobTypeId"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <x-inputs-autocomplete
          :items="getFormattedDepartments"
          :input="employeeForm.departmentId"
          @updateInput="($event) => employeeForm.departmentId = $event"
          light
          :label="$t('input.department')"
          name="departmentId"
          :append-outer-icon="$t('help.icon')"
          :help-message="$t('help.employee.create.department')"
          :rules="fieldsRequired.departmentId"
          :on-change="updateCharges"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <x-inputs-autocomplete
          :items="getFormattedCharges"
          :input="employeeForm.chargeId"
          @updateInput="($event) => employeeForm.chargeId = $event"
          light
          :label="$t('input.charge')"
          name="chargeId"
          :append-outer-icon="$t('help.icon')"
          :help-message="$t('help.employee.create.charge')"
          :rules="fieldsRequired.chargeId"
          :on-change="updateCharges"
        />
      </v-col>

      <v-col cols="12" sm="6" v-if="additionalDemographics1.length">
        <x-inputs-autocomplete
          :items="getFormattedAdditionalDemographics1"
          :input="employeeForm.additionalDemographic1Id"
          @updateInput="($event) => employeeForm.additionalDemographic1Id = $event"
          light
          :label="$t('input.additionalDemographics1')"
          name="additionalDemographic1Id"
          :append-outer-icon="$t('help.icon')"
          :help-message="$t('help.employee.create.additionalDemographics1')"
          clearable
        />
      </v-col>

      <v-col cols="12" sm="6" v-if="additionalDemographics2.length">
        <x-inputs-autocomplete
          :items="getFormattedAdditionalDemographics2"
          :input="employeeForm.additionalDemographic2Id"
          @updateInput="($event) => employeeForm.additionalDemographic2Id = $event"
          light
          :label="$t('input.additionalDemographics2')"
          name="additionalDemographic2Id"
          :append-outer-icon="$t('help.icon')"
          :help-message="$t('help.employee.create.additionalDemographics2')"
          clearable
        />
      </v-col>
    </v-row>

    <v-row justify="end" align="end">
        <v-col md="6" xs="12" sm="6" align="end">
          <v-btn
            small
            @click="goBack"
            text
            class="text-capitalize"
          >{{ $t('input.back') }}</v-btn>

          <v-btn
            color="primary"
            small
            style="margin-left: 1em"
            class="text-capitalize"
            @click="onSubmit"
          >{{ $t(nextAction) }}
          </v-btn>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

import resolver from '../../../utils/resolver'
import formatItems from '../../../utils/form-format-items-list'

import chargesService from '../../../services/charges'
import departmentsService from '../../../services/departments'
import jobTypesService from '../../../services/job-types'
import additionalDemographics1Service from '../../../services/additional-demographics1'
import additionalDemographics2Service from '../../../services/additional-demographics2'

export default {
  props: {
    employee: Object,
    fieldsRequired: Object,
    step: String,
    nextAction: String,
    prevAction: String
  },
  data () {
    return {
      employeeForm: {
        departmentId: '',
        jobTypeId: '',
        admission: '',
        additionalDemographic1Id: '',
        additionalDemographic2Id: ''
      },
      charges: [],
      departments: [],
      validOne: false,
      jobTypes: [],
      additionalDemographics1: [],
      additionalDemographics2: []
    }
  },
  computed: {
    getFormattedCharges () {
      if (!this.charges.length) return []
      return formatItems(this.charges, (item) => {
        const translation = item.translations.find(i => i.lang === this.$i18n.locale)
        const label = !translation || !translation.label ? item.translate.label : translation.label

        return {
          text: label,
          value: item.id
        }
      }).sort((a, b) => b.text - a.text)
    },
    getFormattedDepartments () {
      if (!this.departments.length) return []
      return formatItems(this.departments, (item) => {
        const translation = item.translations.find(i => i.lang === this.$i18n.locale)
        const label = !translation || !translation.label ? item.translate.label : translation.label

        return {
          text: label,
          value: item.id
        }
      }).sort((a, b) => b.text - a.text)
    },
    getFormattedJobTypes () {
      if (!this.jobTypes.length) return []
      return formatItems(this.jobTypes, (item) => {
        const translation = item.translations.find(i => i.lang === this.$i18n.locale)
        const label = !translation || !translation.label ? item.translate.label : translation.label

        return {
          text: label,
          value: item.id
        }
      }).sort((a, b) => b.text - a.text)
    },
    getFormattedAdditionalDemographics1 () {
      if (!this.additionalDemographics1.length) return []
      return formatItems(this.additionalDemographics1, (item) => {
        const translation = item.translations.find(i => i.lang === this.$i18n.locale)
        const label = !translation || !translation.label ? item.translate.label : translation.label

        return {
          text: label,
          value: item.id
        }
      }).sort((a, b) => b.text - a.text)
    },
    getFormattedAdditionalDemographics2 () {
      if (!this.additionalDemographics2.length) return []
      return formatItems(this.additionalDemographics2, (item) => {
        const translation = item.translations.find(i => i.lang === this.$i18n.locale)
        const label = !translation || !translation.label ? item.translate.label : translation.label

        return {
          text: label,
          value: item.id
        }
      }).sort((a, b) => b.text - a.text)
    }
  },
  methods: {
    goBack () {
      this.changeStep(+this.step - 1)
    },
    onSubmit () {
      this.changeStep(+this.step + 1)
    },
    changeStep (step) {
      this.$emit('changeStep', this.employeeForm, step)
    },
    updateCharges (department) {
      chargesService.list(department)
        .then((res) => { this.charges = res.items })
    },
    updateDataForm () {
      this.employeeForm.departmentId = this.employee.departmentId
      this.employeeForm.jobTypeId = this.employee.jobTypeId
      this.employeeForm.admission = this.employee.admission
      this.employeeForm.chargeId = this.employee.chargeId
      this.employeeForm.additionalDemographic1Id = this.employee.additionalDemographic1Id
      this.employeeForm.additionalDemographic2Id = this.employee.additionalDemographic2Id
    }
  },
  created () {
    this.$emit('loading', true)
    resolver
      .all({
        charges: chargesService.list(),
        departments: departmentsService.list(),
        jobTypes: jobTypesService.list(),
        additionalDemographics1: additionalDemographics1Service.list(),
        additionalDemographics2: additionalDemographics2Service.list()
      })
      .then((res) => {
        this.charges = res.charges.items
        this.departments = res.departments.items
        this.jobTypes = res.jobTypes.items
        this.additionalDemographics1 = res.additionalDemographics1.items
        this.additionalDemographics2 = res.additionalDemographics2.items
        this.updateDataForm()
      })
      .finally(() => {
        this.$emit('loading', false)
      })
  }
}
</script>
