
<template>
  <v-container>
    <v-row justify="start">
      <v-col class="display-1">
        {{ $t('settings.products_title') }}
      </v-col>
    </v-row>
    <v-row style="min-height: 350px;" align="center">
      <v-col xs12 sm6 offset-sm3>
        <v-card>
          <v-list style="padding: 0px">
            <v-list-group
              v-model="product.isDeploy"
              v-for="product in products"
              :key="product.id"
              style="min-height: 6em; border: 0.01rem #ccc solid"
            >
              <v-list-item slot="activator">
                <v-list-item-content class="mt-2 pa-0">
                  <v-col class="pa-0">
                    <v-row class="align-center">
                      <v-col sm="2" class="pa-0">
                        <v-img contain
                          id="logo"
                          :src="product.logo || product.defaultLogo || ''"
                          :class="!product.name.includes('LIDERAZGO') ? 'my-1 ml-3' : 'mt-2 mb-1'"
                          :style="product.name.includes('LIDERAZGO') ?
                            'max-width:130px; height:92px;' :
                            'max-width:100px; height:auto;'
                          "
                        ></v-img>
                      </v-col>
                      <v-col sm="10">
                          <strong>{{ product.name }}</strong>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content class="mt-2 pa-0">
                  <v-col class="mx-2 pa-0">
                    <v-row class="light-blue lighten-5 mx-3 py-2" align="center">
                      <v-col cols="12" sm="9" style="color: #424242;">
                        <span><strong>{{ $t('settings.products_description') }}</strong></span>
                        <span style="color: #7E7E7E;">
                          {{
                            product.description[$i18n.locale]
                              ? product.description[$i18n.locale]
                              : product.description.es
                          }}
                        </span>
                      </v-col>
                      <v-col cols="4" sm="1" class="text-center" style="color: #424242;">
                        <v-tooltip bottom color="blue">
                          <template v-slot:activator="{ on }">
                            <div v-on="on">
                              <x-state-switch
                                style="padding: 0; margin: 0;"
                                :value="product.active"
                                :item="product"
                                :enable="{
                                  title: $t('settings.products_modal_enable'),
                                  action: enableProduct,
                                  color: 'success',
                                  btn_text: $t('input.enable'),
                                  question: $t('settings.products_modal_enable_des')
                                }"
                                :disable="{
                                  title: $t('settings.products_modal_disable'),
                                  action: disableProduct,
                                  color: 'error',
                                  btn_text: $t('input.disable'),
                                  question: $t('settings.products_modal_disable_des')
                                }"
                                :can-enable="product.integrated"
                                :alert="{
                                  title: $t('settings.products_active_disabled_title'),
                                  text: $t('settings.products_active_disabled'),
                                  btn_text: $t('input.cancel')
                                }"
                              />
                            </div>
                          </template>
                          <span>
                            {{ $t('settings.products_status') }}
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="4" sm="1" class="text-center" style="color: #424242;">
                        <v-tooltip bottom color="blue">
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" :to="`/settings/product-details/${product.id}/${product.name}`">
                              <v-icon large>mdi-pencil-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            {{ $t('settings.products_edit') }}
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="4" sm="1" class="text-center" style="color: #424242;">
                        <v-tooltip bottom color="blue">
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" :to="`/settings/products/${product.id}/configuration`">
                              <v-icon large>fa-cogs</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            {{ $t('settings.product_settings') }}
                          </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>

                    <v-divider class="mx-3 py-2"></v-divider>

                    <v-data-table
                      :headers="headers"
                      :items="product.productServices"
                      hide-default-footer
                      class="elevation-1"
                    >
                      <template v-slot:item.modality="{ item }">
                        {{ $t(`services.modalities.${item.modality}`) }}
                      </template>
                      <template v-slot:item.periocity="{ item }">
                        {{ $t(`services.periocities.${item.periocity}`) }}
                      </template>
                      <template v-slot:item.recomendation="{ item }">
                        {{ item.recomendation }}
                      </template>
                      <template v-slot:item.price="{ item }">
                        {{ item.tokenPrice }}
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom color="blue">
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" :to="`/settings/product/${product.id}/${product.name}/service-edit/${item.id}/${item.name}`">
                              <v-icon small>mdi-pencil-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            {{ $t('settings.service_edit') }}
                          </span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import productsService from '../../services/products'

const getDefaultHeader = (vue, value) => ({
  text: vue.$t('services.' + value),
  value,
  align: value === 'name' ? 'start' : 'center',
  sortable: false,
  filterable: false,
  divider: false
})

export default {
  data () {
    return {
      dialogAdd: false,
      products: [],
      headers: []
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    disableProduct (item) {
      return productsService.disable(item.id)
        .then(() => {
          item.active = false
        })
    },
    enableProduct (item) {
      return productsService.enable(item.id)
        .then(() => {
          item.active = true
        })
    },
    changeStatus (item) {
      item.active = !item.active
      productsService.updateStatus(item)
    }
  },
  created () {
    [
      'name',
      'modality',
      'periocity',
      'recomendation',
      'price',
      'actions'
    ].forEach(header => {
      this.headers.push(getDefaultHeader(this, header))
    })
    return productsService.lisWithServices()
      .then((res) => {
        this.products = res
        this.products.map(product => {
          product.isDeploy = false
        })
        this.lang = this.user.lang
        let pos = 1
        this.products.forEach(product => {
          let counter = 1
          product.itemPos = pos
          pos = pos + 1
          product.productServices.forEach(service => {
            service.pos = counter
            counter = counter + 1
          })
        })
      })
  }
}
</script>
