
export default {
  emails: 'Emails',
  operations_emails: 'Emails for notification of Token Operations',
  email: 'Email',
  active: 'Active',
  invalid_email: 'Invalid email',
  email_saved: 'Email saved successfully!',
  disabled: 'Disabled',
  modal_enable: 'Do you want to enable email?',
  modal_disable: 'Do you want to disable email?',
  email_required: 'Email is required',
  email_valid: 'Email must be valid',
  sentTo: 'Recipient',
  emails_list: 'Email list',
  date: 'Sent on',
  filter_by: 'Sort by',
  look_for: 'Find email',
  status: 'Status',
  subject: 'Subject',
  message: 'Message',
  pending: 'Pending',
  inProgress: 'In progress',
  success: 'Sent',
  failed: 'Failed',
  no_data: 'No mails to display',
  dateFrom: 'Date from',
  dateTo: 'Date from',
  help_dateFrom: 'Date from h',
  help_dateTo: 'Date until h'
}
