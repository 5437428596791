
import { IMenuGroup } from './interfaces/menu-group'
import { IMenuLeaf } from './interfaces/menu-leaf'

const baseGroups: Array<IMenuGroup | IMenuLeaf> = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: 'dashboard',
    hiddenFor: ['customer&enterpriseAdmin']
  },
  // DEMOGRAPHIC CUTS
  {
    title: 'demographic_cuts',
    icon: 'timeline',
    hiddenFor: ['commercialOrTemporalCommercialSession'],
    child: [
      {
        title: 'departments',
        path: '/settings/departments',
        icon: 'mdi-alpha-d-box-outline',
        permission: 'departments.settings'
      },
      {
        title: 'genders',
        path: '/settings/genders',
        icon: 'mdi-gender-male-female',
        permission: 'genders.settings'
      },
      {
        title: 'job_types',
        path: '/settings/job-types',
        icon: 'mdi-briefcase-check',
        permission: 'jobTypes.settings'
      },
      {
        title: 'charges',
        path: '/settings/charges',
        icon: 'mdi-worker',
        permission: 'charges.settings'
      },
      {
        title: 'academic_degrees',
        path: '/settings/academic-degrees',
        icon: 'mdi-book-multiple',
        permission: 'academicDegrees.settings'
      },
      {
        title: 'academic_degrees',
        path: '/settings/enterprise/academic-degrees',
        icon: 'mdi-book-multiple',
        permission: 'academicDegrees.read',
        hiddenFor: ['admin']
      },
      {
        title: 'countries',
        path: '/settings/countries',
        icon: 'mdi-map',
        permission: 'countries.settings'
      },
      {
        title: 'countries',
        path: '/settings/enterprise/countries',
        icon: 'mdi-map',
        permission: 'countries.read',
        hiddenFor: ['admin']
      },
      {
        title: 'headquarters',
        path: '/settings/headquarters',
        icon: 'mdi-map-marker-radius',
        permission: 'headquarters.settings'
      },
      {
        title: 'ages',
        path: '/settings/ages',
        icon: 'mdi-av-timer',
        hiddenFor: ['admin&employee']
      },
      {
        title: 'antiquity',
        path: '/settings/antiquity',
        icon: 'mdi-timer',
        hiddenFor: ['admin&employee']
      },
      {
        title: 'additional_demographics1',
        path: '/settings/additional-demographics1',
        icon: 'mdi-alpha-d-box-outline',
        permission: 'additionalDemographics1.settings'
      },
      {
        title: 'additional_demographics2',
        path: '/settings/additional-demographics2',
        icon: 'mdi-alpha-d-box-outline',
        permission: 'additionalDemographics2.settings'
      }
    ]
  },
  // EMPLOYEES
  {
    title: 'employees',
    path: '/employees',
    icon: 'mdi-account-group',
    permission: 'enterprises.profile',
    hiddenFor: ['onlyCustomerTemporalSession']
  },
  {
    title: 'employees',
    path: '/employees',
    icon: 'mdi-account-group',
    permission: 'employees.create',
    hiddenFor: ['commercial']
  },
  // TOOLS
  {
    title: 'measuring_tools',
    path: '/measuring-tools',
    icon: 'fa-sliders-h',
    permission: 'enterprises.profile',
    hiddenFor: ['onlyCustomerTemporalSession']
  },
  {
    title: 'measuring_tools',
    path: '/measuring-tools',
    icon: 'fa-sliders-h',
    permission: 'enterprises.profile',
    hiddenFor: ['admin&employee&commercial&enterpriseAdmin']
  },
  // ENTERPRISES
  {
    title: 'enterprises',
    path: '/enterprises',
    icon: 'mdi-briefcase',
    permission: 'enterprises.list',
    hiddenFor: ['admin&personal']
  },
  {
    title: 'enterprises',
    path: '/admin-enterprises',
    icon: 'mdi-briefcase',
    permission: 'enterprises.list',
    hiddenFor: ['admin&customer']
  },
  // PROFILE
  {
    title: 'profile',
    path: '/commercial-profile',
    icon: 'dashboard',
    hiddenFor: ['admin&employee&personal&enterpriseAdmin']
  },
  {
    title: 'profile_list',
    path: '/customer/show-profile',
    icon: 'dashboard',
    permission: 'enterprises.profile',
    hiddenFor: ['onlyCustomerTemporalSession']
  },
  {
    title: 'profile',
    icon: 'mdi-account-outline',
    path: '/setting/enterprise',
    hiddenFor: ['admin&employee&commercial&enterpriseAdmin'],
    permission: 'enterprises.profile'
  },
  // CUSTOMERS
  {
    title: 'customers',
    path: '/admin-customers',
    icon: 'fa-clipboard-list',
    permission: 'enterprises.list',
    hiddenFor: ['customer']
  },
  {
    title: 'group',
    path: '/groups/profile',
    icon: 'mdi-account-group',
    permission: 'groups.profile',
    hiddenFor: ['noGroupCustomer']
  },
  // OCC TOKENS
  {
    title: 'token',
    icon: 'fa-coins',
    hiddenFor: ['employee'],
    child: [
      {
        title: 'calculator',
        path: '/tokens/calculator-view',
        icon: 'mdi-calculator'
      },
      {
        title: 'operations',
        path: '/tokens/operations',
        icon: 'mdi-format-list-bulleted',
        permission: 'tokenAccounts.list'
      },
      {
        title: 'services',
        path: '/tokens/services',
        icon: 'mdi-tag-multiple'
      }
    ]
  },
  // SETTINGS
  {
    title: 'setting',
    icon: 'mdi-settings',
    child: [
      {
        title: 'products',
        path: '/settings/products',
        icon: 'mdi-briefcase-outline',
        permission: 'enterpriseSizes.settings'
      },
      {
        title: 'token_price',
        path: '/settings/tokens',
        icon: 'mdi-wallet',
        permission: 'enterpriseSizes.settings'
      },
      {
        title: 'currencies',
        path: '/settings/currencies',
        icon: 'mdi-cash-multiple',
        permission: 'currencies.list'
      },
      {
        title: 'operations_emails',
        path: '/settings/token-operations-emails',
        icon: 'mdi-email-outline',
        permission: 'enterpriseSizes.settings'
      },
      // {
      //   title: 'languages',
      //   path: '/settings/languages',
      //   icon: 'mdi-globe-model',
      //   permission: 'languages.settings'
      // },
      {
        title: 'enterprise_sizes',
        path: '/settings/enterprise-sizes',
        icon: 'mdi-cash-refund',
        permission: 'enterpriseSizes.settings'
      },
      {
        title: 'sectors',
        path: '/settings/sectors',
        icon: 'mdi-sitemap',
        permission: 'enterpriseSizes.settings'
      },
      {
        title: 'additional_segmentation',
        path: '/settings/additional-segmentation',
        icon: 'mdi-account-box-multiple',
        permission: 'enterprises.profile',
        hiddenFor: ['commercialOrTemporalCommercialSession']
      },
      {
        title: 'terms_and_conditions',
        path: '/settings/terms-and-conditions',
        icon: 'fa-list-alt',
        permission: 'terms.settings'
      },
      {
        title: 'emails',
        path: '/emails',
        icon: 'fa-envelope',
        permission: 'enterpriseSizes.settings'
      }
    ]
  }
]

export { baseGroups }
