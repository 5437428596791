
// import aclCondition from '../../utils/acl-condition'
import store from '../../store'

export default async (to: any, from: any, next: any) => {
  // if (aclCondition(store.state.acl.wolf)) {
  //   await store.dispatch('acl/getAcl')
  // }

  if (!store.state.session.token) {
    next('/auth/sign-in')
    return
  }

  next()
}
