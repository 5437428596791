
<template>
  <v-container>
    <v-layout wrap row>
      <v-flex xs12 mb-3 v-if="!user.lastActivity">
        <v-card flat>
          <v-alert
            :value="true"
            color="warning"
            icon="priority_high"
            outlined
          >
            {{ $t('enterprises.profile_warning') }}
          </v-alert>
        </v-card>
      </v-flex>

      <v-flex x12>
        <v-card>
          <v-card-title primary-title>
            <div>
              <h4 class="display-1">{{ enterprise.name }}</h4>
              <span class="subheading">
                {{
                  $t('enterprises.profile_title', {
                    size: translate(enterprise.enterpriseSize),
                    sector: translate(enterprise.sector),
                  })
                }}
              </span>
              <br />
            </div>
            <v-spacer></v-spacer>
            <v-btn
              :to="`/setting/enterprise/information/edit`"
              large
              color="primary"
            >{{ $t('input.edit') }}</v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-layout row wrap class="mt-4">
              <v-flex xs12 md6 class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-map-marker</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.address') }}</h6>
                    </v-list-item-title>
                    <v-list-item-sub-title class="grey--text subheading">
                      {{
                        $t('enterprises.profile_title_addres', {
                          contry: translate(enterprise.country),
                          address: enterprise.address,
                        })
                      }}
                    </v-list-item-sub-title>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>

              <v-flex xs12 md6 class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>language</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.language') }}</h6>
                    </v-list-item-title>
                    <v-list-item-sub-title
                      class="grey--text subheading"
                    >{{ enterprise.language | translate }}</v-list-item-sub-title>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>
            </v-layout>

            <v-divider class="my-3"></v-divider>
            <v-layout wrap row>
              <v-flex xs12 class="mb-3">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>account_box</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('enterprises.contact_employee') }}</h6>
                    </v-list-item-title>
                    <v-list-item-sub-title
                      class="grey--text subheading"
                    >{{ `${contact.firstName} ${contact.lastName}` }}</v-list-item-sub-title>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>

              <v-flex xs12 md6 class="mb-3">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-account-card-details</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.identify_document') }}</h6>
                    </v-list-item-title>
                    <v-list-item-sub-title
                      class="grey--text subheading"
                    >{{ contact.identifyDocument }}</v-list-item-sub-title>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>

              <v-flex xs12 md6 class="mb-3">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-email</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.email') }}</h6>
                    </v-list-item-title>
                    <v-list-item-sub-title class="grey--text subheading">{{ contact.email }}</v-list-item-sub-title>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>

              <v-flex xs12 md6 class="mb-3">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-phone</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.phone_number') }}</h6>
                    </v-list-item-title>
                    <v-list-item-sub-title class="grey--text subheading">{{ contact.phoneNumber }}</v-list-item-sub-title>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>

              <v-flex xs12 md6 class="mb-3">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-map-marker</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.address') }}</h6>
                    </v-list-item-title>
                    <v-list-item-sub-title class="grey--text subheading">{{ contact.address }}</v-list-item-sub-title>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>
            </v-layout>
            <v-divider class="my-3"></v-divider>

            <v-layout wrap row>
              <v-flex xs12 md4>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>list_alt</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('enterprises.active_plan') }}</h6>
                    </v-list-item-title>
                    <v-list-item-sub-title
                      class="grey--text subheading"
                      v-if="license && license.plan"
                    >{{ license.plan | translate }}</v-list-item-sub-title>
                    <v-list-item-sub-title
                      class="grey--text subheading"
                      v-else
                    >{{ $t('dashboard.expired_plan') }}</v-list-item-sub-title>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>

              <v-flex xs12 md4>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>date_range</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('enterprises.plan_expire_date') }}</h6>
                    </v-list-item-title>
                    <v-list-item-sub-title
                      class="grey--text subheading"
                    >{{ license && license.plan ? formatDate(license.endAt) : $t('dashboard.expired_plan') }}</v-list-item-sub-title>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>

              <v-flex xs12 md4 class="text-xs-right pr-3">
                <v-menu open-on-hover bottom offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn large color="primary" v-on="on" :disabled="!license || !license.plan">
                      <v-icon>update</v-icon>
                      &nbsp;{{ $t('input.plan_extend_info') }}
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item :to="'/enterprises/' + enterprise.id +'/extend-plan'">
                      <v-list-item-title>{{ $t('input.extend_plan') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="`/enterprises/${enterprise.id}/admin-extensions`">
                      <v-list-item-title>{{ $t('input.see_requests') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="'/enterprises/' + enterprise.id +'/extensions-history'">
                      <v-list-item-title>{{ $t('input.view_extension_history') }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-flex>

              <v-flex xs12 md4>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>poll</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('enterprises.active_engagements') }}</h6>
                    </v-list-item-title>
                    <v-list-item-sub-title
                      class="grey--text subheading"
                    >{{ license && license.plan ? license.engagementsQuantityLeft : 0 }}</v-list-item-sub-title>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>

              <v-flex xs12 md4>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-clipboard-pulse</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('enterprises.active_pulses') }}</h6>
                    </v-list-item-title>
                    <v-list-item-sub-title
                      class="grey--text subheading"
                    >{{ license && license.plan ? license.pulsesQuantityLeft : 0 }}</v-list-item-sub-title>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>
            </v-layout>
            <v-divider class="my-3"></v-divider>

            <v-layout row wrap class="mt-4">
              <v-flex xs12 sm10 class="mb-3 my-2">
                <v-list-item style="height: auto;">
                  <v-list-item-action>
                    <v-icon>art_track</v-icon>
                  </v-list-item-action>

                  <v-list-item-content style="height: auto;">
                    <v-list-item-title>
                      <h6 class="title">{{ $t('input.demographic_cuts') }}</h6>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>
              <v-flex xs12 sm2 class="text-xs-right pr-3">
                <v-btn
                  :to="`/setting/enterprise/items/edit`"
                  large
                  color="primary"
                >{{ $t('input.edit') }}</v-btn>
              </v-flex>
              <v-flex xs10>
                <div class="ml-4">
                  <template v-for="(item, $i) in enterprise.demographicItems">
                    <v-chip :key="$i">{{ item | translate }}</v-chip>
                  </template>
                </div>
              </v-flex>
            </v-layout>
            <v-divider class="my-3"></v-divider>

            <v-layout row wrap>
              <v-flex xs12 class="pb-3 pl-3">
                <v-btn
                  :href="helpUrl"
                  outlined
                  color="primary"
                  target="_blank"
                >{{ $t('go_to_manual') }}</v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="firstTime" persistent>
      <v-card>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex xs12 md6 class="offset-md3 text-xs-center grey--text text--darken-1 mt-1">
              <section class="enterprise-logo">
                <img :src="enterprise.logo" alt="Company logo" />
              </section>
              <h3 class="display-2 mt-2">{{ $t('enterprises.first_time_title') }}</h3>
              <v-layout row wrap class="mt-35 text-xs-left">
                <p class="display-1 mt-5" v-html="$t('enterprises.first_time_text')"></p>
                <v-flex xs12 v-for="i in [1, 2, 3]" :key="`step${i}`">
                  <div class="headline mt-3">
                    <strong>{{ $t(`enterprises.first_time_step`) }} {{ i }} </strong>
                    <span>{{ $t(`enterprises.first_time_step_${i}`) }}</span>
                  </div>
                </v-flex>
              </v-layout>
              <v-btn
                @click="closeFirstTime"
                class="mt-5"
                color="primary"
                large
              >{{ $t('input.close') }}</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import translate from '../../utils/translate'
import enterprisesServices from '../../services/enterprises'

export default {
  data () {
    return {
      helpUrl: process.env.VUE_APP_HELP_URL,
      enterprise: {
        logo: ''
      },
      contact: {},
      firstTime: false,
      license: {
        plan: {}
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    })
  },
  methods: {
    translate,
    closeFirstTime () {
      if (window.history.replaceState) {
        window.history.replaceState(window.history.state, '', '/setting/enterprise')
        this.firstTime = false
      } else {
        this.$router.push('/setting/enterprise')
      }
    },
    formatDate (val) {
      return this.$filter(val, { date: true, hour: false })
    }
  },
  created () {
    this.firstTime = this.$route.params.first && !this.user.lastActivity
    enterprisesServices.getProfile().then((res) => {
      this.enterprise = res
      this.contact = this.enterprise.employeeContact || {}
      this.license = this.enterprise.licenses[0]
      if (this.firstTime) {
        this.$nextTick(() => {
          this.enterprise.logo = !this.enterprise.logo ? 'img/20200301_occ_solution_logo.png' : this.enterprise.logo
        })
      }
    })
  }
}
</script>
