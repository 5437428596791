
<template>
  <v-container fluid >
    <x-loading
      :display="loading || loadingPersonal || loadingEmployment"
    ></x-loading>

    <v-alert
      v-if="massiveLoadActive"
      elevation="11"
      type="warning"
    >
      <v-row align="center">
        <v-col class="grow">
          {{ $t('employees.massive_load_in_progress') }}
        </v-col>
        <v-col class="shrink">
          <v-btn @click="$router.back()">Regresar</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <ValidationObserver v-else ref="form">
      <v-form
        @submit.prevent="onSubmit"
      >
        <v-tabs
          v-model="step"
          class="elevation-2"
          centered
          grow
          light
        >

          <v-tabs-slider></v-tabs-slider>
          <v-tab
            href="#1"
            v-if="isVisible('1')"
          >
            {{ $t('employees.personal_data') }}
          </v-tab>
          <v-tab
            href="#2"
            v-if="isVisible('2')"
          >
            {{ $t('employees.employment_data') }}
          </v-tab>

          <v-tab-item
            value="1"
          >
            <ValidationObserver ref="step1">
              <x-step-personal-data
                :employee="employee"
                :fields-required="fieldsRequired"
                step="1"
                nextAction="input.next"
                prevAction="input.cancel"
                @changeStep="verifyStepChanged"
                @loading="($event) => { this.loadingPersonal = $event }"
              ></x-step-personal-data>
            </ValidationObserver>
          </v-tab-item>

          <v-tab-item
            value="2"
          >
            <ValidationObserver ref="step2">
              <x-step-employment-data
                :employee="employee"
                :fields-required="fieldsRequired"
                step="2"
                nextAction="input.save"
                prevAction="input.back"
                @changeStep="verifyStepChanged"
                @loading="($event) => { this.loadingEmployment = $event }"
              ></x-step-employment-data>
            </ValidationObserver>
          </v-tab-item>
        </v-tabs>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>

import Vue from 'vue'

import resolver from '../../utils/resolver'
import XStepPersonalData from './steps/personal-data.vue'
import XStepEmploymentData from './steps/employment-data.vue'

import employeesService from '../../services/employees'
import enterpriseService from '../../services/enterprises'

export default Vue.extend({
  components: {
    XStepPersonalData,
    XStepEmploymentData
  },
  data () {
    return {
      tab: null,
      loading: false,
      loadingPersonal: false,
      loadingEmployment: false,
      employee: {
        academicDegreeId: '',
        countryId: '',
        headquarterId: '',
        chargeId: '',
        departmentId: '',
        genderId: '',
        jobTypeId: '',
        identifyDocument: '',
        identifyTypeId: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        birthdate: '',
        admission: ''
      },
      fieldsRequired: {
        academicDegreeId: '',
        countryId: '',
        headquarterId: '',
        chargeId: '',
        departmentId: '',
        genderId: '',
        jobTypeId: '',
        birthdate: 'dateBefore',
        admission: 'dateBefore'
      },
      step: '1',
      massiveLoadActive: false
    }
  },
  methods: {
    onSubmit (step) {
      if (step && step < this.step) {
        this.step = step.toString()
        return
      }
      if (this.step === '1') {
        this.$refs.step1.validate().then(success => {
          if (!success) {
            const error = this.$t('errors.validator/invalid-input')
            this.$store.dispatch('alert/error', error)
            return
          }
          this.step = step.toString()
        })
      } else {
        this.$refs.form.validate().then(success => {
          if (!success) {
            const error = this.$t('errors.validator/invalid-input')
            this.$store.dispatch('alert/error', error)
            return
          }
          return this.edit()
        })
      }
    },
    verifyStepChanged (data, step) {
      if (step === 0) {
        return this.$router.push('/employees')
      }
      this.employee = Object.assign({}, this.employee, data)
      this.onSubmit(step)
    },
    edit () {
      this.employee.additionalDemographic1Id = this.employee.additionalDemographic1Id || null
      this.employee.additionalDemographic2Id = this.employee.additionalDemographic2Id || null
      this.loading = true
      return employeesService.update(this.$route.params.id, this.employee)
        .then(() => {
          this.$store.dispatch('alert/success', this.$t('employees.updated_employee'))
          this.$router.go(-1)
        })
        .catch((err) => {
          const error = this.$t(`errors.${err.code}`)
          this.$store.dispatch('alert/error', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    reponseParse (res) {
      delete res.item.email
      this.employee = res.item
      this.employee.email = res.item.employee.email
      this.employee.birthdate = this.getDateOfDatetime(res.item.birthdate)
      this.employee.admission = this.getDateOfDatetime(res.item.admission)
      delete this.employee.country
      delete this.employee.department
      delete this.employee.enterprise
      delete this.employee.gender
      delete this.employee.jobType
    },
    getDateOfDatetime (date) {
      return date ? date.split('T').shift() : ''
    },
    isVisible (step) {
      return (step === this.step || !this.$vuetify.breakpoint.xsOnly)
    }
  },
  created () {
    this.loading = true
    resolver
      .all({
        employee: employeesService.getOneById(this.$route.params.id),
        demographicsItems: enterpriseService.getDemographicsItems(),
        massiveLoad: employeesService.massiveFindActual()
      })
      .then((res) => {
        this.massiveLoadActive = Boolean(res.massiveLoad)

        this.reponseParse(res.employee)
        res.demographicsItems.forEach(item => {
          const associated = JSON.parse(item.demographicItem.associated)
          const field = associated.field.split('_').map((f, i) => {
            if (!i) {
              return f
            }

            f = f.trim()
            const lowerCase = f.slice(1).toLowerCase()
            return f.charAt(0)
              .toUpperCase()
              .concat(lowerCase)
          }).join('')

          this.fieldsRequired[field] = 'required' + (this.fieldsRequired[field] ? '|' + this.fieldsRequired[field] : '')
        })
      })
      .finally(() => {
        this.loading = false
      })
  }
})
</script>
