
export default {
  name: 'dateBetween',
  definition: {
    message: (field: string) => `${field} debe estar comprendida entre las fechas principales.`,
    validate: (value: any, param: any) => {
      const date = new Date(`${value} ${param[2]}:00:00`).getTime()
      if (param.length !== 0) {
        return date > param[0] && date < param[1]
      } else {
        return value >= new Date().toISOString().split('T')[0]
      }
    }
  }
}
