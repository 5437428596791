
import Services from './base-services'

const service = new Services('employees-number')

export default {
  list: () => {
    return service.get('list')
  },
  listOutside: () => {
    return service.bGet('auth/list-employees-number')
  }
}
