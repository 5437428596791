
<template>
  <v-container fluid >
    <x-loading v-if="loading" :display="true"/>
    <v-row row wrap v-else>
      <v-col cols="12" sm="9" md="8" class="pt-4 pl-4">
        <h4 class="display-1">{{ $t('settings.additional_segmentation_head') }}</h4>
      </v-col>
      <v-col cols="12" sm="3" md="4" class="text-right">
        <v-btn large
          color="primary"
          @click="createModal = true"
        >
          <v-icon left>mdi-plus-circle-outline</v-icon>
          {{ $t('settings.add') }}
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-subtitle class="mt-1 px-5 text-justify"
            v-html="$t('settings.additional_segmentation_description')"
          ></v-card-subtitle>

          <v-divider></v-divider>

          <v-card-text v-if="!loading && segmentations.length" class="px-0 pt-0">
            <v-col>
              <x-info :text="$t('settings.additional_segmentation_info')"></x-info>
            </v-col>

            <v-row row wrap class="mt-3 px-3">
              <!-- Headers -->
              <v-col cols="10" class="text-center pr-0">
                <v-card flat>
                  <v-card-text class="text-center font-weight-black px-0 mb-2">
                    {{ $t('settings.additional_segmentation_table_heads') }}
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card>
              </v-col>
              <v-col cols="2" class="text-center pl-6">
                <v-card flat>
                  <v-card-text class="text-center font-weight-black px-0 mb-2">
                    {{ $t('settings.additional_segmentation_table_actions') }}
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card>
              </v-col>
            </v-row>

            <v-data-iterator
              :items="segmentations"
              :items-per-page="10"
              :options.sync="pagination"
              :footer-props="footerProps"
              row wrap
            >
              <template v-slot:item="as">
                <v-form ref="form-1" name="form-1"
                  :key="as.item.id"
                  data-vv-scope="form-1"
                  @submit.prevent
                >
                  <!-- Segmentations -->
                  <v-row row wrap no-gutters class="mt-1 mb-6" style="max-height: 3.5em;">

                    <v-col cols="10" class="py-0 pl-3">
                      <v-tooltip bottom color="blue">
                        <template v-slot:activator="{ on }" >
                          <ValidationProvider rules="required" v-slot="{ errors }" :name="' '">
                            <v-text-field outlined
                              v-model="as.item.trans[user.lang].label"
                              v-on="on"
                              :placeholder="$t('settings.additional_segmentation_head')"
                              :color="!as.item.active ? 'grey lighten-2' : 'primary'"
                              :background-color="!as.item.active ? 'grey lighten-2' : ''"
                              :disabled="!as.item.active"
                              :error="!!errors[0]"
                              @keypress.enter="validateName(as.item)"
                            ></v-text-field>
                          </ValidationProvider>
                        </template>
                        {{ $t('settings.tooltip_edit') }}
                      </v-tooltip>
                    </v-col>

                    <v-col cols="2" class="text-center pt-2 pb-0 pl-2">
                      <!-- Details/Options -->
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon
                            v-on="on"
                            color="warning"
                            @click="editSegmentationDetails(as.item)"
                          >
                            <v-icon size="19">fa-edit</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('settings.additional_segmentation_options') }}</span>
                      </v-tooltip>

                      <!-- Disable -->
                      <v-tooltip bottom v-if="as.item.active">
                        <template v-slot:activator="{ on }">
                          <v-btn icon
                            @click="handleToggleActive(as.item, false)"
                            v-on="on"
                            color="primary"
                          >
                            <v-icon medium>mdi-eye-off</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('settings.additional_segmentation_disable') }}</span>
                      </v-tooltip>

                      <!-- Enable -->
                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on }">
                          <v-btn icon
                            v-on="on"
                            color="success"
                            :disabled="!as.item.details.length"
                            @click="handleToggleActive(as.item, true)"
                          >
                            <v-icon medium>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('settings.additional_segmentation_enable') }}</span>
                      </v-tooltip>

                      <!-- Delete -->
                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on }">
                          <v-btn icon
                            v-on="on"
                            color="red"
                            @click="handleDelete(as.item.id)"
                          >
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('input.trash') }}</span>
                      </v-tooltip>

                    </v-col>
                  </v-row>
                </v-form>
              </template>
              <template v-slot:footer.page-text="props">
                {{ props.pageStart }}-{{ props.pageStop }} {{ $t('page_text') }} {{ props.itemsLength }}
              </template>
            </v-data-iterator>
          </v-card-text>

          <v-card-text v-else>
            <v-row row wrap>
              <v-col cols="12" class="text-center pb-4">
                <h5 class="headline grey--text">{{ $t('settings.additional_segmentation_no_data') }}</h5>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Enable Modal -->
    <x-confirmation-modal
      :show.sync="toggleTrueModal"
      :reversible="true"
      :title="$t('settings.additional_segmentation_toggle_false_title')"
      :action="toggleActive"
      @close="toggleTrueModal = false"
      :btn-save="$t('input.enable')"
      color="success"
    >
      <template v-slot:question>{{ $t('settings.additional_segmentation_enable_des') }}</template>
    </x-confirmation-modal>

    <!-- Disable Modal -->
    <x-confirmation-modal
      :show.sync="toggleFalseModal"
      :reversible="true"
      :title="$t('settings.additional_segmentation_toggle_true_title')"
      :action="toggleActive"
      @close="toggleFalseModal = false"
      :btn-save="$t('input.disable')"
      color="error"
    >
      <template v-slot:question>{{ $t('settings.additional_segmentation_disable_des') }}</template>
    </x-confirmation-modal>

    <!-- Delete Modal -->
    <x-confirmation-modal
      :show.sync="deleteModal"
      :title="$t('settings.additional_segmentation_delete_title')"
      :action="deleteSegmentation"
      :btn-save="$t('input.trash')"
      color="error"
      @close="deleteModal = false"
    >
      <template v-slot:question>{{ $t('settings.additional_segmentation_delete_des') }}</template>
    </x-confirmation-modal>

    <!-- Create Modal -->
    <v-dialog v-model="createModal" width="500">
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-form ref="form-2"
          @submit.prevent="handleSubmit(createSegmentation)"
        >
        <v-card>
          <v-card-title class="headline" primary-title>
            {{ $t('settings.additional_segmentation_add') }}
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <v-container fluid>
              <v-row row wrap>
                <v-col cols="6" v-for="(lang, i) in resLanguages" :key ="lang.id">
                  <x-inputs-input outlined
                    :input="newSegmentation.name[i].value"
                    @updateInput="($event) => newSegmentation.name[i].value = $event"
                    :label="lang.code !== user.lang
                      ? `${lang.translate.label} (${$t('settings.optional')})`
                      : lang.translate.label
                    "
                    :name="'name' + lang.id"
                  ></x-inputs-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              @click="createModal = false"
            >
              {{ $t('input.cancel') }}
            </v-btn>

            <v-btn type="submit"
              color="primary"
              :disabled="computedDisabledNewSegmentation"
            >
              {{ $t('input.save') }}
            </v-btn>
          </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>

    <!-- Details Modal -->
    <x-details-modal
      :open-modal="openDetailsModal"
      :editing-details="editingDetails"
      :languages="languages"
      :lang="language"
      @closeModal="($event) => openDetailsModal = $event"
      @refreshList="fetchData()"
    />
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import resolver from '../../utils/resolver'
import additionalSegmentationService from '../../services/additional-segmentation'
import languagesService from '../../services/languages'

import XDetailsModal from './components/additional-segmentation-details'

export default {
  components: {
    XDetailsModal
  },
  data () {
    return {
      // Control variables
      loading: false,
      validOne: false,
      validTwo: false,
      deleteModal: false,
      toggleTrueModal: false,
      toggleFalseModal: false,
      createModal: false,
      openDetailsModal: false,
      editingDetails: {},
      // Data Iterator Pagination
      pagination: {},
      // Data
      segmentations: [],
      originalSegmentations: [],
      newSegmentation: {
        enterpriseId: '',
        name: []
      },
      resLanguages: null,
      languages: [],
      language: '',
      toggleable: {},
      deleteable: {}
    }
  },
  created () {
    this.fetchData()
    this.newSegmentation.enterpriseId = this.user.enterprise.id
  },
  computed: {
    computedDisabledNewSegmentation () {
      return this.newSegmentation.name.find(x => x.code === this.user.lang).value === ''
    },
    footerProps () {
      return {
        'items-per-page-text': this.$t('results_per_pages'),
        'items-per-page-options': [5, 10, 25, { 'text': this.$t('page_all'), 'value': -1 }]
      }
    },
    ...mapState({
      user: state => state.session.user
    })
  },
  watch: {
    openDetailsModal (isOpen) {
      if (!isOpen) {
        this.editingDetails = {}
      }
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      resolver
        .all({
          segmentations: additionalSegmentationService.listByEnterprise(),
          languages: languagesService.list()
        })
        .then(({ segmentations, languages }) => {
          this.originalSegmentations = JSON.parse(JSON.stringify(segmentations.items))
          this.languages = []
          this.resLanguages = languages.items
          this.mapItems(segmentations.items)
          this.language = this.user.lang
        })
        .catch((error) => {
          console.log(error)
          this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
        })
        .finally(() => {
          this.loading = false
        })
    },
    mapItems (items) {
      this.resLanguages.forEach(language => {
        this.languages.push({
          value: language.code,
          text: language.translate.label
        })

        this.newSegmentation.name.push({
          code: language.code,
          value: ''
        })

        this.segmentations = items.map(item => {
          item.trans = item.trans ? item.trans : {}
          item.trans[language.code] = item.translations.find(
            lang => lang.lang === language.code
          ) || { label: '' }

          // Segmentation details/options
          item.details.map(detail => {
            detail.trans = detail.trans ? detail.trans : {}
            detail.trans[language.code] = detail.translations.find(
              lang => lang.lang === language.code
            ) || { label: '' }
          })

          return item
        })
      })
    },
    validateName (as) {
      const oldSegmentation = this.originalSegmentations.find(c => c.id === as.id)
      const oldLabel = oldSegmentation.translations.find(t => t.lang === this.user.lang).label
      const newLabel = as.trans[this.user.lang].label
      if (newLabel && newLabel !== oldLabel) {
        this.updateSegmentation(as)
      }
    },
    updateSegmentation (as) {
      const originalValue = this.originalSegmentations.find((x) => x.id === as.id)
      const originalValueTs = originalValue.translations.filter((l) => l.lang === this.language)
      const originalValueLabel = originalValueTs[0].label
      const payload = {
        label: as.trans[this.user.lang].label,
        lang: this.user.lang
      }

      additionalSegmentationService.update(as.id, payload)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('settings.additional_segmentation_updated'))
          const oldSegmentation = this.originalSegmentations.find(c => c.id === as.id)
          const oldTranslation = oldSegmentation.translations.find(t => t.lang === this.user.lang)
          const newLabel = as.trans[this.user.lang].label
          oldTranslation.label = newLabel
        })
        .catch((err) => {
          if (err.code && err.code === 'already_exists') {
            as.trans[this.language].label = originalValueLabel
            this.$store.dispatch('alert/error',
              this.$t('settings.demographic_already_exists', [this.$t('settings.additional_segmentation_head')])
            )
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
        })
    },
    handleToggleActive (as, active) {
      active ? this.toggleTrueModal = true : this.toggleFalseModal = true
      this.toggleable = { as, active }
    },
    toggleActive () {
      const { as, active } = this.toggleable

      return additionalSegmentationService.toggleActive(as.id, active)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('settings.additional_segmentation_updated'))
          as.active = !as.active
          active ? this.toggleTrueModal = false : this.toggleFalseModal = false
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    handleDelete (id) {
      this.deleteModal = true
      this.deleteable = { id }
    },
    deleteSegmentation () {
      const { id } = this.deleteable

      return additionalSegmentationService.delete(id)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('settings.additional_segmentation_deleted'))
          this.segmentations = this.segmentations.filter((h) => h.id !== id)
          this.deleteModal = false
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    createSegmentation () {
      this.loading = true
      additionalSegmentationService.create(this.newSegmentation)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('settings.additional_segmentation_created'))
          this.newSegmentation.name = []
          this.fetchData()
          this.createModal = false
        })
        .catch((err) => {
          if (err.code && err.code === 'already_exists') {
            this.$store.dispatch('alert/error',
              this.$t('settings.demographic_already_exists', [this.$t('settings.additional_segmentation_head')])
            )
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    editSegmentationDetails (as) {
      this.editingDetails = JSON.parse(JSON.stringify(as))
      this.openDetailsModal = true
    }
  }
}
</script>
