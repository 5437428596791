
<template>
  <v-container fluid>
    <v-row wrap row>
      <v-col cols="12" sm="4" md="6" lg="7">
        <h4 class="display-1">{{ $t('tokens.tokens_list') }}</h4>
      </v-col>

      <v-col cols="12" sm="8" md="6" lg="5">
        <v-card class="d-flex flex-column justify-end" v-if="hasBalance">
          <v-row wrap row>
            <v-col cols="4" justify="center" class="pl-5" v-if="canBuy">
              <h5 class="caption font-weight-light text-center text-uppercase">{{ $t('tokens.balance') }}</h5>
              <h5 class="headline font-weight-black text-center">{{ balance }}</h5>
            </v-col>
            <v-col cols="12" justify="center" class="pl-5" v-else>
              <h5 class="caption font-weight-light text-center text-uppercase">{{ $t('tokens.balance') }}</h5>
              <h5 class="headline font-weight-black text-center">{{ balance }}</h5>
            </v-col>
            <v-col cols="8" class="py-2" v-if="canBuy">
              <v-row justify="center">
                <v-col cols="8" class="text-right">
                  <v-btn color="blue darken-1" dark @click="dialogAdd = true">
                    {{ $t('tokens.acquire') }}
                  </v-btn>
                  <v-dialog v-model="dialogAdd" persistent max-width="600">
                    <v-card>
                      <v-card-title class="d-flex justify-end pr-1 pt-1">
                        <v-btn large icon @click="resetEntry()">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-row class="d-flex justify-space-around mx-12">
                          <v-col xs="12" sm="6">
                            <v-card class="d-flex justify-space-around text-center" color="blue-grey lighten-5">
                              <v-col cols="12">
                                <h4 class="subtitle-1 font-weight-black text-uppercase">{{ $t('tokens.tokens_list') }}</h4>
                                <div class="my-6">
                                  <x-inputs-token
                                    :input="inputValue"
                                    :min="1"
                                    :max="9999999"
                                    @updateInput="($event) => inputValue = $event"
                                  />
                                </div>
                              </v-col>
                            </v-card>
                          </v-col>
                          <v-col xs="12" sm="6">
                            <v-card class="text-center" color="blue-grey lighten-5">
                              <v-col class="subtitle-1 font-weight-black">
                                <h4 class="subtitle-1 font-weight-black text-uppercase">{{ $t('tokens.cost') }}</h4>
                                <div class="my-7">{{ currency.currencySymbol }} {{ outputByCurrency }}</div>
                              </v-col>
                            </v-card>
                          </v-col>
                          <v-col xs="12" sm="6">
                            <v-card class="text-center" color="blue-grey lighten-5">
                              <v-col class="subtitle-1 font-weight-black">
                                <h4 class="subtitle-1 font-weight-black text-uppercase">{{ $t('tokens.iva') }}</h4>
                                <div class="my-7">{{ currency.currencySymbol }} {{ ivaByCurrency }}</div>
                              </v-col>
                            </v-card>
                          </v-col>
                          <v-col xs="12" sm="6">
                            <v-card class="text-center" color="blue-grey lighten-5">
                              <v-col class="subtitle-1 font-weight-black">
                                <h4 class="subtitle-1 font-weight-black text-uppercase">{{ $t('tokens.total_amount') }}</h4>
                                <div class="my-7">{{ currency.currencySymbol }} {{ totalToPayByCurrency }}</div>
                              </v-col>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="d-flex justify-center pb-5">
                        <v-btn color="blue-grey lighten-2" class="text-capitalize" dark @click="resetEntry">{{ $t('input.cancel') }}</v-btn>
                        <v-btn color="blue darken-1" class="text-capitalize" dark @click="acquire">{{ $t('tokens.make_payment') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>

                <v-col cols="4">
                  <v-tooltip bottom color="blue">
                    <template v-slot:activator="{ on }">
                      <v-btn color="blue darken-1" icon v-on="on" @click="dialogCalculate = true">
                        <v-icon large>mdi-calculator</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('tokens.calculator') }}
                    </span>
                  </v-tooltip>
                  <v-dialog v-model="dialogCalculate" persistent max-width="1200px">
                    <v-card>
                      <v-card-title>
                        <v-row>
                          <v-col class="title pl-6 col-md-10 col-10 py-0 text-uppercase">
                            {{ $t('tokens.calculator') }}
                          </v-col>
                          <v-col class="d-flex justify-end pa-0">
                            <v-btn icon class="mr-6" @click="dialogCalculate = false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-divider class="mx-10"></v-divider>
                      <modal-calculator @refresh="($event) => { getBalance(); seeAllEnterprises() }"></modal-calculator>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row wrap row>
      <v-col xs12>
        <v-divider></v-divider>

        <v-card>
          <v-card-title>
            <v-row class="px-12">
              <v-col cols="12" sm="5" class="pt-6">
                <div class="title text-uppercase">{{ $t('tokens.operations') }}</div>
              </v-col>
              <v-col class="12" sm="7">
                <v-text-field
                  v-model.lazy="search"
                  append-icon="mdi-magnify"
                  :label="(this.hasEnterprises && this.seeAll) || (this.user.role === 'admin' && this.userTokenAccount.id === 1) ? $t('tokens.look_for_mix') : $t('tokens.look_for')"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>

          <v-divider class="my-3"></v-divider>

          <v-row class="px-12">
            <v-col cols="12" sm="6" class="d-flex justify-center" v-if="hasEnterprises">
              <v-switch :label="$t('tokens.see_all_companies')" v-model="seeAll" @change="seeAllEnterprises"></v-switch>
            </v-col>
            <v-col cols="12" sm="6" v-if="hasEnterprises">
              <v-autocomplete
                :label="$t('tokens.filter_by')"
                :items="filters"
                clearable
                prepend-inner-icon="mdi-filter-outline"
                @change="filterItems"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" v-else>
              <v-autocomplete
                :label="$t('tokens.filter_by')"
                :items="filters"
                clearable
                prepend-inner-icon="mdi-filter-outline"
                @change="filterItems"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <x-data-table
            :headers="headers"
            :parent-fetch-data="getTokenAccountDetails"
            :options="options"
            align="center"
            no-data="tokens.no_data"
          >
            <template v-slot:structure="prop">
              <td class="text-center">{{ prop.item.movementDate | date({date: true, hour:false}) }}</td>
              <td class="text-center">{{ operationFormat(prop.item.id.toString()) }}</td>
              <td class="text-center">
                <strong class="title"> {{ getOwnerDescription(prop.item) }} </strong>
                <span class="subtitle-1">{{ descriptionFormat(prop.item) }}</span>
              </td>
              <td class="text-center">{{ prop.item.mount }}</td>
              <td class="text-center px-0">
                <v-tooltip bottom color="blue">
                  <template v-slot:activator="{ on }" v-slot:item.action="{ prop }">
                    <v-btn color="blue darken-1" icon v-on="on" @click="selectItem(prop.item)">
                      <v-icon larger>remove_red_eye</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('input.view_details') }}
                  </span>
                </v-tooltip>
              </td>
            </template>
          </x-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent
      v-model="dialogDetails"
      :retain-focus="false"
      :max-width="selectedItem && selectedItem.description && hasUser(selectedItem) ? '1000' : '800'"
    >
      <v-card>
        <v-card-title class="subtitle-1 font-weight-black text-uppercase pa-4 pt-5">
          {{ $t('tokens.operation_details') }}
        </v-card-title>
        <v-simple-table class="px-3">
          <template v-slot:default>
            <thead class="tHeadColor">
              <tr>
                <th class="text-center text-uppercase" scope="col">{{ $t('tokens.transaction_number') }}</th>
                <th class="text-center text-uppercase" v-if="hasUser(selectedItem)" scope="col">
                  {{ $t('tokens.user') }}
                </th>
                <th class="text-center text-uppercase" v-if="hasMeasuringTool(selectedItem)" scope="col">
                  {{ $t('tokens.tool') }}
                </th>
                <th class="text-center text-uppercase" scope="col">{{ $t('input.details') }}</th>
                <th class="text-center text-uppercase" scope="col">{{ $t('tokens.total_amount') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center text-uppercase">
                  {{ operationFormat(selectedItem.id.toString()) }}
                </td>
                <td class="text-center text-lowercase" v-if="hasUser(selectedItem)">
                  {{ getUserEmail(selectedItem) }}
                </td>
                <td class="text-center text-uppercase" v-if="hasMeasuringTool(selectedItem)">
                  {{ measuringToolFormat(selectedItem) }}
                </td>
                <td class="text-center text-uppercase pb-2">
                  <strong class="title"> {{ getOwnerDescription(selectedItem) }} </strong>
                  {{ descriptionFormat(selectedItem) }}
                </td>
                <td class="text-center text-uppercase">
                  {{ selectedItem.mount }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn dark
            color="blue darken-1"
            @click="dialogDetails = false"
          >
            {{ $t('input.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showPendingTransactionAlert" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ $t('tokens.pending_transaction_title') }}
        </v-card-title>

        <v-card-text>
          {{ $t('tokens.pending_transaction_text') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="acceptedPendingTransactionAlert = true; showPendingTransactionAlert = false">
            {{ $t('input.accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <x-loading
      :display="loading"
    ></x-loading>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import tokenAccountService from '../../services/token-account'
import tokenAccountDetailService from '../../services/token-account-detail'
import tokenAuthorizationService from '../../services/token-authorization'
import tokenPriceService from '../../services/token-price'
import productService from '../../services/products'
import productServicesService from '../../services/product-services'
import ModalCalculator from './calculator'
import payment from '@/mixins/payment'

export default {
  mixins: [payment],
  components: {
    ModalCalculator
  },
  data () {
    return {
      loading: false,
      hasBalance: false,
      canBuy: false,
      inputValue: 0,
      userTokenAccount: {},
      tokenAuthorizationsList: [],
      temporal: [],
      currentTokenPrices: [],
      productList: [],
      productServiceList: [],
      balance: 0,
      seeAll: false,
      hasEnterprises: false,
      dialogAdd: false,
      dialogDetails: false,
      dialogCalculate: false,
      headers: [
        'tokens.date',
        'tokens.transaction_number',
        'tokens.operation_detail',
        'tokens.token_qty',
        'tokens.actions'
      ],
      operations: [
        'tokens.date',
        'tokens.transaction_number',
        'tokens.operation_detail',
        'tokens.token_qty',
        'tokens.actions'
      ],
      selectedItem: {
        id: 0,
        description: '',
        mount: 0
      },
      options: {
        filter: null,
        search: null
      },
      search: null,
      timer: null,
      currentTransactionId: null
    }
  },
  mounted () {
    this.$store.dispatch('session/showSelectorCurrency', true)
  },
  beforeDestroy () {
    this.$store.dispatch('session/showSelectorCurrency', false)
  },
  computed: {
    filters () {
      const translate = (option) => this.$t(`tokens.${option}`)
      return [
        { text: translate('consumer_transactions'), value: 'spend' },
        { text: translate('authorization_transactions'), value: 'authorization' },
        { text: translate('movements_between_accounts'), value: 'move' },
        { text: translate('purchase'), value: 'buy' }
      ]
    },
    outputValue () {
      const input = Number(this.inputValue)

      for (const price of this.currentTokenPrices) {
        if (input >= price.minRange && (input <= price.maxRange || price.maxRange === 0)) {
          return Math.round((input * price.price) * 100) / 100
        }
      }
      return 0
    },
    outputByCurrency () {
      return Math.round((this.outputValue * this.currency.value) * 100) / 100
    },
    iva () {
      return Math.round(this.outputValue * 0.19 * 100) / 100
    },
    ivaByCurrency () {
      return Math.round((this.iva * this.currency.value) * 100) / 100
    },
    totalToPay () {
      return Math.round((this.outputValue + this.iva) * 100) / 100
    },
    totalToPayByCurrency () {
      return Math.round((this.totalToPay * this.currency.value) * 100) / 100
    },
    ...mapState({
      user: (state) => state.session.user,
      currency: state => state.session.currency
    })
  },
  methods: {
    filterItems (value) {
      this.options = {
        ...this.options,
        filter: value
      }
    },
    operationFormat (inputOp) {
      let res = inputOp.padStart(10, '0')
      if (res.length > 10) {
        res = `0${res}`
      }
      return res
    },
    hasMeasuringTool (item) {
      return item.type === 'spend' || item.type === 'authorizationSpend'
    },
    measuringToolFormat (item) {
      if (item.productId) {
        const product = this.productList.find(product => product.id === item.productId)
        if (product) {
          return product.name
        }
      }
      return '----'
    },
    hasUser (item) {
      const desc = JSON.parse(item.description)
      return desc.hasOwnProperty('user')
    },
    getUserEmail (item) {
      const desc = JSON.parse(item.description)
      return desc.user.email
    },
    getOwnerDescription (item) {
      if (item.type === 'moveDown' && this.user.role === 'admin' && this.userTokenAccount.id === 1) {
        return this.getNameDescription(item.fromTokenAccountId)
      }
      if (item.type === 'moveDown' || item.type === 'authorizationDown') {
        return ''
      }
      const withNames = (this.hasEnterprises && this.seeAll) || (this.user.role === 'admin' && this.userTokenAccount.id === 1)

      if (withNames) {
        if (item.type === 'moveUp' || item.type === 'authorizationUp') {
          return item.toTokenAccountId === this.userTokenAccount.id ? '' : this.getNameDescription(item.toTokenAccountId)
        } else if (item.type === 'spend' || item.type === 'authorizationSpend') {
          return this.getNameDescription(item.fromTokenAccountId)
        }
        if (this.userTokenAccount.id !== item.toTokenAccountId) {
          return this.getNameDescription(item.toTokenAccountId)
        }
      }
      return ''
    },
    getNameDescription (tokenAccountId) {
      const searchName = this.tokenAuthorizationsList.find(tokenAuthorization => tokenAuthorization.id === tokenAccountId)
      if (searchName) {
        return searchName.name
      } else {
        return tokenAccountId === 1 ? this.$t('tokens.administrator') : this.$t('tokens.group')
      }
    },
    getSpendDescription (serviceId, qty, related, isAuthorized) {
      const service = this.productServiceList.find(service => service.id === serviceId)
      let result = ''

      if (this.user.customer && this.user.customer.type === 'personal') {
        isAuthorized = false
      }
      if (service.modality === 'Download') {
        result += `${this.$t('tokens.consumption')} ${(isAuthorized ? this.$t('tokens.authorized') : '')} ${this.$t('tokens.by')}
          ${this.$t('tokens.download')} ${this.$t('tokens.of')} ${service.name}${related ? ' [' + this.$t('tokens.upgrade') + ']' : ''}`
      } else if (service.modality === 'Workshop') {
        result += `${this.$t('tokens.consumption')} ${(isAuthorized ? this.$t('tokens.authorized') : '')} ${this.$t('tokens.by')}
          ${this.$t('tokens.workshop')} ${this.$t('tokens.of')} ${service.name}${related ? ' [' + this.$t('tokens.upgrade') + ']' : ''}`
      } else {
        result += `${this.$t('tokens.consumption')} ${(isAuthorized ? this.$t('tokens.authorized') : '')} ${this.$t('tokens.by')}
          ${this.$t('tokens.creation')} ${this.$t('tokens.of')} ${service.name} ${this.$t('tokens.for')} ${qty} ${this.$t('tokens.collaborators_lowercase')}
          ${related ? ' [' + this.$t('tokens.upgrade') + ']' : ''}`
      }
      return result
    },
    descriptionFormat (item) {
      switch (item.type) {
        case 'buy':
          return this.$t('tokens.purchase')
        case 'moveUp':
          return `${this.$t('tokens.transfer')} ${this.$t('tokens.received')} ${this.$t('tokens.since')} ${this.getNameDescription(item.fromTokenAccountId)}`
        case 'moveDown':
          if (item.toTokenAccountId === 1) {
            return this.$t('tokens.adjustment')
          }
          return `${this.$t('tokens.transfer')} ${this.$t('tokens.sended')} ${this.$t('tokens.to')} ${this.getNameDescription(item.toTokenAccountId)}`
        case 'spend':
          return `${this.getSpendDescription(item.serviceId, item.qty, item.related, false)}`
        case 'authorizationUp':
          return `${this.$t('tokens.authorization')} ${this.$t('tokens.received_female')} ${this.$t('tokens.since')}
            ${this.getNameDescription(item.fromTokenAccountId)}`
        case 'authorizationDown':
          return `${this.$t('tokens.authorization')} ${this.$t('tokens.sended_female')} ${this.$t('tokens.to')} ${this.getNameDescription(item.toTokenAccountId)}`
        case 'authorizationSpend':
          return `${this.getSpendDescription(item.serviceId, item.qty, item.related, true)}`
        default:
          return '----'
      }
    },
    resetEntry () {
      this.inputValue = 0
      this.dialogAdd = false
    },
    acquire () {
      this.loading = true
      const mount = Number(this.inputValue)
      if (mount === 0) {
        this.$store.dispatch('alert/error', this.$t('tokens.invalid_quantity'))
        this.loading = false
        return
      }
      return this.checkPending()
        .then(continuar => {
          if (continuar) {
            return this.startTransaction(mount, this.outputByCurrency, this.ivaByCurrency, this.currency.paymentSymbol, () => {
              this.resetEntry()
              this.getBalance()
              this.seeAllEnterprises()
            })
          }
        })
    },
    selectItem (item) {
      this.dialogDetails = true
      this.selectedItem = Object.assign({}, item)
    },
    getActiveProducts () {
      return productService.list({ filter: true })
        .then((res) => {
          this.productList = res
        })
    },
    getActiveProductServices () {
      return productServicesService.list({ filter: true })
        .then((res) => {
          this.productServiceList = res
        })
    },
    getTokenAccountDetails (options) {
      return tokenAccountService.getOneByUser()
        .then((res) => {
          this.userTokenAccount = res
          const result = this.seeAll ? tokenAccountDetailService.listByUserWithEnterprises(options) : tokenAccountDetailService.listByUser(options)
          return result
            .then((res) => ({
              items: res.items.map(item => {
                const json = JSON.parse(item.description)
                item.type = json.type
                item.productId = json.product
                item.serviceId = json.service
                item.qty = json.qty
                item.related = json.related
                return item
              }),
              total: res.total
            }))
            .then((res) => {
              const values = res.items ? res.items : res
              this.getAuthorizationTokenAccounts(values)
              return res
            })
        })
    },
    getAllTokenAccountDetails () {
      const options = {
        page: 1,
        itemsPerPage: -1
      }
      return tokenAccountService.getOneByUser()
        .then((res) => {
          this.userTokenAccount = res
          const result = this.seeAll ? tokenAccountDetailService.listByUserWithEnterprises(options) : tokenAccountDetailService.listByUser(options)
          return result
            .then((res) => ({
              items: res.items.map(item => {
                const json = JSON.parse(item.description)
                item.type = json.type
                item.productId = json.product
                item.serviceId = json.service
                item.qty = json.qty
                item.related = json.related
                return item
              }),
              total: res.total
            }))
            .then((res) => {
              const values = res.items ? res.items : res
              this.getAuthorizationTokenAccounts(values)
              return res
            })
        })
    },
    getBalance () {
      tokenAccountDetailService.getBalance()
        .then((res) => {
          this.balance = res.balance === undefined ? 0 : res.balance
        })
    },
    getCurrentTokenPrices () {
      tokenPriceService.getActives()
        .then((res) => {
          this.currentTokenPrices = res
        })
    },
    getAuthorizationTokenAccounts (movements) {
      for (const movement of movements) {
        this.tokenAuthorizationsList.push(movement.fromTokenAccountId)
        this.tokenAuthorizationsList.push(movement.toTokenAccountId)
      }
      const withoutRepeated = this.tokenAuthorizationsList.filter((value, currentIndice, array) => array.indexOf(value) === currentIndice)

      tokenAuthorizationService.listByAuthorization(withoutRepeated)
        .then((res) => {
          this.tokenAuthorizationsList = res
        })
    },
    seeAllEnterprises () {
      this.getTokenAccountDetails(this.options)
      this.search = this.search === null ? undefined : null
    }
  },
  watch: {
    search () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
        this.getAllTokenAccountDetails()
      }

      this.timer = setTimeout(() => {
        if (this.search !== null && this.search !== undefined && isNaN(this.search)) {
          let filteredIds = []
          if (typeof this.search === 'string') {
            const filtered = this.tokenAuthorizationsList.filter(token => token.name.toLowerCase().includes(this.search.toLowerCase()))
            filtered.forEach(element => filteredIds.push(element.id))
            this.options = {
              ...this.options,
              searchUser: filteredIds.length === 0 ? [-1] : filteredIds
            }
            return
          } else {
            filteredIds = []
            return
          }
        }
        this.options = {
          ...this.options,
          search: this.search,
          searchUser: undefined
        }
      }, 500)
    }
  },
  created () {
    this.getCurrentTokenPrices()
    this.getActiveProducts()
    this.getActiveProductServices()
    this.getBalance()
    if (this.user.customer && this.user.customer.type === 'commercial' && !this.user.enterprise) {
      this.hasEnterprises = true
    }
    if (this.user.role === 'customer' && (!this.user.enterprise || this.user.customer.type === 'personal')) {
      this.canBuy = true
    }
    if (this.user.role === 'customer') {
      this.hasBalance = true
    }
  }
}
</script>
<style scoped>
  .tHeadColor {
    background-color: #51C7AF
  }
</style>
