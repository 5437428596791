
import Services from './base-services'
import mapParameters from '../utils/map-parameters'

const service = new Services('groups')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
}

export default {
  list: (options: IOptionsList) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null
    })
    return service.get('list', params)
  },
  disable: (id: number) => {
    return service.put('disable', { id })
  },
  enable: (id: number) => {
    return service.put('enable', { id })
  },
  create: (data: object) => {
    return service.post('create', data)
  },
  getOne (id: number) {
    return service.get(`${id}`)
  },
  getOneByLeader () {
    return service.get('by/leader')
  },
  update: (id: number, data: any) => {
    return service.put(`${id}`, data)
  },
  getPlansForUpdate: () => {
    return service.get(`plans-update`)
  },
  updatePlan: (id: number, data: object) => {
    return service.post(`update-plan/${id}`, data)
  },
  applyUpdatePlan: (id: number) => {
    return service.post(`apply-update-plan/${id}`)
  },
  rejectUpdatePlan: (id: number) => {
    return service.post(`reject-update-plan/${id}`)
  },
  getPlanUpdateRequest: (id: number) => {
    return service.get(`get-update-request/${id}`)
  },
  extendPlan: (id: number, data: object) => {
    return service.post(`extend-plan/${id}`, data)
  },
  getPlanExtendRequest: (id: number) => {
    return service.get(`get-extend-request/${id}`)
  },
  applyExtendPlan: (id: number) => {
    return service.post(`apply-extend-plan/${id}`)
  },
  rejectExtendPlan: (id: number) => {
    return service.post(`reject-extend-plan/${id}`)
  },
  addmember: (id: number, customerId: number) => {
    return service.post(`add-member/${id}/${customerId}`)
  },
  changeLeader: (id: number, oldLeader: number, newLeader: number) => {
    return service.post(`change-leader/${id}`, { oldLeader, newLeader })
  },
  deleteMember: (id: number) => {
    return service.get(`delete-member/${id}`)
  },
  profile: () => {
    return service.get(`profile`)
  }
}
