<template>
  <div class="text-center">
    <x-loading :display="loading"></x-loading>
    <v-dialog
      style="margin-left: -20px"
      v-model="display"
      width="60em"
      persistent
    >

      <v-card>
        <v-card-title
          class="headline grey lighten-2 text-center"
          style="padding: 1em"
          primary-title
        >
          {{ $t('components.termsNotAcceptedDialog.title') }}
        </v-card-title>

        <v-card-text>
          <div class="ql-editor text-md-left" v-html="(termsAndConditions || {}).description"/>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-uppercase"
            large
            @click="acceptTerms"
          >
            {{ $t('components.termsNotAcceptedDialog.input') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import termsAndConditionsService from '../services/terms-and-conditions'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  props: {
    user: Object,
    loading: Boolean
  },
  data () {
    return {
      termsAndConditions: {},
      display: true
    }
  },
  created () {
    this.fetchLastTerms()
  },
  methods: {
    acceptTerms () {
      this.$emit('acceptNewTerms', (this.user.customer || {}).id, this.termsAndConditions.id)
    },
    fetchLastTerms () {
      if (this.user.id) {
        this.$emit('changeLoading', true)
        return termsAndConditionsService.getActiveByType((this.user.customer || {}).type)
          .then((res) => {
            this.termsAndConditions = res
            this.$emit('changeLoading', false)
          }).catch((err) => {
            this.$emit('changeLoading', false)
            Error(err)
          })
      }
    }
  }
}
</script>
