
export default {
  add_group: 'Create Group',
  groups_list: 'List of Groups',
  watch_requests: 'See Requests',
  no_data: 'No groups to display',
  // Headers
  id: 'ID',
  group_name: 'Group name',
  enterprises_no: 'No. Companies',
  main_customer: 'Boss',
  main_customer_tokens: 'Boss Token Balance',
  group_tokens: 'Group Token Balance',
  change_leader: 'Change boss',
  delete_from_group: 'Delete Client form Group',
  customer_no_group: 'This Client is not assigned to any Group',
  login_as_customer: 'Log in as this Client',
  add_member: 'Add Member',
  actual_tokens: 'Current Token Balance',
  delete_member: 'Delete Member',
  add_member_success: 'Member successfully added',
  delete_member_message: 'Member successfully deleted',
  leader_changed_success: 'Boss successfully changed',
  delete_member_question: 'Do you want to delete the member from the group?',
  status: 'Status',
  actions: 'Actions',
  modal_enable: 'Do you want to enable the griup?',
  modal_disable: 'Do you want to disable the group?',
  modal_enable_des: 'Once the group is enabled, the group administrator will be able to manage the licensing of the group companies.',
  modal_disable_des: 'Once the group is disabled, the group administrator will not be able to manage the licensing of the group companies.',
  modal_save_btn: 'Accept',
  created_group: 'Created Group',
  updated_group: 'Group successfully modified!',
  edit_group: 'Edit Group',
  main_enterprise: 'Main Client',
  current_plan: 'Balance',
  associated_enterprises: 'Company',
  want_to_select_enterprises: 'Do you want to select the group companies to apply this change to?',
  want_other_start: 'Do you want to specify a start date?',
  must_select_enterprises: 'You must select the companies to update from the list',
  must_select_plan: 'You must select the new plan',
  no_update_requests: 'This group has no pending update requests.',
  plan_updated: 'This request has been successfully applied.',
  plan_rejected: 'This request has been successfully rejected.',
  select_client: 'Select a Client'
}
