
<template>
  <div>
    <v-card-title primary-title class="text-center">
      <h2
        class="display-1 text-xs-center grey--text"
        style="width: 100%; word-break: normal"
      >
        {{ $t('outside.insert_document') }}
      </h2>
      <h3
        class="text-center grey--text font-weight-light"
        style="width: 100%; word-break: normal"
      >
        {{ $t('outside.insert_document2') }}
      </h3>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-alert color="error" outlined :value="error !== ''">
          <span class="font-weight-bold" v-if="error" v-html="$t(`errors.${error}`)"></span>
        </v-alert>
        <v-row>
          <v-col cols="2">
            <v-autocomplete
              :items="identifyTypes"
              v-model="identifyTypeId"
              :disabled="!valid"
              light
              :label="$t('input.identify_type_s')"
              name="identify_type_id"
              :error="identifyTypeIdState"
              :append-icon="null"
            ><template slot="selection" slot-scope="data">{{ data.item | identification }}</template>
            </v-autocomplete>
          </v-col>
          <v-col cols="10">
            <v-text-field
              v-model="identificationNumber"
              :label="$t('input.identify_document').charAt(0).toUpperCase() + $t('input.identify_document').substring(1)"
              autofocus
              :disabled="!valid"
              name="identificationNumber"
              :error="identificationNumberState"
            ></v-text-field>
          </v-col>
        </v-row>
        <x-async-btn
          class="white--text"
          :disabled="!valid"
          :action="verifyDocument"
          block
          large
          type="submit"
        >{{ $t('outside.verify') }}</x-async-btn>
      </v-form>
    </v-card-text>
  </div>
</template>

<script>

import Vue from 'vue'

import AuthService from '../../services/auth'
import identifyTypesService from '../../services/identify-types'
import formatItems from '../../utils/form-format-items-list'

export default Vue.extend({
  data () {
    return {
      identificationNumber: '',
      valid: false,
      error: '',
      identifyTypes: [],
      identifyTypeId: '',
      identificationNumberState: false,
      identifyTypeIdState: false
    }
  },
  watch: {
    '$i18n.locale': {
      handler () {
        this.getData()
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    verifyDocument () {
      if (this.identifyTypeId !== '' && this.identificationNumber !== '') {
        this.error = ''
        return this.sendData()
      }
      this.identificationNumberState = !this.identificationNumber
      this.identifyTypeIdState = !this.identifyTypeId
      this.error = 'validator/verify_fields'
      return Promise.resolve(false)
    },
    sendData () {
      return AuthService.verifyIdentification({
        identification: this.identificationNumber,
        identifyTypeId: this.identifyTypeId,
        token: this.$route.params.token
      })
        .then((res) => {
          this.$router.push(`/auth/list-polls/${this.$route.params.token}/${this.identificationNumber}/${this.identifyTypeId}`)
        })
        .catch((error) => {
          this.error = error.code
          this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
        })
    },
    getData () {
      return AuthService.getEnterpriseByInvitationCode(this.$route.params.token)
        .then(() => identifyTypesService.listOutside(this._i18n.locale))
        .then((res) => {
          this.identifyTypes = formatItems(res.items, item => ({
            text: item.translate.label,
            value: item.id,
            code: item.code
          }))
          this.valid = true
          const it = this.identifyTypes.find(i => i.code === 'others')
          const index = this.identifyTypes.indexOf(it)
          this.identifyTypes.splice(index, 1)
          this.identifyTypes.splice(this.identifyTypes.length, 0, it)
        })
        .catch((error) => {
          this.error = error.code
          this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
          this.$nextTick(() => {
            this.valid = false
          })
        })
    }
  }
})
</script>
