
import Services from './base-services'

const service = new Services('currencies')

export default {
  list: () => {
    return service.get('')
  },
  getById: (id: number, locale: string) => {
    return service.get(`${id}/${locale}`)
  },
  getBase: () => {
    return service.get('base-currency')
  },
  getActives: () => {
    return service.get('list-active')
  },
  delete: (id: number) => {
    return service.get(`delete/${id}`)
  },
  create: (data: any) => {
    return service.post('', data)
  },
  update: (id: number, data: any) => {
    return service.put(`${id}`, data)
  },
  disable: (id: number) => {
    return service.get(`disable/${id}`)
  },
  enable: (id: number) => {
    return service.get(`enable/${id}`)
  }
}
