
<template>
  <v-col cols="12" class="text-xs-center text-sm-center text-md-center text-lg-center">
    <v-badge
      class="upload-image"
      bottom
      color="blue-grey lighten-4"
      :offset-y="17"
      light overlap
    >
      <template v-slot:badge>
        <v-icon>mdi-camera</v-icon>
      </template>
      <section class="mini-logo-detail enterprise-logo" @click="pickFile">
        <img :src="src || '/img/document-image_314906.png'" alt="Company Logo"/>
      </section>
    </v-badge>
    <br v-if="title"/><small style="color: #3899da" v-if="title">{{ title }}</small>
    <input
      type="file"
      style="display: none"
      ref="image"
      accept="image/*"
      @change="onFilePicked"
    >
  </v-col>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    title: String,
    input: String,
    vid: String,
    maxSize: {
      type: Number,
      default: 2000000
    }
  },
  data () {
    return {
      src: '',
      name: ''
    }
  },
  methods: {
    setValue (val) {
      this.$emit('updateInput', val)
    },
    resetImage () {
      this.src = ''
      this.name = ''
      this.setValue('')
    },
    readFile (files) {
      const fr = new FileReader()
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        this.src = fr.result
        this.setValue(files[0])
      })
    },
    pickFile () {
      this.$refs.image.click()
    },
    onFilePicked ($event) {
      const files = $event.target.files
      if (files[0] !== undefined) {
        if (files[0].size > this.maxSize) {
          this.$store.dispatch('alert/error', this.$t('errors.image/size'))
        } else {
          this.name = files[0].name
          return this.name.lastIndexOf('.') > 0 ? this.readFile(files) : this.resetImage()
        }
      } else {
        this.resetImage()
      }
    }
  },
  created () {
    this.src = this.input || '/img/document-image_314906.png'
    this.name = this.input
  }
})
</script>
