
export default {
  enterprises_list: 'List of Companies',
  add_enterprise: 'Add company',
  edit_enterprise: 'Edit company',
  filter_by: 'Sort by...',
  look_for: 'Find a company',
  no_data: 'No companies to display',
  id: 'Id',
  enterprise_name: 'Company name',
  group: 'Group',
  license: 'License',
  expiration_date: 'Expiration date',
  status: 'Status',
  actions: 'Actions',
  modal_enable: 'Do you want to enable the company?',
  modal_disable: 'Do you want to disable the company?',
  modal_enable_des: 'Once enabled, the company will be able to access the system again',
  modal_disable_des: 'Once disabled, the company will not be able to access the system until enabled again',
  action: 'Execute',
  accept: 'Accept',
  close: 'Close',
  enterprise_information: 'Company information',
  contact_employee: 'Contact employee',
  licensing: 'Licensing',
  demographic_cuts: 'Demographic Cuts',
  tool_customization: 'Tool customization',
  primary_color: 'Primary color',
  secondary_color: 'Secondary color',
  informative_cuts: 'The following data is informative and does not represent actions in the system. These data are required to identify collaborators.',
  select_demographic_cuts: 'Please select the categories of demographic cuts you want to assess.',
  obligatories_demographic_cuts: 'The {required} categories are required.',
  created_enterprise: 'The company has been successfully created.',
  section_edited: 'The section has been successfully edited.',
  enterprise_edited: 'The company has been successfully edited.',
  profile_title: 'The company was successfully created.',
  profile_title_addres: 'Located in: {country} - {address}.',
  no_extend_requests: 'This company has no pending extension requests.',
  extend_request_accepted: 'Request accepted',
  extend_request_rejected: 'Request rejected',
  extend_request_cancelled: 'Request cancelled',
  status_pending: 'Pending',
  status_accepted: 'Accepted',
  status_rejected: 'Rejected',
  status_cancelled: 'Cancellled',
  created_demo_enterprise: 'Your company has been registered. You will now receive an email with the instructions to follow to activate your account.',
  profile_warning: 'Remember to set your demographic cuts to use the system correctly.',
  first_time_title: 'Welcome to OCC - Suite',
  first_time_text: 'To have a better experience when interacting with the platform, you should follow these steps:',
  first_time_step: 'Step',
  first_time_step_1: 'Verify the company information, edit or upload only the demographic cuts that you will use to generate the reports.',
  first_time_step_2: 'Enter the collaborators section for loading the company\'s payroll, before carrying out any assessment.',
  first_time_step_3: 'Create your first Engagement or Pulse assessment according to your license.',
  register_date: 'Register date',
  balance: 'Current balance OCC Tokens',
  resumen: 'Company summary',
  admin: 'Administrator',
  available_tokens: 'Available tokens',
  company_name: 'Company name',
  profile_picture: 'Profile picture',
  load_company_logo: 'Load company logo',
  customer: 'Client',
  successfully_edited: 'Successfully edited'
}
