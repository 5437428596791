
<template>
  <v-stepper v-model="internaStep" class="x-stepper">
    <v-stepper-header>
      <template v-for="(header, $i) in headers">
        <v-stepper-step
          :key="`${$i}-step`"
          :complete="step > ($i + initialStep)"
          :step="($i + initialStep)"
          :editable="editable"
          v-if="stepsVisible[$i]"
        >{{ $t(header) }}
        </v-stepper-step>
        <v-divider :key="$i" v-if="divider && $i !== steps"></v-divider>
      </template>
    </v-stepper-header>
    <v-stepper-items>
      <slot></slot>
    </v-stepper-items>
  </v-stepper>
</template>

<script>

import Vue from 'vue'

const getUp = (start, end) => {
  const sequence = []
  for (let i = start; i <= end; i++) {
    sequence.push(i)
  }

  return sequence
}

const getNearbies = (total, current, max) => {
  const half = Math.floor(max / 2)
  let nearbies = []
  let missings = max

  const end = current + half
  if (current < total) {
    nearbies = getUp(current + 1, end < total ? end : total)
    missings = missings - nearbies.length
  }

  if (current > 1) {
    const start = current - missings
    nearbies = nearbies.concat(getUp(start <= 1 ? 1 : start, current - 1))
    missings = max - nearbies.length
  }

  if (missings > 0 && end < total) {
    nearbies = nearbies.concat(getUp(end + 1, (end + missings) < total ? (end + missings) : total))
  }
  return nearbies.sort((a, b) => a - b)
}

export default Vue.extend({
  name: 'stepper',
  props: {
    headers: {
      type: Array,
      required: true
    },
    step: {
      type: Number,
      required: true
    },
    initialStep: { type: Number, default: 1 },
    maxXs: { type: Number, default: 1 },
    maxSm: { type: Number, default: 2 },
    maxMd: { type: Number, default: 3 },
    maxLg: { type: Number, default: 4 },
    maxXl: { type: Number, default: 5 },
    editable: Boolean,
    divider: Boolean
  },
  data () {
    return {
      internaStep: 0,
      stepsVisible: [],
      xsLength: 0,
      smLength: 0,
      mdLength: 0,
      lgLength: 0,
      xlLength: 0
    }
  },
  watch: {
    maxXs () {
      this.updateLength()
    },
    maxSm () {
      this.updateLength()
    },
    maxMd () {
      this.updateLength()
    },
    maxLg () {
      this.updateLength()
    },
    maxXl () {
      this.updateLength()
    },
    step () {
      this.internaStep = this.step
      if (this.step >= this.initialStep || this.step <= this.headers.length) {
        this.updateVisible()
      }
    },
    internaStep () {
      this.$emit('step', this.internaStep)
    }
  },
  methods: {
    updateLength () {
      this.xsLength = this.maxXs > 0 ? this.maxXs : 1
      this.smLength = this.maxSm > this.maxXs ? this.maxSm : this.maxXs
      this.mdLength = this.maxMd > this.maxSm ? this.maxMd : this.maxSm
      this.lgLength = this.maxLg > this.maxMd ? this.maxLg : this.maxMd
      this.xlLength = this.maxXl > this.maxLg ? this.maxXl : this.maxLg
    },
    updateVisible () {
      const nearbies = getNearbies(this.headers.length, this.step, this.getMaxLength())
      for (let i = 0; i < this.headers.length; i++) {
        this.stepsVisible[i] = this.step === (i + this.initialStep) || nearbies.lastIndexOf(i + this.initialStep) !== -1
      }
    },
    getMaxLength () {
      switch (this.$vuetify.breakpoint.name) {
        case 'sm': return this.smLength
        case 'md': return this.mdLength
        case 'lg': return this.lgLength
        case 'xl': return this.xlLength
        default: return this.xsLength
      }
    }
  },
  created () {
    this.internaStep = this.step
    this.updateLength()
    this.updateVisible()
  }
})
</script>

<style>
.x-stepper .v-stepper__label{
  display: inline-flex;
}

.x-stepper .v-stepper__step__step{
  margin-right: 8px;
}
</style>
