
<template>
  <trans-wrapper
    :showCurrencySelect="true"
    :page-title="$t('settings.country')"
    :add-label="$t('settings.add')"
    :card-title="$t('settings.countries')"
    :alert-info="$t('settings.country_info')"
    :modal-title-add="$t('settings.country_add')"
    :modal-title-ena="$t('settings.country_enable')"
    :modal-title-dis="$t('settings.country_disable')"
    :modal-title-del="$t('settings.country_delete')"
    :modal-question-ena="$t('settings.master_references_enable_des')"
    :modal-question-dis="$t('settings.master_references_disable_des')"
    :modal-question-del="$t('settings.master_references_delete_des')"
    :item-services="countriesService"
    :is-enterprise-editable="false"
  ></trans-wrapper>
</template>

<script>

import countriesService from '../../services/countries'

import TransWrapper from './components/trans-wrapper.vue'

export default {
  components: {
    TransWrapper
  },
  data () {
    return {
      countriesService
    }
  }
}
</script>
