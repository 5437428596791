import Services from './base-services'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const service = new Services('payments')
let paymentConfig = {
  isProd: true,
  baseUrl: '',
  entityCode: null,
  srvCode: null,
  SessionToken: null,
  ip: null,
  srvCurrency: 'USD',
  urlRedirect: '',
  baseRedirectUrl: document.location.origin,
  deviceFP: ''
}

export default {
  setTransaction: async (TransValue: number, TransVatValue: number, SrvCurrency: string, transactionId: any) => {
    if (!paymentConfig.deviceFP) {
      const fp = await FingerprintJS.load()
      const result = await fp.get()
      paymentConfig.deviceFP = result.visitorId
    }

    return service.post('createTransactionPayment', {
      TransValue,
      TransVatValue,
      SrvCurrency,
      transactionId,
      DeviceFingerPrint: paymentConfig.deviceFP
    })
  },
  getTransactionInformation: (transactionId: number) => {
    return service.get(`getTransactionInformation/${transactionId}`)
  }
}
