
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <h4 class="display-1">{{ $t('currencies.list_title') }}</h4>
      </v-col>
      <v-col cols="12" md="6" class="text-right">
        <v-spacer></v-spacer>
        <v-btn large
          color="primary"
          to="/settings/currencies/create"
          class="text-capitalize"
        >
          <v-icon left>mdi-plus-circle-outline</v-icon>
          {{ $t('currencies.add_currency') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider></v-divider>

        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <!-- <v-text-field
              v-model.lazy="search"
              append-icon="mdi-magnify"
              :label="$t('customers.look_for')"
              single-line
              hide-details
            ></v-text-field> -->

          </v-card-title>

          <v-divider class="my-3"></v-divider>

          <!-- <v-row row wrap class="ml-3">
            <v-col xs12 sm3>
              <v-autocomplete
                :label="$t('customers.filter_by')"
                :items="filters"
                prepend-inner-icon="mdi-filter-outline"
                @change="filterItems"
              ></v-autocomplete>
            </v-col>
          </v-row> -->

          <x-data-table
            :headers="headers"
            :parent-fetch-data="getCurrencies"
            :reload="reloadTable"
            @offReload="offReload"
            no-data="currencies.no_data"
          >
            <template v-slot:structure="prop">
              <td class="text-center text-capitalize">{{ prop.item.name }}</td>
              <td class="text-center">{{ prop.item.paymentSymbol }}</td>
              <td class="text-center">{{ prop.item.currencySymbol }}</td>
              <td class="text-center">{{ prop.item.value | formatCurrency(2) }}</td>
              <td class="text-center">
                <v-switch readonly
                  v-model="prop.item.isActive"
                  class="ma-2"
                  @click="openModal(prop.item.isActive, prop.item)"
                />
              </td>
              <td class="text-center">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`/settings/currencies/${prop.item.id}/edit`"
                      v-on="on"
                      text icon>
                      <v-icon small>fa-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.edit') }}</span>
                </v-tooltip>
                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="openDelModal(prop.item)"
                      v-on="on"
                      text icon>
                      <v-icon small color="red">fa-trash</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.trash') }}</span>
                </v-tooltip>
              </td>
            </template>
          </x-data-table>
        </v-card>
      </v-col>
    </v-row>
    <x-confirmation-modal
      :show.sync="modalEna.open"
      :reversible="true"
      :title="$t('currencies.modal_enable')"
      :action="enableCurrency"
      color="success"
      :btn-save="$t('input.enable')"
      @close="modalEna.open = false"
    >
    </x-confirmation-modal>

    <x-confirmation-modal
      :show.sync="modalDis.open"
      :reversible="true"
      :title="$t('currencies.modal_disable')"
      :action="disableCurrency"
      color="error"
      :btn-save="$t('input.disable')"
      @close="modalDis.open = false"
    >
    </x-confirmation-modal>
    <x-confirmation-modal
      :show.sync="modalDel.open"
      :reversible="true"
      :title="$t('currencies.modal_delete')"
      :action="deleteCurrency"
      :btn-save="$t('input.trash')"
      color="error"
      @close="modalDel.open = false"
    >
    </x-confirmation-modal>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import currencyService from '../../services/currencies'

export default {
  data () {
    return {
      headers: [
        'currencies.name',
        'currencies.code',
        'currencies.symbol',
        'currencies.value',
        'currencies.status',
        'currencies.actions'
      ],
      filters: [
        { text: 'Todos', value: null },
        { text: 'Activo', value: '1' },
        { text: 'Inactivo', value: '0' }
      ],
      baseCurrency: {},
      reloadTable: false,
      modalDis: {
        open: false,
        item: {}
      },
      modalEna: {
        open: false,
        item: {}
      },
      modalDel: {
        open: false,
        item: {}
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    // Aca obtengo la moneda base
    currencyService.getBase()
      .then((res) => {
        this.baseCurrency = res.data
      }).catch((err) => {
        this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
      })
    this.$store.dispatch('session/showSelectorCurrency', true)
  },
  mounted () {
    this.$store.dispatch('session/showSelectorCurrency', true)
  },
  beforeDestroy () {
    this.$store.dispatch('session/showSelectorCurrency', false)
  },
  methods: {
    getCurrencies () {
      return currencyService.list()
        .then((res) => ({
          items: res.items,
          total: res.items.length
        }))
    },
    deleteCurrency () {
      if (this.modalDel.item.isBase) {
        this.modalDel.open = false
        return
      }
      currencyService.delete(this.modalDel.item.id)
        .then(() => {
          this.reloadTable = true
        })
    },
    disableCurrency () {
      if (this.modalDis.item.isBase) {
        this.modalDis.open = false
        return
      }
      return currencyService.disable(this.modalDis.item.id)
        .then((res) => {
          this.modalDis.item.isActive = false
          this.modalDis.open = false
        })
    },
    enableCurrency () {
      return currencyService.enable(this.modalEna.item.id)
        .then((res) => {
          this.modalEna.item.isActive = true
          this.modalEna.open = false
        })
    },
    openModal (active, item) {
      if (active) {
        if (item.isBase) return
        this.modalDis.item = item
        this.modalDis.open = true
      } else {
        this.modalEna.item = item
        this.modalEna.open = true
      }
    },
    openDelModal (item) {
      if (item.isBase) return
      this.modalDel.item = item
      this.modalDel.open = true
    },
    offReload () {
      this.reloadTable = false
    }

  }
}
</script>
