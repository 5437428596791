
import axios from 'axios'
import sendformData from '../utils/form-data'
import mapResponse from '../utils/map-response'

export default class Services {
  private readonly baseUrl = process.env.VUE_APP_SUITE_BASE_API;
  // private readonly baseUrl = 'http://localhost:3000/';
  // private readonly baseUrl = 'https://occsuiteserver.azurewebsites.net/';
  private module: string | undefined = '';

  constructor (module?: string) {
    this.module = module
  }

  public get (url: string, params?: object) {
    return mapResponse(axios.get(this.getUrl(url), params))
  }

  public post (url: string, data?: object) {
    return mapResponse(axios.post(this.getUrl(url), data))
  }

  public put (url: string, data?: object) {
    return mapResponse(axios.put(this.getUrl(url), data))
  }

  public patch (url: string, data?: object) {
    return mapResponse(axios.patch(this.getUrl(url), data))
  }

  public form (url: string, data?: object, transf?: object) {
    return sendformData(this.getUrl(url), data, transf)
  }

  public bGet (url: string, params?: object) {
    return mapResponse(axios.get(this.getUrl(url, true), params))
  }

  public bPost (url: string, data?: object) {
    return mapResponse(axios.post(this.getUrl(url, true), data))
  }

  public bPut (url: string, data?: object) {
    return mapResponse(axios.put(this.getUrl(url, true), data))
  }

  public bForm (url: string, data?: object, transf?: object) {
    return sendformData(this.getUrl(url, true), data, transf)
  }

  private getUrl (url: string, onlyBase = false) {
    return `${this.baseUrl}${onlyBase ? url : this.checkModule(url)}`
  }

  private checkModule (url: string) {
    return (this.module ? `${this.module}/` : '') + url
  }
}
