
import { ACL, config } from '../../libraries/acl'
import store from '../../store'

const capitalicePase = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

const baseAcl = new ACL(config)

const aclCan = (permssion: string) => {
  return async (to: any, from: any, next: any) => {
    if (!store.state.session.token) {
      next('/auth/sign-in')
      return
    }

    if (!baseAcl.can(permssion, store.state.session)) {
      next('/forbidden')
    }
    next()
  }
}

const getModule = (config: {[key: string]: any}, key: string) => config[key]

class AclGuards {
  constructor () {
    for (let keyModule of Object.keys(config)) {
      const module = getModule(config, keyModule)
      for (let keyAction of Object.keys(module)) {
        const action = module[keyAction]
        Object.defineProperty(
          this,
          `${capitalicePase(keyModule)}${capitalicePase(keyAction)}`,
          { value: aclCan(`${keyModule}.${keyAction}`) }
        )
      }
    }
  }
}

export default AclGuards
