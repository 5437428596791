
<template>
  <v-row row wrap>
    <v-col xs12 sm12>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            {{ $t('notifications.notifications') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(notification, index) in notifications.last_notifications"
              :key="index"
              :class="!notification.read ? 'unread' : ''"
            >
               <template v-if="user.role === 'admin'">
                <v-list-item-title @click="readNotification(notification)" v-if="notification.sourceType === 'enterprise_plan'">
                  <router-link
                    :to="notification.action"
                    class="no-decoration"
                    :class="!notification.read ? 'font-unread' : 'read'"
                  >
                    {{ $t(`notifications.${notification.type}`, {enterprise: notification.source.name}) }}
                  </router-link>
                </v-list-item-title>
                <v-list-item-title @click="readNotification(notification)" v-else>
                  <router-link
                    :to="notification.action"
                    class="no-decoration"
                    :class="!notification.read ? 'font-unread' : 'read'"
                  >
                    {{ $t(`notifications.${notification.type}`, {enterprise: notification.source.enterprise.name, poll: notification.source.name}) }}
                  </router-link>
                </v-list-item-title>
              </template>
              <template v-else>
                <v-list-item-title @click="readNotification(notification)">
                  <router-link
                    :to="notification.action"
                    class="no-decoration"
                    :class="!notification.read ? 'font-unread' : 'read'"
                  >
                    {{ $t(`notifications.${notification.type}`) }}
                  </router-link>
                </v-list-item-title>
              </template>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>

    </v-col>
  </v-row>
</template>

<script>

import { mapState } from 'vuex'

import NotificationService from '../../services/notifications'

export default {
  data () {
    return {
      menu: false,
      notifications: this.getNotifications()
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    async readNotification (notification) {
      await NotificationService.readNotification(notification.id).then((response) => {
        this.getNotifications()
      })
    },
    getNotifications () {
      return NotificationService.listAll()
        .then((res) => {
          this.notifications = res
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    }
  },
  created () {
    this.getNotifications()
  }
}
</script>

<style scoped>
.styled-select,
.styled-select label[for] {
  max-width: 100px;
  font-size: 10pt;
}

.unread {
  background-color: #3899da47;
}

.unread:hover {
  background-color: white;
}

.seemore {
  text-align: center;
}

.no-decoration{
  text-decoration: none;
}

.font-unread {
  color: midnightblue;
}

.see-more-btn {
  background-color: #e0e0e087;
}

.see-more-btn:hover {
  background-color: aliceblue;
}

.read {
  cursor: auto;
}
</style>
