
<template>
  <v-container fluid>
    <v-row wrap row>
      <v-col cols="12" sm="8">
        <h4 class="display-1">{{ $t('enterprises.enterprises_list') }}</h4>
      </v-col>
      <v-col cols="12" sm="4" class="text-right">
        <v-row justify="end">
          <v-btn
            color="primary"
            to="enterprises/create"
            class="text-capitalize"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>
            {{ $t('enterprises.add_enterprise') }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row wrap row>
      <v-col xs12>
        <v-divider></v-divider>

        <v-card>
          <v-card-title>
            <div class="title text-uppercase pt-3 pl-1">{{ $t('enterprises.resumen') }}</div>

            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="mdi-magnify"
              :label="$t('enterprises.look_for')"
              single-line
              hide-details
            ></v-text-field>

          </v-card-title>

          <v-divider class="my-3"></v-divider>

          <v-row row wrap class="mx-3">
            <v-col cols="12" sm="4" md="3">
              <v-autocomplete
                :label="$t('enterprises.filter_by')"
                :items="filters"
                prepend-inner-icon="mdi-filter-outline"
                @change="filterItems"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <x-data-table
            :headers="headers"
            :parent-fetch-data="getEnterprises"
            :options="options"
            :reload="reloadTable"
            no-data="enterprises.no_data"
            @offReload="offReload"
          >
            <template v-slot:structure="prop">
              <td class="text-center">{{ prop.item.name }}</td>
              <td class="text-center">{{ prop.item.createdAt | date({date: true, hour:false}) }}</td>
              <td class="text-center">{{ prop.item.balance >= 0 ? prop.item.balance : '--' }}</td>
              <td class="text-center">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`enterprises/${prop.item.id}/edit`"
                      v-on="on"
                      text icon>
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.edit') }}</span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`enterprises/profile/${prop.item.id}`"
                      v-on="on"
                      text icon>
                      <v-icon small>remove_red_eye</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.enterprise_profile') }}</span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn text icon
                      v-on="on"
                      :ripple="prop.item.admin && prop.item.admin.status !== 'pending'"
                      :color="getAdminIcon(prop.item).color"
                      @click="
                        prop.item.admin && prop.item.admin.status === 'pending'
                          ? ''
                          : showAdminModal(prop.item)
                      "
                    >
                      <v-icon small>{{ getAdminIcon(prop.item).icon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{
                      prop.item.admin && prop.item.admin.status === 'pending'
                        ? $t('dashboard.pending')
                        : `Co-${$t('enterprises.admin').toLowerCase()}`
                    }}
                  </span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="openTokensModal(prop.item)"
                      v-on="on"
                      text
                      icon>
                      <v-icon small>fa-coins</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.manage_token') }}</span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="openTokenAuthorizationModal(prop.item)"
                      v-on="on"
                      text
                      :color="colorIconAuthorization(prop.item.isAuthorized)"
                      icon>
                      <v-icon small>mdi-account-switch</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.authorizations') }}</span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="loginAsEnterprise(prop.item.id)"
                      v-on="on"
                      text
                      icon>
                      <v-icon small>fa-sign-out-alt</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.login_as_enterprise') }}</span>
                </v-tooltip>
              </td>
            </template>
          </x-data-table>
        </v-card>
      </v-col>
    </v-row>

    <enterprise-admin-modal
      v-if="adminModal"
      :show="adminModal"
      :enterprise-id="enterpriseId"
      :enterprise-name="enterpriseName"
      :enterprise-admin="enterpriseAdmin"
      @close="() => closeAdminModal()"
      @refreshList="reloadTable = true"
    />

    <token-authorization-modal
      :enterprise="enterpriseSelected"
      v-if="tokenAuthorizationModal"
      :action="authorizeTokens"
      @close="hideTokenAuthorizationModal"
    />

    <token-enterprise-modal
      :enterprise="enterpriseSelected"
      :customer-balance="customerBalance"
      v-if="tokenEnterpriseModal"
      :action="handleTokens"
      @close="hideTokenModal"
    />

  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import enterpriseService from '../../services/enterprises'
import authService from '../../services/auth'
import tokenAuthorizationService from '../../services/token-authorization'
import tokenAccountDetailService from '../../services/token-account-detail'

import EnterpriseAdminModal from './components/create-multi-enterprise-admin'
import TokenAuthorizationModal from '../customers/token-authorization-modal.vue'
import TokenEnterpriseModal from '../customers/token-enterprise-modal.vue'

export default {
  components: {
    EnterpriseAdminModal,
    TokenAuthorizationModal,
    TokenEnterpriseModal
  },
  data () {
    return {
      headers: [
        'enterprises.enterprise_name',
        'enterprises.register_date',
        'enterprises.balance',
        'enterprises.actions'
      ],
      options: {
        filter: null,
        search: null
      },
      search: null,
      timer: null,
      demoRequests: [],
      reloadTable: false,
      adminModal: false,
      enterpriseId: 0,
      enterpriseName: '',
      enterpriseAdmin: {},
      tokenAuthorizationModal: false,
      tokenEnterpriseModal: false,
      enterpriseSelected: {},
      customerBalance: 0
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    filters () {
      const translation = (option) => this.$t(`customers.filters.${option}`)
      return [
        { text: translation('all'), value: null },
        { text: translation('active'), value: '1' },
        { text: translation('inactive'), value: '0' }
      ]
    }
  },
  methods: {
    colorIconAuthorization (isAuthorized) {
      if (isAuthorized) {
        return 'green'
      }
      return ''
    },
    hideTokenAuthorizationModal (enterprise) {
      this.tokenAuthorizationModal = !this.tokenAuthorizationModal
    },
    hideTokenModal (enterprise) {
      this.tokenEnterpriseModal = !this.tokenEnterpriseModal
    },
    getAdminIcon (item) {
      const admin = {
        icon: 'fa-user-tie',
        color: ''
      }

      if (item.admin) {
        switch (item.admin.status) {
          case 'active':
            admin.icon = 'fa-user-tie'
            admin.color = 'success darken-1'
            break
          case 'inactive':
            admin.icon = 'fa-user-slash'
            admin.color = 'warning lighten-1'
            break
          case 'pending':
            admin.icon = 'fa-user-clock'
            admin.color = 'warning lighten-2'
            break
        }
      }
      return admin
    },
    showAdminModal (enterprise) {
      this.enterpriseId = enterprise.id
      this.enterpriseName = enterprise.name
      this.enterpriseAdmin = enterprise.admin
      this.adminModal = true
    },
    closeAdminModal () {
      this.enterpriseId = 0
      this.enterpriseName = ''
      this.adminModal = false
      this.enterpriseAdmin = {}
    },
    offReload () {
      this.reloadTable = false
    },
    getEnterprises (options) {
      const result = enterpriseService.listWithBalance(options)
        .then((res) => ({
          items: res.items.map(item => {
            item.licenses = item.licenses ? item.licenses[0] : undefined
            return item
          })
        }))
        .then((res) => {
          return this.getAuthorizations(res)
        })
      return result
    },
    getAuthorizations (enterprises) {
      return tokenAuthorizationService.listByEnterprises(enterprises.items.map(enterprise => `${enterprise.id}-${enterprise.customer.id}`).join('|'))
        .then((res) => ({
          items: enterprises.items.map(item => {
            const authorization = res.find(authorization => authorization.enterpriseId === item.id)
            item.isAuthorized = authorization
            return item
          })
        }))
    },
    getDemos () {
      return enterpriseService.getDemoRequests(this.$route.params.id)
        .then((res) => {
          this.demoRequests = res
        })
    },
    filterItems (value) {
      this.options = {
        ...this.options,
        filter: value
      }
    },
    loginAsEnterprise (enterpriseId) {
      return authService.loginAsEnterprise(enterpriseId, this.user)
        .then((res) => {
          return this.$store
            .dispatch('session/loginAs', res)
            .then((res) => {
              this.$router.push('/customer/show-profile')
              setTimeout(() => {
                window.location.reload(false)
              }, 150)
            })
            .catch(err => (this.$store.dispatch('alert/error', err)))
        }).catch(err => (this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))))
    },
    openTokenAuthorizationModal (enterpriseSelected) {
      this.enterpriseSelected = enterpriseSelected
      this.tokenAuthorizationModal = !this.tokenAuthorizationModal
    },
    openTokensModal (enterpriseSelected) {
      this.tokenEnterpriseModal = !this.tokenEnterpriseModal
      this.enterpriseSelected = enterpriseSelected
    },
    getCustomerBalance () {
      return tokenAccountDetailService.getCustomerBalance(this.user.customer.id)
        .then((res) => {
          this.customerBalance = res.balance
        })
    },
    handleTokens (enterpriseId, value) {
      return tokenAccountDetailService.move(enterpriseId, value)
        .then((res) => {
          this.hideTokenModal()
          this.$store.dispatch('alert/success', this.$t('tokens.created_transfer'))
          this.search = this.search === null ? undefined : null
          this.getCustomerBalance()
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    authorizeTokens (enterpriseId, tokenAccountId, value) {
      return tokenAuthorizationService.toggle({ enterpriseId, tokenAccountId, value })
        .then((res) => {
          this.hideTokenAuthorizationModal()
          if (value) {
            this.$store.dispatch('alert/success', this.$t('tokens.created_authorization'))
          } else {
            this.$store.dispatch('alert/success', this.$t('tokens.eliminated_authorization'))
          }
          this.search = this.search === null ? undefined : null
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    }
  },
  created () {
    this.getCustomerBalance()
  },
  watch: {
    search () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.options = {
          ...this.options,
          search: this.search
        }
      }, 1000)
    }
  }
}
</script>
