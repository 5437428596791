
<template>
  <v-container style="margin: 0.4em 3em">
    <v-row wrap row>
      <v-col cols="12">
        <h4 class="display-1 mb-3">
          {{ $t('navigation.measuring_tools') }}
        </h4>
      </v-col>
    </v-row>
    <v-card class="pl-2 pr-2">
      <v-row class="text-center">
        <v-simple-table>
          <tbody>
            <tr v-for="product in products" :key="product.id" class="mt-10">
              <td style="width: 320px;">
                <a :href="product.url">
                  <img :src="product.logo || product.defaultLogo" alt="Product logo" style="width: 100%; padding: 1em; max-width: 300px; max-height: 300px;">
                </a>
              </td>
              <td class="hidden-xs-only">
                <b class="text-capitalize"> {{ product.name }} </b>
                <p v-html="getProductDescription(product.description)"/>
              </td>
              <td class="hidden-sm-and-down">
                <v-btn :href="product.url" color="primary">{{ $t('customers.to_access') }}</v-btn>
              </td>
              <v-divider></v-divider>
            </tr>
            <tr v-for="product in sytemProducts" :key="product.id" class="mt-10">
              <td style="width: 320px;">
                <a :href="product.url">
                  <img :src="product.logo || product.defaultLogo" alt="Product logo" style="width: 100%; padding: 1em; max-width: 300px; max-height: 300px;" class="desaturate">
                </a>
              </td>
              <td class="hidden-xs-only">
                <b class="text-capitalize"> {{ product.name }} </b>
                <p v-html="getProductDescription(product.description)"/>
              </td>
              <td class="hidden-sm-and-down">
                <v-btn @click="displayRequestModal(product)" color="primary">{{ $t('customers.request_product') }}</v-btn>
              </td>
              <v-divider></v-divider>
            </tr>
          </tbody>
        </v-simple-table>
      </v-row>
    </v-card>
    <v-dialog v-model="show" width="50em" >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col align="center" cols="12">
              <img :src="productRequested.logo || productRequested.defaultLogo" alt="Product logo" style="width: 100%; padding: 1em; max-width: 300px; max-height: 300px;">
            </v-col>
            <v-col align="center" cols="12">
              <h1 class="headline">
                {{ $t('customers.request_product') }}
              </h1>
              <p>
                {{ $t('customers.request_message_description') }}
              </p>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="message" :label="$t('customers.message')" no-resize />
            </v-col>
            <v-col cols="12" align="end">
              <v-btn
                @click="hideRequestModal"
                class="mr-4"
              >
                {{ $t('input.cancel') }}
              </v-btn>
              <v-btn
                color="primary"
                @click="sendProductRequest"
              >
                {{ $t('input.send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import Vue from 'vue'

import productsService from '../../services/products'
import customersService from '../../services/customers'

export default Vue.extend({
  data () {
    return {
      products: [],
      sytemProducts: [],
      show: false,
      productRequested: {
        logo: '',
        defaultLogo: ''
      },
      message: ''
    }
  },
  created () {
    this.listProducts()
  },
  methods: {
    listProducts () {
      return productsService.listByCustomer()
        .then((res) => {
          this.products = res
          this.getActiveProducts()
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    getActiveProducts () {
      return productsService.listFiltered(this.products.map(item => item.id))
        .then((res) => {
          this.sytemProducts = res
        })
    },
    displayRequestModal (product) {
      this.show = true
      this.productRequested = product
    },
    hideRequestModal (product) {
      this.show = false
      this.productRequested = {
        logo: '',
        defaultLogo: ''
      }
      this.message = ''
    },
    getProductDescription (productDesc) {
      return productDesc[this.$i18n.locale] ? productDesc[this.$i18n.locale] : productDesc.es
    },
    sendProductRequest () {
      if (!this.message) {
        this.$store.dispatch('alert/error', this.$t('customers.message_not_found'))
      } else {
        return customersService.requestProduct(this.productRequested.name, this.message)
          .then((res) => {
            this.$store.dispatch('alert/success', this.$t('customers.request_sent'))
            this.hideRequestModal()
          }).catch((err) => {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          })
      }
    }
  }
})
</script>

<style>
img.desaturate{
    filter: gray; /* IE */
    -webkit-filter: grayscale(1); /* Old WebKit */
    -webkit-filter: grayscale(100%); /* New WebKit */
    filter: grayscale(100%); /* Current draft standard */
}
</style>
