
export default {
  title: 'Comprobante Transacción',
  transaction_status: 'Estado Transacción',
  finnancial_institution: 'Entidad Financiera',
  transaction_number: 'Nro. Transacción',
  datetime: 'Fecha y Hora',
  currency: 'Moneda',
  amount: 'Total Transacción',
  payment_desc: 'Descripción de Pago',
  business_name: 'Razón Social',
  customer: 'Cliente',
  tokens: 'OCC Tokens',
  identification: 'Identificación',
  payment_system: 'Forma de Pago',
  cost: 'Costo',
  iva: 'IVA 19%'
}
