
export default {
  create_title: 'Crear Divisa',
  editar_title: 'Editar Divisa',
  list_title: 'Listado de Divisas',
  add_currency: 'Agregar Divisa',
  name: 'Nombre',
  code: 'Código de Moneda',
  code_desc: 'Corresponde al código de 3 letras<br />que identifica a la moneda, de<br />acuerdo al estandar ISO 4217.',
  symbol: 'Símbolo',
  value: 'Valor',
  status: 'Estado',
  actions: 'Acciones',
  equals: ' equivale a ',
  no_data: 'No hay Divisas para mostrar',
  modal_enable: '¿Desea habilitar la divisa?',
  modal_disable: '¿Desea inhabilitar la divisa?',
  modal_delete: '¿Desea eliminar la divisa?',
  currencies: 'Divisas',
  currency: 'Divisa',
  successfully_registered: 'Registro exitoso',
  successfully_edited: 'Modificado exitosamente'
}
