
<template>
  <v-app>
    <v-main class="background">
      <v-container fluid>
        <v-row>
          <v-col class="py-0 text-xs-center" cols="12">
            <v-card class="elevation-12" outlined>
              <v-row no-gutters>
                <v-col cols="12" class="pt-4">
                  <!-- Languages -->
                  <v-select dense hide-details
                    v-model="language"
                    :items="languages"
                    :menu-props="{ top: false, offsetY: true }"
                    class="styled-select mx-5"
                    prepend-icon="translate"
                    @change="changeLocale"
                  ></v-select>

                  <!-- Currencies -->
                  <v-select dense hide-details
                    v-model="currencySelected"
                    :items="currencies"
                    :menu-props="{ top: false, offsetY: true }"
                    return-object
                    item-text="name"
                    :label="$t('currencies.currencies')"
                    class="styled-select"
                    style="min-width: 191px;"
                    @change="currencyChange"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="mt-n1 mt-md-n4 text-center">
                  <img
                    :src="`${baseUrl}/img/20200301_occ_solution_logo.png`"
                    alt="OCC - Solutions Logo"
                    class="mt-sm-1 ml-sm-12"
                    width="140px"
                  />
                </v-col>
              </v-row>

              <v-card-text class="mt-n2 mt-sm-n4 pt-0 px-10">
                <v-row justify="start">
                  <!-- Title -->
                  <v-col class="display-1 pt-0">
                    {{ $t('calculatorView.title') }}
                    <p class="mb-0 pt-1 body-2">{{ $t('tokens.subtitle') }}</p>
                  </v-col>

                  <!-- Rates / Recomendations -->
                  <v-col sm="4" md="3" lg="2" class="pt-0">
                    <v-card>
                      <v-row no-gutters>
                        <v-col class="py-3 text-center">
                          <h5 class="caption font-weight-light text-center text-uppercase">
                            {{ $t('tokens.equivalences_rates') }}
                          </h5>
                          <v-btn small dark
                            color="primary"
                            class="mt-1"
                            @click="showTokenRangeDialog = true"
                          >
                            <v-icon size="19" class="mr-2">mdi-eye</v-icon>
                            {{ $t('input.see') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-dialog
                    v-model="showTokenRangeDialog"
                    max-width="900px"
                  >
                    <v-card>
                      <x-token-price-table :is-public="true" />
                    </v-card>
                  </v-dialog>
                </v-row>

                <!-- Calculator -->
                <x-calculator />
              </v-card-text>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <x-alert></x-alert>
  </v-app>
</template>

<script>
import { localize } from 'vee-validate'

import publicService from '../../services/public'

import XTokenPriceTable from '@/components/token-price-range-table'
import XCalculator from './calculator'

function loadLocale (code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    locale.messages.required = code === 'es' ? 'Este campo es obligatorio' : 'This field is required'
    localize(code, locale)
  })
}

export default {
  components: {
    XCalculator,
    XTokenPriceTable
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_SUITE_BASE_WEB,
      languages: [],
      language: this.$store.state.session.user.lang || this.$i18n.locale,
      showTokenRangeDialog: false,
      currencies: [],
      currencySelected: {}
    }
  },
  watch: {
    '$i18n.locale': {
      handler () {
        const languages = this.$t('languages')
        this.languages = []
        for (let key in languages) {
          this.languages.push({ value: key, text: languages[key] })
        }
        this.$i18n.locale = this.language
      }
    }
  },
  created () {
    const languages = this.$t('languages')
    for (let key in languages) {
      this.languages.push({ value: key, text: languages[key] })
    }
    this.getCurrencies()
    this.changeLocale()
  },
  methods: {
    getCurrencies () {
      publicService.getActiveCurrencies()
        .then(res => {
          this.currencies = res.data
          this.currencies.map(item => {
            if (item.isBase) {
              this.currencySelected = item
            }
          })
          this.$store.dispatch('session/setCurrency', this.currencySelected)
        })
    },
    currencyChange () {
      this.$store.dispatch('session/setCurrency', this.currencySelected)
    },
    changeLocale () {
      this.$store
        .dispatch('session/changeOutsideLocale', this.language)
        .then((res) => {
          this.$i18n.locale = res.user.lang
        })
      loadLocale(this.language)
    }
  }
}
</script>

<style scoped>

.styled-select,
.styled-select label[for] {
  max-width: 140px;
  font-size: 10pt;
  float: right;
}

</style>
