
export default {
  emails: 'Correos Electrónicos',
  operations_emails: 'Correos electrónicos para notificación de Operaciones de Tokens',
  email: 'Correo electrónico',
  active: 'Activo',
  invalid_email: 'Correo inválido',
  email_saved: '¡Correo electrónico guardado con éxito!',
  disabled: 'Desactivado',
  modal_enable: '¿Desea habilitar el correo electrónico?',
  modal_disable: '¿Desea inhabilitar el correo electrónico?',
  email_required: 'El correo electrónico es requerido',
  email_valid: 'El correo electrónico debe ser válido',
  sentTo: 'Destinatario',
  emails_list: 'Listado de Correos',
  date: 'Fecha de Envio',
  filter_by: 'Filtrar por',
  look_for: 'Busqueda de Correos',
  status: 'Estado',
  subject: 'Asunto',
  message: 'Mensaje',
  pending: 'Pendiente',
  inProgress: 'En progreso',
  success: 'Enviado',
  failed: 'Fallido',
  no_data: 'No hay correos para mostrar',
  dateFrom: 'Fecha desde',
  dateTo: 'Fecha Hasta',
  help_dateFrom: 'Fecha desde h',
  help_dateTo: 'Fecha hasta h'
}
