
import mapParameters from '../utils/map-parameters'
import Services from './base-services'

const service = new Services('public')

export default {
  getTokenRanges: () => {
    return service.get('list-token-ranges')
  },
  getActiveCurrencies: () => {
    return service.get('list-active-currencies')
  },
  getActiveProducts: () => {
    return service.get('list-active-products')
  },
  getCitiesByCountryCode: (code: string) => {
    return service.get(`list-cities-by-country/${code}`)
  },
  getActiveProductServices: () => {
    return service.get('list-active-product-services')
  }
}
