
<template>
  <div>
    <v-card-title v-if="!loading && info.length && activityCount" primary-title class="text-center">
      <h2 class="display-1 text-center grey--text" style="width: 100%;">
        {{ $t('outside.polls_list') }}
      </h2>
    </v-card-title>
    <v-card-text :class="{'px-0': $vuetify.breakpoint.xs}">
      <v-row justify="center">
        <template v-if="info.length && activityCount">
          <template v-if="!productSelected">
            <template v-for="product in info">
              <v-col sm="6" cols="12" :key="product.id" v-if="product.activitiesByProduct.length">
                <v-card
                  class="px-4 py-4"
                  style="cursor: pointer"
                  @click="setAndSeeActivities(product.activitiesByProduct, product.code)"
                >
                  <v-row>
                    <v-col cols="12" align="center">
                      <img :src="product.logo || product.defaultLogo" :alt="product.name" width="180em" height="180em">
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" align="center">
                      <h1 class="headline">{{ product.name }}</h1>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" align="center">
                      <v-chip color="primary">
                        {{ product.activitiesByProduct.length }} {{ product.activitiesByProduct.length === 1 ? $t('dashboard.pending_activity') : $t('dashboard.pending_activities') }}
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </template>
          </template>
          <display-pulse-evaluations v-else-if="productSelected === 'FuNtJB' && activities.length" :info="activities"/>
          <display-cultura-evaluations v-else-if="productSelected === 'DFntHL' && activities.length" :info="activities"/>
          <display-dml-evaluations v-else-if="productSelected === 'AzEgeL' && activities.length" :info="activities"/>
          <display-cc180-evaluations v-else-if="productSelected === 'fwnuNZ' && activities.length" :info="activities"/>
          <display-por-evaluations v-else-if="productSelected === 'hNbpnu' && activities.length" :info="activities"/>
          <display-leadership360-evaluations v-else-if="productSelected === 'gLbSYp' && activities.length" :info="activities"/>
          <display-energy-compass-evaluations v-else-if="productSelected === 'LiPKmX' && activities.length" :info="activities"/>
          <display-deip-evaluations v-else-if="productSelected === 'oHVyMS' && activities.length" :info="activities"/>
          <display-tmp-evaluations v-else-if="productSelected === 'lr4Foc' && activities.length" :info="activities"/>
          <!-- <v-col cols="12" align="centered" class="py-10" v-else>
            <h1 class="display-2 my-10 text-center" style="color: #90a4ae">
              {{ $t('dashboard.no_pending_activities') }}
            </h1>
          </v-col> -->
        </template>
        <v-col cols="12" align="center" v-else-if="!loading">
          <h2 class="display-1 mb-12 text-center" style="width: 100%; color: #90a4ae">
            {{ $t('outside.no_pending_polls') }}
          </h2>

          <v-btn outlined large rounded
            color="primary"
            class="mt-4"
            @click="goBack()"
          >
            {{ $t('input.go_back') }}
          </v-btn>
        </v-col>
      </v-row>
      <!--
      <v-row>
        <v-col class="pt-6 pb-0">
          <v-alert v-if="typeof error === 'string'" color="error" outlined :value="error !== ''">
            <span class="font-weight-bold" v-if="error" v-html="$t(`errors.${error}`)"></span>
          </v-alert>
          <template v-else>
            <v-alert color="error" outlined :value="true" class="mb-0">
              <strong>{{ $t('errors.error_verify-identification/products-conection-fail') }}</strong><br/>
              <span v-for="(err, i) in error" :key="i" class="font-weight-bold">{{ err.product.name }}<br/></span>
              <strong>{{ $t('errors.error_verify-identification/products-conection-fail2') }}</strong><br/>
            </v-alert>
          </template>
        </v-col>
      </v-row>
      -->
    </v-card-text>
    <v-row v-if="productSelected !== ''">
      <v-col align="center">
        <v-btn outlined
          color="primary"
          rounded
          large
          @click="goProducts()"
        >
          {{ $t('input.go_back') }}
        </v-btn>
      </v-col>
    </v-row>
    <x-loading
      :display="loading"
    ></x-loading>
  </div>
</template>

<script>
import Vue from 'vue'

import AuthService from '../../services/auth'
import DisplayDeipEvaluations from './polls-display/deip.vue'
import DisplayEnergyCompassEvaluations from './polls-display/energy-compass.vue'
import DisplayLeadership360Evaluations from './polls-display/leadership360.vue'
import DisplayDmlEvaluations from './polls-display/dml.vue'
import DisplayCulturaEvaluations from './polls-display/cultura.vue'
import DisplayPulseEvaluations from './polls-display/pulse.vue'
import DisplayCc180Evaluations from './polls-display/cc180.vue'
import DisplayPorEvaluations from './polls-display/por.vue'
import DisplayTmpEvaluations from './polls-display/tmp.vue'

export default Vue.extend({
  components: {
    DisplayDeipEvaluations,
    DisplayEnergyCompassEvaluations,
    DisplayLeadership360Evaluations,
    DisplayDmlEvaluations,
    DisplayCulturaEvaluations,
    DisplayPulseEvaluations,
    DisplayCc180Evaluations,
    DisplayPorEvaluations,
    DisplayTmpEvaluations
  },
  data () {
    return {
      info: [],
      activities: null,
      activityCount: 0,
      productSelected: '',
      loading: false,
      error: ''
    }
  },
  created () {
    this.getPolls()
  },
  methods: {
    getPolls () {
      this.loading = true
      return AuthService.getPollList({
        identification: this.$route.params.identification,
        identifyTypeId: this.$route.params.type,
        token: this.$route.params.token
      })
        .then(res => {
          this.info = res.activities
          this.activityCount = this.info.reduce((acc, obj) => {
            return acc + obj.activitiesByProduct.length
          }, 0)

          if (res.errors.length) {
            this.error = res.errors
            this.error.forEach(x => {
              if (x.error) {
                console.log(x.product.name, x.error.msg)
              }
            })
          }
        })
        .catch((error) => {
          this.error = error.code
          this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
        })
        .finally(() => { this.loading = false })
    },
    setAndSeeActivities (activities, productName) {
      this.activities = activities
      this.productSelected = productName.trim()
    },
    goBack () {
      this.goProducts()
      window.history.back()
    },
    goProducts () {
      this.activities = null
      this.productSelected = ''
    }
  }
})
</script>

<style lang="css" scoped>
.text-center{
  text-align: center !important;
}
</style>
