
<template>
  <v-container fluid>
    <v-row wrap row>
      <v-col cols="12" md="6">
        <h4 class="display-1 mt-1">{{$t('models.groups') }}</h4>
      </v-col>

      <v-col cols="12" md="6"  class="text-right">
        <v-row justify="end">
          <v-btn
            color="primary"
            to="groups/create"
            class="text-capitalize"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>
            {{ $t('groups.add_group') }}
          </v-btn>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-divider></v-divider>
        <v-card>
          <v-card-title>
            <div class="title">{{ $t('groups.groups_list') }}</div>
          </v-card-title>

          <v-divider class="my-3"></v-divider>
          <x-data-table
            :headers="headers"
            :parent-fetch-data="getGroups"
            no-data="groups.no_data"
          >
            <template v-slot:structure="prop">
              <td class="text-center">{{ prop.item.name }}</td>
              <td class="text-center">{{ prop.item.leaderName? prop.item.leaderName : '--' }}</td>
              <td class="text-center">{{ prop.item.enterprisesCount }}</td>
              <td class="text-center">{{ prop.item.balance ? prop.item.balance : '--' }}</td>
              <td class="text-center">
                <v-switch
                  v-model="prop.item.active"
                  class="ma-2"
                  @click="openModal(prop.item.active, prop.item)"
                />
              </td>
              <td class="text-xs-center px-0">
                <v-tooltip
                  v-if="prop.item.updatePlanRequestCount > 0"
                  bottom
                  color="green"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`groups/${prop.item.id}/admin-update`"
                      v-on="on"
                      icon>
                      <v-icon small>restore_page</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.admin_update') }}</span>
                </v-tooltip>

                <v-tooltip
                  v-if="prop.item.extendPlanRequestCount > 0"
                  bottom
                  color="green"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`groups/${prop.item.id}/admin-extend`"
                      v-on="on"
                      icon>
                      <v-icon small>restore_page</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.admin_extension') }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`/groups/${prop.item.id}/show`"
                      v-on="on"
                      icon>
                      <v-icon small >remove_red_eye</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('input.view_details') }}
                  </span>
                </v-tooltip>

                <v-tooltip bottom color="green">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`groups/${prop.item.id}/edit`"
                      v-on="on"
                      icon>
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.edit') }}</span>
                </v-tooltip>
              </td>
            </template>
          </x-data-table>
        </v-card>
      </v-col>
    </v-row>

    <x-confirmation-modal
      :show.sync="modalEna.open"
      :reversible="true"
      :title="$t('groups.modal_enable')"
      :action="enableGroup"
      @close="modalEna.open = false"
      :btn-save="$t('groups.modal_save_btn')"
    >
      <template v-slot:question>{{ $t('groups.modal_enable_des') }}</template>
    </x-confirmation-modal>

    <x-confirmation-modal
      :show.sync="modalDis.open"
      :reversible="true"
      :title="$t('groups.modal_disable')"
      :action="disableGroup"
      @close="modalDis.open = false"
      :btn-save="$t('groups.modal_save_btn')"
    >
      <template v-slot:question>{{ $t('groups.modal_disable_des') }}</template>
    </x-confirmation-modal>

  </v-container>
</template>

<script>

import groupService from '../../services/groups'

export default {
  data () {
    return {
      headers: [
        'groups.group_name',
        'groups.main_customer',
        'groups.enterprises_no',
        'groups.actual_tokens',
        'groups.status',
        'groups.actions'
      ],
      modalDis: {
        open: false,
        item: {}
      },
      modalEna: {
        open: false,
        item: {}
      },
      options: {
        filter: null,
        search: null
      }
    }
  },
  methods: {
    getGroups (options) {
      return groupService.list(options)
        .then((res) => ({
          items: res.items,
          total: res.total
        }))
    },
    disableGroup () {
      return groupService.disable(this.modalDis.item.id)
        .then((res) => {
          this.modalDis.item.active = false
          this.modalDis.open = false
        })
    },
    enableGroup () {
      return groupService.enable(this.modalEna.item.id)
        .then((res) => {
          this.modalEna.item.active = true
          this.modalEna.open = false
        })
    },
    openModal (active, item) {
      if (active) {
        this.modalDis.item = item
        this.modalDis.open = true
      } else {
        this.modalEna.item = item
        this.modalEna.open = true
      }
    }
  }
}
</script>
