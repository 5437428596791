
export default {
  insert_document: 'Enter your document number',
  insert_document2: 'Only letters and numbers are allowed',
  verify: 'Verify assessments',
  polls_list: 'Available assessments',
  no_pending_polls: 'You have available assessments',
  pending_polls_header: [
    { text: 'Name', value: '', sortable: false, class: 'text-xs-left' },
    { text: 'Type', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Completion', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Actions', value: '', sortable: false, class: 'text-xs-center' }
  ],
  fill_poll: 'Perform assessment',
  back_to_verify_polls: 'Back to verify assessments'
}
