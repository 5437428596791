
<template>
  <trans-wrapper
    :page-title="$t('settings.job_type')"
    :add-label="$t('settings.add')"
    :card-title="$t('settings.job_types')"
    :alert-info="$t('settings.job_type_info')"
    :modal-title-add="$t('settings.job_type_add')"
    :modal-title-ena="$t('settings.job_type_enable')"
    :modal-title-dis="$t('settings.job_type_disable')"
    :modal-title-del="$t('settings.job_type_delete')"
    :modal-question-ena="$t('settings.master_references_enable_des')"
    :modal-question-dis="$t('settings.master_references_disable_des')"
    :modal-question-del="$t('settings.master_references_delete_des')"
    :item-services="jobTypesService"
  ></trans-wrapper>
</template>

<script>

import jobTypesService from '../../services/job-types'

import TransWrapper from './components/trans-wrapper.vue'

export default {
  components: {
    TransWrapper
  },
  data () {
    return {
      jobTypesService
    }
  }
}
</script>
