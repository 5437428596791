
export default {
  dashboard: 'Tablero',
  enterprises: 'Empresas',
  employees: 'Colaboradores',
  setting: 'Configuración',
  academic_degrees: 'Nivel académico',
  languages: 'Idiomas',
  departments: 'Área / Departamento',
  countries: 'País',
  demographic_cuts: 'Cortes demográficos',
  enterprise_sizes: 'Tamaños de empresa',
  genders: 'Género',
  job_types: 'Tipo de contratación',
  sectors: 'Sectores',
  committalQuestions: 'Preguntas de compromiso',
  wordQuestions: 'Preguntas abiertas',
  group: 'Grupo',
  headquarters: 'Sede',
  charges: 'Nivel de cargo',
  ages: 'Rango de edad',
  antiquity: 'Rango de antigüedad',
  sign_out: 'Cerrar sesión',
  token: 'OCC Tokens',
  profile: 'Perfil',
  go_back: 'Volver',
  language: 'Idioma',
  calculator: 'Calculadora',
  operations: 'Resumen de Operaciones',
  services: 'Tarifas y recomendaciones',
  enterprises_list: 'Empresas',
  profile_list: 'Perfil de la empresa',
  measuring_tools: 'Herramientas',
  customers: 'Clientes',
  terms_and_conditions: 'Términos y condiciones',
  products: 'Productos',
  token_price: 'Precio de Tokens',
  currencies: 'Divisas',
  emails: 'Correos electrónicos',
  operations_emails: 'Correo operaciones',
  additional_demographics1: 'Demográfico adicional 1',
  additional_demographics2: 'Demográfico adicional 2',
  additional_segmentation: 'Segmentación Adicional'
}
