
<template>
  <v-container>
    <v-row justify="start">
      <v-col class="display-1">
        {{ $t('paymentResult.title') }}
      </v-col>
      <v-col md="4" lg="3">
        <v-card v-if="hasBalance">
          <v-row>
            <v-col>
              <h5 class="caption font-weight-light text-center text-uppercase">{{ $t('tokens.balance') }}</h5>
              <h5 class="headline font-weight-black text-center">{{ balance }}</h5>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row  class="child-flex">
      <v-card v-if="!loading && paymentInfo">
        <v-simple-table class="horizontal-table">
          <template v-slot:default>
            <tbody>
            <tr>
              <th>{{ $t('paymentResult.business_name') }}</th>
              <th>{{ $t('paymentResult.customer') }}</th>
              <th>{{ $t('paymentResult.identification') }}</th>
            </tr>
            <tr>
              <td>OCC SOLUTIONS</td>
              <td>{{ paymentInfo.ReferenceArray[3] }}</td>
              <td>{{ paymentInfo.ReferenceArray[0] }} {{ paymentInfo.ReferenceArray[1] }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-simple-table class="vertical-table">
          <template v-slot:default>
            <tbody>
            <tr>
              <th>{{ $t('paymentResult.transaction_number') }}</th>
              <td>{{ paymentInfo.TicketId }}</td>
            </tr>
            <tr>
              <th>{{ $t('paymentResult.transaction_status') }}</th>
              <td><v-chip :color="statusColor" label outlined>{{ paymentInfo.Estado }}</v-chip></td>
            </tr>
            <tr>
              <th>{{ $t('paymentResult.payment_system') }}</th>
              <td>{{ formaPago }}</td>
            </tr>
            <tr>
              <th>{{ $t('paymentResult.finnancial_institution') }}</th>
              <td>{{ paymentInfo.FiName }}</td>
            </tr>
            <tr>
              <th>{{ $t('paymentResult.datetime') }}</th>
              <td>{{ fechaHora }}</td>
            </tr>
            <tr>
              <th>{{ $t('paymentResult.payment_desc') }}</th>
              <td>{{ paymentInfo.PaymentDesc }}</td>
            </tr>
            <tr>
              <th>{{ $t('paymentResult.tokens') }}</th>
              <td>{{ paymentInfo.mount }}</td>
            </tr>
            <tr>
              <th>{{ $t('paymentResult.currency') }}</th>
              <td>{{ paymentInfo.PayCurrency }}</td>
            </tr>
            <tr>
              <th>{{ $t('paymentResult.cost') }}</th>
              <td>$ {{ (paymentInfo.TransValue - paymentInfo.TransVatValue).toFixed(2) }}</td>
            </tr>
            <tr>
              <th>{{ $t('paymentResult.iva') }}</th>
              <td>$ {{ paymentInfo.TransVatValue }}</td>
            </tr>
            <tr>
              <th>{{ $t('paymentResult.amount') }}</th>
              <td>$ {{ paymentInfo.TransValue }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-card-actions class="d-print-none">
          <v-spacer></v-spacer>
          <v-btn @click="imprimir" color="blue">
            <v-icon>mdi-printer</v-icon>Imprimir
          </v-btn>
          <v-btn color="blue" to="/tokens/operations">Resumen Operaciones</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <x-loading
      :display="loading"
    ></x-loading>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import tokenAccountDetailService from '../../services/token-account-detail'
import paymentsService from '../../services/payments'

export default {
  data () {
    return {
      balance: 0,
      hasBalance: false,
      loading: true,
      paymentInfo: null,
      paymentSystemList: [
        { code: '0', name: 'PSE' },
        { code: '1', name: 'Tarjeta de Crédito' }
      ]
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    fechaHora () {
      if (this.paymentInfo.BankProcessDate) {
        return (new Date(this.paymentInfo.BankProcessDate)).toLocaleString()
      } else {
        return ''
      }
    },
    formaPago () {
      if (this.paymentInfo.PaymentSystem) {
        const fp = this.paymentSystemList.find(f => f.code === this.paymentInfo.PaymentSystem)
        return fp ? fp.name : ''
      } else {
        return ''
      }
    },
    statusColor () {
      let color
      switch (this.paymentInfo.Estado) {
        case 'APROBADA':
          color = 'green'
          break
        case 'PENDIENTE':
          color = 'blue'
          break
        default:
          color = 'red'
          break
      }
      return color
    }
  },
  methods: {
    imprimir () {
      window.print()
    },
    getBalance () {
      return tokenAccountDetailService.getBalance()
        .then((res) => {
          this.balance = res.balance === undefined ? 0 : res.balance
        })
    },
    getTransactionInformation (transactionId) {
      return paymentsService.getTransactionInformation(transactionId)
        .then((res) => {
          this.paymentInfo = res
          let status
          switch (this.paymentInfo.TranState) {
            case 'OK':
              status = 'APROBADA'
              break
            case 'NOT_AUTHORIZED':
              status = 'NO AUTORIZADA'
              break
            case 'EXPIRED':
            case 'FAILED':
              status = 'FALLIDA'
              break
            case 'BANK':
            case 'PENDING':
            case 'CAPTURED':
            case 'CREATED':
              status = 'PENDIENTE'
              break
          }
          this.paymentInfo.Estado = status
          console.log('this.paymentInfo >', this.paymentInfo)
          this.getBalance()
        })
    }
  },
  created () {
    this.loading = true
    Promise.all([
      this.getTransactionInformation(this.$route.params.transactionId)
    ]).catch(err => {
      console.log(err)
      const error = err.code ? this.$t(`errors.${err.code}`) : err
      this.$store.dispatch('alert/error', error)
    }).finally(() => {
      this.loading = false
    })

    if (this.user.role === 'customer') {
      this.hasBalance = true
    }
  }
}
</script>

<style scoped>
.horizontal-table {
  padding-left: 10%;
}

.vertical-table {
  width: 70%;
  margin: auto;
}
.vertical-table th {
  width: 30%;
}
</style>

<style>
@media print {
  .v-content {
    padding: 0 !important;
  }
}
</style>
