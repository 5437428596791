
<template>
  <v-container fluid grid-list-xl>
    <template v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </div>
    </template>
    <template v-else>
      <v-row>
        <v-col xs12>
          <h4 class="display-1"> {{$t('dashboard.general_title')}} </h4>
        </v-col>
      </v-row>
      <template v-if="user.role === 'admin'">
        <dashboard-admin :info="info"/>
      </template>
      <template v-else-if="user.role === 'enterprise'">
        <dashboard-enterprise :info="info" :user="user"/>
      </template>
      <template v-else-if="user.role === 'employee'">
        <dashboard-employee :info="info"/>
      </template>
    </template>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import DashboardService from '../services/dashboard'
import DashboardAdmin from './dashboard/dashboard-admin.vue'
import DashboardEmployee from './dashboard/dashboard-employee.vue'
import DashboardEnterprise from './dashboard/dashboard-enterprise.vue'

const echarts = require('echarts')

export default {
  components: {
    DashboardAdmin,
    DashboardEmployee,
    DashboardEnterprise
  },
  data () {
    return {
      loading: true,
      info: {},
      today: new Date(),
      chart: null,
      chartTitle: '',
      rates: [],
      selected: { id: 1 },
      selectItems: [],
      loadingChart: false,
      hasChart: false,
      adminPolls: []
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.getInfo()
  },
  methods: {
    getInfo: function () {
      DashboardService.getInfo()
        .then((res) => {
          this.info = res
          this.loading = false
        })
    }
  }
}
</script>

<style lang="css">
.no-polls-msg {
  font-size: 1.8em;
  margin-left: 0.6em;
  color: gray;
}
#text-no-chart {
  margin-bottom: 10em;
}
</style>
