
export default {
  title: 'Transaction Receipt',
  transaction_status: 'Transaction Status',
  finnancial_institution: 'Financial Entity',
  transaction_number: 'Transaction Number',
  datetime: 'Date and Time',
  currency: 'Currency',
  amount: 'Transaction Total',
  payment_desc: 'Payment Description',
  business_name: 'Company Name',
  customer: 'Client',
  tokens: 'OCC Tokens',
  identification: 'Identification',
  payment_system: 'Payment method',
  cost: 'Cost',
  iva: 'VAT 19%'
}
