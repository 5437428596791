<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="750"
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-card>
          <v-toolbar light flat>
            <v-toolbar-title> {{ $t('groups.add_member') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon light @click="closeModal">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-row class="text-center" color="blue-grey lighten-5">
              <v-col cols="12">
                <v-card class="mx-6 my-6" height="95" flat>
                  <h4 class="subtitle-1 font-weight-black text-uppercase">{{ $t('groups.add_member') }}</h4>
                  <v-row wrap row class="d-flex justify-center">
                    <x-inputs-autocomplete
                      :label="'Elige un Cliente'"
                      :items="membersAvailable"
                      :input="memberId"
                      @updateInput="($event) => memberId = $event"
                      light
                      name="membersAvailable"
                      rules="required"
                    />
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="end" align="end">
              <v-col justify="end" align="end">
                <v-btn color="primary" dark type="submit" class="mr-3">
                  &nbsp;{{ $t('input.save') }}
                </v-btn>
                <v-btn color="secondary" dark @click="closeModal">
                  &nbsp;{{ $t('input.cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'
import CustomerService from '../../services/customers'

export default Vue.extend({
  props: {
    // customer: Object,
    // groupBalance: Number,
    action: Function
  },
  data () {
    return {
      membersAvailable: [],
      memberId: '',
      show: true
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    return CustomerService.listNoGrouped().then((res) => {
      this.membersAvailable = res.items.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      })
    })
  },
  methods: {
    closeModal () {
      this.show = !this.show
      this.$emit('close', this.enterprise)
    },
    minus () {
      this.inputValue--
    },
    plus () {
      this.inputValue++
    },
    onSubmit () {
      return this.action(this.memberId)
        .then(() => this.closeModal())
    }
  }
})
</script>
