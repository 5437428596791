
<template>
  <trans-wrapper
    :page-title="$t('settings.gender')"
    :add-label="$t('settings.add')"
    :card-title="$t('settings.genders')"
    :alert-info="$t('settings.gender_info')"
    :modal-title-add="$t('settings.gender_add')"
    :modal-title-ena="$t('settings.gender_enable')"
    :modal-title-dis="$t('settings.gender_disable')"
    :modal-title-del="$t('settings.gender_delete')"
    :modal-question-ena="$t('settings.master_references_enable_des')"
    :modal-question-dis="$t('settings.master_references_disable_des')"
    :modal-question-del="$t('settings.master_references_delete_des')"
    :item-services="gendersService"
  ></trans-wrapper>
</template>

<script>

import gendersService from '../../services/genders'

import TransWrapper from './components/trans-wrapper.vue'

export default {
  components: {
    TransWrapper
  },
  data () {
    return {
      gendersService
    }
  }
}
</script>
