
import Vue from 'vue'
import Router from 'vue-router'

import AppLayout from '../views/app-layout.vue'
import AuthLayout from '../views/auth/auth-layout.vue'
import Calculator from '../views/public-calculator/index-old.vue'
import CreatePassword from '../views/auth/create-password.vue'
import ResetPassword from '../views/auth/reset-password.vue'
import SelectCustomerType from '../views/auth/select-customer-type.vue'
import SignIn from '../views/auth/sign-in.vue'
import SignUpCommercial from '../views/auth/sign-up-commercial.vue'
import SignUpFinished from '../views/auth/sign-up-finished.vue'
import SignUpPersonal from '../views/auth/sign-up-personal.vue'
import UpdatePassword from '../views/auth/update-password.vue'
import CustomerCommercialProfile from '../views/customers/commercial-profile.vue'
import CustomerCreateCommercial from '../views/customers/create.vue'
import CustomerEditCommercial from '../views/customers/edit.vue'
import CustomerEnterpriseProfile from '../views/customers/enterprise-profile.vue'
import CustomersList from '../views/customers/list.vue'
import CustomerEnterpriseMesuringTools from '../views/customers/measuring-tools.vue'
import Dashboard from '../views/dashboard.vue'
import EmailsList from '../views/emails/list.vue'
import EmployeeCreate from '../views/employees/create.vue'
import EmployeeEdit from '../views/employees/edit.vue'
import EmployeesList from '../views/employees/list.vue'
import EmployeeMassiveUpload from '../views/employees/massive-upload.vue'
import EmployeeShow from '../views/employees/show.vue'
import EnterprisesCreate from '../views/enterprises/create.vue'
import EnterpriseDetails from '../views/enterprises/details.vue'
import EnterprisesEdit from '../views/enterprises/edit.vue'
import EnterprisesListAdmin from '../views/enterprises/list-admin.vue'
import EnterprisesList from '../views/enterprises/list.vue'
import EnterprisesProfile from '../views/enterprises/profile.vue'
import EnterprisesEditProfile from '../views/enterprises/self-edit.vue'
import Forbidden from '../views/errors/forbidden.vue'
import NotFound from '../views/errors/not-found.vue'
import GroupCreate from '../views/groups/create.vue'
import GroupEdit from '../views/groups/edit.vue'
import GroupsListEnterprises from '../views/groups/list-enterprises.vue'
import GroupsList from '../views/groups/list.vue'
import GroupOperations from '../views/groups/operations.vue'
import GroupProfile from '../views/groups/profile.vue'
import GroupShow from '../views/groups/show.vue'
import Home from '../views/home.vue'
import Maintenance from '../views/maintenance.vue'
import Notifications from '../views/notifications/notifications.vue'
import Products from '../views/products.vue'
import PaymentResult from '../views/token/payment-result.vue'
import SettingsAcademicDegrees from '../views/settings/academic-degrees.vue'
import SettingsAges from '../views/settings/age-range.vue'
import SettingsAntiquity from '../views/settings/antiquity.vue'
import SettingsCharges from '../views/settings/charges.vue'
import SettingsCreateTermsAndConditions from '../views/settings/components/create-terms-and-conditions.vue'
import SettingsShowTermsAndConditions from '../views/settings/components/read-terms-and-conditions.vue'
import SettingsCreateDataProtectionPolicies from '../views/settings/components/create-data-protection-policies.vue'
import SettingsShowDataProtectionPolicies from '../views/settings/components/read-data-protection-policies.vue'
import SettingsCountries from '../views/settings/countries.vue'
import SettingsDepartments from '../views/settings/departments.vue'
import SettingsAdditionalDemographics1 from '../views/settings/additional-demographics1.vue'
import SettingsAdditionalDemographics2 from '../views/settings/additional-demographics2.vue'
import SettingsAdditionalSegmentation from '../views/settings/additional-segmentation.vue'
import SettingsEnterpriseAcademicDegrees from '../views/settings/enterprise-academic-degrees.vue'
import SettingsEnterpriseCountries from '../views/settings/enterprise-countries.vue'
import SettingsEnterpriseSize from '../views/settings/enterprise-sizes.vue'
import SettingsGenders from '../views/settings/genders.vue'
import SettingHeadquarters from '../views/settings/headquarters.vue'
import SettingsJobTypes from '../views/settings/job-types.vue'
import SettingsLanguage from '../views/settings/language.vue'
import SettingProductDetails from '../views/settings/product-details.vue'
import CurrenciesCreate from '../views/currencies/create.vue'
import CurrenciesEdit from '../views/currencies/edit.vue'
import CurrenciesList from '../views/currencies/list.vue'
import PollsList from '../views/auth/polls-list.vue'
import ProductSettings from '../views/settings/product-settings.vue'
import QuotationPDF from '../views/public-calculator/quotation.vue'
import SettingProducts from '../views/settings/products.vue'
import SettingsSectors from '../views/settings/sectors.vue'
import SettingServiceProduct from '../views/settings/service-edit.vue'
import SettingsTermsAndConditions from '../views/settings/terms-and-conditions.vue'
import SettingTokensPrice from '../views/settings/token-price-edit.vue'
import SettingTokens from '../views/settings/token-price.vue'
import TokenCalculatorView from '../views/token/calculator-view.vue'
import TokenOperations from '../views/token/operations.vue'
import TokenOperationsEmails from '../views/token-operations/list.vue'
import TokenServices from '../views/token/services.vue'
import TokenMaintenance from '../views/token/maintenance-tokens.vue'
import VerifyIdentification from '../views/auth/verify-identification.vue'
import WaitingProgress from '../views/waiting.vue'
import RequireAuthentication from './guards/require-authentication'
import RequireGuest from './guards/require-guest'
import RequireAuthenticationNotCoAdmin from './guards/require-authentication-not-co-admin'
import SsoAuthentication from './guards/sso-authentication'
import Acl from './guards/acl'

const acl: any = new Acl()

Vue.use(Router)

const routes = [
  {
    path: '/calculator',
    component: Calculator,
    beforeEnter: RequireGuest
  },
  {
    path: '/quotation/:uuid',
    component: QuotationPDF,
    beforeEnter: RequireGuest
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: 'sso',
        beforeEnter: SsoAuthentication
      },
      {
        path: 'sign-in',
        component: SignIn,
        beforeEnter: RequireGuest
      },
      {
        path: 'reset-password',
        component: ResetPassword,
        beforeEnter: RequireGuest
      },
      {
        path: 'update-password/:token',
        component: UpdatePassword,
        beforeEnter: RequireGuest
      },
      {
        path: 'create-password/:token',
        component: CreatePassword,
        beforeEnter: RequireGuest
      },
      {
        path: 'sign-up',
        component: SelectCustomerType,
        beforeEnter: RequireGuest
      },
      {
        path: 'sign-up-as-personal',
        name: 'SignUpPersonal',
        component: SignUpPersonal,
        beforeEnter: RequireGuest
      },
      {
        path: 'sign-up-as-commercial',
        component: SignUpCommercial,
        beforeEnter: RequireGuest
      },
      {
        name: 'SignUpFinished',
        path: 'sign-up-completed',
        component: SignUpFinished,
        beforeEnter: RequireGuest,
        props: true
      },
      {
        path: 'verify-polls/:token',
        component: VerifyIdentification
      },
      {
        path: 'list-polls/:token/:identification/:type',
        component: PollsList
      }
    ]
  },
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '',
        component: Home,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/dashboard',
        component: Dashboard,
        beforeEnter: RequireAuthenticationNotCoAdmin
      },
      {
        path: '/admin-enterprises',
        component: EnterprisesListAdmin,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/admin-customers',
        name: 'AdminCustomers',
        props: true,
        component: CustomersList,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/measuring-tools',
        component: CustomerEnterpriseMesuringTools,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/commercial-profile',
        component: CustomerCommercialProfile,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/enterprises',
        component: EnterprisesList,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/enterprises/create',
        component: EnterprisesCreate,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/enterprises/:id/edit',
        component: EnterprisesEdit,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/enterprises/profile/:id',
        component: EnterpriseDetails,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/customer/maesuring-tools',
        component: CustomerEnterpriseMesuringTools,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/customer/show-profile',
        component: CustomerEnterpriseProfile,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/customer/create-commercial',
        component: CustomerCreateCommercial,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/customer/create-personal',
        component: EnterprisesCreate,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/customer/select-type',
        component: SelectCustomerType,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/customer/:id/edit',
        component: CustomerEditCommercial,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/employees',
        component: EmployeesList,
        beforeEnter: acl.EmployeesList || RequireAuthentication
      },
      {
        path: '/employees/create',
        component: EmployeeCreate,
        beforeEnter: acl.EmployeesCreate || RequireAuthentication
      },
      {
        path: '/employees/massive-upload',
        component: EmployeeMassiveUpload,
        beforeEnter: acl.EmployeesMassiveUpload || RequireAuthentication
      },
      {
        path: '/employees/:id/edit',
        component: EmployeeEdit,
        beforeEnter: acl.EmployeesEdit || RequireAuthentication
      },
      {
        path: '/employees/:id/show',
        component: EmployeeShow,
        beforeEnter: acl.EmployeesGetOne || RequireAuthentication
      },
      {
        path: '/setting/enterprise',
        component: EnterpriseDetails,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/terms-and-conditions',
        component: SettingsTermsAndConditions,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/terms-and-conditions/:id/edit',
        component: SettingsCreateTermsAndConditions,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/terms-and-conditions/:id/show',
        component: SettingsShowTermsAndConditions,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/data-protection-policies/:id/edit',
        component: SettingsCreateDataProtectionPolicies,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/data-protection-policies/:id/show',
        component: SettingsShowDataProtectionPolicies,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/setting/enterprise/:first',
        component: EnterprisesProfile,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/setting/enterprise/:step/edit',
        component: EnterprisesEditProfile,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/academic-degrees',
        component: SettingsAcademicDegrees,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/countries',
        component: SettingsCountries,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/languages',
        component: SettingsLanguage,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/enterprise-sizes',
        component: SettingsEnterpriseSize,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/sectors',
        component: SettingsSectors,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/products',
        component: SettingProducts,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/tokens',
        component: SettingTokens,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/token-price-edit/:id',
        component: SettingTokensPrice,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/product-details/:id/:name',
        component: SettingProductDetails,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/product/:product_id/:product_name/service-edit/:service_id/:service_name',
        component: SettingServiceProduct,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/departments',
        component: SettingsDepartments,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/additional-demographics1',
        component: SettingsAdditionalDemographics1,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/additional-demographics2',
        component: SettingsAdditionalDemographics2,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/genders',
        component: SettingsGenders,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/headquarters',
        component: SettingHeadquarters,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/job-types',
        component: SettingsJobTypes,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/charges',
        component: SettingsCharges,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/ages',
        component: SettingsAges,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/antiquity',
        component: SettingsAntiquity,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/enterprise/countries',
        component: SettingsEnterpriseCountries,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/enterprise/academic-degrees',
        component: SettingsEnterpriseAcademicDegrees,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/additional-segmentation',
        component: SettingsAdditionalSegmentation,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/products/:id/configuration',
        component: ProductSettings,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/token-operations-emails',
        component: TokenOperationsEmails,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/currencies/create',
        component: CurrenciesCreate,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/currencies/:id/edit',
        component: CurrenciesEdit,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/settings/currencies',
        component: CurrenciesList,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/notifications',
        component: Notifications,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/groups',
        component: GroupsList,
        beforeEnter: acl.GroupsList || RequireAuthentication
      },
      {
        path: '/groups/enterprises',
        component: GroupsListEnterprises,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/groups/create',
        component: GroupCreate,
        beforeEnter: acl.GroupsCreate || RequireAuthentication
      },
      {
        path: '/groups/:id/edit',
        component: GroupEdit,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/groups/:id/show',
        component: GroupShow,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/groups/profile',
        component: GroupProfile,
        beforeEnter: acl.GroupsProfile || RequireAuthentication
      },
      {
        path: '/groups/operations',
        name: 'GroupOperations',
        component: GroupOperations,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/tokens/operations/:transactionId',
        component: PaymentResult,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/tokens/operations',
        component: TokenOperations,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/tokens/calculator-view',
        component: TokenCalculatorView,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/tokens/services',
        component: TokenServices,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/tokens/maintenance',
        component: TokenMaintenance,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/product/:product',
        component: Products,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/emails',
        component: EmailsList,
        beforeEnter: RequireAuthentication
      }
    ]
  },
  {
    path: '/wp/:progress/:role?',
    component: WaitingProgress
  },
  {
    path: '/forbidden',
    component: Forbidden
  },
  {
    path: '/maintenance',
    component: Maintenance
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    component: NotFound
  },
  {
    path: '*',
    redirect: '/404'
  }
]

export default new Router({
  mode: 'history',
  routes
})
