<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="600"
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-card>
          <v-toolbar light flat>
            <v-toolbar-title>{{ $t('input.manage_authorizations')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon light @click="closeModal">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-row class="text-center" color="blue-grey lighten-5">
              <v-col cols="10" class="offset-1">
                <v-card raised>
                  <v-card-text class="pa-2">
                    <p class="subtitle-1">
                      {{ $t('tokens.authorization_message_p1') }}
                      <span class="subtitle-1 font-weight-black">{{ $t('tokens.authorization_message') }} {{ getName() }}</span>
                      {{ $t('tokens.authorization_message_p2') }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="text-center" color="blue-grey lighten-5">
              <v-col cols="6" class="offset-3">
                <v-card class="mx-6 my-6 pa-4">
                  <h4 class="subtitle-1 font-weight-black text-uppercase">{{ $t('tokens.authorization') }}</h4>
                  <v-row wrap row class="d-flex justify-center">
                    <v-switch
                      v-model="inputValue"
                    />
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="end" align="end">
              <v-col justify="end" align="end">
                <v-btn class="mr-3" color="secondary" dark @click="closeModal">
                  &nbsp;{{ $t('input.cancel') }}
                </v-btn>
                <v-btn color="primary" type="submit" :disabled="inputValue===getStatus()">
                  &nbsp;{{ $t('input.save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  props: {
    enterprise: Object,
    customer: Object,
    action: Function
  },
  data () {
    return {
      show: true,
      inputValue: false
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    if (this.enterprise) {
      this.inputValue = this.enterprise.isAuthorized !== undefined
    } else {
      this.inputValue = this.customer.isAuthorized !== undefined
    }
  },
  methods: {
    getStatus () {
      if (this.enterprise) {
        return this.enterprise.isAuthorized !== undefined
      } else {
        return this.customer.isAuthorized !== undefined
      }
    },
    closeModal () {
      this.show = !this.show
      this.$emit('close', this.enterprise)
    },
    getName () {
      if (this.enterprise && this.enterprise.id) {
        return this.enterprise.name
      }
      if (this.customer && this.customer.customerId) {
        return this.customer.customer.name
      }
    },
    onSubmit () {
      let tokenAccountId
      if (this.enterprise && this.enterprise.isAuthorized) {
        tokenAccountId = this.enterprise.isAuthorized.tokenAccountId
      } else if (this.customer && this.customer.isAuthorized) {
        tokenAccountId = this.customer.isAuthorized.tokenAccountId
      } else {
        tokenAccountId = 0
      }
      if (this.enterprise) {
        return this.action(this.enterprise.id, tokenAccountId, this.inputValue)
          .then(() => this.closeModal())
      } else {
        return this.action(this.customer.customerId, tokenAccountId, this.inputValue)
          .then(() => this.closeModal())
      }
    }
  }
})
</script>
