
<template>
  <v-container>

    <v-row justify="start">
      <v-col class="display-1">
        {{ $t('settings.tokens_title') }}
      </v-col>
    </v-row>

    <v-row class="child-flex">
      <v-col>
        <v-card class="mx-5">
          <v-row class="child-flex">
            <v-simple-table>
              <template v-slot:default>
                <thead class="blue lighten-1">
                  <tr>
                    <th class="text-left text-uppercase" style="color: white;">
                      {{ $t('pricing.table_ranges') }}
                    </th>
                    <th class="text-center"></th>
                    <th class="text-right text-uppercase" style="color: white;">{{ $t('pricing.table_prices') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item of prices" v-bind:key="item.id">
                    <td style="padding: 0px">
                      <v-row class="text-right">
                        <v-col sm="3"><span>{{ item.minRange }}</span></v-col>
                        <v-col v-if="item.maxRange !== '~'" sm="3"><span>{{ item.maxRange }}</span></v-col>
                        <v-col v-else sm="3"><span>{{ $t('pricing.ormore') }}</span></v-col>
                      </v-row>
                    </td>
                    <td></td>
                    <td>
                      <v-row justify="end" class="text-right" align="center">
                        <v-col sm="2">USD</v-col>
                        <v-col sm="2">{{ item.price }}</v-col>
                        <v-col cols="1" class="text-left mr-2" style="color: #424242;">
                          <v-tooltip bottom color="blue">
                            <template v-slot:activator="{ on }">
                              <v-btn color="green lighten-2" icon v-on="on" :to="`/settings/token-price-edit/${item.id}`">
                                <v-icon small>mdi-pencil-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>
                              {{ $t('settings.tokens_edit_price') }}
                            </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import tokenPriceService from '../../services/token-price'

export default {
  data () {
    return {
      prices: []
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    return tokenPriceService.getActives()
      .then((res) => {
        this.prices = res.sort((a, b) => {
          return a.minRange - b.minRange
        })
        this.prices.map(range => {
          range.price = Number(range.price).toFixed(2)
        })
        this.prices[res.length - 1].maxRange = '~'
      })
  }
}

</script>
