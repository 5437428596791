
export default {
  add_employee: 'Agregar colaborador',
  employee_fields: 'Datos del colaborador',
  birthdate: 'Fecha de nacimiento',
  personal_data: 'Datos personales',
  employment_data: 'Datos laborales',
  created_employee: 'Colaborador creado exitosamente',
  import_employees: 'Importar colaboradores',
  download_template: 'Descargar plantilla',
  download_docs: 'Descargar instructivo',
  upload_description: 'Cargue un archivo excel (.xls/.xlsx) o csv (.csv) que contenga los datos de sus colaboradores. Esta acción actualizará su base total de colaboradores, por lo que debe agregar la base completa de colaboradores disponibles.',
  select_file_to_upload: 'Seleccionar archivo a cargar',
  select_file: 'Seleccionar archivo',
  summary: 'Resumen del proceso',
  fix_errors: 'Corrección de errores',
  total_unchanged: 'Se mantendrán activos {total} colaboradores.',
  total_updated: 'Se actualizarán {total} colaboradores.',
  total_inserted: 'Se agregarán {total} colaboradores.',
  total_deleted: 'Se desactivarán {total} colaboradores.',
  total_failed: 'Se han encontrado {total} registros con errores.',
  file_updated: 'Archivo actualizado',
  save_changes: 'Guardar cambios',
  error_fix: 'Corregir errores',
  if_any: 'De existir',
  process_cancelled: 'Proceso cancelado',
  massive_success: 'Carga masiva ejecutada exitosamente.',
  massive_upload: 'Carga masiva',
  download_log: 'Descargar registros',
  id: 'Id',
  name: 'Nombre',
  last_name: 'Apellido',
  city: 'Ciudad',
  email: 'Correo',
  action: 'Acción',
  employment: 'Empleo',
  employees: 'Colaboradores',
  list_employees: 'Listado de colaboradores',
  look_for: 'Buscar un colaborador',
  filter_by: 'Filtrar por...',
  no_data: 'No hay colaboradores para mostrar',
  modal_enable: '¿Desea habilitar el colaborador?',
  modal_disable: '¿Desea Inhabilitar el colaborador?',
  modal_action: 'Realizar acción',
  status: 'Estado',
  edit_employee: 'Editar colaborador',
  enable_title: 'Habilitar colaborador',
  disable_title: 'Deshabilitar colaborador',
  enable_text: '¿Seguro que desea habilitar a este colaborador? Tras esto, el colaborador podrá ingresar a la plataforma y participar en mediciones.',
  disable_text: '¿Seguro que desea deshabilitar a este colaborador? Tras esto, el colaborador no podrá ingresar a la plataforma ni participar en mediciones. Esta acción no afecta las mediciones en las que haya participado previamente.',
  employment_at: '{title} en',
  updated_employee: 'Colaborador actualizado exitosamente',
  detected_errors: 'Errores detectados:',
  massive_load_in_progress: 'Usted tiene un proceso de carga masiva de colaboradores en progreso. Mientras dure este proceso no puede realizar cambios ni agregar nuevos colaboradores de forma manual. Si lo desea puede ir a la opción de carga masiva para completar o cancelar el proceso activo',
  massive_upload_recomendations: 'Esta sección tiene como finalidad realizar la carga masiva de los colaboradores que van a realizar las mediciones, para prevenir errores en la carga de datos de usuarios. Por favor tener en cuenta:',
  massive_upload_recomendations_items: [
    'Antes de realizar la carga masiva debes crear todos los cortes demográficos que vayan a tener los colaboradores.',
    'Da click en el botón descargar plantilla, para descargar el archivo donde vas a enlistar los colaboradores de la compañía.',
    'Todas las columnas del archivo  son obligatorios a excepción del correo electrónico.',
    'No se permiten colaboradores con el mismo número de identificación ni con el mismo correo electrónico.',
    'El archivo que cargarás, es la base de datos de empleados a realizar la medición. Por favor incluir todos los empleados que participaran de la medición. Después de lanzar la encuesta, no se pueden agregar, editar o quitar colaboradores.',
    'La plataforma es sensible a mayúsculas, espacios y tildes. Por favor diligencia cada demográfico tal cual como lo has creado anteriormente.',
    'Recuerda que cualquier cambio realizado aplica únicamente para mediciones futuras.',
    'Este proceso también sirve para actualizar la base de datos de empleados que ya has cargado previamente.'
  ],
  massive_upload_confirm_delete: '¿Está seguro que desea eliminar este colaborador de la carga masiva?',
  employee_data_recomendations: [
    'En caso de no encontrar la opción de demográfico requerido, puedes crearla en la sección de cortes demográficos.',
    'Todos los campos son obligatorios a excepción del correo electrónico.',
    'No se permiten colaboradores con el mismo número de identificación ni con el mismo correo electrónico.',
    'Recuerda que cualquier cambio realizado aplica únicamente para mediciones futuras.'
  ]
}
