
<template>
  <trans-wrapper
    :page-title="$t('settings.additional_demographics2_setting')"
    :add-label="$t('settings.add')"
    :card-title="$t('settings.additional_demographics2')"
    :alert-info="$t('settings.additional_demographics2_info')"
    :modal-title-add="$t('settings.additional_demographics2_add')"
    :modal-title-ena="$t('settings.additional_demographics2_enable')"
    :modal-title-dis="$t('settings.additional_demographics2_disable')"
    :modal-title-del="$t('settings.additional_demographics2_delete')"
    :modal-question-ena="$t('settings.master_references_enable_des')"
    :modal-question-dis="$t('settings.master_references_disable_des')"
    :modal-question-del="$t('settings.master_references_delete_des')"
    :item-services="additionalDemographics2Service"
    is-optional
  ></trans-wrapper>
</template>

<script>

import additionalDemographics2Service from '../../services/additional-demographics2'

import TransWrapper from './components/trans-wrapper.vue'

export default {
  components: {
    TransWrapper
  },
  data () {
    return {
      additionalDemographics2Service
    }
  }
}
</script>
