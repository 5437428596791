
import occLogoWhite from '../base64/occ-logo-w'
import line from '../base64/line'
import directors from '../base64/directors'
import rings from '../base64/rings'
import clientsLogos from '../base64/clients-logos'

import iconWeb from '../base64/icon-web'
import iconYoutube from '../base64/icon-youtube'
import iconLinkedin from '../base64/icon-linkedin'
import iconMail from '../base64/icon-mail'
import iconCalendar from '../base64/icon-calendar'
import button from '../base64/button'

export default {
  methods: {
    $generatePageThree () {
      return [
        {
          pageBreak: 'before',
          text: 'Nosotros',
          fontSize: 12.5,
          bold: true
        },
        {
          image: line,
          margin: [0, 0, 0, 0],
          width: 52,
          height: 2.4
        },
        {
          text: 'OCC Solutions, ha reunido durante más de 30 años, la experiencia de un equipo de profesionales multidisciplinario de alta trayectoria para ofrecer al mundo empresarial servicios que permitan contribuir con su éxito e incrementar su competitividad.',
          margin: [0, 15, -10, 0],
          fontSize: 10,
          color: '#333333'
        },
        {
          text: 'Por más de tres décadas hemos trabajado con más de 500 organizaciones privadas y públicas en Colombia y América Latina, diseñando y acompañando procesos hechos a la medida en temas relacionados con: transformación organizacional, gestión del desarrollo, cultura organizacional, engagement, planeación estratégica, estrategia de talento humano y comercial, desarrollo de competencias de liderazgo y equipos de alto desempeño.',
          margin: [0, 15, -10, 0],
          fontSize: 10,
          color: '#333333'
        },
        {
          image: directors,
          margin: [47, 20, 0, 0],
          width: 400
        },
        {
          columns: [
            {
              width: '65%',
              margin: [0, 47, 0, 0],
              text: [
                { text: '+15 ', color: '#EB604D', bold: true },
                { text: ' Países', color: '#222222' },
                { text: '\u200B\t \u200B\t' },
                { text: ' +30 ', color: '#EB604D', bold: true },
                { text: ' Años de experiencia\n\n', color: '#222222' },
                { text: '+500 ', color: '#EB604D', bold: true },
                { text: ' Clientes\u200B\t', color: '#222222' },
                { text: ' +100,000 ', color: '#EB604D', bold: true },
                { text: ' Personas impactadas', color: '#222222' }
              ]
            },
            {
              width: '*',
              image: rings,
              margin: [0, 20, 0, 0],
              fit: [154, 154]
            }
          ]
        },
        {
          text: 'Clientes que han trabajado con OCC Solutions',
          margin: [-24, 25, 0, 5],
          fontSize: 12.5,
          bold: true
        },
        {
          image: line,
          margin: [-24, 0, 0, 0],
          width: 257,
          height: 2
        },
        {
          image: clientsLogos,
          margin: [-10, 25, 0, 0],
          width: 520
        },
        {
          margin: [-47, 21, -47, 0],
          table: {
            widths: ['22%', '40%', '8%', '*'],
            heights: 104,
            body: [
              [
                {
                  margin: [34, 17, 0, 0],
                  image: occLogoWhite,
                  width: 68,
                  height: 64
                },
                {
                  margin: [0, 18, 0, 0],
                  text: '¿Necesitas saber algo más para\nmedir y gestionar tu talento humano?',
                  color: '#FFFFFF'
                },
                {
                  text: ''
                },
                {
                  text: ''
                }
              ]
            ]
          },
          layout: {
            hLineWidth: (i, node) => { return 0.1 },
            vLineWidth: (i, node) => { return 0.1 },
            hLineColor: (i, node) => { return '#1999DA' },
            vLineColor: (i, node) => { return '#1999DA' },
            fillColor: (rowIndex, node, columnIndex) => { return '#1999DA' }
          }
        },
        {
          image: iconWeb,
          fit: [17, 17],
          link: 'https://occ-solutions.com',
          absolutePosition: { x: 170, y: 800 }
        },
        {
          image: iconYoutube,
          fit: [18, 18],
          link: 'https://www.youtube.com/channel/UCwtvUOLyjARhyd1_9SNk_xw',
          absolutePosition: { x: 212, y: 802 }
        },
        {
          image: iconLinkedin,
          fit: [17, 17],
          link: 'https://co.linkedin.com/company/occ-solutions',
          absolutePosition: { x: 254, y: 800 }
        },
        {
          image: iconMail,
          fit: [17, 17],
          absolutePosition: { x: 385, y: 760.5 }
        },
        {
          image: button,
          width: 99,
          link: 'mailto:info@occsolutions.org',
          absolutePosition: { x: 417, y: 758 }
        },
        {
          text: 'Escríbenos',
          color: '#FFFFFF',
          fontSize: 10,
          absolutePosition: { x: 440, y: 764 }
        },
        {
          image: iconCalendar,
          fit: [17, 17],
          absolutePosition: { x: 385, y: 795 }
        },
        {
          image: button,
          width: 99,
          link: 'https://calendly.com/marcelaacevedo/30min',
          absolutePosition: { x: 417, y: 792 }
        },
        {
          text: 'Agenda una demo',
          color: '#FFFFFF',
          fontSize: 10,
          absolutePosition: { x: 427, y: 798 }
        }
      ]
    }
  }
}
