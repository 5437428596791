
export default {
  login: 'LOG IN',
  problems_to_login: 'Forgot your password?',
  recover_password: 'Password recovery',
  go_back: 'go back',
  email_not_found: 'Email address not found.',
  unexpected_error: 'An unexpected error has occurred. If the problem persists contact the administrator.',
  password_successfully_restablish: 'Password reset successful',
  password_sent_to_email: 'Your new password has been sent to your email.',
  password_notification_sent: 'Notification sent to the administrator to proceed with their password reset.',
  accept: 'Accept',
  password_not_updated: 'We\'re sorry, your password couldn\'t be updated.',
  password_confirm: 'Confirm password',
  password_confirmation: 'Password confirmation',
  new_password: 'New password',
  new_password_confirm: 'Confirm new password',
  save_new_password: 'Save new password',
  password_update: 'Password update.',
  password_updated: 'Your password has been successfully updated.',
  password_reset_requested: 'Password recovery requested',
  check_your_email: 'We have sent an email to the specified address with the steps to follow to recover your password.',
  create_password: 'Create password',
  change_password: 'Change password',
  save_password: 'Save password',
  password_create: 'Password creation',
  password_created: 'Password created',
  password_not_created: 'We’re sorry, your password couldn’t be created. Remember that passwords must meet the requirements listed on the right:',
  password_created_greet_title: 'Your password has been successfully created',
  password_created_greet_text: 'ou can now use your email and this new password to log in',
  token_not_valid: 'The verification is invalid',
  token_expired: 'This code has expired, remember that for your security our access codes are only valid for a maximum of three days.',
  token_used: 'This code has already been used.',
  verify_or_contact: 'Please verify the code or link you used and try again. You can also contact OCC Solutions for more information.',
  go_sign_in: 'Go back to sign in',
  logged_as_enterprise: 'At the moment you are logged in as one of the associated companies, you can temporarily close your session, if you wish to choose another company or acquire more tokens',
  logged_as_enterprise_superadmin: 'Currently you are logged in as one of the companies associated with this client, you can temporarily close your session as a company if you wish to choose another company; or you can temporarily log out as a client to return to the Super Administrator dashboard',
  logged_as_employee: 'Temporarily logged in as an employee',
  logged_as_customer: 'Currently you are in a temporary Administrator User Session, you can close your temporary session to return to the Super Administrator dashboard',
  close_session: 'Log out',
  close_session_as_customer: 'Log out as client',
  close_session_as_enterprise: 'Log out as company',
  sign_up: 'Sign up',
  select_customer_title: 'Select the account that best suits your needs.',
  select_customer_personal: 'Single Compnay account',
  select_customer_personal_text: 'You can register as an administrator and register your company to manage it as a person',
  select_customer_personal_select: 'Select',
  select_customer_commercial: 'multi-Company account',
  select_customer_commercial_text: 'You can register as an administrator to manage multiple companies',
  select_customer_commercial_select: 'Select',
  has_no_account: 'Don’t have an account yet?',
  customer_personal_d: 'Select this option if you are going to administer and manage a business account.',
  customer_commercial_d: 'Select this option if you are a business group or a consultant who administers and manages several companies.',
  customer_personal_d_2: 'Register a user who is a business group or a consultant who administers and manages multiple companies.',
  customer_commercial_d_2: 'Register a user as an administrator of multiple companies.',
  personal_account: 'Single Company account',
  commercial_account: 'multi-Company account',
  success_register: 'You have been successfully registered!',
  has_sent_to: 'We have sen tan email to <span style="color: #3375d2;">{0}</span> with a link so you can create your password and finish registering on the platform.',
  please_verify_account: 'Please check your inbox or the spam folder <span class="font-italic">{0}</span>',
  spam: 'spam',
  view_select_title: 'Select the account that best suits your needs',
  view_sign_up_personal_enterprise: 'Cmpany information',
  view_sign_up_personal_user: 'Administrator details',
  user_data: 'user data',
  leader: 'Boss',
  leaders: 'Bosses',
  dependent: 'Employee',
  dependents: 'Employees',
  pair: 'Peer',
  pairs: 'Peers',
  accept_terms_and_conditions: 'I accept the Terms and Conditions',
  accept_data_protection_policies: 'I accept the Data Protection Policies',
  enter_real_email: 'Please enter the email you created your account with.',
  confirm_logout: 'Are you sure you want to log out?',
  password_rules: [
    'Minimum 8 characters',
    'Maximum 100 characters',
    'At least 1 uppercase letter (A-Z)',
    'At least 1 lowercase letter (a-z)',
    'At least 1 number (0-9)',
    'No blanks'
  ]
}
