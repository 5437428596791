
<template>
  <trans-wrapper
    :page-title="$t('settings.enterprise_size')"
    :add-label="$t('settings.add')"
    :card-title="$t('settings.enterprise_sizes')"
    :alert-info="$t('settings.enterprise_size_info')"
    :modal-title-add="$t('settings.enterprise_size_add')"
    :modal-title-ena="$t('settings.enterprise_size_enable')"
    :modal-title-dis="$t('settings.enterprise_size_disable')"
    :modal-title-del="$t('settings.enterprise_size_delete')"
    :modal-question-ena="$t('settings.master_references_enable_des')"
    :modal-question-dis="$t('settings.master_references_disable_des')"
    :modal-question-del="$t('settings.master_references_delete_des')"
    :item-services="enterpriseSizeService"
    :inputs="{ label: true, description: true}"
  >
    <template v-slot:after-input="props">
      <v-tooltip bottom color="blue">
        <template v-slot:activator="{ on }" >
          <v-text-field
            v-on="on"
            style="margin-top: .75em; margin-right: .5em"
            light outlined
            :color="props.item.item.active ? 'blue' : 'grey lighten-2'"
            :background-color="props.item.item.active ? '' : 'grey lighten-2'"
            :placeholder="$t('settings.no_translation')"
            name="enterprise_sizes"
            v-model="props.item.item.trans[$i18n.locale].description"
            @keypress.enter="props.item.updateRecord(props.item.item)"
            @blur="props.item.updateRecord(props.item.item)"
          ></v-text-field>
        </template>
        {{ $t('settings.tooltip_edit') }}
      </v-tooltip>
    </template>
  </trans-wrapper>
</template>

<script>

import enterpriseSizeService from '../../services/enterprise-sizes'

import TransWrapper from './components/trans-wrapper.vue'

export default {
  components: {
    TransWrapper
  },
  data () {
    return {
      enterpriseSizeService
    }
  }
}
</script>
