
<template>
  <v-card-text class="pa-3" style="min-height: calc(100vh - 256px);">
    <v-row no-gutters
      class="px-3"
      :class="toAddNewLines && !itemsBeingCalculated.length ? 'fill-height justify-center' : 'justify-left'"
      :align="toAddNewLines && !itemsBeingCalculated.length ? 'center' : 'start'"
    >
      <v-btn
        v-if="toAddNewLines"
        :color="toAddNewLines ? 'primary' : 'secondary'"
        :disabled="(!toAddNewLines && !itemsBeingCalculated.length) || editable"
        class="px-2"
        @click="toggleForm"
      >
        <v-icon class="mr-1">{{ signed }}</v-icon>
        <h5 class="text-capitalize" v-if="toAddNewLines">
          {{ !itemsBeingCalculated.length ? $t('tokens.quote_new') : $t('tokens.quote_another') }}
        </h5>
        <h5 class="text-capitalize" v-else>{{ $t('input.cancel') }}</h5>
      </v-btn>
    </v-row>

    <!-- Form -->
    <v-row v-if="calculatorForm" class="no-gutters">
      <v-col cols="12" class="px-4">
        <v-form v-model="valid">
          <v-row>
            <!-- TOOL -->
            <v-col cols="12" sm="3" class="mt-2">
              <v-select required
                v-model="variableItemInForm.product"
                :items="productList"
                item-text="name"
                :label="$t('tokens.tool')"
                data-vv-name="select"
                @change="filterServices"
              ></v-select>
            </v-col>

            <!-- SERVICE -->
            <v-col cols="12" sm="3" class="mt-2">
              <v-select required
                v-model="variableItemInForm.service"
                :items="productServiceFiltered"
                :disabled="!variableItemInForm.product"
                item-text="name"
                :label="$t('tokens.type')"
                data-vv-name="select"
                @change="updateCosts"
              ></v-select>
            </v-col>

            <!-- MODALITY -->
            <v-col cols="12" sm="2">
              <v-row class="px-3">
                <v-col cols="12" class="pa-0">
                  <p class="text-center ma-0 body-2">{{ modality || $t('tokens.modality') }}</p>
                  <x-inputs-token
                    :input="variableItemInForm.qty"
                    :min="1"
                    :max="!variableItemInForm.service ? 1 : 999999"
                    @updateInput="($event) => { variableItemInForm.qty = $event; changeQty() }"
                  />
                </v-col>
              </v-row>
            </v-col>

            <!-- TOKEN QTY -->
            <v-col cols="12" sm="2" class="mt-2">
              <v-text-field required
                v-model="variableItemInForm.tokensQty"
                :label="$t('tokens.unit')"
              >
                <template v-slot:append>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on }">
                      <v-btn icon dark small
                        v-on="on"
                        color="primary"
                      >
                        <v-icon medium>mdi-information</v-icon>
                      </v-btn>
                    </template>
                    <p class="mb-0 caption" v-html="$t('tokens.tokens_desc')"></p>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <!-- COST / VALUE -->
            <v-col cols="12" sm="2" class="mt-2">
              <v-text-field required readonly
                v-model="variableItemInForm.tokensCostConverted"
                :label="$t('currencies.value')"
                :prefix="`${currency.paymentSymbol} ${currency.currencySymbol}`"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- FORM ACTION BUTTONS -->
          <v-row class="justify-end mr-0 mb-4">
            <v-btn
              v-if="itemsBeingCalculated.length || editable"
              class="mr-4"
              @click="toggleForm"
            >
              {{ $t('input.cancel') }}
            </v-btn>
            <v-btn
              class="primary"
              :disabled="isDisabled || limitReached"
              @click="addToCalculated"
            >
              {{ $t('input.next') }}
            </v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-divider v-if="!showEmpty && itemsBeingCalculated.length" class="mt-3"></v-divider>

    <!-- Items Table -->
    <v-row class="justify-center child-flex mx-5" v-if="!showEmpty">
      <v-data-table
        :headers="headersCalculated"
        :items="itemsBeingCalculated"
        :items-per-page="100"
        hide-default-footer
        hide-default-header
        no-data-text=""
        class="mt-3"
      >
        <template v-slot:item.products="{ item }">
          <p class="body-2 pt-3">
            <strong>{{ item.product }}</strong>
            <span class="ml-1">{{ capitalize(getMeditionType(item.service)) }}</span>
            <span>
              {{ $t('tokens.of') }} {{ item.service }},
              <i>{{ item.qty }} {{ getMeditionUnit(item.service, item.qty) }}</i>
            </span>
          </p>
        </template>
        <template v-slot:item.qty="{ item }">
          <div style="min-width: 150px;">
            <strong>{{ item.tokensQty | formatCurrency(0) }}</strong> {{ $t('tokens.unit') }}
          </div>
        </template>
        <template v-slot:item.amounts="{ item }">
          <div style="min-width: 150px;">
            <strong>
              {{ currency.paymentSymbol }} {{ currency.currencySymbol }}{{ (item.tokensCost * currency.value) | formatCurrency(2) }}
            </strong>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div style="min-width: 70px;">
            <v-btn small icon class="mr-1" :disabled="calculatorForm" @click="editItem(item)">
              <v-icon small>edit</v-icon>
            </v-btn>
            <v-btn small icon color="red" :disabled="calculatorForm" @click="deleteItem(item)">
              <v-icon small>mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-row>

    <!-- Totals -->
    <v-container v-if="totalFields" class="pa-0">
      <v-row class="justify-end ma-5">
        <!-- Blue box -->
        <v-col cols="12" md="7" class="blue lighten-5 rounded">
          <div class="text-uppercase totalItem">
            <span>
              {{ $t('tokens.total') }} {{ $t('tokens.unit') }}:
            </span>
            <strong class="totalValue">
              {{ totalTokens | formatCurrency(0) }}
            </strong>
          </div>
          <div class="text-uppercase totalItem">
            <span>
              {{ $t('currencies.value') }} {{ $t('tokens.by') }} occ token:
            </span>
            <strong class="totalValue">
              {{ currency.paymentSymbol }} {{ currency.currencySymbol }}{{ currentTokenRange.price * currency.value | formatCurrency(2) }}
            </strong>
          </div>
          <div class="text-uppercase totalItem">
            <span v-html="$t('tokens.if_you_buy', [
              $options.filters.formatCurrency(currentTokenRange.maxRange + 1, 0)
            ])"></span>
            <strong class="totalValue">
              {{ currency.paymentSymbol }} {{ currency.currencySymbol }}{{ findNextTokenRange() | formatCurrency(2) }}
            </strong>
          </div>
        </v-col>

        <!-- Green box -->
        <v-col cols="12" md="5" class="py-0 pr-0">
          <div class="pa-3 green lighten-5 rounded">
            <div class="text-uppercase totalItem">
              <span>{{ $t('tokens.value') }}:</span>
              <strong class="totalValue">
                {{ currency.paymentSymbol }} {{ currency.currencySymbol }}{{ totalCostByCurrency | formatCurrency(2) }}
              </strong>
            </div>
            <div class="text-uppercase totalItem">
              <span>{{ $t('tokens.iva') }}:</span>
              <strong class="totalValue">
                {{ currency.paymentSymbol }} {{ currency.currencySymbol }}{{ ivaByCurrency | formatCurrency(2) }}
              </strong>
            </div>
            <div class="text-uppercase totalItem">
              <span>
                {{ $t('tokens.total_value') }}:
              </span>
              <strong class="totalValue">
                {{ currency.paymentSymbol }} {{ currency.currencySymbol }}{{ totalToPayByCurrency | formatCurrency(2) }}
              </strong>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- Action Buttons -->
      <v-row class="justify-end mr-2">
        <v-col cols="12" class="pb-0">
          <p class="mb-0 caption info--text text-right font-weight-medium">
            {{ $t('tokens.buy_msg') }}
          </p>
        </v-col>
        <v-col cols="12" md="2" class="pr-0 text-right">
          <v-dialog v-model="dialogCancel" persistent max-width="600">
            <template v-slot:activator="{ on }">
              <v-btn color="white" v-on="on">
                {{ $t('input.cancel') }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="d-flex justify-center py-5">
                <v-icon large color="orange">mdi-alert-circle</v-icon>
              </v-card-title>
              <v-card-text>
                <p class="text-center body-1">{{ $t('tokens.calculator_closing_message') }}</p>
              </v-card-text>
              <v-card-actions class="d-flex justify-end pb-5 mr-10">
                <v-btn color="blue-grey lighten-2" class="text-capitalize mr-2" dark @click="dialogCancel = false">{{ $t('tokens.return') }}</v-btn>
                <v-btn color="blue darken-1" class="text-capitalize" dark @click="cancelCalc">
                  {{ $t('tokens.confirm_close_calculator') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="2" class="pl-0 text-right">
          <v-btn
            color=primary
            class="px-7"
            :disabled="disableAcquire || calculatorForm"
            @click="acquire"
          >
            {{ $t('input.buy') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="showPendingTransactionAlert" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ $t('tokens.pending_transaction_title') }}
        </v-card-title>

        <v-card-text>
          {{ $t('tokens.pending_transaction_text') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text
            color="primary"
            @click="acceptedPendingTransactionAlert = true; showPendingTransactionAlert = false"
          >
            {{ $t('input.accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <x-loading
      :display="loading"
    ></x-loading>
  </v-card-text>
</template>

<script>

import { mapState } from 'vuex'

import tokenPriceService from '../../services/token-price'
import productService from '../../services/products'
import productServicesService from '../../services/product-services'
import payment from '@/mixins/payment'

export default {
  name: 'Modal',
  mixins: [payment],
  props: {
    isGroup: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      justifyIcon: 'center',
      classIcon: 'mt-12 pt-12',
      showEmpty: true,
      dialogCancel: false,
      toAddNewLines: true,
      signed: 'mdi-plus-circle-outline',
      calculatorForm: false,
      limitReached: false,
      currentTokenPrices: [],
      productList: [],
      productServiceList: [],
      productServiceFiltered: [],
      productServicePrice: 0,
      selectedProductService: {},
      variableItemEditing: {
        product: '',
        service: '',
        qty: '',
        tokensQty: '',
        tokensCost: '',
        tokensCostConverted: '0.00'
      },
      modality: '',
      editable: false,
      variableItemInForm: {
        product: '',
        service: '',
        qty: '1',
        tokensQty: '0',
        tokensCost: '0.00',
        tokensCostConverted: '0.00'
      },
      itemsBeingCalculated: [],
      valid: true,
      disableAcquire: false,
      loading: false,
      currentTransactionId: null
    }
  },
  computed: {
    headersCalculated () {
      return [
        { text: '', value: 'products', sortable: false },
        { text: '', value: 'qty', sortable: false, align: 'right' },
        { text: '', value: 'amounts', sortable: false, align: 'right' },
        {
          text: this.$t('currencies.actions'),
          value: 'actions',
          sortable: false,
          align: 'center'
        }
      ]
    },
    isDisabled () {
      let valid = true
      for (const item in this.variableItemInForm) {
        if (this.variableItemInForm.hasOwnProperty(item) && !this.variableItemInForm[item]) {
          valid = false
          break
        }
      }
      valid = valid && this.variableItemInForm && Number(this.variableItemInForm.tokensCost) > 0
      return !valid
    },
    totalTokens () {
      let totalTokens = 0
      for (const prop in this.itemsBeingCalculated) {
        totalTokens = (this.itemsBeingCalculated[prop].tokensQty) + totalTokens
      }
      return Number(totalTokens) + Number(this.variableItemInForm.tokensQty)
    },
    currentTokenRange () {
      return this.currentTokenPrices.find(rng => {
        if (rng.maxRange === 0) {
          return this.totalTokens >= rng.minRange
        } else {
          return this.totalTokens >= rng.minRange && this.totalTokens <= rng.maxRange
        }
      })
    },
    isSameCurrency () {
      return this.currency.currencySymbol === this.currency.paymentSymbol
    },
    totalCost () {
      let totalCost = 0
      for (const prop in this.itemsBeingCalculated) {
        totalCost += Number(this.itemsBeingCalculated[prop].tokensCost)
      }
      return Number(totalCost) + Number(this.variableItemInForm.tokensCost)
    },
    totalCostByCurrency () {
      return Math.round((this.totalCost * this.currency.value) * 100) / 100
    },
    iva () {
      return Math.round(this.totalCost * 0.19 * 100) / 100
    },
    ivaByCurrency () {
      return Math.round((this.iva * this.currency.value) * 100) / 100
    },
    totalToPay () {
      return Math.round((this.totalCost + this.iva) * 100) / 100
    },
    totalToPayByCurrency () {
      return Math.round((this.totalToPay * this.currency.value) * 100) / 100
    },
    totalFields () {
      let valid = false
      if (Object(this.itemsBeingCalculated).length !== 0) {
        valid = true
      }
      return valid
    },
    ...mapState({
      user: (state) => state.session.user,
      currency: state => state.session.currency
    })
  },
  watch: {
    '$i18n.locale': {
      handler () {
        this.updateCosts()
      }
    },
    'currency.currencySymbol': function (val, old) {
      if (val !== old) {
        this.updateCosts()
      }
    }
  },
  methods: {
    capitalize (s) {
      if (typeof s !== 'string') {
        return ''
      }
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    findNextTokenRange () {
      const found = this.currentTokenPrices.find(x => x.minRange === this.currentTokenRange.maxRange + 1)
      return found.price * this.currency.value
    },
    setEmptyForm () {
      this.variableItemInForm = {
        product: '',
        service: '',
        qty: '1',
        tokensQty: '0',
        tokensCost: '0.00',
        tokensCostConverted: '0.00'
      }
    },
    setEmptyEdit () {
      this.editable = false
      this.variableItemEditing.product = ''
      this.variableItemEditing.service = ''
      this.variableItemEditing.qty = ''
      this.variableItemEditing.tokensQty = ''
      this.variableItemEditing.tokensCost = ''
      this.variableItemEditing.tokensCostConverted = ''
    },
    deleteEdition () {
      this.setEmptyForm()
      this.setEmptyEdit()
      this.selectedProductService = {}
      this.toggleForm()
      this.editable = false
    },
    toggleForm () {
      if (this.calculatorForm) {
        if (this.variableItemEditing.product !== '') {
          this.itemsBeingCalculated = [...this.itemsBeingCalculated, Object.assign({}, this.variableItemEditing)]
        }
        if (this.editable) {
          this.cancelEdit()
          return
        }
        this.calculatorForm = false
        this.signed = 'mdi-plus-circle-outline'
        this.toAddNewLines = true
        this.productServicePrice = 0
        this.productServiceFiltered = []
        this.setEmptyForm()
        this.setEmptyEdit()
        if (this.itemsBeingCalculated.length < 1) {
          this.showEmpty = true
          this.justifyIcon = 'center'
          this.classIcon = 'mt-12 pt-12'
        }
      } else {
        this.calculatorForm = true
        this.signed = 'mdi-minus-circle-outline'
        this.showEmpty = false
        this.justifyIcon = 'start'
        this.classIcon = ''
        this.toAddNewLines = false
      }

      if (this.itemsBeingCalculated.length) {
        const price = this.currentTokenRange.price
        this.itemsBeingCalculated.forEach(item => {
          item.tokensCost = item.tokensQty * price
          item.tokensCostConverted = item.tokensQty * (price * this.currency.value)
        })
      }
    },
    deleteItem (item) {
      const index = this.itemsBeingCalculated.indexOf(item)
      this.itemsBeingCalculated.splice(index, 1)
      if (!this.itemsBeingCalculated.length) {
        this.toggleForm()
      }
    },
    editItem (item) {
      if (this.variableItemEditing.product !== '') {
        return
      }
      const index = this.itemsBeingCalculated.indexOf(item)
      this.variableItemInForm.product = item.product
      this.itemsBeingCalculated.splice(index, 1)
      this.calculatorForm = true
      this.toAddNewLines = false
      this.filterServices()
      this.variableItemInForm.service = item.service
      this.variableItemInForm.qty = item.qty
      this.variableItemInForm.tokensQty = item.tokensQty
      this.variableItemInForm.tokensCost = item.tokensCost
      this.variableItemInForm.tokensCostConverted = item.tokensCostConverted
      // Editable
      this.editable = true
      this.variableItemEditing.product = item.product
      this.variableItemEditing.service = item.service
      this.variableItemEditing.qty = item.qty
      this.variableItemEditing.tokensQty = item.tokensQty
      this.variableItemEditing.tokensCost = item.tokensCost
      this.variableItemEditing.tokensCostConverted = item.tokensCostConverted
      this.updateCosts()
    },
    cancelEdit () {
      this.setEmptyEdit()
      this.toggleForm()
      this.setEmptyForm()
      this.editable = false
    },
    addToCalculated () {
      this.itemsBeingCalculated = [...this.itemsBeingCalculated, Object.assign({}, this.variableItemInForm)]
      this.setEmptyEdit()
      this.toggleForm()
      this.setEmptyForm()
      this.editable = false
      if (this.itemsBeingCalculated.length > 99) {
        this.limitReached = true
      }
    },
    updateCosts () {
      if (this.variableItemInForm.service) {
        const localSelectedProductService = this.productServiceList.find(productService => productService.name === this.variableItemInForm.service)
        this.selectedProductService = localSelectedProductService
        this.productServicePrice = localSelectedProductService.tokenPrice
        this.modality = this.getModality(localSelectedProductService.modality)
        this.changeQty()
      }
    },
    getModality (modality) {
      let label
      switch (modality) {
        case 'Download':
          label = this.$t('tokens.num_reports')
          break
        case 'Medition':
          label = this.$t('tokens.num_polls')
          break
        case 'Workshop':
          label = this.$t('tokens.num_workshop')
          break
        default:
          label = this.$t('tokens.num_leaders')
          break
      }
      return label
    },
    getMeditionType (service) {
      if (!service) return ''
      const productService = this.productServiceList.find(productService => productService.name === service)
      if (productService.modality === 'Medition') {
        return this.$t('tokens.creation')
      } else if (productService.modality === 'Download') {
        return this.$t('tokens.download')
      } else {
        return this.$t('tokens.evaluation')
      }
    },
    getMeditionUnit (service, qty) {
      if (!service) return ''
      const productService = this.productServiceList.find(productService => productService.name === service)
      const idx = qty > 1 ? 2 : 1

      let unit
      switch (productService.modality) {
        case 'Download':
          unit = this.$tc('tokens.modalities.report', idx)
          break
        case 'Medition':
          unit = this.$tc('tokens.modalities.poll', idx)
          break
        case 'Workshop':
          unit = this.$tc('tokens.modalities.workshop', idx)
          break
        default:
          unit = this.$tc('tokens.modalities.leader', idx)
          break
      }
      return unit
    },
    filterServices () {
      this.variableItemInForm.service = ''
      this.variableItemInForm.qty = 1
      this.variableItemInForm.tokensQty = 0
      this.variableItemInForm.tokensCost = 0
      this.variableItemInForm.tokensCostConverted = '0.00'
      const selectedProduct = this.productList.find(product => product.name === this.variableItemInForm.product)
      this.productServiceFiltered = this.productServiceList.filter(productService => productService.productId === selectedProduct.id)
    },
    changeQty () {
      if (!this.currency.value) {
        this.$store.dispatch('alert/error', this.$t('tokens.currency_required'))
        return
      }

      this.variableItemInForm.tokensQty = this.variableItemInForm.qty * this.productServicePrice
      const input = this.variableItemInForm.tokensQty
      const price = this.currentTokenRange.price

      this.variableItemInForm.tokensCost = (input * price).toFixed(2)
      this.variableItemInForm.tokensCostConverted = (input * (price * this.currency.value)).toFixed(2)

      this.itemsBeingCalculated.forEach(item => {
        item.tokensCost = item.tokensQty * price
        item.tokensCostConverted = item.tokensQty * (price * this.currency.value)
      })
    },
    acquire () {
      this.loading = true
      const mount = Number(this.totalTokens)
      if (mount === 0) {
        this.$store.dispatch('alert/error', this.$t('tokens.invalid_quantity'))
        return
      }
      return this.checkPending()
        .then(continuar => {
          if (continuar) {
            return this.startTransaction(mount, this.totalCostByCurrency, this.ivaByCurrency, this.currency.paymentSymbol, () => {
              this.cancelCalc()
              this.$emit('refresh')
            })
          }
        })
    },
    cancelCalc () {
      this.dialogCancel = false
      this.showEmpty = true
      this.justifyIcon = 'center'
      this.classIcon = 'mt-12 pt-12'
      this.itemsBeingCalculated = []
      this.setEmptyForm()
      if (this.calculatorForm) {
        this.toggleForm()
      }
    },
    getCurrentTokenPrices () {
      return tokenPriceService.getActives()
        .then((res) => {
          this.currentTokenPrices = res
        })
    },
    getActiveProducts () {
      return productService.list({ filter: true })
        .then((res) => {
          this.productList = res
        })
    },
    getActiveProductServices () {
      return productServicesService.list({ filter: true })
        .then((res) => {
          this.productServiceList = res
        })
    }
  },
  created () {
    this.getCurrentTokenPrices()
    this.getActiveProducts()
    this.getActiveProductServices()
    this.toggleForm()
    const commercialWithEnterprise = this.user.customer && this.user.customer.type === 'commercial' && this.user.enterprise
    if (['admin', 'employee'].includes(this.user.role) || (commercialWithEnterprise && this.user.role !== 'enterprise_admin')) {
      this.disableAcquire = true
    }
  }
}
</script>
<style scoped>
  .cardBackground {
    background-color: #444343;
    color: white;
  }
  .cardBackgroundTotal {
    background-color: #DCDCDC;
    color: black;
  }
  .totalItem {
    display: flex;
    justify-content: space-between;
  }
  .totalValue {
    white-space: nowrap;
    align-self: flex-end;
  }
</style>
