
import mapParameters from '../utils/map-parameters'
import Services from './base-services'

const service = new Services('token-authorization')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
}

export default {
  list: (options: IOptionsList) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null
    })
    return service.get('list', params)
  },
  listByAuthorization: (tokenAccounts: number[]) => {
    const params = mapParameters({
      tokenAccounts: tokenAccounts
    })
    return service.get('list-by-authorization', params)
  },
  listByEnterprises: (enterprises: string) => {
    return service.get(`list-by-enterprises?enterprises=${enterprises}`)
  },
  listByCustomers: (customers: number[]) => {
    const params = mapParameters({
      customers
    })
    return service.get('list-by-customers', params)
  },
  toggle: (data: any) => {
    if (data.value) {
      if (data.enterpriseId) {
        return service.post('create', { enterpriseId: data.enterpriseId })
      }
      if (data.customerId) {
        return service.post('create', { customerId: data.customerId, isGroup: true })
      }
    } else {
      return service.put('disable', { tokenAccountId: data.tokenAccountId })
    }
    return null
  }
}
