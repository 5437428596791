var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"display-1"},[_vm._v(" "+_vm._s(_vm.$t('settings.products_title'))+" ")])],1),_c('v-row',{staticStyle:{"min-height":"350px"},attrs:{"align":"center"}},[_c('v-col',{attrs:{"xs12":"","sm6":"","offset-sm3":""}},[_c('v-card',[_c('v-list',{staticStyle:{"padding":"0px"}},_vm._l((_vm.products),function(product){return _c('v-list-group',{key:product.id,staticStyle:{"min-height":"6em","border":"0.01rem #ccc solid"},model:{value:(product.isDeploy),callback:function ($$v) {_vm.$set(product, "isDeploy", $$v)},expression:"product.isDeploy"}},[_c('v-list-item',{attrs:{"slot":"activator"},slot:"activator"},[_c('v-list-item-content',{staticClass:"mt-2 pa-0"},[_c('v-col',{staticClass:"pa-0"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"pa-0",attrs:{"sm":"2"}},[_c('v-img',{class:!product.name.includes('LIDERAZGO') ? 'my-1 ml-3' : 'mt-2 mb-1',style:(product.name.includes('LIDERAZGO') ?
                          'max-width:130px; height:92px;' :
                          'max-width:100px; height:auto;'),attrs:{"contain":"","id":"logo","src":product.logo || product.defaultLogo || ''}})],1),_c('v-col',{attrs:{"sm":"10"}},[_c('strong',[_vm._v(_vm._s(product.name))])])],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',{staticClass:"mt-2 pa-0"},[_c('v-col',{staticClass:"mx-2 pa-0"},[_c('v-row',{staticClass:"light-blue lighten-5 mx-3 py-2",attrs:{"align":"center"}},[_c('v-col',{staticStyle:{"color":"#424242"},attrs:{"cols":"12","sm":"9"}},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$t('settings.products_description')))])]),_c('span',{staticStyle:{"color":"#7E7E7E"}},[_vm._v(" "+_vm._s(product.description[_vm.$i18n.locale] ? product.description[_vm.$i18n.locale] : product.description.es)+" ")])]),_c('v-col',{staticClass:"text-center",staticStyle:{"color":"#424242"},attrs:{"cols":"4","sm":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('div',_vm._g({},on),[_c('x-state-switch',{staticStyle:{"padding":"0","margin":"0"},attrs:{"value":product.active,"item":product,"enable":{
                                title: _vm.$t('settings.products_modal_enable'),
                                action: _vm.enableProduct,
                                color: 'success',
                                btn_text: _vm.$t('input.enable'),
                                question: _vm.$t('settings.products_modal_enable_des')
                              },"disable":{
                                title: _vm.$t('settings.products_modal_disable'),
                                action: _vm.disableProduct,
                                color: 'error',
                                btn_text: _vm.$t('input.disable'),
                                question: _vm.$t('settings.products_modal_disable_des')
                              },"can-enable":product.integrated,"alert":{
                                title: _vm.$t('settings.products_active_disabled_title'),
                                text: _vm.$t('settings.products_active_disabled'),
                                btn_text: _vm.$t('input.cancel')
                              }}})],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('settings.products_status'))+" ")])])],1),_c('v-col',{staticClass:"text-center",staticStyle:{"color":"#424242"},attrs:{"cols":"4","sm":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":("/settings/product-details/" + (product.id) + "/" + (product.name))}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('settings.products_edit'))+" ")])])],1),_c('v-col',{staticClass:"text-center",staticStyle:{"color":"#424242"},attrs:{"cols":"4","sm":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":("/settings/products/" + (product.id) + "/configuration")}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("fa-cogs")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('settings.product_settings'))+" ")])])],1)],1),_c('v-divider',{staticClass:"mx-3 py-2"}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":product.productServices,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.modality",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("services.modalities." + (item.modality))))+" ")]}},{key:"item.periocity",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("services.periocities." + (item.periocity))))+" ")]}},{key:"item.recomendation",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(item.recomendation)+" ")]}},{key:"item.price",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(item.tokenPrice)+" ")]}},{key:"item.actions",fn:function(ref){
                              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":("/settings/product/" + (product.id) + "/" + (product.name) + "/service-edit/" + (item.id) + "/" + (item.name))}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('settings.service_edit'))+" ")])])]}}],null,true)})],1)],1)],1),_c('v-divider')],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }