
<template>
  <v-container>
    <v-row align-center column>
      <v-col>
        <img class="hidden-xs-only" src="/img/logo_empresa.png" alt="OCC - Solutions Logo" style="width: 75em;"/>
        <img class="hidden-sm-and-up" src="/img/logo_empresa.png" alt="OCC - Solutions Logo" style="width: 250px;"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      user: state => state.session.user
    })
  },
  created () {
    if (this.user.role === 'customer') {
      if (this.user.customer.type === 'personal') {
        this.$router.push('/measuring-tools')
      } else {
        this.$router.push('/commercial-profile')
      }
    } else {
      this.$router.push('/dashboard')
    }
  }
}
</script>
