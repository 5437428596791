
export default {
  title: 'OCC Solutions Instructions - Bulk upload module.',
  title_body: 'This tutorial is intended to guide the end user on the correct way to ' +
    'enter the bulk upload format to prevent errors when uploading user data.',
  demographic_items: 'Demographic cuts: ',
  identifyType: 'Type of identity document.',
  matchCaseValue: 'One of the values stored in the application must be specified for this demographic ' +
    'cut, taking into account uppercase and lowercase letters.',
  example: 'Example:',
  identifyDocument: 'Identity document',
  matchCaseNumber: ' You must enter only numbers, without letters, spaces or special characters.',
  firstName: 'Firts name',
  matchCaseText: ' You must enter only letters or spaces, no numbers or special characters.',
  lastName: 'Last name',
  email: 'Email',
  matchCaseEmail: ' You must specify a valid email, it cannot be repeated. ' +
    'Or you can leave this field empty.',
  gender: 'Gender',
  age: 'Age range (Date of birth)',
  antiquity: 'Seniority range (Start date)',
  matchCaseDate: ' A valid date must be entered in yyyy/mm/dd or yyyy-mm-dd format, without spaces or letters ',
  departments: 'Department/Area',
  charge: 'Job title',
  charge_dependent_info: ' The positions with departments identified in parentheses "()", can only be\n assigned to collaborators belonging to said department.',
  jobTypes: 'Type of contract',
  academicDegree: 'Level of education',
  country: 'Country',
  headquarter: 'Location',
  headquarter_dependent_info: ' The locations with countries identified in parentheses "()", can only be assigned\n to collaborators belonging to said country.',
  importDataToExcelTitle: ' Import data from CSV file to Excel Document.',
  step1: 'Step 1',
  step1_desc: 'Open Excel.',
  step2: 'Step 2',
  step2_desc: 'Select the option Blank workbook',
  step3: 'Step 3',
  step3_desc: ' Go to the Data tab and in the Obtain external data section, select the option\n ' +
    'From a text file.',
  step4: 'Step 4',
  step4_desc: ' Select the CSV data file you want to import. and then press the\nImport button.',
  step5: 'Step 5',
  step5_desc: ' Here, the settings that will be used to import the data file are specified.\n ' +
    'It must be indicated that the values are Delimited, and that the Unicode uft-8 encoding will be used,\n ' +
    'as shown in the image below. This, for the correct conversion of the \naccented words. ' +
    'Then you must press the Next button.',
  step6: 'Step 6',
  step6_desc: ' At this point you must indicate which character will be used to separate values.\n ' +
    'You must mark the comma as shown in the image below.Then you must press the\nFinish button.',
  step7: 'Step 7',
  step7_desc: ' Next you will be asked if this data will be imported into the existing sheet\n ' +
    'o en una hoja nueva. Por defecto aparecerá seleccionada importar en la hoja existente,\n ' +
    'if not, select this option and press OK.',
  step8_desc: ' Finally, the data will be separated correctly and the accented words will be\n ' +
    'imported correctly.',
  availableItems: 'Available values:',
  optionalDemo1: 'Additional Demographic 1',
  optionalDemo2: 'Additional Demographic 2'
}
