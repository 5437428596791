<template>
  <v-container>
    <x-loading v-if="loading" :display="loading"/>
    <v-row v-else>
      <v-col cols="12">
        <h4 class="display-1">{{ $t('settings.data_protection_policies_settings')}}</h4>
      </v-col>
      <v-col cols="12">
        <v-card flat>
          <v-row>
            <v-col cols="9" class="px-8 headline">
              {{ $t(`settings.data_protection_policies_for_${policy.type}`)}}
            </v-col>
          </v-row>
          <v-row v-if="policy">
            <v-col class="mx-6">
              <quill-editor
                v-model="policy.description"
                :options="editorOption">
              </quill-editor>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="end" class="mx-6">
              <v-btn
                small
                @click="$router.go(-1)"
              >
                Volver
              </v-btn>
              <v-btn
                small
                color="primary"
                @click="setNewPolicyDescription"
                class="ml-3"
              >
                {{ $t('input.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from 'vue'

import dataProtectionPoliciesService from '../../../services/data-protection-policies'
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default Vue.extend({
  components: {
    quillEditor
  },
  data () {
    return {
      policy: null,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }]
          ]
        },
        placeholder: ''
      },
      loading: true
    }
  },
  created () {
    this.getPolicy()
  },
  methods: {
    getPolicy () {
      return dataProtectionPoliciesService.getOne(this.$route.params.id)
        .then((res) => {
          this.loading = !this.loading
          this.policy = res
        })
    },
    setNewPolicyDescription () {
      this.loading = !this.loading
      return dataProtectionPoliciesService.create(this.policy)
        .then((res) => {
          this.loading = !this.loading
          this.$router.go(-1)
          this.$store.dispatch('alert/success', this.$t('record_saved_successfully'))
        }).catch((err) => {
          this.loading = !this.loading
          console.log(err)
          this.$store.dispatch('alert/error', this.$t('error_trying_save'))
        })
    }
  }
})
</script>
