
<template>
  <v-container>

    <v-row justify="start">
      <v-col class="display-1">
        {{ $t('settings.tokens_title') }}
      </v-col>
    </v-row>

    <v-row class="child-flex">
      <v-col>
        <v-card class="mx-5">
          <v-container>
            <v-form>
              <v-row justify="center">
                <v-col cols="3">
                  <v-text-field
                    :label="$t('settings.tokens_edit_minrange')"
                    v-model="price.minRange"
                    readonly
                    outlined
                  >{{price.id}}</v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="$t('settings.tokens_edit_maxrange')"
                    v-model="price.maxRange"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="$t('settings.tokens_edit_price_amount')"
                    outlined
                    v-model="price.price"
                    type="number"
                    step="0.01"
                    min="0"
                    max="99999"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0" align="center">
                <v-col class="text-right">
                  <v-btn
                    small
                    class="secondary"
                    :to="`/settings/products`">
                    {{ $t('input.cancel') }}
                  </v-btn>
                </v-col>
                <v-col class="text-left">
                  <x-async-btn
                    small
                    class="white--text"
                    :action="confirm"
                  ><v-icon></v-icon>&nbsp;{{ $t('input.save') }}
                  </x-async-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import tokenPriceService from '../../services/token-price'

export default {
  data () {
    return {
      price: {}
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    confirm () {
      return tokenPriceService.disable(this.$route.params.id)
        .then((res) => {
          return tokenPriceService.create(this.price)
            .then((res) => {
              this.hideModal()
              this.$store.dispatch('alert/success', this.$t('settings.tokens_price_edited'))
              this.$router.go(-1)
            })
        })
    },
    hideModal () {
      this.$emit('close')
    }
  },
  created () {
    return tokenPriceService.getActives()
      .then((res) => {
        this.price = res.filter(price => price.id === Number(this.$route.params.id))
        this.price = this.price[0]
        this.price.price = Number(this.price.price).toFixed(2)
      })
  }
}

</script>
