
import line from '../base64/line'
import dot from '../base64/dot'
import piePulse from '../base64/pie-pulse'

export default {
  methods: {
    printDotsPulse () {
      const arr = []
      for (var i = 0; i < 3; i++) {
        arr.push({
          image: dot,
          fit: [14, 14],
          absolutePosition: { x: 47, y: 82.5 + (i * 50) }
        })
      }

      return arr
    },
    $generatePageTwoPulse () {
      return [
        {
          pageBreak: 'before',
          text: 'Nuestro modelo',
          fontSize: 12.5,
          bold: true
        },
        {
          image: line,
          margin: [0, 0, 0, 0],
          width: 90,
          height: 2.4
        },
        this.printDotsPulse(),
        {
          text: 'Modelo conformado por seis dimensiones que revelan\nel nivel de engagement de la organización.',
          margin: [28, 20, 210, 0],
          color: '#222222',
          fontSize: 10
        },
        {
          text: 'Conoce el Employee Net Protomoter score (ENPS) en tu empresa.',
          margin: [28, 22, 210, 0],
          color: '#222222',
          fontSize: 10
        },
        {
          text: 'Conoce el índice de Engagement (nivel de Compromiso) de tus colaboradores.',
          margin: [28, 22, 210, 0],
          color: '#222222',
          fontSize: 10
        },
        {
          image: piePulse,
          absolutePosition: { x: 360, y: 80 },
          width: 180
        },
        {
          text: '¿Cómo funciona OCC Engagement?',
          margin: [0, 25, 0, 0],
          fontSize: 12.5,
          bold: true
        },
        {
          image: line,
          margin: [0, 4, 0, 0],
          width: 198,
          height: 2.1
        },
        {
          margin: [0, 20, 0, 0],
          columns: [
            {
              width: '20%',
              ol: [
                { text: '\u200B\tMIDE', fontSize: 10, bold: true, color: '#1999DA' },
                { text: '\u200B\tACTÚA', fontSize: 10, bold: true, color: '#1999DA' },
                { text: '\u200B\tMONITOREA', fontSize: 10, bold: true, color: '#1999DA' }
              ],
              lineHeight: 2.8,
              markerColor: '#1999DA'
            },
            {
              width: '*',
              text: [
                'Mide el nivel de engagement de la organización o el grupo objetivo. Contarás con una encuesta completa que podrás ajustar a tus necesidades.\n',
                { text: '\n', lineHeight: 0.4 },
                'Visualiza y entiende los resultados de la medición para desarrollar e implementar un\nplan de acción que permita mejorar los niveles del clima organizacional.\n',
                { text: '\n', lineHeight: 0.4 },
                'Haz seguimiento a tus equipos en tiempo real con las mediciones tipo pulso. OCC Pulse te permite enviar encuestas cortas a grupos específicos.'
              ],
              color: '#222222',
              fontSize: 10
            }
          ]
        },
        this.generateHowWorks(),
        {
          text: 'Propuesta Económica',
          margin: [0, 18, 0, 0],
          fontSize: 12.5,
          bold: true
        },
        {
          image: line,
          margin: [0, 4, 0, 0],
          width: 123,
          height: 2.5
        }
      ]
    }
  }
}
