
import mapParameters from '../utils/map-parameters'
import Services from './base-services'

const service = new Services('product-services')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
}

export default {
  list: (options: IOptionsList, productId?: number) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null,
      productId
    })
    return service.get('list', params)
  },
  create: (data: object) => {
    return service.form('', data, {
      contact: 'object',
      license: 'object',
      demographicItems: 'array'
    })
  },
  edit: (id: number, data: object) => {
    return service.form(`edit/${id}`, data)
  }
}
