
export default {
  create_title: 'Create Currency',
  editar_title: 'Edit Currency',
  list_title: 'List of Currencies',
  add_currency: 'Add Currency',
  name: 'Name',
  code: 'Currency Code',
  code_desc: 'Corresponds to the 3-letter code<br />that identifies the currency,<br />according to the ISO 4217 standard.',
  symbol: 'Symbol',
  value: 'Value',
  status: 'Status',
  actions: 'Actions',
  equals: ' equals ',
  no_data: 'No Currencies to display',
  modal_enable: 'Do you want to enable currency?',
  modal_disable: 'Do you want to disable currency?',
  modal_delete: 'Do you want to delete the currency?',
  currencies: 'Currencies',
  currency: 'Currency',
  successfully_registered: 'Successfully registered',
  successfully_edited: 'Successfully edited'
}
