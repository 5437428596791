<template>
  <v-container id="enterprise-create" fluid style="max-width: 55rem; margin-top: 6rem;">
    <x-loading
      v-if="loading"
      :display="loading"
    ></x-loading>
    <v-card v-else>
      <v-card-title primary-title class="text-center" color="primary">
        <h2 class="text-uppercase" style="width: 100%; color: #3375d2"> {{$t('customers.client_info')}} </h2>
      </v-card-title>
      <v-card-text>
        <ValidationObserver v-slot="{ handleSubmit }">
          <v-form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <x-inputs-autocomplete
                  :items="getFormattedIdentifyTypes"
                  :input="customer.identifyTypeId"
                  @updateInput="($event) => customer.identifyTypeId = $event"
                  light
                  :label="$t('input.identify_type_s')"
                  name="identify_type_id"
                  rules="required"
                >
                </x-inputs-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <x-inputs-input
                  :input="customer.identifyDocument"
                  @updateInput="($event) => customer.identifyDocument = $event"
                  light
                  :label="$t('input.identify_document')"
                  name="identify_document"
                  :append-outer-icon="$t('help.icon')"
                  :help-message="$t('help.enterprise.create.identifyDocument')"
                  rules="required"
                ></x-inputs-input>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <x-inputs-input
                  :input="customer.name"
                  @updateInput="($event) => customer.name = $event"
                  light
                  :label="$t('input.complete_name')"
                  name="complete_name"
                  :append-outer-icon="$t('help.icon')"
                  :help-message="$t('help.enterprise.create.name')"
                  rules="required"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <x-inputs-input
                  :input="customer.email"
                  @updateInput="($event) => customer.email = $event"
                  light
                  :label="$t('input.email')"
                  name="email"
                  :append-outer-icon="$t('help.icon')"
                  :help-message="$t('help.enterprise.create.email')"
                  rules="required|email"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <x-inputs-input
                  :input="customer.phoneNumber"
                  @updateInput="($event) => customer.phoneNumber = $event"
                  light
                  :label="$t('input.phone_number')"
                  name="phone_number"
                  :append-outer-icon="$t('help.icon')"
                  :help-message="$t('help.enterprise.create.phoneNumber')"
                  rules="required"
                />
              </v-col>
            </v-row>
            <v-row justify="end" align="end">
              <v-col cols="12" sm="6" md="4">
                <v-btn
                  @click="$router.go(-1)"
                  text
                  small
                  style="margin-left: 1em"
                  class="text-capitalize"
                >{{ $t('input.cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  type="submit"
                  small
                  style="margin-left: 1em"
                  class="text-capitalize"
                >{{ $t('input.update') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import identifyTypesService from '../../services/identify-types'
import customerService from '../../services/customers'
import formatItems from '../../utils/form-format-items-list'

export default Vue.extend({
  data () {
    return {
      identifyTypes: [],
      customer: {
        identifyTypeId: null,
        identifyDocument: '',
        name: '',
        email: '',
        phoneNumber: ''
      },
      loading: true
    }
  },
  created () {
    this.getCustomer()
    this.getIdentifyTypes()
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    getFormattedIdentifyTypes () {
      if (!this.identifyTypes.length) return []
      return formatItems(this.identifyTypes, (item) => {
        const iType = item.translations.find(i => i.lang === this.user.lang)
        return {
          text: iType.label,
          value: item.id,
          code: item.code
        }
      })
    }
  },
  methods: {
    getCustomer () {
      return customerService.getOne(this.$route.params.id)
        .then((res) => {
          this.customer = res
          this.customer.email = this.customer.user.email
        }).catch((error) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
          this.loading = false
        })
    },
    getIdentifyTypes () {
      identifyTypesService.listOutside()
        .then((res) => {
          this.identifyTypes = res.items
          const it = this.identifyTypes.find(i => i.code === 'others')
          const index = this.identifyTypes.indexOf(it)
          this.identifyTypes.splice(index, 1)
          this.identifyTypes.splice(this.identifyTypes.length, 0, it)
          this.loading = false
        })
        .catch((error) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
          this.loading = false
        })
    },
    onSubmit () {
      this.loading = true
      return customerService.edit(this.$route.params.id, this.customer)
        .then((res) => {
          this.loading = false
          this.$router.push('/admin-customers')
          this.$store.dispatch('alert/success', this.$t(`customers.successfully_edited`))
        }).catch((err) => {
          this.loading = false
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    }
  }
})
</script>
