
<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row justify="end" class="mt-2" align="end" style="height: 300px;">
          <v-col cols="12" md="6" class="mr-5">
            <img src="img/2-403-OCC.svg" alt="403 Suite Error">
          </v-col>
        </v-row>
        <v-row justify="end" class="mt-2">
          <v-col cols="12" md="6" class="mr-5 text-end">
            <p>
              <strong>{{ $t('errors.apology') }}</strong>
              <br>
              {{ $t('errors.forbidden') }}
            </p>
          </v-col>
        </v-row>
        <v-row justify="end" class="mt-2">
          <v-col cols="12" md="6" class="mr-5 text-end">
            <v-btn to="/" color="primary" small>{{ $t('errors.back_home') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue'

export default Vue.extend()
</script>
