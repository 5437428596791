<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="40em"
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-card>
          <v-toolbar light flat>
            <v-toolbar-title>{{ $t('input.assign_products')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon light @click="closeModal">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-row class="text-center" color="blue-grey lighten-5">
              <v-col cols="10" class="offset-1">
                <x-inputs-autocomplete
                  :items="products"
                  :input="customerProducts"
                  @updateInput="($event) => customerProducts = $event"
                  :on-change="insertDeletedProducts"
                  light
                  :label="$t('input.products')"
                  name="identify_type_id"
                  multiple
                  chips
                />
              </v-col>
            </v-row>
            <v-row justify="end" align="end">
              <v-col justify="end" align="end">
                <v-btn class="mr-3" color="secondary" dark @click="closeModal">
                  &nbsp;{{ $t('input.cancel') }}
                </v-btn>
                <v-btn color="primary" type="submit">
                  &nbsp;{{ $t('input.save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    item: Object,
    products: Array
  },
  data () {
    return {
      show: true,
      customerProducts: null,
      baseProducts: null,
      deletedProducts: []
    }
  },
  created () {
    this.customerProducts = this.item.products.map(product => product.productId)
    this.baseProducts = this.customerProducts
  },
  methods: {
    closeModal () {
      this.$emit('close')
    },
    onSubmit () {
      this.$emit('assignProducts', this.customerProducts, this.deletedProducts)
    },
    insertDeletedProducts () {
      this.deletedProducts = []
      for (const baseProduct of this.baseProducts) {
        if (!this.customerProducts.includes(baseProduct)) {
          this.deletedProducts.push(baseProduct)
        }
      }
    }
  }
})
</script>
