<template>
  <v-row justify="center">
    <v-col align="center" cols="12" sm="4" v-for="activity in info" :key="activity.id">
      <v-card>
        <v-toolbar dense class="mb-3">
          <v-toolbar-title class="text-capitalize center--text">{{activity.evaluation.displayName || activity.evaluation.name}}</v-toolbar-title>
        </v-toolbar>
        <v-hover
          v-slot:default="{ hover }"
        >
          <v-row>
            <v-col cols="12" align="center">
              <v-progress-circular
                :rotate="360"
                :size="120"
                :width="15"
                :value="Math.floor(activity.score.percent)"
                color="primary"
                class="around"
                @click="doRedirect(activity.token, activity.type)"
              >
                <span class="justify-center minipercentage" v-if="!hover"> {{ Math.floor(activity.score.percent) }}% </span>
                <p v-else>
                  <small color="rgb(56, 153, 218)" v-if="activity.score.percent > 0 && activity.score.percent < 100"> {{ $t('dashboard.continue') }} <br> </small>
                  <small color="rgb(56, 153, 218)" v-else-if="activity.score.percent === 0"> {{ $t('dashboard.start') }} <br> </small>
                  <small color="rgb(56, 153, 218)" v-else> {{ $t('dashboard.completed') }} <br> </small>
                  <v-icon size="35" color="rgb(56, 153, 218)" v-if="activity.score.percent < 100">play_arrow</v-icon>
                  <v-icon size="35" color="rgb(56, 153, 218)" v-else>fa-check</v-icon>
                </p>
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-hover>
        <v-row>
          <v-col class="px-8">
            <v-btn
              :disabled="activity.score.percent === 100"
              color="primary"
              @click="doRedirect(activity.token, activity.type)"
              block
            >
              {{ $t('dashboard.reply') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    info: Array
  },
  data () {
    return {
      showDialog: false,
      evaluatedToDisplay: null,
      i: 1
    }
  },
  methods: {
    doRedirect (token, type = 'actual') {
      return this.$store
        .dispatch('session/findHost', 'Cultura')
        .then((host) => {
          if (host && host.url) {
            location.replace(`${host.url}/evaluations/${type}/${token}`)
          }
        })
        .catch(err => (this.error = err.code))
    }
  }
})
</script>

<style lang="css" scoped>
.minipercentage{
  font-size: 2em !important;
}
</style>
