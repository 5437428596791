
<template>
  <v-container fluid >
    <v-row wrap row>
      <v-col cols="12" sm="9" md="8" class="pt-4 pl-4">
        <h4 class="display-1">{{ cardTitle }}</h4>
      </v-col>
      <v-col cols="12" sm="3" md="4" class="text-right">
        <v-btn large
          color="primary"
          @click="add = true"
        >
          <v-icon left>mdi-plus-circle-outline</v-icon>
          {{ addLabel }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row wrap row>
      <v-col cols="12">
        <v-card>
          <!--
          <v-card-title class="headline">
            {{ cardTitle }}
          </v-card-title>
          -->
          <v-card-subtitle class="mt-1 text-justify"
            v-if="!isOptional && isEnterpriseEditable"
            v-html="$t('settings.master_references_section')"
          ></v-card-subtitle>
          <v-card-subtitle class="mt-1 text-justify"
            v-if="isOptional && isEnterpriseEditable"
            v-html="$t('settings.master_references_section_additionals')"
          ></v-card-subtitle>
          <v-divider class="mb-1"></v-divider>
          <v-col>
            <x-info :text="alertInfo" v-if="alertInfo"></x-info>
          </v-col>
          <v-container fluid>
            <v-data-iterator
              :items="getPrimaryItems"
              :items-per-page="10"
              :options.sync="pagination"
              :footer-props="footerProps"
              row wrap
            >
              <template v-slot:header>
                <v-row row v-if="headers.length">
                  <v-col class="text-xs-center" v-for="(header, $index) in headers" :key="$index">
                    <v-card flat>
                      <v-card-text class="text-center font-weight-black px-0 mb-2">{{ $t(`settings.${header}`) }}</v-card-text>
                      <v-divider></v-divider>
                    </v-card>
                  </v-col>
                  <template v-if="showCurrencySelect">
                    <v-col class="text-xs-center">
                      <v-card flat>
                        <v-card-text class="text-center font-weight-black px-0 mb-2">{{ $t('currencies.currency') }}</v-card-text>
                        <v-divider></v-divider>
                      </v-card>
                    </v-col>
                  </template>
                  <v-col sm="2" class="text-xs-center">
                    <v-card flat>
                      <v-card-text class="text-center font-weight-black px-0 mb-2">{{ $t('settings.actions') }}</v-card-text>
                      <v-divider></v-divider>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item="props">
                <v-row row v-if="!props.item.deletedAt" class="pl-2 pr-2" style="max-height: 4.5em;">
                    <slot name="before-input" v-bind:item="{
                      item: props.item,
                      language,
                      updateRecord,
                    }"></slot>
                    <v-col class="pl-1 pr-1">
                      <v-tooltip bottom color="blue">
                        <template v-slot:activator="{ on }" >
                          <v-text-field
                            v-on="on"
                            light outlined
                            :color="props.item.active ? 'blue' : 'grey lighten-2'"
                            :background-color="props.item.active ? '' : 'grey lighten-2'"
                            :placeholder="$t('settings.no_translation')"
                            name="academic_degree"
                            v-model="props.item.trans[user.lang].label"
                            @keypress.enter="updateRecord(props.item)"
                            @blur="updateRecord(props.item)"
                          ></v-text-field>
                        </template>
                        {{ $t('settings.tooltip_edit') }}
                      </v-tooltip>
                    </v-col>
                    <template v-if="showCurrencySelect">
                      <v-col class="pl-1 pr-1">
                        <v-tooltip bottom color="blue">
                          <template v-slot:activator="{ on }" >
                            <v-select
                              v-on="on"
                              :items="currencies"
                              outlined
                              item-text="name"
                              item-value="id"
                              :label="$t('currencies.currency')"
                              @change="changeCurrency(props.item.id)"
                              v-model="selectedCurrency[props.item.id]"
                            ></v-select>
                          </template>
                          {{ $t('settings.tooltip_edit') }}
                        </v-tooltip>
                      </v-col>
                    </template>
                    <slot name="after-input" v-bind:item="{
                      item: props.item,
                      language,
                      updateRecord,
                    }"></slot>
                    <v-col cols="2" class="text-center">
                      <v-tooltip v-if="!props.item.active" bottom color="green">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="green"
                            @click="openModal(props.item, modalEna)">
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('input.enable') }}</span>
                      </v-tooltip>

                      <v-tooltip v-else bottom color="blue lighten-2">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="blue lighten-2"
                            @click="openModal(props.item, modalDis)">
                            <v-icon>mdi-eye-off</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('input.disable') }}</span>
                      </v-tooltip>

                      <v-tooltip bottom color="red" v-if="!isLanguage || (props.item.code !== language && props.item.code !== defaultLang)">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="red"
                            @click="openModal(props.item, modalDel)">
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('input.trash') }}</span>
                      </v-tooltip>
                    </v-col>
                </v-row>
              </template>
              <template v-slot:footer.page-text="props">
                {{ props.pageStart }}-{{ props.pageStop }} {{ $t('page_text') }} {{ props.itemsLength }}
              </template>
            </v-data-iterator>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <x-add
      :show="add"
      :trans="true"
      :currency="showCurrencySelect"
      :title="modalTitleAdd"
      icon="mdi-notebook"
      :languages="resLanguages"
      :inputs="inputs"
      :action="addRecord"
      @close="hideAdd"
    ></x-add>

    <x-confirmation-modal
      :show="modalEna.open"
      :reversible="true"
      :title="modalTitleEna"
      :action="enableRecord"
      @close="modalEna.open = false"
      :btn-save="$t('input.enable')"
      color="success"
    >
      <template v-slot:question>{{ modalQuestionEna }}</template>
    </x-confirmation-modal>

    <x-confirmation-modal
      :show="modalDis.open"
      :reversible="true"
      :title="modalTitleDis"
      :action="disableRecord"
      :btn-save="$t('input.disable')"
      color="error"
      @close="modalDis.open = false"
    >
      <template v-slot:question>{{ modalQuestionDis }}</template>
    </x-confirmation-modal>

    <x-confirmation-modal
      :show="modalDel.open"
      :title="modalTitleDel"
      :action="deleteRecord"
      :btn-save="$t('input.trash')"
      color="error"
      @close="modalDel.open = false"
    >
      <template v-slot:question>{{ modalQuestionDel }}</template>
    </x-confirmation-modal>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import resolver from '../../../utils/resolver'

import languagesService from '../../../services/languages'

import currencyService from '../../../services/currencies'

import countryService from '../../../services/countries'

import XAdd from './add.vue'

export default {
  components: {
    XAdd
  },
  props: {
    pageTitle: String,
    addLabel: String,
    cardTitle: String,
    alertInfo: String,
    modalTitleAdd: String,
    modalTitleEna: String,
    modalTitleDis: String,
    modalTitleDel: String,
    modalQuestionEna: String,
    modalQuestionDis: String,
    modalQuestionDel: String,
    showCurrencySelect: Boolean,
    isOptional: Boolean,
    isEnterpriseEditable: {
      type: Boolean,
      default: true
    },
    itemServices: Object,
    inputs: {
      type: Object,
      default: () => ({
        label: true
      })
    }
  },
  data () {
    return {
      primaryItems: [],
      resLanguages: [],
      languages: [],
      language: '',
      add: false,
      pagination: {},
      modalEna: { open: false, item: {} },
      modalDis: { open: false, item: {} },
      modalDel: { open: false, item: {} },
      headers: [],
      isLanguage: false,
      defaultLang: 'es',
      currencies: [],
      originalPrimaryItems: [],
      selectedCurrency: []
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    getPrimaryItems () {
      return this.primaryItems.map(pItem => {
        const trans = pItem.trans
        if (trans.en.label === '' && !!trans.es.label) {
          trans.en.label = trans.es.label
        }
        if (trans.es.label === '' && !!trans.en.label) {
          trans.es.label = trans.en.label
        }
        return pItem
      })
    },
    footerProps () {
      return {
        'items-per-page-text': this.$t('results_per_pages'),
        'items-per-page-options': [5, 10, 25, { 'text': this.$t('page_all'), 'value': -1 }]
      }
    }
  },
  methods: {
    hideAdd () {
      this.add = false
    },
    openModal (item, modal) {
      modal.open = true
      modal.item = item
    },
    addRecord (data) {
      return this.itemServices.create(data)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('record_saved_successfully'))
          this.updateList()
          this.hideAdd()
        })
        .catch((err) => {
          if (err.code && err.code === 'already_exists') {
            this.$store.dispatch('alert/error',
              this.$t('settings.demographic_already_exists', [this.cardTitle])
            )
          } else {
            this.$store.dispatch('alert/error', this.$t('error_trying_save'))
          }
        })
    },
    updateRecord (item) {
      const originalValue = this.originalPrimaryItems.find((x) => x.id === item.id)
      const data = {}
      for (const key in this.inputs) {
        data[key] = item.trans[this.language][key]
        if (!data[key] && this.inputs[key]) {
          return this.$store.dispatch('alert/error', this.$t('errors.validator/empty-field'))
        }
      }
      this.itemServices.update(item.id, this.language, data)
        .then((res) => this.$store.dispatch('alert/success', this.$t('registration_updated_successfully')))
        .catch((err) => {
          if (err.code && err.code === 'already_exists') {
            item.trans[this.language].label = originalValue.trans[this.language].label
            this.$store.dispatch('alert/error',
              this.$t('settings.demographic_already_exists', [this.cardTitle])
            )
          } else {
            this.$store.dispatch('alert/error', this.$t('error_trying_update'))
          }
        })
    },
    enableRecord () {
      return this.toggleRecord(this.modalEna.item, 'enable')
    },
    disableRecord () {
      return this.toggleRecord(this.modalDis.item, 'disable')
    },
    deleteRecord () {
      if (!this.isOptional && this.primaryItems.length === 1) {
        return this.$store.dispatch('alert/error', this.$t('delete_not_allowed'))
      }
      return this.toggleRecord(this.modalDel.item, 'delete')
    },
    toggleRecord (item, action) {
      return this.itemServices.toggle(item.id, action)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('registration_updated_successfully'))
          if (action === 'delete') {
            return this.updateList()
          }

          this.$set(item, 'active', res.active)
          this.$set(item, 'deletedAt', res.deletedAt)
        })
        .catch((res) => this.$store.dispatch('alert/error', this.$t('error_trying_update')))
    },
    updateList () {
      if (this.isLanguage) {
        return languagesService.list().then((res) => {
          this.resLanguages = res.items
          return this.itemServices.listData()
            .then((res) => this.mapItems(res.items))
        })
      }

      return this.itemServices.listData()
        .then((res) => this.mapItems(res.items))
    },
    mapItems (items) {
      this.primaryItems = items.map(item => {
        item.trans = {}
        this.resLanguages.forEach(language => {
          this.languages.push({
            value: language.code,
            text: language.translate.label
          })

          item.trans[language.code] = item.translations.find(lang => lang.lang === language.code) || { label: '' }
        })

        return item
      })
    },
    changeCurrency (countryId, currencyId) {
      countryService.updateCurrency(countryId, { currencyId: this.selectedCurrency[countryId] })
        .then((res) => this.$store.dispatch('alert/success', this.$t('registration_updated_successfully')))
        .catch((res) => this.$store.dispatch('alert/error', this.$t('error_trying_update')))
    }
  },
  created () {
    resolver.all({
      primaryItems: this.itemServices.listData().then((res) => res.items),
      languages: languagesService.list().then((res) => res.items)
    }).then((res) => {
      this.resLanguages = res.languages
      this.mapItems(res.primaryItems)
      this.primaryItems.map(item => {
        this.selectedCurrency[item.id] = item.currencyId
      })
      this.language = this.user.lang
      for (const key in this.inputs) {
        this.headers.push(key)
      }
      this.isLanguage = JSON.stringify(this.itemServices === JSON.stringify(languagesService))
      this.originalPrimaryItems = JSON.parse(JSON.stringify(this.primaryItems))
    })

    if (this.showCurrencySelect) {
      currencyService.getActives()
        .then(res => {
          this.currencies = res.data
        })
    }
  }
}
</script>

<style scoped>
  #master-manager .container {
    padding: 0 24px;
  }
  #master-manager .v-card__text{
    padding: 0;
  }
  .v-input__control {
    max-height: 2em !important;
  }
</style>
