<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="40em"
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-card>
          <!-- Title -->
          <v-card-title class="pl-6">
            {{ $t('input.dashboard_products')}}
            <v-spacer />
            <v-btn icon light
              :disabled="loading"
              @click="closeModal"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>

          <!-- Sub-tile -->
          <v-card-subtitle class="pl-6">
            {{
              customer.type === 'commercial'
                ? customer.name
                : customer.enterprise.name
            }}
          </v-card-subtitle>

          <!-- Content -->
          <v-card-text class="pt-10 pb-12">
            <v-row class="text-center" color="blue-grey lighten-5">
              <v-col cols="10" class="offset-1">
                <x-inputs-autocomplete light multiple chips
                  :items="formattedProducts"
                  :input="customerDashboards"
                  :label="$t('input.products')"
                  :disabled="loading"
                  @updateInput="($event) => customerDashboards = $event"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <!-- Action Buttons -->
          <v-card-actions class="pa-4">
            <v-spacer />
            <v-btn
              color="secondary"
              class="mr-3"
              :disabled="loading"
              @click="closeModal"
            >
              {{ $t('input.cancel') }}
            </v-btn>
            <v-btn
              type="submit"
              color="primary"
              :loading="loading"
            >
              {{ $t('input.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>

import Vue from 'vue'
import customersService from '../../services/customers'

export default Vue.extend({
  props: {
    customer: Object,
    products: Array
  },
  data () {
    return {
      show: true,
      loading: false,
      formattedProducts: [],
      customerDashboards: null
    }
  },
  created () {
    this.formattedProducts = this.products.map(x => ({
      text: x.name,
      value: x.id
    }))
    this.customerDashboards = this.customer.dashboards
      .filter(d => d.pbiDashboardEnabled)
      .map(p => p.productId)
  },
  methods: {
    closeModal () {
      this.$emit('close')
    },
    onSubmit () {
      this.loading = true
      customersService.assignDashboards(this.customer.id, this.customerDashboards)
        .then(customer => {
          this.$store.dispatch('alert/success', this.$t('registration_updated_successfully'))
          this.$emit('updateDashboards', customer.dashboards)
        })
        .catch(err => {
          if (err.code === 'nothing_to_change') {
            this.$store.dispatch('alert/error', this.$t('errors.nothing_to_change'))
          } else {
            console.log(err)
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
})
</script>
