
import occLogo from '../base64/occ-logo'
import greenCloud from '../base64/green-cloud'
import check from '../base64/check'
import line from '../base64/line'
import square from '../base64/square'

export default {
  methods: {
    printChecks () {
      const arr = []
      for (var i = 0; i < 6; i++) {
        arr.push({
          image: check,
          fit: [12, 12],
          absolutePosition: { x: 257, y: 342 + (i * 18) }
        })
      }

      return arr
    },
    printSquares (y) {
      const arr = []
      for (var i = 0; i < 3; i++) {
        arr.push({
          image: square,
          absolutePosition: { x: 13 + (i * 190), y }
        })
      }

      return arr
    },
    $generatePageOne () {
      return [
        {
          columns: [
            {
              width: '*',
              image: occLogo,
              link: 'https://occ-solutions.com',
              margin: [-10, -5, 0, 0],
              fit: [90, 90]
            },
            {
              width: '80%',
              margin: [0, 5, 0, 0],
              ul: [
                { text: `Cotización para ${this.data.company} / ${this.data.name} ${this.data.lastName}\n`, bold: true, fontSize: 13 },
                { text: `Fecha: ${this.quotationDate}`, fontSize: 9, color: '#555555' },
                { text: 'Validez de la propuesta: 3 meses', fontSize: 9, color: '#555555' }
              ],
              markerColor: '#FFFFFF'
            }
          ]
        },
        {
          columns: [
            {
              width: '*',
              margin: [-10, 24, 0, 44],
              image: greenCloud,
              fit: [234, 164]
            },
            {
              width: '*',
              margin: [8, 66, 15, 0],
              text: 'OCC Solutions ofrece herramientas de medición de talento humano y consultoría para lograr el éxito duradero de tu empresa.'
            }
          ]
        },
        this.printChecks(),
        {
          columns: [
            {
              width: '*',
              margin: [27, 0, 27, 0],
              text: 'Mide y trabaja con datos,\nconstruye equipos comprometidos y mejora tus resultados.',
              alignment: 'center'
            },
            {
              width: '62%',
              margin: [40, -18.5, 0, 0],
              text: [
                'Incrementa el compromiso o "engagement" del equipo\n',
                'Reduce la rotación  de personas\n',
                'Consigue líderes que inspiren a sus equipos\n',
                'Atrae talento top\n',
                'Disminuye el trabajo en silos y la comunicación deficiente\n',
                'Crea e implementa una cultura empresarial diferenciadora'
              ],
              lineHeight: 1.53,
              color: '#444444',
              fontSize: 10
            }
          ]
        },
        {
          text: 'Herramientas de medición OCC Solutions',
          margin: [-25, 25, 0, 10],
          fontSize: 12.5,
          bold: true
        },
        {
          image: line,
          margin: [-25, 0, 0, 0]
        },
        this.printSquares(520),
        this.printSquares(660),
        {
          margin: [-21, 25, 0, 0],
          columns: [
            {
              width: '*',
              margin: [0, 0, 11, 0],
              text: [
                { text: 'OCC Cultura\n', bold: true, color: '#1999DA', fontSize: 10 },
                { text: 'Cultura Organizacional\n', fontSize: 10 },
                { text: '\n', fontSize: 4 },
                {
                  text: 'Mide la cultura actual de la organización y apoya la definición de la cultura requerida para alinearla con la estrategia.\nReportes con análisis de brechas, subculturas y tendencias.',
                  color: '#444444',
                  lineHeight: 1.1,
                  fontSize: 9
                }
              ]
            },
            {
              width: '*',
              margin: [16, 0, -5, 0],
              text: [
                { text: 'OCC Pulse\n', bold: true, color: '#1999DA', fontSize: 10 },
                { text: 'Engagement\n', fontSize: 10 },
                { text: '\n', fontSize: 4 },
                {
                  text: 'Identifica los factores claves de bienestar y ambiente laboral que afectan el engagement y permite mediciones periódicas para entender cómo se sienten las personas en el día a día y como avanzan los planes de acción a través de mediciones tipo pulso.',
                  color: '#444444',
                  lineHeight: 1.1,
                  fontSize: 9
                }
              ]
            },
            {
              width: '*',
              margin: [32, 0, -20, 0],
              text: [
                { text: 'DML 360°\n', bold: true, color: '#1999DA', fontSize: 10 },
                { text: 'Liderazgo\n', fontSize: 10 },
                { text: '\n', fontSize: 4 },
                {
                  text: 'Permite a los líderes conocer las percepciones de quienes influyen en su desempeño, para complementarlas con su auto percepción y así tener un panorama completo sobre sus competencias relacionadas con dirección, movilización y logro.',
                  color: '#444444',
                  lineHeight: 1.1,
                  fontSize: 9
                }
              ]
            }
          ]
        },
        {
          margin: [-21, 25, 0, 0],
          columns: [
            {
              width: '*',
              margin: [0, 0, 11, 0],
              text: [
                { text: 'POR\n', bold: true, color: '#1999DA', fontSize: 10 },
                { text: 'Trabajo en Equipo\n', fontSize: 10 },
                { text: '\n', fontSize: 4 },
                {
                  text: 'Valora las 3 dimensiones que definen a un equipo: las Personas, la Organización y los Resultados. Auto evaluación comparable con la forma en que sus compañeros perciben el trabajo en equipo.',
                  color: '#444444',
                  lineHeight: 1.1,
                  fontSize: 9
                }
              ]
            },
            {
              width: '*',
              margin: [16, 0, -5, 0],
              text: [
                { text: 'CC180°\n', bold: true, color: '#1999DA', fontSize: 10 },
                { text: 'Coaching y Conversaciones\n', fontSize: 10 },
                { text: '\n', fontSize: 4 },
                {
                  text: 'Recoge las percepciones que los colaboradores tienen sobre el comportamiento de su líder como coach y sus habilidades para dar retroalimentación.',
                  color: '#444444',
                  lineHeight: 1.1,
                  fontSize: 9
                }
              ]
            },
            {
              width: '*',
              margin: [32, 0, -20, 0],
              text: [
                { text: 'Energy Compass\n', bold: true, color: '#1999DA', fontSize: 10 },
                { text: 'Bienestar\n', fontSize: 10 },
                { text: '\n', fontSize: 4 },
                {
                  text: 'Mide el nivel de energía de colaboradores, equipos y empresas, en 4 dimensiones: física, mental, emocional y profesional. Permite identificar fortalezas y áreas de oportunidad en la gestión de la energía y definir planes de acción.',
                  color: '#444444',
                  lineHeight: 1.1,
                  fontSize: 9
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
