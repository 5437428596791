
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <h4 class="display-1">{{ $t('customers.customers_list') }}</h4>
      </v-col>
      <v-col cols="12" md="6" class="text-right">
        <v-spacer></v-spacer>
        <v-btn large
          color="primary"
          to="/groups"
          class="text-capitalize mr-1"
        >
          <v-icon left>mdi-account-group</v-icon>
          {{ $t('models.groups') }}
        </v-btn>
        <v-btn large
          color="primary"
          to="customer/select-type"
          class="text-capitalize"
        >
          <v-icon left>mdi-plus-circle-outline</v-icon>
          {{ $t('customers.add_customer') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider></v-divider>

        <v-card>
          <v-card-title>
            <div class="title text-uppercase">{{ $t('customers.resumen') }}</div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="mdi-magnify"
              :label="$t('customers.look_for')"
              single-line
              hide-details
            ></v-text-field>

          </v-card-title>

          <v-divider class="mt-3 mb-1"></v-divider>

          <v-row row wrap class="mx-2">
            <v-col xs12 sm3>
              <v-autocomplete
                :label="$t('customers.filter_by')"
                :items="filters"
                prepend-inner-icon="mdi-filter-outline"
                @change="filterItems"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <x-data-table
            :headers="headers"
            :parent-fetch-data="getCustomers"
            :options="options"
            no-data="customers.no_data"
          >
            <template v-slot:structure="prop">
              <td class="text-center text-capitalize" v-if="prop.item.type === 'commercial'">{{ prop.item.name }}</td>
              <td class="text-center text-capitalize" v-else>{{ (prop.item.enterprise || {}).name }}</td>
              <td class="text-center">{{ prop.item.createdAt | date({date: true, hour:false}) }}</td>
              <td class="text-center text-capitalize">
                {{ $t(`customers.${prop.item.type}`) }}<br/>
                <v-chip color="info" outlined
                  v-if="prop.item.type === 'commercial' && prop.item.enterprises && prop.item.enterprises.length"
                >{{ prop.item.enterprises.length }} {{ $t('customers.list_registered_enterprise') }}</v-chip>
              </td>
              <td class="text-center">{{ prop.item.balance }}</td>
              <td class="text-center">
                <x-state-switch
                  :value="prop.item.active"
                  :item="prop.item"
                  class="ma-2"
                  :enable="{
                    title: $t('customers.modal_enable'),
                    action: enableCustomer,
                    color: 'success',
                    btn_text: $t('input.enable'),
                    question: $t('customers.modal_enable_des')
                  }"
                  :disable="{
                    title: $t('customers.modal_disable'),
                    action: disableCustomer,
                    color: 'error',
                    btn_text: $t('input.disable'),
                    question: $t('customers.modal_disable_des')
                  }"
                />
              </td>
              <td class="text-center">
                <template v-if="prop.item.type === 'personal'">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :to="`enterprises/profile/${prop.item.enterprise.id}`"
                        v-on="on"
                        text icon>
                        <v-icon small>remove_red_eye</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('input.enterprise_profile') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :to="`enterprises/${prop.item.enterprise.id}/edit`"
                        v-on="on"
                        text icon>
                        <v-icon small>fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('input.edit') }}</span>
                  </v-tooltip>
                </template>
                <v-tooltip bottom color="primary" v-else>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`customer/${prop.item.id}/edit`"
                      v-on="on"
                      text icon>
                      <v-icon small>fa-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.edit') }}</span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="openTokensModal(prop.item)"
                      v-on="on"
                      text
                      icon>
                      <v-icon small>fa-coins</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.manage_token') }}</span>
                </v-tooltip>
                <v-tooltip bottom color="primary" v-if="user.role === user.view">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="loginAsCustomer(prop.item.id, prop.item.type)"
                      v-on="on"
                      text
                      icon>
                      <v-icon small>fa-sign-out-alt</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.login_as_customer') }}</span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="openProductsModal(prop.item)"
                      v-on="on"
                      text
                      icon>
                      <v-icon small>fa-clipboard-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.assign_products') }}</span>
                </v-tooltip>

                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn text icon
                      v-on="on"
                      @click="openDashboardAccessModal(prop.item)"
                    >
                      <v-icon small>fa-chart-column</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.dashboard_products') }}</span>
                </v-tooltip>

              </td>
            </template>
          </x-data-table>
        </v-card>
      </v-col>
    </v-row>

    <dashboard-access-modal
      v-if="dashboardAccessModal.open"
      :customer="dashboardAccessModal.customer"
      :products="productsWithDashboard"
      @updateDashboards="updateDashboardAccess"
      @close="dashboardAccessModal.open = false"
    />

    <products-assign-modal
      @close="assignProductModal.open = false"
      :item="assignProductModal.item"
      :products="products"
      @assignProducts="assignProducts"
      v-if="assignProductModal.open"
    />

    <token-customer-modal
      :customer="customerSelected"
      v-if="tokenCustomerModal"
      :action="handleTokens"
      @close="hideTokenModal"
    />

    <x-loading
      v-if="loading"
      :display="loading"
    ></x-loading>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import customersService from '../../services/customers'
import authService from '../../services/auth'
import productsService from '../../services/products'

import tokenAccountDetailService from '../../services/token-account-detail'
import TokenCustomerModal from './token-customer-modal.vue'
import DashboardAccessModal from './dashboard-access-modal'
import ProductsAssignModal from './product-assignation-modal'

export default {
  props: {
    hasFilter: {
      type: Boolean,
      default: false
    },
    routeFilter: String
  },
  components: {
    TokenCustomerModal,
    ProductsAssignModal,
    DashboardAccessModal
  },
  data () {
    return {
      headers: [
        'customers.customer_name',
        'customers.register_date',
        'customers.type',
        'customers.balance',
        'customers.status',
        'customers.actions'
      ],
      options: {
        filter: null,
        search: null
      },
      search: null,
      timer: null,
      demoRequests: [],
      tokenCustomerModal: false,
      customerSelected: null,
      dashboardAccessModal: {
        open: false,
        customer: {}
      },
      assignProductModal: {
        open: false,
        item: {}
      },
      products: null,
      productsWithDashboard: null,
      loading: false
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    filters () {
      const translation = (option) => this.$t(`customers.filters.${option}`)
      return [
        { text: translation('all'), value: null },
        { text: translation('active'), value: '1' },
        { text: translation('inactive'), value: '0' }
      ]
    }
  },
  created () {
    this.getActiveProducts()
  },
  methods: {
    runWithLoading (callBack) {
      this.loading = true
      return callBack()
        .then(() => { this.loading = false })
        .catch(() => { this.loading = false })
    },
    getCustomers (options) {
      if (this.hasFilter) {
        options.filter = this.routeFilter
        this.hasFilter = false
      }
      return customersService.list(options)
        .then((res) => ({
          items: res.items.map(item => {
            item.licenses = item.licenses ? item.licenses[0] : undefined
            return item
          }),
          total: res.total
        }))
    },
    openTokensModal (customerSelected) {
      this.tokenCustomerModal = true
      this.customerSelected = customerSelected
    },
    hideTokenModal () {
      this.tokenCustomerModal = false
    },
    openDashboardAccessModal (customer) {
      this.dashboardAccessModal.open = true
      this.dashboardAccessModal.customer = customer
    },
    updateDashboardAccess (newDashboards) {
      this.dashboardAccessModal.open = false
      this.dashboardAccessModal.customer.dashboards = newDashboards
    },
    openProductsModal (customer) {
      this.assignProductModal.open = true
      this.assignProductModal.item = customer
    },
    assignProducts (customerProducts, deletedProducts) {
      return this.runWithLoading(() => {
        return customersService.assignProducts(customerProducts, this.assignProductModal.item.id, deletedProducts)
          .then((res) => {
            this.assignProductModal.open = false
            this.assignProductModal.item.products = res
          })
      })
    },
    disableCustomer (item) {
      return this.runWithLoading(() => {
        return customersService.disable(item.id)
          .then(() => { item.active = false })
      })
    },
    enableCustomer (item) {
      return this.runWithLoading(() => {
        return customersService.enable(item.id)
          .then(() => { item.active = true })
      })
    },
    filterItems (value) {
      this.options = {
        ...this.options,
        filter: value
      }
    },
    loginAsCustomer (customerId) {
      return this.runWithLoading(() => {
        return authService.loginAsCustomer(customerId, this.user)
          .then((res) => {
            return this.$store
              .dispatch('session/loginAs', res)
              .then((res) => {
                this.$router.push(res.user.customer.type === 'commercial' ? '/commercial-profile' : '/measuring-tools')
                setTimeout(() => { window.location.reload(false) }, 150)
              })
              .catch(err => (this.$store.dispatch('alert/error', err)))
          }).catch(err => (this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))))
      })
    },
    handleTokens (customerId, value) {
      return this.runWithLoading(() => {
        return tokenAccountDetailService.move(customerId, value)
          .then(() => {
            this.hideTokenModal()
            this.$store.dispatch('alert/success', this.$t('tokens.created_transfer'))
            this.search = this.search === null ? undefined : null
          }).catch((err) => {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          })
      })
    },
    getActiveProducts () {
      return this.runWithLoading(() => {
        return productsService.listActive()
          .then((res) => {
            this.products = res.map((product) => {
              return {
                text: product.name,
                value: product.id
              }
            })

            this.productsWithDashboard = res.filter(p => p.dashboard !== null)
          })
      })
    }
  },
  watch: {
    search () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.options = {
          ...this.options,
          search: this.search
        }
      }, 3000)
    }
  }
}
</script>
