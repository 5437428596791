<template>
  <v-container>
    <x-loading v-if="loading" :display="loading"/>
    <v-row v-else>
      <v-col cols="12">
        <h4 class="display-1">{{ $t('settings.data_protection_policies_settings')}}</h4>
      </v-col>
      <v-col cols="12" v-if="policy">
        <v-card flat>
          <v-row>
            <v-col cols="9" class="px-8 headline">
              {{ $t(`settings.data_protection_policies_for_${policy.type}`)}}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mx-6">
              <div class="ql-editor" v-html="policy.description"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="end" class="mx-6">
              <v-btn
                small
                @click="$router.go(-1)"
              >
                Volver
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from 'vue'

import dataProtectionPoliciesService from '../../../services/data-protection-policies'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default Vue.extend({
  data () {
    return {
      policy: null,
      loading: true
    }
  },
  created () {
    this.getPolicy()
  },
  methods: {
    getPolicy () {
      return dataProtectionPoliciesService.getOne(this.$route.params.id)
        .then((res) => {
          this.policy = res
          this.loading = !this.loading
        })
    }
  }
})
</script>
