
<template>
  <v-container fluid>
    <v-row justify="start">
      <v-col class="display-1">
        {{ $t('settings.products_conf') }}
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="px-5">
          <x-inputs-upload-image
            :key="product.id"
            :input="String(product.logo || product.defaultLogo)"
            :title="$t('settings.products_logo')"
            @updateInput="($event) => product.logo = $event"
          ></x-inputs-upload-image>

          <v-col cols="12">
            <v-text-field
              dense
              v-model="product.name"
              :label="$t('input.name')"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea dense outlined
              v-model="product.description[$i18n.locale]"
              counter
              maxlength="500"
              rows="3"
              :label="$t('settings.description')"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="6">
                <v-btn
                  block
                  :to="`/settings/products`">
                  {{ $t('input.cancel') }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <x-async-btn
                  block
                  :disabled="isDisabled"
                  class="white--text"
                  :action="confirm"
                >{{ $t('input.save') }}
                </x-async-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import productsService from '../../services/products'

export default {
  data () {
    return {
      product: {
        name: '',
        description: '',
        logo: '',
        defaultLogo: ''
      },
      image: '',
      file: null
    }
  },
  computed: {
    isDisabled () {
      return !this.product.name || !this.product.description[this.$i18n.locale]
    },
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.fetchProduct()
  },
  methods: {
    confirm () {
      const payload = JSON.parse(JSON.stringify(this.product))
      payload.description = JSON.stringify(payload.description)
      return productsService.edit(this.$route.params.id, payload)
        .then((res) => {
          this.hideModal()
          this.$store.dispatch('alert/success', this.$t('settings.products_edited'))
          this.$router.go(-1)
        })
    },
    hideModal () {
      this.$emit('close')
    },
    fetchProduct () {
      const id = this.$route.params.id
      return productsService.getOneById(id)
        .then((res) => {
          this.product = res
        })
    }
  }
}
</script>
