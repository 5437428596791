
import Vue from 'vue'
import Vuex from 'vuex'

import acl from './modules/acl'
import alert from './modules/alert'
import customer from './modules/customer'
import help from './modules/help'
import session from './modules/session'

Vue.use(Vuex)

// give a type to store.state, so tscompiler is pleased
// we must add all new modules as properties in state's type
const state: {
  session?: any,
  alert?: any,
  acl?: any,
} = {}

export default new Vuex.Store({
  state,
  modules: {
    acl,
    alert,
    customer,
    help,
    session
  }
})
