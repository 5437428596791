
<template>
  <v-container fluid >
    <v-row>
      <v-col cols="6" sm="9" md="8" class="pt-4 pl-4">
        <h4 class="display-1">{{ $t('settings.headquarters_head') }}</h4>
      </v-col>
      <v-col cols="6" sm="3" md="4" class="text-right">
        <v-btn large
          color="primary"
          @click="createModal = true"
        >
          <v-icon left>mdi-plus-circle-outline</v-icon>
          {{ $t('settings.add') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row wrap row>
      <v-col cols="12">
        <v-card>
          <v-card-subtitle class="mt-1 text-justify"
            v-html="$t('settings.master_references_section')"
          ></v-card-subtitle>

          <v-divider class="mb-1"></v-divider>

          <v-card-text v-if="fetched && headquarters.length" class="px-0 pt-0">
            <v-col>
              <x-info :text="$t('settings.headquarters_info')"></x-info>
            </v-col>

            <v-row row wrap class="mt-3 px-3">
              <!-- Cabeceras -->
              <v-col cols="4" class="text-center pr-0">
                <v-card flat>
                  <v-card-text class="text-center font-weight-black px-0 mb-2">
                    {{ $t('settings.headquarters_countries') }}
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card>
              </v-col>
              <v-col cols="6" class="text-center pr-0 pl-6">
                <v-card flat>
                  <v-card-text class="text-center font-weight-black px-0 mb-2">
                    {{ $t('settings.headquarters_heads') }}
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card>
              </v-col>
              <v-col cols="2" class="text-center pl-6">
                <v-card flat>
                  <v-card-text class="text-center font-weight-black px-0 mb-2">
                    {{ $t('settings.headquarters_actions') }}
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card>
              </v-col>
            </v-row>

            <v-data-iterator
              :items="headquarters"
              :items-per-page="10"
              :options.sync="pagination"
              :footer-props="footerProps"
              row wrap
            >
            <!-- <template v-for="headquarter in headquarters"> -->
              <template v-slot:item="headquarter">
                <v-container fluid class="px-0" :key="headquarter.id">
                  <!-- Elementos -->
                  <v-row row wrap class="pl-5" style="max-height: 3.5em;">
                        <!-- :on-change="updateHeadquarter(headquarter)" -->
                    <v-col cols="4">
                      <x-inputs-autocomplete
                        :items="getFormattedCountries"
                        :input="headquarter.item.countryId"
                        @updateInput="($event) => updateHeadquarter($event, headquarter.item)"
                        :label="$t('settings.headquarters_country')"
                        :color="!headquarter.item.active ? 'grey lighten-2' : 'primary'"
                        :background-color="!headquarter.item.active ? 'grey lighten-2' : ''"
                        :disabled="!headquarter.item.active"
                        outlined
                        light
                      ></x-inputs-autocomplete>
                    </v-col>

                    <v-col cols="6">
                        <v-form ref="form" >
                          <v-tooltip bottom color="blue">
                            <template v-slot:activator="{ on }" >
                              <validation-provider rules="required" v-slot="{ errors }">
                                  <v-text-field
                                  @keypress.enter="validateName(headquarter.item)"
                                  @blur="validateName(headquarter.item)"
                                  v-model="headquarter.item.name"
                                  v-on="on"
                                  :label="$t('settings.headquarters_head')"
                                  :color="!headquarter.item.active ? 'grey lighten-2' : 'primary'"
                                  :background-color="!headquarter.item.active ? 'grey lighten-2' : ''"
                                  :disabled="!headquarter.item.active"
                                  name="name"
                                  :error-messages="errors[0]"
                                  :error="!!errors[0]"
                                  light
                                ></v-text-field>
                              </validation-provider>
                            </template>
                            {{ $t('settings.tooltip_edit') }}
                          </v-tooltip>
                        </v-form>
                    </v-col>

                    <v-col cols="2" class="text-center">
                      <!-- Deshabilitar -->
                      <v-tooltip bottom v-if="headquarter.item.active">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="handleToggleActive(headquarter.item, false)"
                            icon
                            large
                            color="primary"
                            v-on="on"
                          >
                            <v-icon medium>mdi-eye-off</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('settings.headquarters_disable') }}</span>
                      </v-tooltip>

                      <!-- Habilitar -->
                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="handleToggleActive(headquarter.item, true)"
                            icon
                            large
                            color="success"
                            v-on="on"
                          >
                            <v-icon medium>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('settings.headquarters_enable') }}</span>
                      </v-tooltip>

                      <!-- Eliminar -->
                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="red"
                            @click="handleDelete(headquarter.item.id)">
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('input.trash') }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:footer.page-text="props">
                {{ props.pageStart }}-{{ props.pageStop }} {{ $t('page_text') }} {{ props.itemsLength }}
              </template>
            </v-data-iterator>
          </v-card-text>

          <v-card-text v-else>
            <v-row row wrap>
              <v-col cols="12" class="text-xs-center">
                <h5 class="headline grey--text">{{ $t('settings.no_data') }}</h5>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Modal de eliminación -->
    <x-confirmation-modal
      :show.sync="deleteModal"
      :reversible="true"
      :title="$t('settings.headquarters_delete_title')"
      :action="deleteHeadquarter"
      :btn-save="$t('input.trash')"
      color="error"
      @close="deleteModal = false"
    >
      <template v-slot:question>{{ $t('settings.master_references_delete_des') }}</template>
    </x-confirmation-modal>

    <!-- Modal de habilitación -->
    <x-confirmation-modal
      :show.sync="toggleTrueModal"
      :reversible="true"
      :title="$t('settings.headquarters_toggle_false_title')"
      :action="toggleActiveHeadquarter"
      @close="toggleTrueModal = false"
      :btn-save="$t('input.enable')"
      color="success"
    >
      <template v-slot:question>{{ $t('settings.master_references_enable_des') }}</template>
    </x-confirmation-modal>

    <!-- Modal de inhabilitación -->
    <x-confirmation-modal
      :show.sync="toggleFalseModal"
      :reversible="true"
      :title="$t('settings.headquarters_toggle_true_title')"
      :action="toggleActiveHeadquarter"
      @close="toggleFalseModal = false"
      :btn-save="$t('input.disable')"
      color="error"
    >
      <template v-slot:question>{{ $t('settings.master_references_disable_des') }}</template>
    </x-confirmation-modal>

    <!-- Modal de creación -->
    <v-dialog v-model="createModal" width="500">
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-form ref="form-2"
          @submit.prevent="handleSubmit(createHeadquarter)"
        >
          <v-card>
            <v-card-title class="headline" primary-title>
              {{ $t('settings.headquarters_add') }}
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-container fluid class="px-0">
                <v-row row wrap class="pl-5">
                  <v-col cols="12">
                    <x-inputs-autocomplete
                      :input="newHeadquarter.countryId"
                      @updateInput="($event) => newHeadquarter.countryId= $event"
                      :items="getFormattedCountries"
                      :label="$t('settings.headquarters_country')"
                      name="country"
                      rules="required"
                    ></x-inputs-autocomplete>
                    <!-- <v-autocomplete
                      v-model="newHeadquarter.countryId"
                      v-validate="'required'"
                      :items="countries"
                      :label="$t('settings.headquarters_country')"
                      :error-messages="'' /*errors.collect('form-2.country')*/"
                      :data-vv-as="$t('input.country') | lowerCase"
                      name="country"
                    ></v-autocomplete> -->
                  </v-col>

                  <v-col cols="12">
                    <x-inputs-input
                      :input="newHeadquarter.name"
                      @updateInput="($event) => newHeadquarter.name= $event"
                      :label="$t('settings.headquarters_head')"
                      name="name"
                      rules="required"
                    ></x-inputs-input>
                    <!-- <v-text-field
                      v-model="newHeadquarter.name"
                      v-validate="'required'"
                      :label="$t('settings.headquarters_head')"
                      :error-messages="'' /*errors.collect('form-2.name')*/"
                      :data-vv-as="$t('input.headquarter') | lowerCase"
                      name="name"
                    ></v-text-field> -->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="createModal = false"
                color="secondary"
              >{{ $t('input.cancel') }}</v-btn>

              <v-btn
                type="submit"
                color="primary"
              >{{ $t('input.save') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import resolver from '../../utils/resolver'
import formatItems from '../../utils/form-format-items-list'
import countriesService from '../../services/countries'
import headquartersService from '../../services/headquarters'

export default {
  data () {
    return {
      // Variables de control
      fetched: false,
      validOne: false,
      validTwo: false,
      deleteModal: false,
      toggleTrueModal: false,
      toggleFalseModal: false,
      createModal: false,
      // Data
      countries: [],
      headquarters: [],
      originalHeadquarters: [],
      newHeadquarter: {
        enterpriseId: '',
        countryId: '',
        name: ''
      },
      // Momentaneas
      toggleable: {},
      deleteable: {},
      // Paginacion
      pagination: {}
    }
  },
  created () {
    this.fetchData()
    this.newHeadquarter.enterpriseId = this.user.enterprise.id
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    }),
    footerProps () {
      return {
        'items-per-page-text': this.$t('results_per_pages'),
        'items-per-page-options': [5, 10, 25, { 'text': this.$t('page_all'), 'value': -1 }]
      }
    },
    getFormattedCountries () {
      if (!this.countries.length) return []
      return formatItems(this.countries, (item) => {
        const translation = item.translations.find(i => i.lang === this.$i18n.locale)
        const label = !translation || !translation.label ? item.translate.label : translation.label

        return {
          text: label,
          value: item.id
        }
      }).sort((a, b) => b.text - a.text)
    }
  },
  methods: {
    fetchData () {
      resolver
        .all({
          countries: countriesService.list(),
          headquarters: headquartersService.fetchByEnterpriseForView()
        })
        .then(({ countries, headquarters }) => {
          this.countries = countries.items
          this.headquarters = headquarters
          this.originalHeadquarters = JSON.parse(JSON.stringify(this.headquarters))
          this.fetched = true
        })
        .catch((error) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
        })
    },
    validateName ({ countryId, id, name }) {
      if (!name) {
        return
      }
      this.updateHeadquarter(countryId, { id, name })
    },
    updateHeadquarter (countryId, { id, name }) {
      const originalValue = this.originalHeadquarters.find((x) => x.id === id)
      const changedItem = this.headquarters.find((x) => x.id === id)
      return headquartersService.update(id, { countryId, name })
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('settings.headquarters_updated'))
        })
        .catch((err) => {
          if (err.code && err.code === 'already_exists') {
            changedItem.name = originalValue.name
            this.$store.dispatch('alert/error',
              this.$t('settings.demographic_already_exists', [this.$t('settings.headquarters_head')])
            )
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
        })
    },
    handleToggleActive (headquarter, active) {
      active ? this.toggleTrueModal = true : this.toggleFalseModal = true
      this.toggleable = { headquarter, active }
    },
    toggleActiveHeadquarter () {
      const { headquarter, active } = this.toggleable

      return headquartersService.toggleActive(headquarter.id, active)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('settings.headquarters_updated'))
          headquarter.active = !headquarter.active
          active ? this.toggleTrueModal = false : this.toggleFalseModal = false
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    handleDelete (id) {
      this.deleteModal = true
      this.deleteable = { id }
    },
    deleteHeadquarter () {
      const { id } = this.deleteable

      return headquartersService.delete(id)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('settings.headquarters_deleted'))
          this.headquarters = this.headquarters.filter((h) => h.id !== id)
          this.deleteModal = false
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    createHeadquarter () {
      headquartersService.create(this.newHeadquarter)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('settings.headquarters_created'))
          const headquarter = { deleted_at: null, active: true, ...res }
          this.headquarters.push(headquarter)
          this.createModal = false
          this.newHeadquarter.countryId = ''
          this.newHeadquarter.name = ''
        })
        .catch((err) => {
          if (err.code && err.code === 'already_exists') {
            this.$store.dispatch('alert/error',
              this.$t('settings.demographic_already_exists', [this.$t('settings.headquarters_head')])
            )
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
        })
    }
  }
}
</script>
