
export default {
  enterprises_list: 'Listado de Empresas',
  add_enterprise: 'Agregar empresa',
  edit_enterprise: 'Editar empresa',
  filter_by: 'Filtrar por...',
  look_for: 'Buscar una empresa',
  no_data: 'No hay empresas para mostrar',
  id: 'Id',
  enterprise_name: 'Nombre empresa',
  group: 'Grupo',
  license: 'Licencia',
  expiration_date: 'Vencimiento',
  status: 'Estado',
  actions: 'Acciones',
  modal_enable: '¿Desea habilitar la empresa?',
  modal_disable: '¿Desea inhabilitar la empresa?',
  modal_enable_des: 'Una vez habilitada, la empresa podrá acceder nuevamente al sistema',
  modal_disable_des: 'Una vez sea inhabilitada, la empresa no podrá acceder al sistema hasta ser habilitada nuevamente',
  action: 'Realizar acción',
  accept: 'Aceptar',
  close: 'Cerrar',
  enterprise_information: 'Información de la empresa',
  contact_employee: 'Colaborador de contacto',
  licensing: 'Licenciamiento',
  demographic_cuts: 'Cortes Demográficos',
  tool_customization: 'Personalización de la herramienta',
  primary_color: 'Color primario',
  secondary_color: 'Color secundario',
  informative_cuts: 'Los siguientes datos son informativos y no representan acciones en el sistema. Estos datos son obligatorios para identificar a los colaboradores.',
  select_demographic_cuts: 'Por favor, seleccione las categorías de los cortes demográficos que desea medir.',
  obligatories_demographic_cuts: 'Las categorías {obligatories} son obligatorias.',
  created_enterprise: 'La empresa fue creada con éxito.',
  section_edited: 'La sección fue editada exitosamente.',
  enterprise_edited: 'La empresa fue editada exitosamente.',
  profile_title: 'Empresa {size} del sector {sector}.',
  profile_title_addres: 'Ubicada en: {contry} - {address}.',
  no_extend_requests: 'Esta empresa no posee solicitudes de extensión pendientes.',
  extend_request_accepted: 'Solicitud aceptada',
  extend_request_rejected: 'Solicitud rechazada',
  extend_request_cancelled: 'Solicitud cancelada',
  status_pending: 'Pendiente',
  status_accepted: 'Aceptada',
  status_rejected: 'Rechazada',
  status_cancelled: 'Cancelada',
  created_demo_enterprise: 'Se ha registrado su empresa. A continuación, recibirá un correo con las instrucciones a seguir para la activación de su cuenta.',
  profile_warning: 'Recuerde configurar sus cortes demográficos para utilizar el sistema correctamente.',
  first_time_title: 'Bienvenido a OCC - Suite',
  first_time_text: 'Para tener una mejor experiencia al interactuar con la plataforma es recomendable seguir los siguientes pasos:',
  first_time_step: 'Paso',
  first_time_step_1: 'Verifique la información de la empresa, edite o cargue únicamente los cortes demográficos que utilizará para generar los reportes.',
  first_time_step_2: 'Ingrese a la sección de colaboradores para la carga de nómina de la empresa antes de realizar cualquier medición.',
  first_time_step_3: 'Create your first Engagement or Pulse measurement acording to the your license.',
  register_date: 'Fecha de registro',
  balance: 'Saldo actual OCC Tokens',
  resumen: 'Resumen de empresas',
  admin: 'Administrador',
  available_tokens: 'Tokens disponibles',
  company_name: 'Nombre de la compañía',
  profile_picture: 'Foto de perfil',
  load_company_logo: 'Cargar logo de la compañía',
  customer: 'Cliente',
  successfully_edited: 'Edición exitosa'
}
