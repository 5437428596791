
const required = '<br/><br/><b>Este campo es obligatorio.</b>'

export default {
  icon: 'mdi-help-circle',
  engagement: {
    create: {
      name: {
        title: 'Nombre de la encuesta',
        text: `Sirve como medio de identificación para la encuesta. ${required}`
      },
      displayName: {
        title: 'Nombre externo para los encuestados',
        text: 'En caso de ser seleccionado, este campo es el medio de identificación para los encuestados de la encuestas, reemplazando el "nombre de la encuesta".'
      },
      deliveredAt: {
        title: 'Fecha de lanzamiento',
        text: `Representa la fecha a partir de la cual se podrá participar en la encuesta. ${required}`
      },
      validUntil: {
        title: 'Encuesta válida hasta',
        text: `Representa la fecha hasta la cual se podrá particiapr en la encuesta. ${required}`
      },
      timezone: {
        title: 'Zona horaria',
        text: `Las fechas de lanzamiento, validez y recordatorios se basarán en la zona horaria elegida. ${required}`
      },
      reminders: {
        title: 'Recordatorios',
        text: 'Cada recordatorio envía un correo a todos los encuestados de la encuesta en la fecha especificada. Además, la fecha de entrega del recordatorio debe estar comprendida entres la fechas de lanzamiento y validez de la encuesta.'
      },
      openQuestion: {
        title: 'Añadir preguntas abiertas adicionales',
        text: 'Permite agregar hasta tres preguntas abiertas extras a la encuesta con un máximo de cinco opciones cada una.'
      },
      subject: {
        title: 'Asunto del mensaje',
        text: 'Asunto que se utilizará en el correo electrónico enviado.'
      },
      body: {
        title: 'Cuerpo del mensaje',
        text: 'Contenido del correo electrónico enviado.'
      },
      thankMessage: {
        title: 'Mensaje de agradecimiento',
        text: 'Este mensaje será enviado al correo electrónico del receptor que finalice satisfactoriamente el llenado de la encuesta.'
      }
    }
  },
  employee: {
    create: {
      identifyDocument: {
        title: 'Documento de identificación',
        text: `Documento de identificación del colaborador. Es necesario indicar el tipo de documento.<br>Ingresa únicamente letras y números. No se permiten caracteres especiales. ${required}`,
        required
      },
      firstName: {
        title: 'Nombre',
        text: `Los nombres del colaborador. ${required}`,
        required
      },
      lastName: {
        title: 'Apellido',
        text: `Los apellidos del colaborador. ${required}`,
        required
      },
      gender: {
        title: 'Género',
        text: `Género del colaborador.`,
        required
      },
      birthdate: {
        title: 'Fecha de nacimiento',
        text: `Fecha de nacimiento del colaborador.`,
        required
      },
      academicDegree: {
        title: 'Nivel académico',
        text: `Selecciona el último nivel académico alcanzado por el colaborador. .`,
        required
      },
      country: {
        title: 'País',
        text: `País de residencia del colaborador.`,
        required
      },
      headquarter: {
        title: 'Sede',
        text: `Sede donde trabaja el colaborador.`,
        required
      },
      email: {
        title: 'Correo electrónico',
        text: `Correo electrónico del colaborador. En caso de no tener correo, dejar este campo vacío.`,
        required
      },
      phoneNumber: {
        title: 'Número de teléfono',
        text: `Número de teléfono del colaborador.`,
        required
      },
      admission: {
        title: 'Fecha de ingreso',
        text: `Fecha en la cual fue contratado el colaborador.`,
        required
      },
      jobType: {
        title: 'Tipo de contratación',
        text: `Tipo de contratación del colaborador.`,
        required
      },
      department: {
        title: 'Área o departamento',
        text: `Área o departamento al cual pertenece el colaborador.`,
        required
      },
      charge: {
        title: 'Nivel de Cargo',
        text: `Cargo que desempeña el colaborador dentro de la organización.`,
        required
      },
      additionalDemographics1: {
        title: 'Demográfico adicional 1',
        text: 'Valor correspondiente al demográfico adicional 1'
      },
      additionalDemographics2: {
        title: 'Demográfico adicional 2',
        text: 'Valor correspondiente al demográfico adicional 2'
      }
    }
  },
  pulse: {
    create: {
      committal: {
        title: 'Añadir preguntas de compromiso',
        text: 'Permite añadir de una a seis preguntas de compromiso a la encuesta. Esta acción es obligatoria en caso de no incluir preguntas de un cuestionario en la encuesta.'
      },
      questionnaire: {
        title: 'Añadir preguntas de un cuestionario',
        text: 'Permite añadir las preguntas de una o más dimensiones pertenecientes a un cuestionario. Esta acción es obligatoria en caso de no incluir preguntas de compromiso en la encuesta.'
      },
      open: {
        title: 'Añadir preguntas abiertas adicionales',
        text: 'Permite agregar hasta tres preguntas abiertas extras a la encuesta con un máximo de cinco opciones cada una.'
      },
      selection: {
        title: '¿A quién desea enviar esta encuesta?',
        text: `Permite seleccionar a los encuestados de de la encuesta en base a tres criterios distintos: toda la organización, filtrados por cortes demográficos o por selección aleatoria en base a un porcentaje de todos los colaboradores. ${required}`
      }
    }
  },
  questionnaire: {
    create: {
      name: {
        title: 'Nombre',
        text: `Nombre que será asignado al cuestionario. ${required}`
      },
      base: {
        title: 'Cuestionario base',
        text: `Cuestionario del cual se hará una copia para generar el nuevo cuestionario. ${required}`
      },
      language: {
        title: 'Idiomas',
        text: `Idiomas en los que estará disponible el cuestionario. ${required}`
      },
      assignate: {
        title: 'Asignar cuestionario',
        text: 'Permite asignar el cuestionario a un sector empresarial específico o a una organización en particular.'
      }
    }
  },
  enterprise: {
    create: {
      logo: {
        title: 'Logo',
        text: 'El logo de la empresa debe ser una imagen cuyo peso no supere los 2MB.'
      },
      name: {
        title: 'Nombre',
        text: `Nombre de la empresa. ${required}`
      },
      sector: {
        title: 'Sector',
        text: `Selecciona el sector económico que más se asemeje a la actividad de la compañía. En caso de no encontrar uno, por favor selecciona otro. ${required}`
      },
      language: {
        title: 'Idioma',
        text: `Idioma principal de la empresa. ${required}`
      },
      country: {
        title: 'País',
        text: `País en donde se encuentra el domicilio principal de la compañía. ${required}`
      },
      headquarter: {
        title: 'Sede',
        text: `Escribe la sede o ciudad principal de operaciones de la compañía. ${required}`
      },
      address: {
        title: 'Dirección',
        text: `Escribe la dirección principal de la compañía. ${required}`
      },
      groups: {
        title: 'Grupo',
        text: 'Grupo al cual pertenece la empresa {0}.'
      },
      size: {
        title: 'Tamaño de la empresa',
        text: `Seleccione el tamaño de la compañía en base a su facturación anual. ${required}`
      },
      employeesNumber: {
        title: 'Número de colaboradores',
        text: `Número de colaboradores de la empresa. ${required}`
      },
      license: {
        title: 'Licencia',
        text: `Licencia que se asignará a la empresa. ${required}`
      },
      engagements: {
        title: 'Cantidad de Engagement',
        text: 'Cantidad de Engagement disponibles en el plan personalizado.'
      },
      pulses: {
        title: 'Cantidad de Pulse',
        text: 'Cantidad de Pulse disponibles en el plan personalizado.'
      },
      startAt: {
        title: 'Fecha de Inicio',
        text: `Fecha de inicio para el plan de la empresa. ${required}`
      },
      duration: {
        title: 'Duración',
        text: `Duración de la licencia. ${required}`
      },
      identifyDocument: {
        title: 'Documento de identificación',
        text: `Documento de identificación del administrador. Es necesario indicar el tipo de documento. ${required}`
      },
      firstName: {
        title: 'Nombre',
        text: `Nombre y apellido del administrador. ${required}`
      },
      lastName: {
        title: 'Apellido',
        text: `Los apellidos del colaborador de contacto. ${required}`
      },
      email: {
        title: 'Correo electrónico',
        text: `Correo electrónico del administrador. ${required}`
      },
      phoneNumber: {
        title: 'Número de teléfono',
        text: `Número de teléfono del administrador. ${required}`
      },
      contactAddress: {
        title: 'Dirección',
        text: `Dirección del colaborador de contacto.`
      },
      intoCurrentPassword: {
        title: 'Ingrese contraseña actual',
        text: `Debe indicar su contraseña actual para realizar esta operación. ${required}`,
        required
      },
      intoPassword: {
        title: 'Ingrese contraseña',
        text: `Ingrese nueva contraseña. ${required}`,
        required
      },
      intoConfirmPassword: {
        title: 'Ingrese confirmación de contraseña',
        text: `Ingrese la contraseña nuevamente. ${required}`,
        required
      },
      intoEmail: {
        title: 'Ingrese correo electrónico',
        text: `Ingrese correo electrónico actual. ${required}`,
        required
      },
      intoConfirmEmail: {
        title: 'Ingrese nuevo de correo electrónico',
        text: `Ingrese el correo electrónico que será asignado a la cuenta. ${required}`,
        required
      }
    },
    plan: {
      pulse_quantity: {
        title: 'Cantidad de Pulse a agregar',
        text: `Pulse que se agregarán a la empresa en la extensión del plan. ${required}`
      },
      engagement_quantity: {
        title: 'Cantidad de Engagement a agregar',
        text: `Engagement que se agregarán a la empresa en la extensión del plan. ${required}`
      },
      new: {
        title: 'Nuevo plan',
        text: `Plan que será asignado a la empresa. ${required}`
      },
      duration: {
        title: 'Duración',
        text: `Duración en meses para el nuevo plan. ${required}`
      }
    },
    massive: {
      file_input: {
        title: 'Archivo a cargar',
        text: `Cargue un archivo excel (.xls/.xlsx) o csv (.csv) que contenga los datos de sus colaboradores. Esta acción actualizará su base total de colaboradores, por lo que debe agregar la base completa de colaboradores disponibles.<br/><br/>Recuerde utilizar la plantilla descargable que puede obtener al hacer click en el botón "DESCARGAR PLANTILLA". ${required}`
      }
    },
    group: {
      name: {
        title: 'Nombre del grupo',
        text: `Nombre con el que se identificará al grupo de empresas. ${required}`
      },
      leader: {
        title: 'Empresa líder',
        text: 'La empresa líder (líder del grupo) tiene la capacidad de gestionar los planes de las empresas partenecientes a su grupo.'
      }
    }
  }
}
