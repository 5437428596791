<template>
   <v-row justify="center">
    <template v-if="info.length">
      <template v-for="(activity, k) in info">
        <v-col align="center" xs="12" sm="" :key="k" v-if="activity.isEvaluated || activity.isEvaluator">
          <v-card>
            <v-toolbar dense class="mb-3">
              <v-toolbar-title class="text-capitalize center--text">{{activity.displayName || activity.name}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center" v-for="(header, k) in $t('dashboard.employee_activities_table_header')" :key="k">
                        {{ header }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="activity.isEvaluated">
                      <tr>
                        <td class="text-center">{{ $t('dashboard.autoevaluation') }}</td>
                        <td class="text-center">
                          <v-btn
                            class="text-capitalize"
                            rounded
                            outlined
                            color="primary"
                            @click="goFill(activity.asEvaluated.employee.token)"
                            v-if="!activity.asEvaluated.employee.completed"
                          >
                            {{ $t('dashboard.reply') }}
                          </v-btn>
                          <v-btn
                            v-else
                            disabled
                            rounded
                            outlined
                            class="text-capitalize"
                          >
                            {{ $t('dashboard.evaluation_finished') }}
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">{{ $t('dashboard.evaluators') }}</td>
                        <td class="text-center">
                          <v-btn class="text-capitalize" rounded outlined color="primary" @click="showEvaluators(activity.asEvaluated)">
                            {{ $t('dashboard.see_evaluated') }}
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <template v-if="activity.isEvaluator">
                      <tr v-for="evaluator in activity.asEvaluator" :key="evaluator.employee.employee._id">
                        <td class="text-center"> {{ evaluator.evaluated }} ({{ $t(`dashboard.${evaluator.as}`) }}) </td>
                        <td class="text-center">
                          <v-btn
                            class="text-capitalize"
                            rounded
                            outlined
                            color="primary"
                            @click="goFill(evaluator.token)"
                            v-if="!evaluator.completed"
                          >
                            {{ $t('dashboard.reply') }}
                          </v-btn>
                          <v-btn
                            v-else
                            disabled
                            rounded
                            outlined
                            class="text-capitalize"
                          >
                            {{ $t('dashboard.evaluation_finished') }}
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" align="centered" class="py-10" :key="k" v-else>
            <h1 class="display-2 my-10 text-center" style="color: #90a4ae">
              {{ $t('dashboard.no_pending_activities') }}
            </h1>
        </v-col>
      </template>
    </template>
    <v-col cols="12" align="centered" class="py-10" v-else>
      <h1 class="display-2 my-10 text-center" style="color: #90a4ae">
        {{ $t('dashboard.no_pending_activities') }}
      </h1>
    </v-col>
    <v-dialog v-model="showDialog" v-if="showDialog" width="45em">
      <v-card>
        <v-toolbar dense class="mb-3">
          <v-toolbar-title class="text-capitalize center--text">{{ $t('dashboard.evaluators') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">{{$t('input.relation')}}</th>
                <th class="text-left">{{$t('dashboard.evaluators')}}</th>
                <th class="text-left">{{$t('dashboard.status')}}</th>
              </tr>
            </thead>
            <tbody>
              <template>
                <tr v-for="leader in evaluatedToDisplay.leadersEvaluators" :key="leader.employee._id">
                  <td>
                    {{$t('auth.leader')}}
                  </td>
                  <td>
                    {{ leader.employee.firstName }} {{ leader.employee.lastName }}
                  </td>
                  <td>
                    {{ leader.completed ? $t('dashboard.completed') : $t('dashboard.pending') }}
                  </td>
                </tr>
              </template>
              <template>
                <tr v-for="pair in evaluatedToDisplay.pairsEvaluators" :key="pair.employee._id">
                  <td>
                    {{$t('auth.pair')}}
                  </td>
                  <td>
                    {{ pair.employee.firstName }} {{ pair.employee.lastName }}
                  </td>
                  <td>
                    {{ pair.completed ? $t('dashboard.completed') : $t('dashboard.pending') }}
                  </td>
                </tr>
              </template>
              <template>
                <tr v-for="dependent in evaluatedToDisplay.dependentsEvaluators" :key="dependent.employee._id">
                  <td>
                    {{$t('auth.dependent')}}
                  </td>
                  <td>
                    {{ dependent.employee.firstName }} {{ dependent.employee.lastName }}
                  </td>
                  <td>
                    {{ dependent.completed ? $t('dashboard.completed') : $t('dashboard.pending') }}
                  </td>
                </tr>
              </template>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    info: Array
  },
  data () {
    return {
      showDialog: false,
      evaluatedToDisplay: null,
      i: 1
    }
  },
  methods: {
    showEvaluators (evaluators) {
      this.evaluatedToDisplay = evaluators
      this.showDialog = true
    },
    goFill (token) {
      return this.$store
        .dispatch('session/findHost', 'DML')
        .then((host) => {
          if (host && host.url) {
            location.replace(`${host.url}/outside/evaluations/dml/${token}`)
          }
        })
        .catch(err => (this.error = err.code))
    }
  }
})
</script>

<style lang="css" scoped>
.text-center{
  text-align: center !important;
}
</style>
