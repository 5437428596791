
<template>
  <v-container>
    <v-row justify="start">
      <v-col class="display-1">
        {{ $t('services.title') }}
        <small>{{ service.name }}</small>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>
        <v-card>
          <v-row class="mt-5">
            <v-col cols="12" class="text-xs-center text-sm-center text-md-center text-lg-center" style="padding: 0px;">
              <section class="mini-logo-detail enterprise-logo">
                <img :src="product.logo || product.defaultLogo || ''" height="200" width="200">
              </section>
            </v-col>
          </v-row>

          <v-form>
            <v-row justify="center" class="mt-5">
              <v-col cols="12" sm="4">
                <v-select
                  :label="$t('services.periocity')"
                  :items="periocity"
                  v-model="service.periocity"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  :label="$t('services.recomendation')"
                  outlined
                  v-model="service.recomendation"
                  type="number"
                  step="1"
                  min="1"
                  max="99999"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  :label="$t('services.price')"
                  outlined
                  v-model="service.tokenPrice"
                  type="number"
                  step="1"
                  min="1"
                  max="99999"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>

          <v-row>
            <v-col cols="12" sm="6">
              <v-btn
                block
                :to="`/settings/products`">
                {{ $t('input.cancel') }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <x-async-btn
                block
                class="white--text"
                :action="confirm"
              ><v-icon></v-icon>&nbsp;{{ $t('input.save') }}
              </x-async-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import productServiceService from '../../services/product-services'
import productsService from '../../services/products'

export default {
  data () {
    return {
      product: {},
      service: {},
      periocity: []
    }
  },
  created () {
    const servicesPeriocities = this.$t('services.periocities')
    for (const i in servicesPeriocities) {
      const value = i.charAt(0).toUpperCase() + i.slice(1)
      this.periocity.push({ value, text: servicesPeriocities[i] })
    }
    this.fetchService()
  },
  methods: {
    fetchService () {
      return productsService.lisWithServices()
        .then((res) => {
          this.product = res.filter(product => product.id === Number(this.$route.params.product_id))
          this.product = this.product[0]
          this.service = this.product.productServices.filter(service => service.id === Number(this.$route.params.service_id))
          this.service = this.service[0]
          this.service.periocity = this.service.periocity
        })
    },
    confirm () {
      return productServiceService.edit(this.$route.params.service_id, this.service)
        .then((res) => {
          this.hideModal()
          this.$store.dispatch('alert/success', this.$t('settings.products_edited'))
          this.$router.go(-1)
        })
    },
    hideModal () {
      this.$emit('close')
    }
  }
}
</script>
