
<template>
  <v-row wrap>
    <!-- <v-col md4 lg4>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            {{$t('dashboard.enterprise_left_table_title')}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row row wrap>
          <v-col xs6>
            <v-card-text class="text-center">
              <span class="info-data justify-center"> {{ info.employees }} </span>
              <br>
              <span>
                {{$t('dashboard.qty_employees')}}
              </span>
            </v-card-text>
          </v-col>
          <v-col xs6>
            <v-card-text class="text-center" v-if="info.plan.leftDays > 0">
              <span class="info-data justify-center"> {{ info.plan.leftDays }} </span>
              <br>
              <span>
                {{$t('dashboard.left_days')}}
              </span>
            </v-card-text>
            <v-card-text class="text-center expired-plan" v-else>
              <span class="info-data justify-center"> 0 </span>
              <br>
              <span>
                {{$t('dashboard.left_days')}}
                <small>{{$t('dashboard.expired_plan')}} </small>
              </span>
            </v-card-text>
          </v-col>
          <template v-if="info.plan.licenses">
              <v-col xs5>
                <span > {{$t('dashboard.init')}} </span> <span> {{ info.plan.licenses.startAt | date({date: true, hour:false}) }} </span>
              </v-col>
              <v-col xs7 class="text-xs-right" v-if="info.plan.leftDays > 0">
                <span> {{$t('dashboard.end')}} </span> <span class="padding-right-name"> {{ info.plan.licenses.endAt | date({date: true, hour:false}) }} </span>
              </v-col>
              <v-col xs7 class="text-xs-right expired-plan" v-else>
                <span> {{$t('dashboard.ended')}} </span> <span class="padding-right-name"> {{ info.plan.licenses.endAt | date({date: true, hour:false}) }} </span>
              </v-col>
          </template>
          <v-col xs12>
            <div class="text-xs-center text-center" v-if="info.plan.showUpdatePlan">
              <v-btn rounded color="primary" dark :to="`/enterprises/${user.id}/update-plan`">
                {{$t('dashboard.update_plan')}}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col md8 lg8>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            {{$t('dashboard.enterprise_right_table_title')}}
          </v-toolbar-title>
        </v-toolbar>
      </v-card>
      <v-card>
        <v-data-table
          :headers="$t('dashboard.enterprise_right_table_header')"
          :items="info.polls"
          :hide-actions="true"
          :rows-per-page-text="$t('results_per_pages')"
        >
          <template slot="items" slot-scope="props">
            <tr class="hidden-xs-only">
              <td class="text-xs-left padding-left-name px-0"> {{props.item.name}} </td>
              <td class="text-xs-center px-0"> {{props.item.isPulse ? 'Pulse' : 'Engagement'}} </td>
              <td class="text-xs-center px-0"> {{props.item.totalReceivers}} </td>
              <td class="text-xs-center px-0 text-success" v-if="props.item.status === 'completed'"> {{$t('dashboard.finished') }}</td>
              <td class="text-xs-center px-0 text-progress" v-else> {{$t('dashboard.progress') }} </td>
              <td class="text-xs-center px-0" v-if="props.item.isPulse">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" :to="'/pulse/'+props.item.id+'/show'">
                      <v-icon small>remove_red_eye</v-icon>
                    </v-btn>
                  </template>
                  <span> {{$t('dashboard.view_poll_details')}} </span>
                </v-tooltip>
              </td>
              <td class="text-xs-center px-0" v-else>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" :to="'/engagement/'+props.item.id+'/show'">
                      <v-icon small>remove_red_eye</v-icon>
                    </v-btn>
                  </template>
                  <span> {{$t('dashboard.view_poll_details')}} </span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:pageText="props">
            {{ props.pageStart }} - {{ props.pageStop }} {{ $t('page_text') }} {{ props.itemsLength }}
          </template>
        </v-data-table>
      </v-card>
    </v-col> -->
  </v-row>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    info: Object,
    user: Object
  },
  data () {
    return {

    }
  }
})
</script>

<style lang="css" scoped>
.text-center{
  text-align: center !important;
}
.info-data{
  font-size: 3em;
}
.minipercentage{
  font-size: 2em !important;
}
.padding-left-name{
  padding-left: .4em !important;
}
.expired-plan{
  color: red;
  font-weight: bold;
}
.padding-right-name{
  padding-right: .4em !important;
}
.text-success{
  color: #4caf50 !important
}
.text-error{
  color: #ff5252 !important
}
.text-progress{
  color: #ad7816 !important;
}
.no-polls{
  font-size: 1.8em;
  color: lightgray;
}
.padding-card{
  padding: 3em;
}
.no-color{
  color: #ffffff00;
}
.pulse {
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgb(56, 153, 218);
  animation: pulse 2s infinite;
}

.no-decoration{
  text-decoration: none;
}

.survey-title{
  font-size: 1.2em;
  font-weight: bold;
}
</style>
