<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h4 class="display-1">
          {{
            tab === 0
              ? $t('settings.terms_and_conditions_settings')
              : $t('settings.data_protection_policies_settings')
          }}
        </h4>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-tabs grow
            v-model="tab"
          >
            <v-tab>{{ $t('settings.terms_and_conditions')}}</v-tab>
            <v-tab>{{ $t('settings.data_protection_policies')}}</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <!-- Terms & Conditions -->
            <v-tab-item>
              <v-simple-table class="mt-7">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">{{ $t('settings.customer_type') }}</th>
                      <th class="text-center">{{ $t('settings.description') }}</th>
                      <th class="text-center">{{ $t('settings.actions') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="term in terms" :key="term.id">
                      <td class="text-center">{{ $t(`customers.${term.type}`) }}</td>
                      <td style="max-width: 30em">
                        <p v-html="`${((term || {}).description || '').substring(0, 400)}...`"/>
                      </td>
                      <td class="text-center">
                        <v-tooltip bottom color="success">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :to="`/settings/terms-and-conditions/${term.id}/show`"
                              v-on="on"
                              icon
                              color="success"
                            >
                              <v-icon small>fa-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('input.see_more') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom color="primary">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :to="`/settings/terms-and-conditions/${term.id}/edit`"
                              v-on="on"
                              icon
                              color="primary"
                            >
                              <v-icon small>fa-edit</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('input.edit') }}</span>
                        </v-tooltip>
                      </td>
                      <v-divider></v-divider>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>

            <!-- Data Protection Policies -->
            <v-tab-item>
              <v-simple-table class="mt-7">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">{{ $t('settings.customer_type') }}</th>
                      <th class="text-center">{{ $t('settings.description') }}</th>
                      <th class="text-center">{{ $t('settings.actions') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="policy in policies" :key="policy.id">
                      <td class="text-center">{{ $t(`customers.${policy.type}`) }}</td>
                      <td style="max-width: 30em">
                        <p v-html="`${((policy || {}).description || '').substring(0, 513)}...`"/>
                      </td>
                      <td class="text-center">
                        <v-tooltip bottom color="success">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :to="`/settings/data-protection-policies/${policy.id}/show`"
                              v-on="on"
                              icon
                              color="success"
                            >
                              <v-icon small>fa-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('input.see_more') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom color="primary">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :to="`/settings/data-protection-policies/${policy.id}/edit`"
                              v-on="on"
                              icon
                              color="primary"
                            >
                              <v-icon small>fa-edit</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('input.edit') }}</span>
                        </v-tooltip>
                      </td>
                      <v-divider></v-divider>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from 'vue'

import termsAndConditionsService from '../../services/terms-and-conditions'
import dataProtectionPoliciesService from '../../services/data-protection-policies'

export default Vue.extend({
  data () {
    return {
      tab: 0,
      policies: [],
      terms: []
    }
  },
  created () {
    this.getTerms()
    this.getPolicies()
  },
  methods: {
    getTerms () {
      return termsAndConditionsService.listActive()
        .then((res) => {
          this.terms = res
        })
    },
    getPolicies () {
      return dataProtectionPoliciesService.listActive()
        .then((res) => {
          this.policies = res
        })
    }
  }
})
</script>
