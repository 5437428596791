<template>
  <v-container>
    <x-loading
      :display="loading"
    ></x-loading>
    <template v-if="!loading">
      <v-row>
        <v-col>
          <v-card flat style="padding: 0 4em 0 4em">
          <v-row wrap row>
            <v-col cols="auto" class="mr-auto">
              <article class="mt-5 d-inline">
                <x-inputs-upload-image
                  v-if="isEditing"
                  :input="enterprise.logo"
                  @updateInput="($event) => enterprise.logo = $event"
                ></x-inputs-upload-image>
                <section class="mini-logo enterprise-logo" v-else>
                  <img :src="enterprise.logo || '/img/20200301_occ_solution_logo.png'" alt="Company Logo"/>
                </section>
              </article>
            </v-col>
            <v-col xs8 sm8>
            <v-list-item two-line justify="center" class="d-inline">
              <v-list-item-content>
                <v-list-item-title class="text-uppercase title">
                  {{ enterprise.name }}
                </v-list-item-title>
                <span>
                  {{ $t('enterprises.enterprise_information') }}
                </span>
              </v-list-item-content>
            </v-list-item>
            </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
        <v-row style="padding: 0 4em 0 4em">
          <v-col>
            <v-card flat>
              <v-toolbar flat dense color="primary">
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                    text
                    class="white--text"
                    @click="isEditing = !isEditing"
                  >
                    <v-icon small>edit</v-icon>
                    &nbsp;&nbsp;{{ $t('input.edit') }}
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text v-if="!isEditing">
                <v-row>
                  <v-col>
                    <v-list>
                      <v-list-item-content>
                        <span
                          class="font-weight-regular caption"
                        >{{ $t('enterprises.company_name') }}</span>
                        <span>{{ enterprise.name }}</span>
                      </v-list-item-content>
                    </v-list>
                  </v-col>
                  <v-col>
                    <v-list>
                      <v-list-item-content>
                        <span
                          class="font-weight-regular caption"
                        >{{ $t('input.sector') }}</span>
                        <span>{{ enterprise.sector | translate }}</span>
                      </v-list-item-content>
                    </v-list>
                  </v-col>
                  <v-col>
                    <v-list>
                      <v-list-item-content>
                        <span
                          class="font-weight-regular caption"
                        >{{ $t('input.country') }}</span>
                        <span>{{ enterprise.country | translate }}</span>
                      </v-list-item-content>
                    </v-list>
                  </v-col>
                  <v-col>
                    <v-list>
                      <v-list-item-content>
                        <span
                          class="font-weight-regular caption"
                        >{{ $t('input.headquarter') }}</span>
                        <span>{{ enterprise.headquarter }}</span>
                      </v-list-item-content>
                    </v-list>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-list>
                      <v-list-item-content>
                        <span
                          class="font-weight-regular caption"
                        >{{ $t('input.address') }}</span>
                        <span>{{ enterprise.address }}</span>
                      </v-list-item-content>
                    </v-list>
                  </v-col>
                  <v-col>
                    <v-list>
                      <v-list-item-content>
                        <span
                          class="font-weight-regular caption"
                        >{{ $t('input.enterprise_size') }}</span>
                        <span>{{ computedEnterpriseSize }}</span>
                      </v-list-item-content>
                    </v-list>
                  </v-col>
                  <v-col>
                    <v-list>
                      <v-list-item-content>
                        <span
                          class="font-weight-regular caption"
                        >{{ $t('input.employees_number') }}</span>
                        <span>
                          {{ enterprise.employeeNumber.min }} -
                          {{ enterprise.employeeNumber.max || '∞' }}
                        </span>
                      </v-list-item-content>
                    </v-list>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="11">
                    <v-text-field
                      :label="$t('input.invitation_url')"
                      :readonly="true"
                      outlined
                      v-model="tokenUrl"
                      :messages="$t('input.copy_invitation_url')"
                      id="tokenUrl"
                    >
                      <template v-slot:message="props">
                        <span class="body-2">{{ props.message }}</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-tooltip bottom color="black">
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="copyUrl" class="mt-2">
                          <v-icon>file_copy</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('input.invitation_url_copy') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else>
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(onSubmit)">
                    <v-row>
                      <v-col>
                        <x-inputs-input
                          :input="enterprise.name"
                          @updateInput="($event) => enterprise.name = $event"
                          :append-outer-icon="$t('help.icon')"
                          :label="$t('input.name')"
                          rules="required"
                          autofocus
                          light
                          name="name"
                          :help-message="$t('help.enterprise.create.name')"
                        ></x-inputs-input>
                      </v-col>
                      <v-col>
                        <x-inputs-autocomplete
                          :items="getSectors"
                          :input="enterprise.sectorId"
                          @updateInput="($event) => enterprise.sectorId = $event"
                          light
                          :label="$t('input.sector')"
                          name="sectorId"
                          :append-outer-icon="$t('help.icon')"
                          :help-message="$t('help.enterprise.create.sector')"
                          rules="required"
                        >
                        </x-inputs-autocomplete>
                      </v-col>
                      <v-col>
                        <x-inputs-autocomplete
                          :items="getCountries"
                          :input="enterprise.countryId"
                          @updateInput="($event) => enterprise.countryId = $event"
                          light
                          :label="$t('input.country')"
                          name="countryId"
                          :append-outer-icon="$t('help.icon')"
                          :help-message="$t('help.enterprise.create.country')"
                          rules="required"
                        >
                        </x-inputs-autocomplete>
                      </v-col>
                      <v-col>
                        <x-inputs-input
                          :input="enterprise.headquarter"
                          @updateInput="($event) => enterprise.headquarter = $event"
                          light
                          :label="$t('input.headquarter')"
                          name="headquarter"
                          :append-outer-icon="$t('help.icon')"
                          :help-message="$t('help.enterprise.create.headquarter')"
                          rules="required"
                        ></x-inputs-input>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <x-inputs-input
                          :input="enterprise.address"
                          @updateInput="($event) => enterprise.address = $event"
                          light
                          :label="$t('input.address')"
                          name="address"
                          :append-outer-icon="$t('help.icon')"
                          rules="required"
                          :help-message="$t('help.enterprise.create.address')"
                        ></x-inputs-input>
                      </v-col>
                      <v-col>
                        <x-inputs-autocomplete
                          :items="getEnterpriseSizes"
                          :input="enterprise.enterpriseSizeId"
                          @updateInput="($event) => enterprise.enterpriseSizeId = $event"
                          light
                          :label="$t('input.enterprise_size')"
                          name="enterpriseSizeId"
                          :append-outer-icon="$t('help.icon')"
                          :help-message="$t('help.enterprise.create.size')"
                          rules="required"
                          :on-change="changeEnterpriseSizes"
                          :persistent-hint="!(!enterpriseSizesDescription)"
                          :messages="enterpriseSizesDescription"
                        >
                        </x-inputs-autocomplete>
                      </v-col>
                      <v-col>
                        <x-inputs-autocomplete
                          :items="getEmployeesNumber"
                          :input="enterprise.employeesNumberId"
                          @updateInput="($event) => enterprise.employeesNumberId = $event"
                          light
                          :label="$t('input.employees_number')"
                          name="employeesNumberId"
                          :append-outer-icon="$t('help.icon')"
                          :help-message="$t('help.enterprise.create.employeesNumber')"
                          rules="required"
                        >
                        </x-inputs-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-btn
                        type="submit"
                        color="primary"
                        small
                        class="text-capitalize"
                      >
                        {{ $t('input.save') }}
                      </v-btn>
                    </v-row>
                  </form>
                </ValidationObserver>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import formatItems from '../../utils/form-format-items-list'
import resolver from '../../utils/resolver'

import authServices from '../../services/auth'
import enterpriseService from '../../services/enterprises'
import countriesService from '../../services/countries'
import sectorsService from '../../services/sectors'
import employeesNumberService from '../../services/employees-number'
import enterpriseSizesService from '../../services/enterprise-sizes'
import languagesService from '../../services/languages'

export default Vue.extend({
  data () {
    return {
      isEditing: false,
      loading: true,
      image: {
        src: '',
        name: ''
      },
      enterprise: {
        logo: null
      },
      sectors: [],
      countries: [],
      enterpriseSizes: [],
      employeesNumber: [],
      languages: [],
      enterpriseSizesDescription: '',
      tokenUrl: ''
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    getEnterpriseSizes () {
      if (!this.enterpriseSizes.length) return
      return formatItems(this.enterpriseSizes, item => {
        const eSize = item.translations.find(i => i.lang === this.user.lang)
        return {
          value: item.id,
          text: eSize.label,
          description: `${eSize.label}: ${eSize.description}`,
          translations: item.translations
        }
      }).sort((a, b) => b.text - a.text)
    },
    getSectors () {
      if (!this.sectors.length) return
      return formatItems(this.sectors, item => {
        const sector = item.translations.find(i => i.lang === this.user.lang)
        return {
          value: item.id,
          text: sector.label
        }
      }).sort((a, b) => b.text - a.text)
    },
    getCountries () {
      if (!this.countries.length) return []
      return formatItems(this.countries, item => {
        const country = item.translations.find(i => i.lang === this.user.lang)
        return {
          value: item.id,
          text: country.label
        }
      }).sort((a, b) => b.text - a.text)
    },
    getEmployeesNumber () {
      if (!this.employeesNumber) return
      return formatItems(this.employeesNumber, (item) => {
        const epNumber = item.translations.find(ep => ep.lang === this.user.lang)
        return {
          value: item.id,
          text: epNumber.label
        }
      }, false)
    },
    computedEnterpriseSize () {
      const found = this.enterpriseSizes.find(x => x.code === this.enterprise.enterpriseSize.code)
      const translation = found.translations.find(x => x.lang === this.user.lang)
      return translation.label
    }
  },
  watch: {
    '$i18n.locale': {
      handler () {
        this.getEnterprise()
      }
    },
    enterprise: {
      handler () {
        this.watchImage()
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    this.getEnterprise()
    resolver
      .all({
        countries: countriesService.list(),
        sectors: sectorsService.list(),
        employeesNumber: employeesNumberService.list(),
        enterpriseSizes: enterpriseSizesService.list(),
        languages: languagesService.list()
      })
      .then((res) => {
        this.countries = res.countries.items
        this.sectors = res.sectors.items
        this.employeesNumber = res.employeesNumber.items
        this.enterpriseSizes = res.enterpriseSizes.items
        this.languages = formatItems(res.languages.items)
        this.updateDataForm()
      })
  },
  methods: {
    watchImage () {
      if (this.enterprise.logo && typeof this.enterprise.logo === 'string') {
        this.image.src = this.enterprise.logo
        const logo = this.enterprise.logo.split('?')
        this.image.name = (logo[0].split('/').pop())
      } else if (this.enterprise.logo && typeof this.enterprise.logo === 'object') {
        this.image.name = this.enterprise.logo.name
      }
    },
    getEnterprise () {
      return enterpriseService.getOne(this.user.enterprise.id, this.user.lang)
        .then((res) => {
          this.enterprise = res
          this.enterprise.headquarter = this.enterprise.headquarter.name
          this.fetchEnterpriseSizes(res.enterpriseSize.code)
          this.setUrl()
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    fetchEnterpriseSizes (sizeCode) {
      return enterpriseSizesService.list()
        .then((res) => {
          this.enterprise.enterpriseSize = res.items.find((size) => {
            return size.code === sizeCode
          })
          this.loading = false
        })
    },
    updateDataForm () {
      if (this.enterprise.logo && typeof this.enterprise.logo === 'string') {
        this.image.src = this.enterprise.logo
        const logo = this.enterprise.logo.split('?')
        this.image.name = (logo[0].split('/').pop())
      } else if (this.enterprise.logo && typeof this.enterprise.logo === 'object') {
        this.image.name = this.enterprise.logo.name
      }
      if (this.enterprise.enterpriseSizeId) {
        this.changeEnterpriseSizes(this.enterprise.enterpriseSizeId)
      }
    },
    changeEnterpriseSizes () {
      const item = this.enterpriseSizes.find(item => item.value === this.enterprise.enterpriseSizeId)
      this.enterpriseSizesDescription = item ? item.description : ''
    },
    onSubmit () {
      this.loading = !this.loading
      return enterpriseService.edit(this.enterprise.id, this.enterprise)
        .then((res) => {
          this.user.enterprise = this.enterprise
          this.$store.dispatch('alert/success', this.$t('enterprises.enterprise_edited'))
          this.$store.dispatch('session/updateEnterprise', this.user)
            .then(() => this.$emit('switchf'))
          authServices.updatedInfo()
          this.isEditing = !this.isEditing
          this.loading = !this.loading
        })
        .catch((err) => {
          const error = this.$t(`errors.${err.code}`)
          this.$store.dispatch('alert/error', error)
          this.loading = !this.loading
        })
    },
    copyUrl () {
      let input = document.getElementById('tokenUrl')
      input.select()
      document.execCommand('copy')
      this.$store.dispatch('alert/success', this.$t('input.url_copied'))
    },
    setUrl () {
      const host = process.env.VUE_APP_SUITE_BASE_WEB + 'auth/verify-polls/'
      this.tokenUrl = host + this.enterprise.invitationCode
    }
  }
})
</script>
